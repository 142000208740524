import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HospitalServices } from "../services/HospitalServices";
export const fetchHospitalCaseStageCount = createAsyncThunk(
  "hospital/fetchCaseStageCount",
  async (hospitalId) => {
    const res = await HospitalServices.getHospitalStageCaseCount(hospitalId);
    return res.data;
  }
);
const HospitalSlice = createSlice({
  name: "hospital",
  initialState: {
    caseStageCounts: 0,
    error: null,
    status: "idle",
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHospitalCaseStageCount.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchHospitalCaseStageCount.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.caseStageCounts =
          action.payload.overallTotalCount.Initiate +
          action.payload.overallTotalCount.Pending +
          action.payload.overallTotalCount.Rejected;
        console.log("action data:", action.payload);
        console.log(
          "case status counts:",
          action.payload.overallTotalCount.Initiate +
            action.payload.overallTotalCount.Pending +
            action.payload.overallTotalCount.Rejected
        );
      })
      .addCase(fetchHospitalCaseStageCount.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        console.log("error:", action.error.message);
      });
  },
});

export default HospitalSlice.reducer;
