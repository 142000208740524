import { useState } from "react";
import UploadDocumentsHospitalPersona from "./UploadDocumentsHospitalPersona";
import ReUploadDocumentsHospitalPersona from "./ReUploadDocumentsHospitalPersona";
import ImagesGalleryHospitalPersona from "../imageGallery/ImageGalleryHospitalPersona";
import ApprovedIcon from "../../assets/approvedIcon.svg";
import InreviewIcon from "../../assets/inreviewIcon.svg";
import ReuploadIcon from "../../assets/reuploadIcon.svg";

const DocumentDetail = ({ document, getDate }) => {
  const [isGalleryOpen, setGalleryOpen] = useState(false);
  const getRejectedCount = () => {
    if (document.completeDocRejected) {
      return "Complete";
    }
    const filteredData = document?.documenturlmasters?.filter(
      (document) => document.status?.toLowerCase() === "rejected"
    );
    return filteredData?.length > 0
      ? filteredData.length + " Document Rejected"
      : "Add New Document";
  };
  return (
    <div
      style={{
        background: document?.status?.toLowerCase().includes("approved")
          ? "linear-gradient(90deg, #FFFFFF 2%, #B4FFCF 18.5%, #B4FFCF 81%, #FFFFFF 98%)"
          : document?.status?.toLowerCase().includes("rejected")
          ? "linear-gradient(90deg, #FFFFFF 2%, #FFA1A1 18.5%, #FEA2A2 81%, #FFFFFF 98%)"
          : document?.status?.toLowerCase().includes("inreview")
          ? "linear-gradient(90deg, #FFFFFF 2%, #EDFF8C 18.5%, #EDFF8C 81%, #FFFFFF 98%)"
          : "linear-gradient(90deg, #FFFFFF 2%, #D9D9D9 18.5%, #D9D9D9 81%, #FFFFFF 98%)",
      }}
      className="flex items-center text-[#1B1B1B] text-[12px] 2xl:text-sm font-medium  leading-[18px] -tracking-[3%] py-2 first:mt-0 mt-2"
    >
      <div className="w-[60%] font-normal pl-2">{document?.documentName}</div>

      <div className="w-[20%] flex justify-end items-center uppercase px-4">
        {document?.status?.toLowerCase() === "rejected" ? (
          <div>{getRejectedCount()}</div>
        ) : document?.documenturlmasters?.length > 0 ? (
          <div>Pages:{document?.documenturlmasters?.length}</div>
        ) : (
          ""
        )}
      </div>
      <div className="w-[10%] ">{getDate(document?.createdAt)}</div>
      <div
        className={` w-[5%] uppercase ${
          document?.status?.toLowerCase().includes("approved")
            ? "text-[#4AB571]"
            : document?.status?.toLowerCase().includes("rejected")
            ? "text-[#B53030]"
            : "text-[#0B0B0B]"
        }`}
      >
        {document?.status}
      </div>
      <div className="w-[5%] flex justify-center">
        {document?.status?.toLowerCase().includes("approved") ? (
          <img src={ApprovedIcon} alt="approved-icon" />
        ) : document?.status?.toLowerCase().includes("inreview") ? (
          <img
            src={InreviewIcon}
            alt="in-review-icon"
            onClick={() => setGalleryOpen(true)}
            className="cursor-pointer"
          />
        ) : document?.status?.toLowerCase().includes("rejected") ? (
          <ReUploadDocumentsHospitalPersona documentDetails={document} />
        ) : (
          <UploadDocumentsHospitalPersona documentDetails={document} />
        )}
      </div>
      {isGalleryOpen && (
        <ImagesGalleryHospitalPersona
          data={document?.documenturlmasters}
          setOpen={setGalleryOpen}
          view={true}
        />
      )}
    </div>
  );
};

export default DocumentDetail;
