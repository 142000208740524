import { useState, useEffect } from "react";
import { CaseServices } from "../../../services/CaseServices";
import AddEnhancementForm from "./AddEnhancementForm";
import AddIcon from "../../../assets/addHospital.svg";
import { logOut } from "../../../config/user";

const AddEnhancement = ({ caseDetails, setValue }) => {
  const [addEnhancement, setAddEnhancement] = useState(false);
  const [caseProcedures, setCaseProcedures] = useState([]);
  const [enhancementList, setEnhancementList] = useState([]);

  const daysOptions = {
    primary: [
      { value: "1", label: "1" },
      { value: "2", label: "2" },
    ],
    secondary: [
      { value: "1", label: "1" },
      { value: "2", label: "2" },
      { value: "3", label: "3" },
    ],
    tertiary: [
      { value: "1", label: "1" },
      { value: "2", label: "2" },
      { value: "3", label: "3" },
      { value: "4", label: "4" },
      { value: "5", label: "5" },
    ],
  };
  const wardOptions = [
    { value: "ICU with ventilator", label: "ICU with ventilator" },
    { value: "ICU without ventilator", label: "ICU without ventilator" },
    { value: "HDU", label: "HDU" },
    { value: "General Ward", label: "General Ward" },
    { value: "SNCU/NICU", label: "SNCU/NICU" },
  ];
  const getEnhancementList = async () => {
    try {
      const response = await CaseServices.getEnhancementsList(caseDetails.id);
      setEnhancementList(response.data.enhancements);
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
  };
  const getCaseProcedures = async () => {
    try {
      const response = await CaseServices.getCaseProcedures(caseDetails.id);
      setCaseProcedures(response.data.caseProcedures);
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
  };
  useEffect(() => {
    getEnhancementList();
    getCaseProcedures();
  }, []);
  console.log("enhancement list:", enhancementList);
  return (
    <div>
      <div className="flex flex-col gap-2 xl:flex-row  xl:justify-between xl:items-center">
        <div className="text-2xl font-semibold text-[#191919]">Enhancement</div>
        <div
          style={{ border: "1px solid #017C57" }}
          className="text-sm font-semibold text-[#017C57] flex items-center gap-1 py-2 px-4 rounded-full cursor-pointer"
          onClick={() => setAddEnhancement(true)}
        >
          <img src={AddIcon} alt="add-more-icon" />{" "}
          {enhancementList.length > 0
            ? "ADD ANOTHER ENHANCEMENT"
            : "Add Enhancement"}
        </div>
      </div>
      <div
        style={{
          border: "1px solid #E4E4E4",
          marginTop: "24px",
          marginBottom: "40px",
        }}
      />
      {addEnhancement && (
        <AddEnhancementForm
          daysOptions={daysOptions}
          wardOptions={wardOptions}
          caseProcedures={caseProcedures}
          totalPackageCost={caseDetails.totalPackageCost}
          setAddEnhancement={setAddEnhancement}
          caseId={caseDetails.id}
          startDate={
            enhancementList.length > 0
              ? enhancementList[0]?.details.toDate
              : caseDetails?.admissionDate
          }
          enhancementNumber={enhancementList.length + 1}
          setValue={setValue}
          caseDetails={caseDetails}
        />
      )}
      {enhancementList.length > 0
        ? enhancementList.map((enhancement, idx) => {
            return (
              <div className="mb-10 ">
                <div>
                  <div className="flex justify-between">
                    <div className="font-semibold text-[#191919]">
                      Enhancement {enhancementList.length - idx}
                    </div>
                  </div>
                  <div className="flex justify-between flex-wrap gap-y-8  mt-8">
                    <div className="w-full xl:w-[48%]">
                      <div className="text-sm text-[#191919] font-medium mb-4">
                        1. For how many days would you like to initiate the
                        enhancement?
                      </div>
                      <input
                        type="text"
                        disabled
                        value={enhancement.details.enhancementDays}
                        className="w-full text-[#BCBCBC] rounded-[8px] px-[12px] py-[10px]"
                      />
                    </div>
                    <div className="w-full xl:w-[48%]">
                      <div className="text-sm text-[#191919] font-medium mb-4">
                        2. From which date to which date should the enhancement
                        last?
                      </div>
                      <div className="grid grid-cols-2 gap-2">
                        <input
                          type="text"
                          placeholder="--/--/---"
                          disabled
                          value={enhancement.details.fromDate}
                          style={{ border: "1px solid #E4E4E4" }}
                          className="text-[#BCBCBC] rounded-[8px] py-[10px] px-2"
                        />
                        <input
                          type="text"
                          placeholder="--/--/---"
                          disabled
                          value={enhancement.details.toDate}
                          style={{ border: "1px solid #E4E4E4" }}
                          className="text-[#BCBCBC] rounded-[8px] py-[10px] px-2"
                        />
                      </div>
                    </div>
                    <div className="w-full xl:w-[48%]">
                      <div className="text-sm text-[#191919] font-medium mb-4">
                        3 .In which ward would you like to take the enhancement?
                      </div>
                      <input
                        type="text"
                        disabled
                        value={enhancement.details.wardType}
                        className="w-full text-[#BCBCBC] rounded-[8px] px-[12px] py-[10px]"
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        : !addEnhancement && (
            <div className="text-center text-xl font-semibold">
              No Enhancemets Done Yet. Please add Enhancement.
            </div>
          )}
    </div>
  );
};

export default AddEnhancement;
