import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Formik, Form, Field } from "formik";
import { object, string, date } from "yup";
import { CaseServices } from "../../../services/CaseServices";
import CloseIcon from "../../../assets/hospitalCloseIcon.svg";
import { CircularProgress } from "@mui/material";
import { logOut } from "../../../config/user";
import RightArrow from "../../../assets/rightArrowWhite.svg";

const errorValidation = object({
  status: string().required("Required Field"),
  statustime: date().required("Required Field"),
  // biometric: string().required("Required Field"),
});

const DischargeFormHospitalPersona = ({
  caseId,
  setAddDischarge,
  AllCases,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dischargeDetails, setDischargeDetails] = useState(null);
  const [initialValues, setInitialValues] = useState({
    status: "",
    statustime: "",
    // biometric: "",
  });
  const dischargeOptions = [
    { value: "Death", label: "Death" },
    { value: "Discharge", label: "Discharge" },
    { value: "LAMA", label: "LAMA" },
    { value: "REFER", label: "REFER" },
  ];

  const biometricOptions = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];

  const handleSubmit = async (values) => {
    const data = [
      {
        detailType: "DISCHARGE",
        caseId: caseId,
        details: {
          dischargedDate: new Date(),
          status: values.status,
          statustime: values.statustime,
          biometric: values.biometric,
        },
        status: 1,
        version: 0,
      },
    ];
    try {
      setIsSubmitting(true);
      const response = await CaseServices.addEnhancement(data);
      await AllCases();
      setAddDischarge(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const getDischarge = async () => {
    try {
      const response = await CaseServices.getDischarge(caseId);
      setDischargeDetails(response?.data?.dischargeDetails?.details);
    } catch (error) {
      window.alert(error);
    }
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    getDischarge();
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  useEffect(() => {
    if (dischargeDetails) {
      setInitialValues(dischargeDetails);
    } else {
      // Get current UTC time and convert it to local time
      const now = new Date();
      const formattedDateTime = now.toISOString().slice(0, 16); // Format for datetime-local
      setInitialValues((prevValues) => ({
        ...prevValues,
        statustime: formattedDateTime,
      }));
    }
  }, [dischargeDetails]);

  return (
    <div className="fixed top-0 left-0 w-screen h-screen flex justify-center items-center bg-[#017C7180]">
      <div className="relative h-fit w-[50%] bg-white p-[30px] rounded-[30px]">
        <div
          className="absolute -top-14 -right-14 cursor-pointer"
          onClick={() => setAddDischarge(false)}
        >
          <img src={CloseIcon} alt="close-icon" />
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={errorValidation}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({ errors, touched, setFieldValue, values }) => {
            const now = new Date();
            const formattedDateTime = now.toISOString().slice(0, 16); // Format for datetime-local

            return (
              <Form>
                <div className="flex justify-between items-center">
                  <div className="text-[40px] font-medium text-black -tracking-[7%]">
                    Discharge
                  </div>
                </div>
                <div
                  style={{
                    borderTop: "1px solid #969696",
                    marginBlock: "25px",
                  }}
                />
                <div className="flex items-end">
                  <div className="w-[70%] flex flex-wrap gap-y-10 ">
                    <div className="w-full">
                      <div className="text-sm text-[#0B0B0B] leading-[21.7px] -tracking-[3%] mb-4">
                        1. Please select Death/Discharge
                      </div>

                      <Select
                        name="status"
                        placeholder="Death/Discharge"
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderRadius: "100px",
                          }),
                        }}
                        options={dischargeOptions}
                        value={dischargeOptions.filter(
                          (option) => option.value === values.status
                        )}
                        onChange={(item) => setFieldValue("status", item.value)}
                        isDisabled={values.status ? true : false} // Disable the select component
                      />
                      {touched.status && errors.status && (
                        <div className="field-error">{errors.status}</div>
                      )}
                    </div>
                    <div className="w-full ">
                      {" "}
                      <div className="text-sm text-[#0B0B0B] leading-[21.7px] -tracking-[3%] mb-4">
                        {" "}
                        2. Please select the date & time for Death/Discharge
                      </div>
                      <Field
                        type="datetime-local"
                        name="statustime"
                        style={{ border: "1px solid #E4E4E4" }}
                        className="rounded-full py-[10px] px-2 w-full"
                        // value={values.statustime}
                        // disabled={values.statustime === "" ? false : true}
                      />
                      {touched.statustime && errors.statustime && (
                        <div className="field-error">{errors.statustime}</div>
                      )}
                    </div>
                  </div>
                  <div className="w-[30%] flex justify-end">
                    {isSubmitting ? (
                      <div className="flex justify-center">
                        <CircularProgress />
                      </div>
                    ) : (
                      <button
                        type="submit"
                        style={{ border: "1px solid #017C71" }}
                        className="w-fit flex items-center gap-2 bg-[#017C71] text-white rounded-full mt-12 px-5 py-4 border-0 cursor-pointer uppercase"
                      >
                        SAVE DETAILS <img src={RightArrow} alt="" />
                      </button>
                    )}
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default DischargeFormHospitalPersona;
