import { combineReducers, configureStore } from "@reduxjs/toolkit";
import RoleReducer from "./roleSlice";
import CaseDetailsReducer from "./caseDetailsSlice";
import holdHospitalReducer from "./holdHospitalSlice";
import NotificationReducer from "./notificationSlice";
import HospitalDetailsReducer from "./hospitalDetailsSlice";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  currentRole: RoleReducer,
  caseDetails: CaseDetailsReducer,
  holdHospital: holdHospitalReducer,
  notificationReducer: NotificationReducer,
  hospitalDetailsReducer: HospitalDetailsReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Create Persistor
export const persistedStore = persistStore(store);
