import { useState, useEffect } from "react";
import LandingPageHeader from "./LandingPageHeader";
import Challenges from "./Challenges";
import Solutions from "./Solutions";
import Roadmap from "./Roadmap";
const LandingPage = () => {
  return (
    <div>
      <LandingPageHeader />
      <Challenges />
      <Solutions />
      <Roadmap />
    </div>
  );
};

export default LandingPage;
