import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import CaseStatusSelectedIcon from "../../assets/caseStatusWhite.svg";
import CaseStatusIcon from "../../assets/caseStatusBlack.svg";
import AddDocumentSelectedIcon from "../../assets/addDocumentsWhite.svg";
import AddDocumentIcon from "../../assets/addDocumentsBlack.svg";
import ReportIcon from "../../assets/reportBlack.svg";
import ProfileSelectedIcon from "../../assets/profileWhite.svg";
import ProfileIcon from "../../assets/profileBlack.svg";
import CallSelectedIcon from "../../assets/callWhite.svg";
import CallIcon from "../../assets/call filled.svg";
import NotificationSelectedIcon from "../../assets/notificationWhite.svg";
import NotificationIcon from "../../assets/notificationBlack.svg";
import { fetchHospitalCaseStageCount } from "../../redux/hospitalDetailsSlice";
import { useSelector, useDispatch } from "react-redux";
import { getUser } from "../../config/user";
const HospitalSidebar = () => {
  const [selectedNavigation, setSelectedNvigation] = useState("case status");
  const dispatch = useDispatch();
  const location = useLocation();
  const { notificationCount } = useSelector(
    (store) => store.notificationReducer
  );
  const { caseStageCounts } = useSelector(
    (store) => store.hospitalDetailsReducer
  );
  const user = getUser();
  useEffect(() => {
    dispatch(fetchHospitalCaseStageCount(user?.hospital?.id));
  }, [dispatch]);
  return (
    <div className="pt-[42px] 2xl:pt-14">
      <div className="text-[24px] 2xl:text-[30px] capitalize font-medium leading-[39.16px] -tracking-[2px]">
        {selectedNavigation}
      </div>
      <div
        style={{ background: "rgba(255,255,255,0.5)" }}
        className="text-sm leading-[15px] -tracking-[0.5px] mt-[20px] 2xl:mt-[30px] p-[7px] rounded-[30px] "
      >
        <Link to={"/dashboard/case-status"} className="no-underline ">
          {" "}
          <div
            className={`flex items-center gap-[10px] transition-all duration-300 ease-in-out ${
              location.pathname.includes("case-status")
                ? "text-white bg-[#1B1B1B]"
                : "text-black bg-white"
            }  p-[12px] 2xl:p-[18px] rounded-full cursor-pointer`}
            onClick={() => setSelectedNvigation("case status")}
          >
            <div>
              {location.pathname.includes("case-status") ? (
                <div>
                  {" "}
                  <img
                    src={CaseStatusSelectedIcon}
                    alt=""
                    width={15}
                    height={15}
                  />
                </div>
              ) : (
                <img src={CaseStatusIcon} alt="" width={15} height={15} />
              )}
            </div>
            <div className="flex w-[90%] justify-between items-center text-[12px] 2xl:text-[16px] uppercase">
              Case Status{" "}
              <div
                style={{ border: "2px solid #FFFFFF" }}
                className=" w-[20px] 2xl:w-[25px] h-[20px] 2xl:h-[25px] flex justify-center items-center -top-1 -right-2  text-[9px] 2xl:text-[10px]  text-white text-center  bg-red-500 rounded-full "
              >
                {caseStageCounts > 99 ? "99+" : caseStageCounts}
              </div>
            </div>
          </div>
        </Link>
        {/* <div
          className={`flex gap-[10px] ${
            location.pathname.includes("add documents")
              ? "text-white bg-[#1B1B1B]"
              : "text-black bg-white"
          }  p-[12px] 2xl:p-[18px] mt-[5px] rounded-full cursor-pointer`}
          onClick={() => setSelectedNvigation("add documents")}
        >
          {" "}
          <div>
            {selectedNavigation.includes("add documents") ? (
              <div>
                {" "}
                <img
                  src={AddDocumentSelectedIcon}
                  alt=""
                  width={15}
                  height={15}
                />
              </div>
            ) : (
              <img src={AddDocumentIcon} alt="" width={15} height={15} />
            )}
          </div>
          <div className=" font-lufga  uppercase"> Add Documents</div>
        </div> */}
        {/* <div
          className={`flex gap-[10px] ${
            location.pathname.includes("report")
              ? "text-white bg-[#1B1B1B]"
              : "text-black bg-white"
          }  p-[12px] 2xl:p-[18px] mt-[5px] rounded-full cursor-pointer`}
          onClick={() => setSelectedNvigation("report")}
        >
          {" "}
          <div>
            <img src={ReportIcon} alt="" width={15} height={15} />
          </div>
          <div className=" font-lufga  uppercase">Report</div>
        </div>
        <div
          className={`flex gap-[10px] ${
            location.pathname.includes("profile")
              ? "text-white bg-[#1B1B1B]"
              : "text-black bg-white"
          }  p-[12px] 2xl:p-[18px] mt-[5px] rounded-full cursor-pointer`}
          onClick={() => setSelectedNvigation("profile")}
        >
          {" "}
          <div>
            {selectedNavigation.includes("profile") ? (
              <div>
                {" "}
                <img src={ProfileSelectedIcon} alt="" width={15} height={15} />
              </div>
            ) : (
              <img src={ProfileIcon} alt="" width={15} height={15} />
            )}
          </div>
          <div className=" font-lufga  uppercase">Profile</div>
        </div>
        <div
          className={`flex gap-[10px] ${
            location.pathname.includes("call history")
              ? "text-white bg-[#1B1B1B]"
              : "text-black bg-white"
          }  p-[12px] 2xl:p-[18px] mt-[5px] rounded-full cursor-pointer`}
          onClick={() => setSelectedNvigation("call history")}
        >
          {" "}
          <div>
            {selectedNavigation.includes("call history") ? (
              <div>
                {" "}
                <img src={CallSelectedIcon} alt="" width={15} height={15} />
              </div>
            ) : (
              <img src={CallIcon} alt="" width={15} height={15} />
            )}
          </div>
          <div className=" font-lufga  uppercase">Call History</div>
        </div> */}
        {user?.roles[0]?.role?.roleId === 1 && (
          <Link
            to={"/dashboard/sub-admin"}
            className=" font-lufga  no-underline "
          >
            {" "}
            <div
              className={`flex gap-[10px] transition-all duration-300 ease-in-out ${
                location.pathname.includes("sub-admin")
                  ? "text-white bg-[#1B1B1B]"
                  : "text-black bg-white"
              }  p-[12px] 2xl:p-[18px] mt-[5px] rounded-full cursor-pointer`}
              onClick={() => setSelectedNvigation("sub-admin")}
            >
              {" "}
              <div>
                {location.pathname.includes("sub-admin") ? (
                  <div>
                    {" "}
                    <img
                      src={ProfileSelectedIcon}
                      alt=""
                      width={15}
                      height={15}
                    />
                  </div>
                ) : (
                  <img src={ProfileIcon} alt="" width={15} height={15} />
                )}
              </div>
              <div className="text-[12px] 2xl:text-[16px] uppercase">
                Sub Admin
              </div>
            </div>
          </Link>
        )}
        <Link to={"/dashboard/notifications"} className="no-underline ">
          {" "}
          <div
            className={`flex items-center gap-[10px] transition-all duration-300 ease-in-out ${
              location.pathname.includes("notifications")
                ? "text-white bg-[#1B1B1B]"
                : "text-black bg-white"
            }  p-[10px] 2xl:p-[16px] mt-[5px] rounded-full cursor-pointer`}
            onClick={() => setSelectedNvigation("notifications")}
          >
            {" "}
            <div>
              {location.pathname.includes("notifications") ? (
                <div>
                  {" "}
                  <img
                    src={ProfileSelectedIcon}
                    alt=""
                    width={15}
                    height={15}
                  />
                </div>
              ) : (
                <img src={ProfileIcon} alt="" width={15} height={15} />
              )}
            </div>
            <div className="flex w-[90%] justify-between items-center text-[12px] 2xl:text-[16px] uppercase">
              Notifications{" "}
              <div
                style={{ border: "2px solid #FFFFFF" }}
                className=" w-[20px] 2xl:w-[25px] h-[20px] 2xl:h-[25px] flex justify-center items-center -top-1 -right-2  text-[9px] 2xl:text-[10px]  text-white text-center  bg-red-500 rounded-full "
              >
                {notificationCount}
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default HospitalSidebar;
