import { useState, useEffect, useRef } from "react";
import NotificationImage from "../assets/notificationImage.png";
import SubNotificationImage from "../assets/subNotificationImage.png";
import { NotificationServices } from "../services/NotificationServices";
import { fetchNotificationCount } from "../redux/notificationSlice";
import { CircularProgress } from "@mui/material";
import KeyboardDoubleArrowRightOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowRightOutlined";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
const NotificationPopUp = ({ setOpen, parentRef, notificationCategory }) => {
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [notificationData, setNotificationData] = useState(null);
  const [readStatus, setReadStatus] = useState("all");
  const [limit, setLimit] = useState(100);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const ref = useRef();
  const navigate = useNavigate();
  const getTime = (createdDate) => {
    const currentDate = new Date();
    const diffInMs = currentDate - new Date(createdDate);

    const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (diffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diffInMs % (1000 * 60)) / 1000);

    return `${days > 0 ? days + "D " : ""}${hours > 0 ? hours + "H " : ""} ${
      minutes > 0 ? minutes + "M" : ""
    } ${seconds}S`;
  };
  const markNotificationStatus = async (notificationId, status) => {
    try {
      const response = await NotificationServices.markNotificationReadUnread(
        [notificationId],
        status
      );
      dispatch(fetchNotificationCount());
    } catch (error) {
      window.alert(error);
    }
  };
  const markedAllReadUnread = async () => {
    try {
      const response =
        await NotificationServices.markAllNotificationsReadUnread(true);
      dispatch(fetchNotificationCount());
      setOpen(false);
      window.alert("Notifications Updated Successfully");
    } catch (error) {
      window.alert(error);
    }
  };
  const getUserNotification = async () => {
    setLoading(true);
    try {
      const response = await NotificationServices.getNotifications(
        readStatus,
        limit,
        1,
        notificationCategory
      );
      setNotificationData(response?.data?.UserNotifcations);
    } catch (error) {
      window.alert(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getUserNotification();

    const checkIfClickedOutside = (e) => {
      if (parentRef.current && parentRef.current.contains(e.target)) {
        setOpen(true);
      } else if (ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("click", checkIfClickedOutside);
    document.body.style.overflow = "hidden";
    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
      document.body.style.overflow = "auto";
    };
  }, []);
  useEffect(() => {
    getUserNotification();
  }, [readStatus]);
  return (
    <div
      style={{ border: "1px solid #E4E4E4" }}
      className="absolute top-[220%] -left-[95px] w-[400px] rounded-[8px] bg-white p-2 z-20"
    >
      <div className="flex justify-end gap-2 text-xs text-white mb-2">
        <div
          style={{ border: "1px solid #017C57" }}
          className={`${
            readStatus === "mark all read"
              ? "text-white bg-[#017C57]"
              : "text-[#017C57] bg-white"
          } px-5 py-2 rounded-full`}
          onClick={() => {
            markedAllReadUnread();
            dispatch(fetchNotificationCount());
            getUserNotification();
          }}
        >
          Mark All READ
        </div>
        <div
          style={{ border: "1px solid #017C57" }}
          className={`${
            readStatus === true
              ? "text-white bg-[#017C57]"
              : "text-[#017C57] bg-white"
          } px-5 py-2 rounded-full`}
          onClick={() => setReadStatus(true)}
        >
          READ
        </div>
        <div
          style={{ border: "1px solid #017C57" }}
          className={`${
            readStatus === false
              ? "text-white bg-[#017C57]"
              : "text-[#017C57] bg-white"
          } px-5 py-2 rounded-full`}
          onClick={() => setReadStatus(false)}
        >
          UNREAD
        </div>
        <div
          style={{ border: "1px solid #017C57" }}
          className={`${
            readStatus === "all"
              ? "text-white bg-[#017C57]"
              : "text-[#017C57] bg-white"
          } px-5 py-2 rounded-full`}
          onClick={() => setReadStatus("all")}
        >
          ALL
        </div>
      </div>
      <div ref={ref}>
        {isLoading ? (
          <div>
            <CircularProgress />{" "}
          </div>
        ) : (
          <div className="max-h-[400px] overflow-y-scroll">
            {notificationData?.map((notification, idx) => {
              return (
                <div key={idx}>
                  <div
                    className={`flex items-start gap-[10px] p-2 mb-1 rounded-[12px] ${
                      notification?.read ? "bg-white" : "bg-neutral-100"
                    }`}
                  >
                    <img src={NotificationImage} alt="notification-image" />
                    <div
                      className="w-full"
                      onClick={() => {
                        setSelectedNotification(idx);
                        if (!notification.read) {
                          markNotificationStatus(notification?.id, "true");
                        }
                      }}
                    >
                      <div
                        className={`flex justify-between gap-1  w-full text-sm text-[${
                          notification.color ? notification.color : "#191919"
                        }] text-start ${
                          notification?.read ? "font-medium" : "font-semibold"
                        } tracking-tight`}
                      >
                        {notification?.title}
                        <div
                          onClick={() =>
                            navigate(
                              `/Dashboard/EditCases/?caseId=${notification.caseId}`,
                              {
                                state: { caseId: notification.caseId },
                              }
                            )
                          }
                        >
                          <KeyboardDoubleArrowRightOutlinedIcon />
                        </div>
                      </div>
                      <div className="text-sm text-[#797979] text-start mt-1 tracking-tight">
                        {getTime(notification?.createdAt)} -
                        {` (${notification?.createdAt.slice(0, 10)})`}
                      </div>
                    </div>
                  </div>
                  {selectedNotification === idx && (
                    <div className="flex gap-[10px]">
                      {/* <div className="w-8 h-8" /> */}

                      <div className="flex items-start gap-4 p-2">
                        <img
                          src={SubNotificationImage}
                          alt="notification-image"
                        />
                        <div className="text-start">
                          {/* <div className="text-sm text-[#191919] font-medium tracking-tight">
                              Details
                            </div> */}
                          <div className="text-sm text-[#797979] text-start mt-1 tracking-tight">
                            {notification?.body}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationPopUp;
