import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import Modal from "@mui/material/Modal";
import { docUploadRefreshContext } from "../contexts/CaseContexts";
import { CircularProgress } from "@mui/material";
import { AuthServices } from "../../../services/AuthServices";
import { CaseServices } from "../../../services/CaseServices";
import { logOut } from "../../../config/user";
import "./uploadDocumentModal.scss";

import DownloadIcon from "../../../assets/downloadIconGreen.svg";

import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export default function UploadDocumentModal({
  documentDetails,
  setDocumentDetails,
  sequencePrev,
  completeDocReupload,
}) {
  const refreshFunction = useContext(docUploadRefreshContext);
  const [pdfDocument, setPdfDocument] = useState(null);
  // const [documentName, setDocumentName] = useState(null);
  const [images, setImages] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [open, setOpen] = useState(false);
  const [docUrl, setDocUrl] = useState("");
  const [percentage, setPercentage] = useState(0);
  const [uploadStatus, setUploadStatus] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [documentsUrl, setDocumentsUrl] = useState([]);
  const [selectAll, setSelectAll] = useState(true);
  const fileInputRef = React.useRef(null);
  const [selectedCount, setSelectedCount] = useState(0);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  function checkTransparency(canvas) {
    const ctx = canvas.getContext("2d");
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const data = imageData.data;

    // Loop through all pixels
    for (let i = 0; i < data.length; i += 4) {
      // alpha channel is the 4th byte in each pixel (data[i+3])
      const alpha = data[i + 3];

      // Check if alpha is less than 255 (fully opaque)
      if (alpha < 255) {
        console.log("Transparency detected!");
        return true; // Transparency detected
      }
    }

    console.log("No transparency detected.");
    return false; // No transparency
  }
  //Method to upload the pdf doc
  async function uploadDocument(event) {
    setUploadStatus(true);
    try {
      const file = event.target.files[0];
      const fileType = file.type.split("/")[0];
      if (fileType === "application" && file.name.endsWith(".pdf")) {
        // setDocumentName(event.target.files[0].name.slice(0, -4));
        const uri = URL.createObjectURL(file);
        const _PDF_DOC = await pdfjs.getDocument(uri).promise;
        setPdfDocument(_PDF_DOC);
      } else if (fileType === "image") {
        const imageBlob = URL.createObjectURL(file);
        setImages([...images, { isSelected: true, documentUrl: imageBlob }]);
        setSelectedCount((prev) => prev + 1);
        setUploadStatus(false);
      }
    } catch (error) {
      alert(error.message);
    }
  }

  //Method to get the image list from the uploaded pdf document
  async function getImageList() {
    const imagesList = [...images];
    const canvas = document.createElement("canvas");
    canvas.setAttribute("class", "canv");
    try {
      for (let i = 1; i <= pdfDocument.numPages; i++) {
        const page = await pdfDocument.getPage(i);
        const viewport = page.getViewport({ scale: 1.5 });
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        const renderContext = {
          canvasContext: canvas.getContext("2d"),
          viewport: viewport,
        };
        let transparency = false;
        // await page.render(renderContext).promise;
        await page.render(renderContext).promise.then(() => {
          // Now you can check for transparency here
          transparency = checkTransparency(canvas);
        });
        canvas.toBlob(
          (blob) => {
            if (blob) {
              const blobUrl = URL.createObjectURL(blob);
              imagesList.push({ isSelected: true, documentUrl: blobUrl });
              setSelectedCount((prev) => prev + 1);
              if (i === pdfDocument.numPages) {
                setImages([...imagesList]);
              }
            }
          },
          transparency ? "image/png" : "image/jpeg"
        );
      }
    } catch (error) {
      console.error("Error getting image list:", error);
    } finally {
    }
    setUploadStatus(false);
  }

  //method to save the image url to settlemed database
  const saveUrl = async (docUrl) => {
    let postData = [];
    let sequence = sequencePrev ? sequencePrev : 0;
    for (let doc of docUrl) {
      sequence++;
      postData.push({
        documentUrl: doc,
        sequence: sequence,
        urlId: "",
      });
    }
    try {
      await CaseServices.saveDocumentUrl(
        documentDetails.id,
        postData,
        completeDocReupload
      );
      window.alert(`${documentDetails?.documentName} Uploaded Successfully`);
      refreshFunction.getMappedDocuments();
      handleClose();
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    } finally {
      setIsSubmitting(false);
    }
  };
  //Method to upload the image list's blob data to s3 bucket
  const uploadImages = async () => {
    setIsSubmitting(true);
    let data = new FormData();
    for (let index = 0; index < images.length; index++) {
      if (images[index].isSelected) {
        let image = images[index].documentUrl;
        if (!(image instanceof Blob)) {
          const response = await fetch(image);
          image = await response.blob();
        }
        data.append(
          "images",
          image,
          image.type === "image/png"
            ? `${documentDetails?.documentName + "_" + index}.png`
            : `${documentDetails?.documentName + "_" + index}.jpeg`
        );
      }
    }

    try {
      let res = await AuthServices.uploadPdfToImage(data, setPercentage);
      console.log("Image data resonse from apis", res.data.urls);
      if (res?.data?.urls) {
        saveUrl(res.data.urls);
      }
      // setDocumentName(null);
      setPdfDocument(null);
      setImages([]);
    } catch (error) {
      console.error("Upload failed:", error);
    }
  };

  const handleSelection = (index) => {
    const modifiedData = images.map((document, idx) => {
      if (idx === index) {
        if (document.isSelected) {
          setSelectedCount(selectedCount - 1);
        } else {
          setSelectedCount(selectedCount + 1);
        }
        return { ...document, isSelected: !document.isSelected };
      } else {
        return document;
      }
    });
    // setDocumentsUrl(modifiedData);
    setImages(modifiedData);
  };
  const handleSelectAll = () => {
    if (selectAll) {
      const modifiedData = images.map((document) => {
        return { ...document, isSelected: false };
      });
      setSelectedCount(0);
      setImages(modifiedData);
    } else {
      const modifiedData = images.map((document) => {
        return { ...document, isSelected: true };
      });
      setImages(modifiedData);
      setSelectedCount(images.length);
    }
  };

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  useEffect(() => {
    //This is done to check selectAll check box when all documents are selected
    if (selectedCount && selectedCount === images?.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedCount]);
  useEffect(() => {
    pdfDocument && getImageList();
  }, [pdfDocument]);

  console.log("Document details:", documentDetails);
  return (
    <div className="upload-modal-container">
      <button className="upload-btn" onClick={handleOpen}>
        <FileUploadOutlinedIcon fontSize="small" /> UPLOAD
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="custom-modal"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: width > 640 ? "720px" : "328px",
            height: "560px",
            borderRadius: "16px",
            bgcolor: "background.paper",
            border: "none",
            boxShadow: 24,
            p: 4,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              fontFamily: "Inter, sans-serif",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <div
              style={{
                fontFamily: "inherit",
                fontSize: "24px",
                fontWeight: "600",
                color: "#191919",
              }}
            >
              Upload {documentDetails?.documentName}
            </div>
            <div
              style={{
                fontFamily: "inherit",
                fontSize: "16px",
                fontWeight: "400",
                color: "#797979",
                marginTop: "16px",
              }}
            >
              {documentDetails?.description}
            </div>

            {uploadStatus ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <button
                style={{
                  height: "34px",
                  width: "118px",
                  borderRadius: "32px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontFamily: "Inter, sans-serif",
                  fontWeight: "600",
                  fontSize: "14px",
                  backgroundColor: "#017c57",
                  color: "white",
                  border: "none",
                  gap: "8px",
                  marginTop: "64px",
                }}
                onClick={() => handleButtonClick()}
              >
                <FileUploadOutlinedIcon fontSize="small" /> UPLOAD
              </button>
            )}
            <input
              type="file"
              onChange={uploadDocument}
              style={{ display: "none" }}
              ref={fileInputRef}
              accept=".pdf,.png,.jpg,.jpeg"
            />
            <div
              style={{
                fontFamily: "inherit",
                fontSize: "12px",
                fontWeight: "400",
                color: "#797979",
                marginTop: "4px",
                marginBottom: "24px",
              }}
            >
              Files Supported: .pdf .png .jpg
            </div>
            {percentage > 0 && (
              <div className="flex items-center w-full">
                <div
                  style={{ width: `${percentage}%` }}
                  className="h-[15px] bg-blue-600 rounded-full"
                />
                <div>{percentage}%</div>
              </div>
            )}

            {images.length > 0 && (
              <div className="flex gap-2 justify-end items-center w-full px-3">
                <input
                  type="checkbox"
                  checked={selectAll}
                  className="h-[16px] w-[16px]"
                  onClick={handleSelectAll}
                />
                <div>Select All</div>
              </div>
            )}
            <div className="flex flex-wrap items-center justify-between h-max-[450px] overflow-y-scroll w-[100%]  px-2">
              {documentsUrl?.map((document, idx) => {
                return (
                  <div
                    key={idx}
                    style={{ border: "1px solid #E4E4E4" }}
                    className="flex justify-between items-center w-[100%] xl:w-[30%] rounded-[16px] p-4 mt-2 bg-[#F9F9F9]"
                  >
                    <div className="w-[50px] h-[50px]">
                      <img
                        src={document.documentUrl}
                        width={50}
                        height={50}
                        alt="img-icons"
                      />
                    </div>

                    <div className="flex gap-2 items-center">
                      <div>
                        <a href={document.documentUrl} download>
                          <img src={DownloadIcon} alt="download-icon" />
                        </a>
                      </div>
                      {/* <div>
                        <img
                          src={CrossIcon}
                          alt="cross-icon"
                          className="cursor-pointer"
                          onClick={() =>
                            handleRemoveDocument(document.sequence)
                          }
                        />
                      </div> */}
                      <input
                        type="checkbox"
                        checked={document.isSelected}
                        onClick={() => handleSelection(idx)}
                        className="h-[20px] w-[20px]"
                      />
                    </div>
                  </div>
                );
              })}
              {images?.map((document, idx) => {
                return (
                  <div
                    key={idx}
                    style={{ border: "1px solid #E4E4E4" }}
                    className="flex justify-between items-center w-[100%] xl:w-[30%] rounded-[16px] p-4 mt-2 bg-[#F9F9F9]"
                  >
                    <div className="w-[50px] h-[50px]">
                      <img
                        src={document.documentUrl}
                        width={50}
                        height={50}
                        alt="img-icons"
                      />
                    </div>

                    <div className="flex gap-2 items-center">
                      <div>
                        <a href={document.documentUrl} download>
                          <img src={DownloadIcon} alt="download-icon" />
                        </a>
                      </div>

                      <input
                        type="checkbox"
                        checked={document.isSelected}
                        onClick={() => handleSelection(idx)}
                        className="h-[20px] w-[20px]"
                      />
                    </div>
                  </div>
                );
              })}
            </div>

            {isSubmitting ? (
              <div className="flex justify-center mt-2">
                <CircularProgress />
              </div>
            ) : (
              <button
                style={{
                  height: "36px",
                  width: "160px",
                  borderRadius: "32px",
                  marginTop: "56px",
                  fontFamily: "Inter, sans-serif",
                  textAlign: "center",
                  fontWeight: "600",
                  fontSize: "12px",
                  backgroundColor: "white",
                  color: "#017C57",
                  border: "1px solid #017C57",
                }}
                onClick={() =>
                  selectedCount > 0
                    ? uploadImages()
                    : window.alert("Please select atleast one image")
                }
              >
                SAVE
              </button>
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
}
