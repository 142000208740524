import { useEffect, useState } from "react";
import { logOut } from "../../config/user";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { AddUserApi, updateUser } from "../../services/AdminServices";
import { emailRegex, passwordRegex } from "../../utils/formUtils";
import CloseIcon from "../../assets/hospitalCloseIcon.svg";
import RightArrow from "../../assets/rightArrowWhite.svg";
import { CircularProgress } from "@mui/material";
const validationSchema = Yup.object().shape({
  fullName: Yup.string().required("Name is required"),
  email: Yup.string()
    .matches(emailRegex, "Invalid Email Address")
    .required("Email is required"),
  phoneNumber: Yup.string()
    .required("Phone number is required")
    .matches(/^\+91\d{10}$/, "Phone number must be 10 digits"),
});
const EditSubAdmin = ({ setOpen, user, getSubAdminList }) => {
  const [isUpdating, setUpdating] = useState(false);
  const handleSubmit = async (values) => {
    setUpdating(true);
    try {
      const response = await updateUser({ userid: user.id, ...values });
      if (response.status === 200) {
        window.alert("Info Updated Successfully");
      }
      await getSubAdminList();
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else if (error.response && error.response.status === 404) {
        window.alert("Phone Number or email already exists");
      }
    }
    setOpen(null);
    setUpdating(false);
  };
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  return (
    <div className="fixed top-0 left-0 w-screen h-screen bg-[#017C7180] flex justify-center items-center">
      <div className="relative w-[70%] h-fit bg-white p-10 rounded-[30px]">
        <div
          className="absolute -top-12 -right-12 cursor-pointer"
          onClick={() => setOpen(false)}
        >
          <img src={CloseIcon} alt="close-icon" />
        </div>
        <div className="text-[40px] 2xl:text-[50px] -tracking-[7%] leading-[32px]">
          Edit your info
        </div>
        <div
          style={{
            border: "1px solid #D4D4D4",
            marginTop: "30px",
            marginBottom: "30px",
          }}
        />

        <div>
          <Formik
            initialValues={{
              fullName: user?.fullName,
              email: user?.email,
              phoneNumber: user?.phone?.toString()?.includes("+91")
                ? user?.phone
                : "+91" + user?.phone,
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              handleSubmit(values);
              setSubmitting(false);
            }}
            validateOnChange={true}
            validateOnBlur={false}
          >
            {({ values, setFieldValue, isSubmitting, errors, touched }) => (
              <Form>
                <div className="flex justify-between items-end">
                  <div className="w-[60%] flex flex-wrap gap-8 justify-between text-sm">
                    <label htmlFor="fullname" className="w-[45%]">
                      <Field
                        name="fullName"
                        placeholder="Enter Full Name"
                        style={{ border: "1px solid #d4d4d4" }}
                        className="w-full rounded-full p-5"
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^A-Za-z ]/g,
                            ""
                          );
                        }}
                      />
                      <ErrorMessage
                        name="fullName"
                        component="div"
                        className="field-error"
                      />
                    </label>
                    <label htmlFor="phoneNumber" className="w-[45%]">
                      <Field
                        type="string"
                        name="phoneNumber"
                        style={{ border: "1px solid #d4d4d4" }}
                        className="w-full rounded-full p-5"
                        placeholder="Enter Phone Number"
                        onInput={(e) => {
                          const value = e.target.value;
                          if (!value.startsWith("+91")) {
                            e.target.value =
                              "+91" +
                              value.replace(/\D/g, "").replace(/^91/, "");
                          } else {
                            e.target.value =
                              "+91" + value.slice(3).replace(/\D/g, "");
                          }
                        }}
                      />
                      <ErrorMessage
                        name="phoneNumber"
                        component="div"
                        className="field-error"
                      />
                    </label>
                    <label htmlFor="email" className="w-[45%]">
                      <Field
                        type="text"
                        name="email"
                        style={{ border: "1px solid #d4d4d4" }}
                        className="w-full rounded-full p-5"
                        placeholder="Enter email address"
                        onChange={(e) => {
                          setFieldValue("email", e.target.value);
                        }}
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="field-error"
                      />
                    </label>{" "}
                  </div>
                  <div style={{ marginTop: "24px" }}>
                    {isUpdating ? (
                      <CircularProgress />
                    ) : (
                      <button
                        type="submit"
                        disabled={isUpdating}
                        style={{ border: "none", cursor: "pointer" }}
                        className="flex items-center gap-4 text-sm text-white bg-[#017C71] leading-[21.7px] -tracking-[3%] px-5 py-[15px] rounded-full"
                      >
                        SAVE
                        <img src={RightArrow} alt="right-arrow" />
                      </button>
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default EditSubAdmin;
