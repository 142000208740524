import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchHospitalCaseStageCount } from "../../redux/hospitalDetailsSlice";
import { CaseServices } from "../../services/CaseServices";
import { HospitalServices } from "../../services/HospitalServices";
import { logOut, getUser } from "../../config/user";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PreinitiateModal from "../../dashboard/cases/preInitiateModal/PreinitiateModal";
import { Link } from "react-router-dom";
import HospitalReferenceDocument from "./hospitalReferenceDocument/HospitalReferenceDocument";
import HospitalCustomPaginaion from "../../utils/HospitalPaginationUtil";
import EnhancementFormHospitalPersona from "./enhancement/EnhancementFormHospitalPersona";
import DischargeFormHospitalPersona from "./discharge/DischargeFormHospitalPersona";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { useNavigate } from "react-router-dom";
import SearchIcon from "../../assets/searchIcon.svg";
import CallIcon from "../../assets/call filled.svg";
import ChatIcon from "../../assets/chat.svg";
import UploadIcon from "../../assets/upload.svg";
import GoToIcon from "../../assets/arrow-right.svg";
import CloseIcon from "../../assets/hospitalCloseIcon.svg";
import RightArrow from "../../assets/rightArrowWhite.svg";
import ReferenceRejected from "../../assets/rejectedReferenceIcon.svg";

const CaseStatus = () => {
  const [caseList, setCaseList] = useState(null);
  const [loading, setIsLoading] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [addDischarge, setAddDischarge] = useState(false);
  const [addEnhancement, setAddEnhancement] = useState(false);
  const [expanded, setExpanded] = useState({ id: "", expand: "false" });
  const [selectedCase, setSelectedCase] = useState(null);
  const [openReferenceModal, setOpenReferenceModal] = useState(false);
  const [referenceNotUploaded, setReferenceNotUploaded] = useState(false);
  const [caseStageCount, setCaseStageCount] = useState(null);
  const [stageType, setStageType] = useState("");
  const [subStageType, setSubStageType] = useState("");
  const dispatch = useDispatch();
  const { caseId } = useSelector((store) => store.caseDetails);
  const searchTimeRef = useRef();
  const hospitalId = getUser()?.hospital?.id;
  const limit = 10;
  const navigate = useNavigate();
  const calculatePrcentage = (uploaded, total) => {
    let percent = 0;
    if (total !== 0) {
      percent = (uploaded / total) * 100;
    }
    return percent;
  };
  const handleClearAllClick = () => {
    setSubStageType("");
  };
  const getDate = (dateString) => {
    const date = new Date(dateString);

    // Get the day, month, and year
    const day = String(date.getDate()).padStart(2, "0"); // Add leading zero if single digit
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed, so add 1
    const year = date.getFullYear();

    // Format the date as DD/MM/YYYY
    return `${day}/${month}/${year}`;
  };
  const handleSearch = (e) => {
    //debouncing structure
    if (searchTimeRef.current) {
      clearInterval(searchTimeRef.current);
    }
    searchTimeRef.current = setTimeout(() => {
      setSearchKey(e.target.value);
    }, 500);
  };
  const handleInitiateAdmission = async (caseItem) => {
    if (caseItem?.totalReferenceDocuments) {
      try {
        await CaseServices.initiateAdmission(caseItem?.id);
        window.alert("Admission Initiated");
        await AllCases();
      } catch (error) {
        if (error?.response?.status === 401) {
          logOut();
        } else {
          window.alert(error?.response?.data?.error);
        }
      }
    } else {
      setSelectedCase(caseItem);
      setReferenceNotUploaded(true);
    }
  };
  const getHospitalCaseStageCount = async () => {
    dispatch(fetchHospitalCaseStageCount(hospitalId));
    try {
      const res = await HospitalServices.getHospitalStageCaseCount(hospitalId);
      setCaseStageCount(res.data);
    } catch (error) {
      window.alert(error);
    }
  };
  const getPendingDocuments = (caseItem) => {
    if (
      caseItem?.submasterstagestatuses[0]?.hsaStage
        ?.toLowerCase()
        ?.includes("admission") &&
      (caseItem?.admissionDocumentsPending ||
        caseItem?.admissionDocumentsRejected)
    ) {
      return (
        caseItem?.admissionDocumentsPending +
        caseItem?.admissionDocumentsRejected
      );
    } else if (
      caseItem?.submasterstagestatuses[0]?.hsaStage
        ?.toLowerCase()
        ?.includes("enhancement") &&
      (caseItem?.latestEnhancementPending ||
        caseItem?.latestEnhancementRejected)
    ) {
      return (
        caseItem?.latestEnhancementPending + caseItem?.latestEnhancementRejected
      );
    } else if (
      caseItem?.submasterstagestatuses[0]?.hsaStage
        ?.toLowerCase()
        ?.includes("claim") &&
      (caseItem?.onDischargePending || caseItem?.onDischargeRejected)
    ) {
      return (
        Number(caseItem?.onDischargePending) +
        Number(caseItem?.onDischargeRejected)
      );
    } else {
      return "";
    }
  };
  const getTotalDocuments = (caseItem) => {
    if (
      caseItem?.submasterstagestatuses[0]?.hsaStage
        ?.toLowerCase()
        ?.includes("admission")
    ) {
      return caseItem?.totalAdmissionDocuments;
    } else if (
      caseItem?.submasterstagestatuses[0]?.hsaStage
        ?.toLowerCase()
        ?.includes("enhancement")
    ) {
      return caseItem?.totalLatestEnhancementDocuments;
    } else if (
      caseItem?.submasterstagestatuses[0]?.hsaStage
        ?.toLowerCase()
        ?.includes("claim")
    ) {
      return caseItem?.totalOnDischargeDocuments;
    } else {
      return "";
    }
  };
  const getRejectedDocuments = (caseItem) => {
    if (
      caseItem?.submasterstagestatuses[0]?.hsaStage
        ?.toLowerCase()
        ?.includes("admission")
    ) {
      return caseItem?.admissionDocumentsRejected;
    } else if (
      caseItem?.submasterstagestatuses[0]?.hsaStage
        ?.toLowerCase()
        ?.includes("enhancement")
    ) {
      return caseItem?.latestEnhancementRejected;
    } else if (
      caseItem?.submasterstagestatuses[0]?.hsaStage
        ?.toLowerCase()
        ?.includes("claim")
    ) {
      return caseItem?.onDischargeRejected;
    } else {
      return "";
    }
  };
  const AllCases = async () => {
    try {
      setIsLoading(true);
      let hsaStages = [];
      if (stageType) {
        hsaStages =
          caseStageCount && subStageType
            ? caseStageCount[`total${stageType}CountNew`][`${subStageType}`]
                ?.stages
            : "";
      } else {
        hsaStages =
          caseStageCount && subStageType
            ? [
                ...caseStageCount[`totalAdmissionCountNew`][`${subStageType}`]
                  ?.stages,
                ...caseStageCount[`totalEnhancementCountNew`][`${subStageType}`]
                  ?.stages,
                ...caseStageCount[`totalDischargeCountNew`][`${subStageType}`]
                  ?.stages,
                ...caseStageCount[`totalClaimCountNew`][`${subStageType}`]
                  ?.stages,
              ]
            : "";
      }
      const res = await HospitalServices.getHospitalCases(
        searchKey,
        page,
        limit,
        "",
        hsaStages?.length > 0 ? "" : stageType + subStageType,
        hsaStages
      );

      setCaseList(res.data.caseList);
      setTotalPages(res?.data?.totalPages);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
  };
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    getHospitalCaseStageCount();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  useEffect(() => {
    AllCases();
  }, [page, searchKey, stageType, subStageType, caseId]);

  return (
    <div>
      <div
        style={{ color: "rgba(0,0,0,0.5)" }}
        className="text-[11px] 2xl:text-sm leading-[18.27px] -tracking-[3%]"
      >
        Home / Case Status / {stageType === "" ? "All" : stageType}
      </div>
      <div className=" flex items-center justify-between mt-[24px] 2xl:mt-[30px]">
        <div
          style={{ background: "rgba(255,255,255,0.5)" }}
          className="w-fit text-[11px] 2xl:text-sm leading-[18.27px] -tracking-[3%] flex gap-[24px] 2xl:gap-[30px] items-center py-3 2xl:py-4 px-4 2xl:px-5  rounded-full"
        >
          <div className="flex items-center">
            <input
              type="radio"
              name="type"
              id="all"
              value={""}
              checked={stageType === ""}
              onChange={(e) => {
                setStageType(e.target.value);
                setSubStageType("");
                setPage(1);
              }}
            />
            <label htmlFor="all" className="ml-[5px]">
              All
            </label>
            <div className="ml-[10px]">
              {" "}
              {caseStageCount?.overallTotalCount?.total}
            </div>
          </div>
          <div className="flex items-center">
            <input
              type="radio"
              name="type"
              id="admission"
              value={"Admission"}
              onChange={(e) => {
                setStageType(e.target.value);
                setPage(1);
              }}
            />
            <label htmlFor="admission" className="ml-[5px]">
              Admission
            </label>
            <div className="ml-[10px]">
              {caseStageCount?.totalAdmissionCount?.total || 0}
            </div>
          </div>
          <div className="flex items-center">
            <input
              type="radio"
              name="type"
              id="enhancement"
              value={"Enhancement"}
              onChange={(e) => {
                setStageType(e.target.value);
                setPage(1);
              }}
            />
            <label htmlFor="enhancement" className="ml-[5px]">
              Enhancement
            </label>
            <div className="ml-[10px]">
              {caseStageCount?.totalEnhancementCount?.total || 0}
            </div>{" "}
          </div>
          <div className="flex items-center">
            <input
              type="radio"
              name="type"
              id="discharge"
              value={"Discharge"}
              onChange={(e) => {
                setStageType(e.target.value);
                setPage(1);
              }}
            />
            <label htmlFor="discharge" className="ml-[5px]">
              Discharge
            </label>
            <div className="ml-[10px]">
              {" "}
              {caseStageCount?.totalDischargeCount?.total || 0}
            </div>{" "}
          </div>
          <div className="flex items-center">
            <input
              type="radio"
              name="type"
              id="claim"
              value={"Claim"}
              onChange={(e) => {
                setStageType(e.target.value);
                setPage(1);
              }}
            />
            <label htmlFor="claim" className="ml-[5px]">
              Claim
            </label>
            <div className="ml-[10px]">
              {" "}
              {caseStageCount?.totalClaimCount?.total || 0}
            </div>{" "}
          </div>
        </div>
        <div
          style={{
            background: "rgba(255,255,255,0.5)",
            border: "1px solid #FFFFFF",
          }}
          className="flex justify-between items-center xl:w-[300px] 2xl:w-[500px] px-4 2xl:px-5 py-[12px] 2xl:py-[15px] rounded-full"
        >
          <input
            type="text"
            placeholder="SEARCH ANYTHING"
            className="w-[80%] text-[12px] 2xl:text-[16px] bg-transparent outline-none border-none"
            onChange={handleSearch}
          />
          <img
            src={SearchIcon}
            alt="search-icon"
            className="h-[14px] w-[18px] 2xl:h-[28px] 2xl:w-[23px]"
          />
        </div>
      </div>
      <div
        id="case-list"
        style={{ background: "rgba(255,255,255,0.5)" }}
        className="p-4 2xl:p-8 mt-4 rounded-[30px]"
      >
        <div className="w-full flex items-center justify-between ">
          <div className="text-[30px] 2xl:text-[50px] leading-[65.26px] -tracking-[7%]">
            {stageType === "" ? "All" : stageType}
          </div>

          <div className="flex text-[11px] 2xl:text-sm leading-[18.27px] -tracking-[3%] items-center gap-[20px] 2xl:gap-[30px]">
            <div
              style={{ background: "rgba(255,255,255,0.5)" }}
              className="w-fit flex gap-[20px] 2xl:gap-[30px] items-center py-3 2xl:py-4 px-4 2xl:px-5 rounded-full"
            >
              <div className="w-fit flex gap-[20px] 2xl:gap-[30px] items-center bg-[#E4E4E4]  rounded-full py-1 px-2">
                <div className="flex items-center">
                  <input
                    type="radio"
                    name="status"
                    id="initiate"
                    value={"Initiate"}
                    checked={subStageType.includes("Initiate")}
                    onChange={(e) => {
                      setSubStageType(e.target.value);
                      setPage(1);
                    }}
                  />
                  <label htmlFor="initiate" className="ml-[5px]">
                    Initiate
                  </label>
                  <div className="ml-[10px]">
                    {" "}
                    {stageType.includes("Admission")
                      ? caseStageCount?.totalAdmissionCountNew?.Initiate?.count
                      : stageType.includes("Enhancement")
                      ? caseStageCount?.totalEnhancementCountNew?.Initiate
                          ?.count
                      : stageType.includes("Discharge")
                      ? caseStageCount?.totalDischargeCountNew?.Initiate?.count
                      : stageType.includes("Claim")
                      ? caseStageCount?.totalClaimCountNew?.Initiate?.count
                      : caseStageCount?.overallTotalCount?.Initiate}
                  </div>{" "}
                </div>
                <div className="flex items-center">
                  <input
                    type="radio"
                    name="status"
                    id="pending"
                    value={"Pending"}
                    checked={subStageType.includes("Pending")}
                    onChange={(e) => {
                      setSubStageType(e.target.value);
                      setPage(1);
                    }}
                  />
                  <label htmlFor="pending" className="ml-[5px]">
                    Pending
                  </label>
                  <div className="ml-[10px]">
                    {" "}
                    {stageType.includes("Admission")
                      ? caseStageCount?.totalAdmissionCountNew?.Pending?.count
                      : stageType.includes("Enhancement")
                      ? caseStageCount?.totalEnhancementCountNew?.Pending?.count
                      : stageType.includes("Discharge")
                      ? caseStageCount?.totalDischargeCountNew?.Pending?.count
                      : stageType.includes("Claim")
                      ? caseStageCount?.totalClaimCountNew?.Pending?.count
                      : caseStageCount?.overallTotalCount?.Pending}
                  </div>{" "}
                </div>
                <div className="flex items-center">
                  <input
                    type="radio"
                    name="status"
                    id="rejected"
                    value={"Rejected"}
                    checked={subStageType.includes("Rejected")}
                    onChange={(e) => {
                      setSubStageType(e.target.value);
                      setPage(1);
                    }}
                  />
                  <label htmlFor="rejected" className="ml-[5px]">
                    Rejected
                  </label>
                  <div className="ml-[10px]">
                    {" "}
                    {stageType.includes("Admission")
                      ? caseStageCount?.totalAdmissionCountNew?.Rejected?.count
                      : stageType.includes("Enhancement")
                      ? caseStageCount?.totalEnhancementCountNew?.Rejected
                          ?.count
                      : stageType.includes("Discharge")
                      ? caseStageCount?.totalDischargeCountNew?.Rejected?.count
                      : stageType.includes("Claim")
                      ? caseStageCount?.totalClaimCountNew?.Rejected?.count
                      : caseStageCount?.overallTotalCount?.Rejected}
                  </div>
                </div>
              </div>
              <div className="flex items-center">
                <input
                  type="radio"
                  name="status"
                  id="inProgress"
                  value={"Progress"}
                  checked={subStageType.includes("Progress")}
                  onChange={(e) => {
                    setSubStageType(e.target.value);
                    setPage(1);
                  }}
                />
                <label htmlFor="inProgress" className="ml-[5px]">
                  In Progress
                </label>
                <div className="ml-[10px]">
                  {" "}
                  {stageType.includes("Admission")
                    ? caseStageCount?.totalAdmissionCountNew?.Progress?.count
                    : stageType.includes("Enhancement")
                    ? caseStageCount?.totalEnhancementCountNew?.Progress?.count
                    : stageType.includes("Discharge")
                    ? caseStageCount?.totalDischargeCountNew?.Progress?.count
                    : stageType.includes("Claim")
                    ? caseStageCount?.totalClaimCountNew?.Progress?.count
                    : caseStageCount?.overallTotalCount?.Progress}
                </div>
              </div>
              <div className="flex items-center">
                <input
                  type="radio"
                  name="status"
                  id="approved"
                  value={"Approved"}
                  checked={subStageType.includes("Approved")}
                  onChange={(e) => {
                    setSubStageType(e.target.value);
                    setPage(1);
                  }}
                />
                <label htmlFor="approved" className="ml-[5px]">
                  Approved
                </label>
                <div className="ml-[10px]">
                  {" "}
                  {stageType.includes("Admission")
                    ? caseStageCount?.totalAdmissionCountNew?.Approved?.count
                    : stageType.includes("Enhancement")
                    ? caseStageCount?.totalEnhancementCountNew?.Approved?.count
                    : stageType.includes("Discharge")
                    ? caseStageCount?.totalDischargeCountNew?.Approved?.count
                    : stageType.includes("Claim")
                    ? caseStageCount?.totalClaimCountNew?.Approved?.count
                    : caseStageCount?.overallTotalCount?.Approved}
                </div>
              </div>
            </div>
            <div className="flex gap-[5px] items-center">
              <div
                style={{ border: "1px solid #017C71" }}
                className=" px-5 py-3 rounded-full cursor-pointer"
                onClick={handleClearAllClick}
              >
                Clear All
              </div>
              {/* <div
                style={{ border: "1px solid #017C71" }}
                className=" px-5 py-3 text-white bg-[#017C71] rounded-full cursor-pointer"
              >
                REFRESH
              </div> */}
            </div>
          </div>
        </div>
        <div
          style={{
            border: "1px solid #FFFFFF",
          }}
          className="mt-[32px] 2xl:mt-[40px] mb-[24px] 2xl:mb-[30px]"
        />
        <div className="flex justify-end items-center mb-[24px] 2xl:mb-[30px]">
          <div>
            <HospitalCustomPaginaion
              page={page}
              totalPages={totalPages}
              setPage={setPage}
            />
          </div>
        </div>
        <div className="bg-[white] p-[24px] 2xl:p-[30px] rounded-[15px]">
          <div className="grid grid-cols-12 text-[12px] 2xl:text-sm font-semibold 2xl:leading-[21.7px] -tracking-[3%]">
            <div>Beneficiary ID</div>
            <div>Patient Name</div>
            <div>Age</div>
            <div>Registered Date</div>
            <div className="col-span-2 text-center">Case Stage</div>
            <div className="col-span-2 text-center">Action Area</div>

            <div className="col-span-4"></div>
          </div>

          <div>
            {caseList?.map((caseItem, idx) => {
              return (
                <div
                  style={{
                    background:
                      Number(getRejectedDocuments(caseItem)) > 0 ||
                      caseItem?.referenceDocumentsRejected > 0
                        ? "linear-gradient(90deg, #FFFFFF 2%, rgba(255, 67, 67, 0.3) 18.5%, rgba(255, 67, 67, 0.3) 81%, #FFFFFF 98%)"
                        : caseItem.submasterstagestatuses[0].hsaStage
                            .toLowerCase()
                            .includes("approved on tms")
                        ? "linear-gradient(90deg, #FFFFFF 0%, #B4FFCF 10.5%, #B4FFCF 88%, #FFFFFF 100%)"
                        : caseItem.submasterstagestatuses[0].hsaStage
                            .toLowerCase()
                            .includes("progress")
                        ? "linear-gradient(90deg, #FFFFFF 0%, rgba(237, 255, 140, 0.5) 13%, rgba(237, 255, 140, 0.5) 85%, #FFFFFF 100%)"
                        : "#FFFFFF",
                  }}
                  className="grid grid-cols-12 py-1 items-center mt-[8px] 2xl:mt-[10px] first:mt-6 2xl:first:mt-8 "
                >
                  <div
                    className="text-[12px] 2xl:text-sm leading-[16px] 2xl:leading-[18px] overflow-hidden whitespace-nowrap text-ellipsis"
                    title={caseItem?.patient?.beneficiaryNo}
                  >
                    {caseItem?.patient?.beneficiaryNo || "--"}
                  </div>
                  <div
                    className="text-[11px] 2xl:text-xs leading-[15.66px] -tracking-[3%] capitalize overflow-hidden whitespace-nowrap text-ellipsis "
                    title={caseItem?.patient?.patientName}
                  >
                    {caseItem?.patient?.patientName}
                  </div>
                  <div className="text-[11px] 2xl:text-xs leading-[15.66px] -tracking-[3%]">
                    {caseItem.patient.age}yr
                  </div>
                  <div className="text-[12px] 2xl:text-sm leading-[18px]">
                    {" "}
                    {caseItem?.patient?.registeredDate
                      ? getDate(caseItem?.patient?.registeredDate)
                      : "--"}
                  </div>
                  <div className="col-span-2 text-[11px] 2xl:text-sm leading-[25.41px] -tracking-[3%] font-medium uppercase">
                    {caseItem.submasterstagestatuses[0].hsaStage
                      ? caseItem.submasterstagestatuses[0].hsaStage
                      : "--"}
                  </div>
                  <div className="col-span-2 flex justify-center">
                    {Number(getRejectedDocuments(caseItem)) > 0 ? (
                      <Link
                        to={`/dashboard/case-status/${caseItem.id}`}
                        style={{ border: "1px solid #FF8282" }}
                        className="no-underline text-white bg-[#FF8282] shadow-md rounded-full px-3 2xl:px-4 py-2 2xl:py-3"
                      >
                        {" "}
                        <div className="text-[10px] 2xl:text-sm -tracking-[3%] cursor-pointer">{`${getRejectedDocuments(
                          caseItem
                        )} REJECTED DOCUMENT`}</div>
                      </Link>
                    ) : caseItem?.submasterstagestatuses[0].hsaStage
                        .toLowerCase()
                        .includes("request to admission") ? (
                      <div
                        className="uppercase w-fit text-[10px] 2xl:text-xs text-white bg-[#017C71] -tracking-[3%] shadow-xl rounded-full py-3 xl:px-4 2xl:px-9 cursor-pointer"
                        onClick={() => handleInitiateAdmission(caseItem)}
                      >
                        Initiate Admission
                      </div>
                    ) : caseItem?.submasterstagestatuses[0].hsaStage
                        .toLowerCase()
                        .includes("admission approved on tms") ||
                      caseItem?.submasterstagestatuses[0].hsaStage
                        .toLowerCase()
                        .includes("enhancement approved on tms") ? (
                      <Accordion
                        expanded={caseItem.id === expanded.id}
                        style={{
                          width: width < 1444 ? "100%" : "80%",
                          borderRadius:
                            caseItem.id === expanded.id ? "25px" : "100px",
                        }}
                      >
                        <AccordionSummary
                          expandIcon={
                            <ExpandMoreIcon
                              style={{
                                color:
                                  caseItem.id === expanded.id
                                    ? "#000000"
                                    : "#ffffff",
                              }}
                            />
                          }
                          style={{
                            background:
                              caseItem.id === expanded.id
                                ? "#FFFFFF"
                                : "#017C71",
                            borderRadius:
                              caseItem.id === expanded.id ? "25px" : "100px",
                            color:
                              caseItem.id === expanded.id
                                ? "#000000"
                                : "#ffffff",
                            fontSize: width < 1444 ? "12px" : "14px",
                          }}
                          onClick={() => {
                            caseItem.id === expanded.id
                              ? setExpanded({ ...expanded, id: "" })
                              : setExpanded({ ...expanded, id: caseItem.id });
                          }}
                        >
                          INITIATE
                        </AccordionSummary>
                        <AccordionDetails
                          style={{ fontSize: "12px", letterSpacing: "-3%" }}
                        >
                          <div
                            className="text-white bg-[#017C71]  rounded-full py-3 text-center text-[11px] 2xl:text-[12px] cursor-pointer -mt-1.5"
                            onClick={() => {
                              {
                                setAddDischarge(true);
                                setSelectedCase(caseItem);
                              }
                            }}
                          >
                            INITIATE DISCHARGE
                          </div>
                          <div
                            className="text-white bg-[#017C71]  rounded-full py-3 text-center text-[11px] 2xl:text-[12px] cursor-pointer mt-1"
                            onClick={() => {
                              setAddEnhancement(true);
                              setSelectedCase(caseItem);
                            }}
                          >
                            INITIATE ENHANCEMENT
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="col-span-4 flex items-center justify-end  ">
                    <div
                      style={{
                        border:
                          getPendingDocuments(caseItem) > 0
                            ? "1px solid #017C71"
                            : "none",
                      }}
                      className="flex items-center gap-0.5 2xl:gap-2 py-1 px-1 pl-4 rounded-full"
                    >
                      {getPendingDocuments(caseItem) && (
                        <Link
                          to={`/dashboard/case-status/${caseItem.id}`}
                          className="no-underline text-black                                                                                                      "
                        >
                          <div className="flex  gap-2 2xl:gap-4 cursor-pointer">
                            <img src={UploadIcon} alt="upload-icon" />
                            <div className="text-[11px] 2xl:text-xs -tracking-[3%]">
                              {getPendingDocuments(caseItem) +
                                " Documents Pending"}
                            </div>
                          </div>
                        </Link>
                      )}
                      <div style={{ width: "46px" }}>
                        <CircularProgressbarWithChildren
                          value={calculatePrcentage(
                            Number(getTotalDocuments(caseItem)) -
                              Number(getPendingDocuments(caseItem)),
                            Number(getTotalDocuments(caseItem))
                          )} // value in percentage
                          strokeWidth={13}
                          styles={{
                            path: {
                              stroke: "#000",
                              strokeLinecap: "round",
                              transformOrigin: "center center",
                            },
                            trail: {
                              stroke: "#E8E8E8",
                              transformOrigin: "center center",
                            },
                          }}
                        >
                          {" "}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                              fontSize: "10px",
                              color: "#000",
                              fontFamily: "inter, sans-serif",
                              fontWeight: 500,
                              // paddingBottom: "18px",
                            }}
                          >
                            {Number(getTotalDocuments(caseItem)) -
                              Number(getPendingDocuments(caseItem))}
                            /{Number(getTotalDocuments(caseItem))}
                          </div>
                        </CircularProgressbarWithChildren>
                      </div>
                    </div>
                    <div className="w-[43px] h-[43px] flex items-center justify-center cursor-pointer hidden">
                      <img src={CallIcon} alt="call-icon" />
                    </div>
                    <div className="w-[43px] h-[43px] flex items-center justify-center cursor-pointer hidden">
                      <img src={ChatIcon} alt="chat-icon" />
                    </div>
                    <div
                      className="w-[43px] h-[43px] flex items-center justify-center cursor-pointer"
                      title="Upload Reference Documents"
                      onClick={() => {
                        setSelectedCase(caseItem);
                        setOpenReferenceModal(true);
                      }}
                    >
                      <img
                        src={
                          caseItem?.referenceDocumentsRejected
                            ? ReferenceRejected
                            : UploadIcon
                        }
                        alt="upload-icon"
                        className={`${
                          caseItem?.referenceDocumentsRejected
                            ? "h-[28px] w-[28px]"
                            : ""
                        }`}
                      />
                    </div>

                    <div
                      className="w-[43px] h-[43px] flex items-center justify-center cursor-pointer"
                      title="Case Details Page"
                    >
                      <Link to={`/dashboard/case-status/${caseItem.id}`}>
                        <img src={GoToIcon} alt="got-to-case-details" />
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {openReferenceModal && (
        <HospitalReferenceDocument
          selectedCase={selectedCase}
          setOpen={setOpenReferenceModal}
          AllCases={AllCases}
        />
      )}
      {referenceNotUploaded && (
        <div className="fixed top-0 left-0 h-screen w-screen flex justify-center items-center bg-[#01716780] z-10 ">
          <div className="relative max-w-[38%] bg-white rounded-[30px] py-[45.5px] px-[25.5px]">
            <div
              className="absolute -top-14 -right-12 cursor-pointer"
              onClick={() => setReferenceNotUploaded(false)}
            >
              <img src={CloseIcon} alt="close-icon" height={53} width={52} />
            </div>
            <div className="text-center text-[33px] leading-[33px] -tracking-[7%]">
              {`Upload Reference Document To Initiate The Admission (Like Clinical
              Notes or OPD Paper).`}
            </div>
            <div className="text-center text-[15px] text-[#797979] leading-[15px] -tracking-[5%] mt-4">
              please make sure they have all been successfully uploaded and
              verified.
            </div>
            <div className=" flex justify-center items-center mt-10">
              <div
                className="flex items-center gap-5 text-sm text-white bg-[#017C71] -tracking-[3%] uppercase  rounded-full px-5 py-4 cursor-pointer"
                onClick={() => {
                  setReferenceNotUploaded(false);
                  setOpenReferenceModal(true);
                }}
              >
                Upload Reference Document
                <img src={RightArrow} alt="arrow-icon" />
              </div>
            </div>
          </div>
        </div>
      )}

      {addEnhancement && (
        <EnhancementFormHospitalPersona
          totalPackageCost={selectedCase.totalPackageCost}
          setAddEnhancement={setAddEnhancement}
          caseDetails={selectedCase}
          caseId={selectedCase.id}
          admissionDate={selectedCase.admissionDate}
          AllCases={AllCases}
        />
      )}
      {addDischarge && (
        <DischargeFormHospitalPersona
          caseId={selectedCase.id}
          setAddDischarge={setAddDischarge}
          AllCases={AllCases}
        />
      )}
    </div>
  );
};

export default CaseStatus;
