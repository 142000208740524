import React, { useState, useEffect, useRef } from "react";
import { logOut } from "../config/user";
import { CircularProgress } from "@mui/material";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import SearchIcon from "@mui/icons-material/Search";
import Animation from "../assets/Animation.gif";
import warnicon from "../assets/warnorange.png";
import RedAnimation from "../assets/RedAnimation.gif";
import {
  HospitalServices,
  hospitalupdate,
  addUpdateTmsCredential,
} from "../services/HospitalServices";
import "./ManageRpa.scss";
import { Switch, Stack } from "@mui/material";
import editIcon from "../assets/editIcon.svg";
import crossIcon from "../assets/crossIcon.svg";
import CustomPagination from "../utils/paginationUtils";
import EyeIcon from "../assets/eyeIcon.svg";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { RpaServices } from "../services/RpaServices";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Close from "@mui/icons-material/Close";

const ManagedRpa = () => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(null);
  const [hospitalList, setHospitalList] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [total, setTotal] = useState(0);
  const [rpaLogs, setRpaLogs] = useState([]);
  const [rpaTotalPages, setRpaTotalPages] = useState(1);
  const [hospitalTmsCredentials, setHospitalTmsCredentials] = useState([]);
  const [addUserPopVisible, setAddUserPopUpVisible] = useState(false);
  const [newUser, setNewUser] = useState("");
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchKey, setSearchKey] = useState("");
  const [hospitalId, setHospitalId] = useState("");
  const [selectedApplication, setSelectedApplication] = useState("");
  const [requestStatus, setRequestStatus] = useState(null);
  const searchTimeRef = useRef(null);
  const limit = 10;
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isLogPopupVisible, setIsLogPopupVisible] = useState(false);
  const [selectedRpaLog, setSelectedRpaLog] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleButtonClick = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  const togglePasswordVisibility = (hospitalId) => {
    setIsPasswordVisible((prevId) =>
      prevId === hospitalId ? null : hospitalId
    );
  };
  const handleSearch = (e) => {
    if (searchTimeRef.current) {
      clearInterval(searchTimeRef.current);
    }
    searchTimeRef.current = setTimeout(() => {
      setSearchKey(e.target.value);
    }, 500);
  };

  const fetchHospitalList = async () => {
    setIsLoading(true);
    try {
      const res = await HospitalServices.getHospitalUserList(
        searchKey,
        page,
        limit
      );
      if (res?.data?.userList) {
        setHospitalList(res?.data?.userList);
        setTotalPages(res?.data?.totalPages || 1);
        setTotal(res?.data?.total || 0);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        alert(error?.response?.data?.error || "An error occurred");
      }
    } finally {
      setIsLoading(false);
    }
  };
  const fetchRpaStatus = async () => {
    try {
      const response = await RpaServices.rpaStatus();

      if (response.status === 200 && response.data?.requests) {
        console.log("Full RPA Requests Response-----:", response.data);

        const requestStatuses = response.data.requests.map(
          (req) => req.requestStatus
        );

        return requestStatuses; // Returning extracted statuses
      } else {
        alert("Failed to fetch RPA Requests. Please try again.");
        return null;
      }
    } catch (error) {
      alert("An error occurred while fetching RPA Requests.");
      return null;
    }
  };

  const fetchrpaLogs = async (page, limit, hospitalId) => {
    setIsLoading(true);
    try {
      const res = await RpaServices.manageRpaHospitalLogs(
        page,
        limit,
        hospitalId
      );
      if (res?.data?.requests) {
        setRpaLogs(res.data.requests);
        setRpaTotalPages(res.data.totalPages || 1);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        console.log(error?.response?.data?.error || "An error occurred");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const [applicationIds, setApplicationIds] = useState([]);

  useEffect(() => {
    const getApplications = async () => {
      try {
        const response = await RpaServices.manageApplicationId();
        const applications = response.data.applications;

        const applicationIdArray = applications.map(
          (application) => application.applicationId
        );

        setApplicationIds(applicationIdArray);

        console.log("Hello", applicationIdArray);
      } catch (error) {
        console.error("Error fetching applications:", error);
      }
    };

    getApplications();
  }, []);

  useEffect(() => {
    fetchRpaStatus();
  }, []);

  const handleUpdateTmsCredential = async () => {
    try {
      const res = await addUpdateTmsCredential(hospitalTmsCredentials);
      console.log("tms cred update res:", res);
    } catch (error) {
      window.alert(error);
    }
  };
  const handleSaveChanges = async () => {
    if (
      !selectedUser?.hospital?.applicationId ||
      !selectedUser?.hospital?.tmsVersion
    ) {
      alert("Please fill in all the required fields.");
      return;
    }

    const data = {
      fullName: selectedUser?.fullName,
      email: selectedUser?.email,
      phone: selectedUser?.phone,
      hospital: selectedUser?.hospital?.name,
      pmjayPassword: selectedUser?.hospital?.pmjayPassword,
      pmjayUserId: selectedUser?.hospital?.pmjayUserId,
      tmsVersion: selectedUser?.hospital?.tmsVersion,
      applicationId: selectedUser?.hospital?.applicationId,
      rpaStatusL: selectedUser?.hospital?.rpaApplicationStatus,
    };

    try {
      setIsLoading(true);
      const response = await hospitalupdate(selectedUser?.hospital?.id, data);
      await handleUpdateTmsCredential();
      console.log("Senfing data", data);
      if (response?.status === 200) {
        alert("Changes saved successfully!");
        setIsPopupVisible(false);
        await fetchHospitalList();
      } else {
        alert("Failed to update hospital details.");
      }
    } catch (error) {
      alert("Error updating hospital details: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleApplicationChange = (e) => {
    const newApplicationId = e.target.value;

    // Ensure selectedUser and hospital exist before updating
    setSelectedUser((prevUser) => {
      if (!prevUser || !prevUser.hospital) return prevUser; // Prevent null updates

      return {
        ...prevUser,
        hospital: {
          ...prevUser.hospital,
          applicationId: newApplicationId, // Update applicationId safely
        },
      };
    });
  };

  const handleStatusChange = async (hospital, status) => {
    const updatedStatus = hospital?.pmjaystatus === true ? false : true;
    const updatedData = {
      userid: hospital.id,
      pmjaystatus: updatedStatus,
    };
    try {
      const response = await HospitalServices.updateUserRpaDetails(updatedData);
      if (response?.status === 200) {
        fetchHospitalList();
      }
    } catch (error) {
      alert("Error while changing status");
    }
  };

  useEffect(() => {
    fetchHospitalList();
  }, [page, searchKey]);

  const handleMenuOpen = (event, rpalog) => {
    setAnchorEl(event.currentTarget);
    setSelectedRpaLog(rpalog);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRpaLog(null);
  };

  const handleLog = () => {
    if (selectedRpaLog) {
      setSelectedUser(selectedRpaLog);
      setIsLogPopupVisible(true);
      fetchrpaLogs(1, 20, selectedRpaLog.hospital.id);
    }
    handleMenuClose();
  };

  return (
    <div className="initiateadmission-box">
      <span className="path_line">
        <WidgetsOutlinedIcon style={{ height: "24px" }} /> &nbsp;/ &nbsp;
        Dashboard &nbsp;/ &nbsp; Manage Hospital
      </span>

      <div className="flex justify-between items-center">
        <div className="heading">
          Manage Hospital <span className="ml-1">({total})</span>
        </div>
        <div
          style={{ border: "1px solid #E4E4E4" }}
          className="flex gap-2 px-2 py-2 rounded-[8px] "
        >
          <SearchIcon />
          <input
            type="text"
            className="outline-none border-0"
            placeholder="Search Anything"
            onChange={handleSearch}
          />
        </div>
      </div>
      <hr style={{ color: "#E7E7E7", marginBottom: "20px" }} />

      <div className="Second-container">
        <div className="flex justify-between">
          {!isLoading ? (
            hospitalList?.length > 0 ? (
              <section className="mt-1">
                <div className="grid grid-cols-8 text-[#191919] font-semibold px-8 py-1 gap-2">
                  <div className="text-[18px] w-2">
                    Sync <br />
                    Status
                  </div>
                  <div className="text-[18px] text-[#191919] font-semibold  ">
                    Hospital Name
                  </div>
                  <div className="text-[18px] ml-[-10px]">Last Request</div>

                  <div className="text-[18px]">Application Id</div>
                  <div className="text-[18px]">Hospital Admin</div>
                  <div className="text-[18px]">Phone Number</div>

                  <div className="text-[18px]">User Details</div>

                  <div className="text-[18px] text-center">Action</div>
                </div>

                {hospitalList.map((hospital, idx) => (
                  <article
                    key={idx}
                    className={`py-6 px-8 rounded-[16px] ${
                      idx % 2 === 0 ? "bg-white" : "bg-[#F9F9F9]"
                    }`}
                  >
                    <div
                      className={`grid grid-cols-8 text-[#797979] text-[18px] gap-2`}
                    >
                      <div className={`text-[#797979] text-[18px]`}>
                        <img
                          src={
                            hospital?.hospital?.rpaApplicationStatus ===
                            "MAPPING PENDING"
                              ? warnicon
                              : hospital?.hospital?.rpaApplicationStatus ===
                                "RUNNING"
                              ? hospital?.hospital?.newRpaRequest == 0
                                ? warnicon
                                : RedAnimation
                              : RedAnimation
                          }
                          alt={hospital?.hospital?.rpaApplicationStatus}
                          title={
                            hospital?.hospital?.rpaApplicationStatus ==
                            "RUNNING"
                              ? hospital?.hospital?.rpaApplicationStatus +
                                " | RPA Request:" +
                                hospital?.hospital?.newRpaRequest
                              : hospital?.hospital?.rpaApplicationStatus
                          }
                          className="w-6 h-6"
                        />
                      </div>
                      <div className={`text-[#797979] text-[18px]`}>
                        {hospital?.hospital?.name + " " || "--"}
                        {hospital?.hospital?.tmsVersion}
                      </div>

                      <div className="text-[#797979] text-[18px] ml-5">
                        {hospital?.hospital?.latestRequestLog?.lastProcessedAt
                          ? new Date(
                              hospital.hospital.latestRequestLog.lastProcessedAt
                            ).toLocaleString("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                              second: "2-digit",
                              hour12: true,
                            })
                          : "NA"}
                      </div>
                      <div className="text-[#797979] text-[18px] ml-8">
                        &nbsp;&nbsp;{hospital?.hospital?.applicationId || "--"}
                        <br />
                        {hospital?.hospital?.applicationId ? (
                          ""
                        ) : (
                          <span
                            onClick={() => {
                              setSelectedUser(hospital); // Set the selected hospital
                              setIsPopupVisible(true); // Show the popup
                            }}
                            className="text-green-700 cursor-pointer"
                          >
                            {" "}
                            Map
                          </span>
                        )}
                      </div>
                      <div className={`text-[#797979] text-[18px]`}>
                        {hospital?.fullName || "--"}
                      </div>
                      <div className={`text-[#797979] text-[18px]`}>
                        {hospital?.phone || "--"}
                      </div>

                      <div className={` text-[#797979] text-[15px] `}>
                        {hospital?.hospital?.tmsVersion === "2.0" ? (
                          <div>
                            {hospital?.hospital?.hospitaltmscredentials?.map(
                              (cred, idx) => {
                                return <div>{cred.pmjayNumber}</div>;
                              }
                            )}
                          </div>
                        ) : (
                          "--"
                        )}{" "}
                      </div>

                      <div className="flex justify-center items-start">
                        <Stack direction="row" spacing={1} alignItems="center">
                          <span style={{ textTransform: "capitalize" }}>
                            {hospital?.pmjaystatus}
                          </span>
                          <Switch
                            checked={hospital?.pmjaystatus === true}
                            onChange={() => {
                              const newStatus = !hospital?.pmjaystatus;
                              const statusText = newStatus
                                ? "activated"
                                : "deactivated";
                              if (
                                window.confirm(
                                  `Are you sure you want to ${statusText} this hospital?`
                                )
                              ) {
                                handleStatusChange(hospital, newStatus);
                              }
                            }}
                            inputProps={{ "aria-label": "toggle status" }}
                            style={{
                              color: hospital?.pmjaystatus
                                ? "#017C57"
                                : "#B0B0B0",
                            }}
                            sx={{
                              "& .MuiSwitch-thumb": {
                                backgroundColor: hospital?.pmjaystatus
                                  ? "#017C57"
                                  : "#D3D3D3",
                              },
                              "& .MuiSwitch-track": {
                                backgroundColor: hospital?.pmjaystatus
                                  ? "#017C57"
                                  : "#D3D3D3",
                              },
                            }}
                          />

                          <span>
                            <img
                              src={editIcon}
                              alt="edit-icon"
                              className="w-[18px] cursor-pointer"
                              onClick={() => {
                                setSelectedUser(hospital); // Set the selected hospital
                                setHospitalTmsCredentials(
                                  hospital?.hospital?.hospitaltmscredentials
                                );
                                setIsPopupVisible(true); // Show the popup
                              }}
                            />
                          </span>

                          <div className="flex justify-center items-center gap-2">
                            <MoreVertIcon
                              onClick={(event) =>
                                handleMenuOpen(event, hospital)
                              }
                              className="cursor-pointer"
                            />
                            <Menu
                              anchorEl={anchorEl}
                              open={Boolean(anchorEl)}
                              onClose={handleMenuClose}
                            >
                              <MenuItem onClick={handleLog}>Log</MenuItem>
                            </Menu>
                          </div>
                        </Stack>
                      </div>
                    </div>
                  </article>
                ))}
              </section>
            ) : (
              <div className="w-full h-full flex justify-center items-center font-semibold text-xl text-[#191919]">
                No Data Found
              </div>
            )
          ) : (
            <div className="w-full h-[500px] flex justify-center items-center">
              <CircularProgress />
            </div>
          )}
        </div>
      </div>

      {isPopupVisible && (
        <div className="popup-overlay">
          <div className="popup-container relative max-h-[90%] w-[328px] xl:w-[50%] bg-white rounded-[16px] p-6 xl:p-8 z-[1200]">
            <div className="absolute right-12 top-8 cursor-pointer"></div>
            <div className="px-12 pt-8 pb-4 border-b-2 border-s-2 border-gray-100 flex justify-between">
              <div className="left-10 top-6 text-xl cursor-pointer font-semibold text-[18px]">
                Update RPA Hospital Details
              </div>
              <div className="right-12 top-6  flex items-center gap-2 cursor-pointer">
                <div
                  className="text-white bg-[#017C57] px-4 py-2 rounded-full"
                  onClick={() => setAddUserPopUpVisible(true)}
                >
                  Add User
                </div>
                <Close
                  className=" font-semibold text-2xl hover:text-gray-700"
                  alt="close-icon"
                  onClick={() => {
                    handleButtonClick();
                  }}
                />
              </div>
            </div>
            <div className="space-y-4">
              <div className="flex gap-[16px] pt-6 pl-10 gap-2">
                <div className="w-[776px] h-[500px] p-[16px] border border-[rgba(228,228,228,1)] rounded-[8px] flex flex-col gap-[16px]">
                  <div className="flex gap-[16px]">
                    <div className="flex-1">
                      <label htmlFor="fullName" className="font-semibold block">
                        UserName:
                      </label>
                      <input
                        id="username"
                        type="text"
                        value={selectedUser?.fullName || ""}
                        onChange={(e) =>
                          setSelectedUser({
                            ...selectedUser,
                            username: e.target.value,
                          })
                        }
                        style={{
                          border: "1px solid #E4E4E4",
                          marginTop: "15px",
                        }}
                        disabled
                        className="w-[380px] h-[40px] outline-none rounded-[8px] px-3 py-[8px]"
                      />
                    </div>

                    <div className="flex-1">
                      <label htmlFor="email" className="font-semibold block">
                        Email:
                      </label>
                      <input
                        id="email"
                        type="text"
                        value={selectedUser?.email || ""}
                        onChange={(e) =>
                          setSelectedUser({
                            ...selectedUser,
                            email: e.target.value,
                          })
                        }
                        style={{
                          border: "1px solid #E4E4E4",
                          marginTop: "15px",
                        }}
                        disabled
                        className="w-[380px] h-[40px] outline-none rounded-[8px] px-3  "
                      />
                    </div>
                  </div>

                  <div className="flex gap-[16px]">
                    <div className="flex-1">
                      <label htmlFor="phone" className="font-semibold block">
                        Phone:
                      </label>
                      <input
                        id="phone"
                        type="text"
                        value={selectedUser?.phone || ""}
                        onChange={(e) =>
                          setSelectedUser({
                            ...selectedUser,
                            phone: e.target.value,
                          })
                        }
                        style={{
                          border: "1px solid #E4E4E4",
                          marginTop: "15px",
                        }}
                        disabled
                        className="w-[380px] h-[40px] outline-none rounded-[8px] px-3 py-[8px]  "
                      />
                    </div>

                    <div className="flex-1">
                      <label htmlFor="hospital" className="font-semibold block">
                        Hospital Name:
                      </label>
                      <input
                        id="hospital"
                        type="text"
                        value={selectedUser?.hospital.name || ""}
                        disabled
                        style={{
                          border: "1px solid #E4E4E4",
                          marginTop: "15px",
                        }}
                        className="w-[380px] h-[40px] outline-none rounded-[8px] px-3 py-[8px]  "
                      />
                    </div>
                  </div>

                  <div className="flex gap-[16px]">
                    <div className="flex-1">
                      <label
                        htmlFor="tmsUserId"
                        className="font-semibold block"
                      >
                        TMS User Id:
                      </label>
                      <input
                        id="tmsUserId"
                        type="text"
                        defaultValue={selectedUser?.hospital?.pmjayUserId || ""}
                        onChange={(e) =>
                          setSelectedUser({
                            ...selectedUser,
                            hospital: {
                              ...selectedUser.hospital,
                              pmjayUserId: e.target.value,
                            },
                          })
                        }
                        style={{
                          border: "1px solid #E4E4E4",
                          marginTop: "15px",
                        }}
                        className="w-[380px] h-[40px] outline-none rounded-[8px] px-3 py-[8px] "
                      />
                    </div>

                    <div className="flex-1">
                      <label
                        htmlFor="tmsPassword"
                        className="font-semibold block"
                      >
                        TMS Password:
                      </label>

                      <div className="relative mt-[15px]">
                        <input
                          id="tmsPassword"
                          type={isPasswordVisible ? "text" : "password"}
                          defaultValue={
                            selectedUser?.hospital?.pmjayPassword || ""
                          }
                          onChange={(e) =>
                            setSelectedUser({
                              ...selectedUser,
                              hospital: {
                                ...selectedUser.hospital,
                                pmjayPassword: e.target.value,
                              },
                            })
                          }
                          style={{ border: "1px solid #E4E4E4" }}
                          className="w-[380px] h-[40px] outline-none rounded-[8px] px-3 py-[8px]"
                        />

                        {isPasswordVisible === selectedUser?.hospital?.id ? (
                          <VisibilityOffOutlinedIcon
                            className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                            onClick={() =>
                              togglePasswordVisibility(
                                selectedUser?.hospital?.id
                              )
                            }
                            sx={{ color: "#017C57" }}
                            fontSize="small"
                          />
                        ) : (
                          <VisibilityOutlinedIcon
                            className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                            onClick={() =>
                              togglePasswordVisibility(
                                selectedUser?.hospital?.id
                              )
                            }
                            sx={{ color: "#017C57" }}
                            fontSize="small"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center flex-wrap gap-3 ">
                    {hospitalTmsCredentials?.map((cred, idx) => {
                      return (
                        <div
                          style={{ border: "1px solid #D4D4D4" }}
                          className="text-sm flex gap-5 px-3 py-2  mt-2 rounded-[8px]"
                        >
                          <div>{cred.pmjayNumber}</div>
                          <div>{cred.medicoAppStatus}</div>
                        </div>
                      );
                    })}
                  </div>

                  <div className="flex gap-[16px]">
                    <div className="flex-1">
                      <label
                        htmlFor="tmsVersion"
                        className="font-semibold block"
                      >
                        TMS Version:
                      </label>
                      <select
                        id="tmsVersion"
                        value={selectedUser?.hospital?.tmsVersion || ""}
                        onChange={(e) =>
                          setSelectedUser({
                            ...selectedUser,
                            hospital: {
                              ...selectedUser.hospital,
                              tmsVersion: e.target.value,
                            },
                          })
                        }
                        className="w-[380px] h-[40px] outline-none rounded-[8px] px-3 py-[8px] border border-[#E4E4E4] mt-[15px]"
                      >
                        <option value="">Select Version</option>
                        <option value="1.0">1.0</option>
                        <option value="2.0">2.0</option>
                      </select>
                    </div>

                    <div className="flex-1">
                      <label
                        htmlFor="applicationId"
                        className="font-semibold block"
                      >
                        Application Id:
                      </label>
                      <select
                        id="applicationId"
                        value={selectedUser?.hospital?.applicationId || ""} // Fallback to empty string if null/undefined
                        onChange={handleApplicationChange}
                        className="w-[380px] h-[40px] outline-none rounded-[8px] px-3 py-[8px] border border-[#E4E4E4] mt-[15px]"
                      >
                        {/* Add a placeholder option */}
                        <option value="" disabled>
                          Select an application
                        </option>

                        {applicationIds.length === 0 ? (
                          <option disabled>No applications available</option>
                        ) : (
                          [...applicationIds] // Create a copy to avoid modifying the original array
                            .sort((a, b) => a - b) // Sort numerically (for number-based IDs)
                            .map((id, index) => (
                              <option key={index} value={id}>
                                {id}
                              </option>
                            ))
                        )}
                      </select>
                    </div>
                  </div>

                  <div className="flex justify-center mt-9">
                    <div className="text-white text-xs font-semibold bg-[#017C57] rounded-full py-1 px-2 cursor-pointer mb-8">
                      <button
                        onClick={handleSaveChanges}
                        className="save-button"
                      >
                        Save Changes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {addUserPopVisible && (
        <div className="fixed top-0 left-0 h-screen w-screen bg-[#017c5780] flex justify-center items-center z-[1201]">
          <div className="relative bg-white rounded-[16px] p-10 h-[20%] w-[20%]">
            <Close
              className="absolute top-3 right-4 font-semibold text-2xl hover:text-gray-700 cursor-pointer"
              alt="close-icon"
              onClick={() => {
                setAddUserPopUpVisible(false);
              }}
            />
            <div className="flex h-full items-center justify-center gap-5">
              <input
                type="text"
                value={newUser}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d{0,10}$/.test(value)) {
                    setNewUser(value);
                  }
                }}
                style={{
                  border: "1px solid #E4E4E4",
                }}
                className="w-[380px] h-[40px] outline-none rounded-[8px] px-3 py-[8px]"
                maxLength={10}
              />

              <div
                style={{ border: "1px solid #017C57" }}
                className="text-[#017C57] px-6 py-2 rounded-full cursor-pointer"
                onClick={() => {
                  if (newUser.length === 10) {
                    setHospitalTmsCredentials([
                      ...hospitalTmsCredentials,
                      {
                        hospitalId: selectedUser?.hospital?.id,
                        pmjayUserId: selectedUser?.hospital?.pmjayUserId,
                        pmjayNumber: newUser,
                        credentialFor: "RPA",
                      },
                    ]);
                    setNewUser("");
                    setAddUserPopUpVisible(false);
                  } else {
                    window.alert("Phone Number must be of 10 digits");
                  }
                }}
              >
                Add
              </div>
            </div>
          </div>
        </div>
      )}
      {isLogPopupVisible && (
        <div className="popup-overlay">
          <div className="popup-container relative max-h-[90%] w-[328px] xl:w-[50%] bg-white rounded-[16px] pb-0 xl:p-0 z-[1200]">
            <div className="absolute right-12 top-8 cursor-pointer">
              <Close
                alt="close-icon"
                onClick={() => {
                  setIsLogPopupVisible(false);
                }}
              />
            </div>

            <div className="space-y-4 p-6">
              <h2 className="text-[#191919] text-[24px] font-semibold">
                Log Details
              </h2>
              <div className="overflow-auto max-h-[480px]">
                {" "}
                {/* Scrollable container */}
                <table className="w-full text-left">
                  <thead>
                    <tr>
                      <th className="text-[#191919] text-[18px] font-semibold p-2 sticky top-0 bg-white">
                        Request ID
                      </th>
                      <th className="text-[#191919] text-[18px] font-semibold p-2 sticky top-0 bg-white">
                        Request Type
                      </th>
                      <th className="text-[#191919] text-[18px] font-semibold p-2 sticky top-0 bg-white">
                        Request By
                      </th>
                      <th className="text-[#191919] text-[18px] font-semibold p-2 sticky top-0 bg-white">
                        Request To (Application ID)
                      </th>
                      {/* Add more headers if needed */}
                    </tr>
                  </thead>
                  <tbody>
                    {rpaLogs.map((log, logIndex) => (
                      <tr key={logIndex} className="border-b border-gray-200">
                        <td className="p-2">{log?.id || "--"}</td>
                        <td className="p-2">
                          {log?.requestType
                            ? log?.requestType?.replace("_", " ")
                            : "-"}
                        </td>
                        <td className="p-2">{log?.hospital?.name || "--"}</td>
                        <td className="p-2">
                          {log?.hospital?.applicationId || "--"}
                        </td>
                        {/* Add more columns if needed */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="flex justify-center mt-4">
        <CustomPagination
          page={page}
          setPage={setPage}
          totalPages={totalPages}
        />
      </div>
    </div>
  );
};

export default ManagedRpa;
