import { useState, useEffect } from "react";
import HospitalCustomPaginaion from "../../utils/HospitalPaginationUtil";
import EditSubAdmin from "./EditSubAdmin";
import AddSubAdmin from "./AddSubAdmin";
import { getlist } from "../../services/AdminServices";
import RemoveIcon from "../../assets/removeIcon.svg";
import EditIcon from "../../assets/editIcon.svg";
import AddIcon from "../../assets/addMoreIcon.svg";
const HospitalPersonaSubAdmin = () => {
  const [subAdminList, setSubAdminList] = useState(null);
  const [addSubAdminModal, setAddSubAdminModal] = useState(false);
  const [editInfo, setEditInfo] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const limit = 10;
  const getSubAdminList = async () => {
    try {
      const res = await getlist();
      console.log("admin list", res);
      setSubAdminList(res?.data?.Users);
    } catch (error) {
      window.alert(error);
    }
  };
  useEffect(() => {
    getSubAdminList();
  }, []);
  return (
    <div>
      <div
        style={{ color: "rgba(0,0,0,0.5)" }}
        className="text-[11px] 2xl:text-sm leading-[18.27px] -tracking-[3%]"
      >
        Home / Dashboard / Sub Admin
      </div>
      <div className="flex justify-between items-center">
        <div className="w-fit text-[30px] 2xl:text-[50px] leading-[65.26px] -tracking-[7%]  mt-[12px] 2xl:mt-[30px]">
          Sub Admin
        </div>
        <div
          className="w-fit flex items-center gap-5 text-[12px] 2xl:text-sm text-white bg-[#017C71] -tracking-[3%] uppercase px-4 2xl:px-5 py-[12px] 2xl:py-[15px] rounded-full cursor-pointer"
          onClick={() => setAddSubAdminModal(true)}
        >
          Add Sub-Admin{" "}
          <img
            src={AddIcon}
            alt="add-icon"
            width={18}
            height={18}
            className="h-[14px] w-[14px] 2xl:h-[18px] 2xl:w-[18px]"
          />
        </div>
      </div>
      <div
        id="case-list"
        style={{ background: "rgba(255,255,255,0.5)" }}
        className="p-4 2xl:p-8 mt-1 2xl:mt-4 rounded-[30px] min-h-[70vh]"
      >
        <div className="text-[30px] 2xl:text-[50px]">List of Sub-Admins</div>
        <div className="bg-white py-[30px] px-[38px] mt-[30px] rounded-[15px]">
          <div className="flex justify-between">
            <div className="w-[60%] 2xl:w-[40%] grid grid-cols-3 text-sm font-semibold -leading-[3%]">
              <div>Name</div>
              <div>Email</div>
              <div>Phone</div>
            </div>
            <div>Action</div>
          </div>
          <div
            style={{ borderTop: "1px solid #0000001A", marginBlock: "30px" }}
          />
          {subAdminList?.map((user, idx) => {
            return (
              <div key={idx} className="flex justify-between items-center mt-8">
                <div className="w-[60%] 2xl:w-[40%] grid grid-cols-3 text-sm leading-[18px]">
                  <div>{user.fullName}</div>
                  <div>{user.email}</div>
                  <div>{user.phone}</div>
                </div>
                <div className="flex gap-2 justify-end items-center cursor-pointer">
                  <div>
                    <img
                      src={RemoveIcon}
                      alt="remove-icon"
                      width={17}
                      height={17}
                    />
                  </div>
                  <div>
                    <img
                      src={EditIcon}
                      alt="edit-icon"
                      onClick={() => setEditInfo(user)}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {addSubAdminModal && (
        <AddSubAdmin
          setOpen={setAddSubAdminModal}
          getSubAdminList={getSubAdminList}
        />
      )}
      {editInfo && (
        <EditSubAdmin
          setOpen={setEditInfo}
          user={editInfo}
          getSubAdminList={getSubAdminList}
        />
      )}
    </div>
  );
};

export default HospitalPersonaSubAdmin;
