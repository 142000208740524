import React, { useState, useEffect } from "react";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export default function HospitalCustomPaginaion({ page, setPage, totalPages }) {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  if (!page) {
    page = 1;
  }

  if (!totalPages) {
    totalPages = 1;
  }
  const handleChange = (e, value) => {
    setPage(value);
  };

  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  return (
    <Stack spacing={2}>
      <Pagination
        count={totalPages}
        defaultPage={page}
        siblingCount={width > 640 ? 1 : 0}
        // boundaryCount={1}
        onChange={handleChange}
        renderItem={(item) => (
          <PaginationItem
            components={{
              previous: (props) => (
                <div
                  {...props}
                  style={{
                    color: "#000000",
                    background: "transparent",
                    height: "36px",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    justifyContent: "center",
                    fontFamily: "Inter",
                  }}
                  className="w-fit xl:w-[138px] text-[12px] 2xl:text-[14px] border-none"
                >
                  <ArrowBackIcon />
                  <div className="hidden xl:block">PREVIOUS</div>
                </div>
              ),
              next: (props) => (
                <div
                  {...props}
                  style={{
                    color: "#000000",
                    height: "36px",
                    borderRadius: "32px",
                    backgroundColor: "transparent",
                    // width: "101px",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    justifyContent: "center",

                    fontFamily: "Inter",
                  }}
                  className="w-fit xl:w-[100px] text-[12px] 2xl:text-[14px]"
                >
                  <div className="hidden xl:block">NEXT</div>
                  <ArrowForwardIcon />
                </div>
              ),
            }}
            sx={{
              "&.MuiPaginationItem-root": {
                color: "#191919", // Custom color for pagination items
                "&.Mui-selected": {
                  backgroundColor: "#000000", // Custom color for selected item
                  color: "#ffffff", // Custom text color for selected item
                },
              },
            }}
            {...item}
          />
        )}
      />
    </Stack>
  );
}
