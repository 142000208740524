import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import HospitalSettlemedLogo from "../../assets/HospitalSettlemedLogo.svg";
import HospitalSettlemedText from "../../assets/HospitalSettlemedName.svg";
import RotatedArrow from "../../assets/rotatedArrow.svg";
import CaseMappingIcon from "../../assets/caseMappingIcon.svg";
import SearchIcon from "../../assets/searchIcon.svg";
import HospitalCallIcon from "../../assets/hospitalCallIcon.svg";
import HospitalBellIcon from "../../assets/hospitalBellIcon.svg";
import MaleProfileIcon from "../../assets/maleProfileIcon.svg";
import DownArrow from "../../assets/downArrowWhite.svg";
import { logOut, getUser } from "../../config/user";
import { RpaServices } from "../../services/RpaServices";
const Header = () => {
  const [expandProfile, setExpandProfile] = useState(false);
  const navigate = useNavigate();
  const user = getUser();
  const { notificationCount } = useSelector(
    (store) => store.notificationReducer
  );

  console.log("user:", user);
  const handleNewCaseMapping = async () => {
    try {
      const res = await RpaServices.checkNewCaseAdded(user?.hospital?.id);
      console.log("case added res:", res.data);
      if (res?.data?.total === 0) {
        const data = { hospitalId: user?.hospital?.id, caseStatus: "NEW_CASE" };
        await RpaServices.addRpaRequest(data);
        window.alert(
          "The Case is currently in progress and will appear in the list soon"
        );
      } else {
        window.alert(
          "A case is already in progress. Please wait before adding a new case."
        );
      }
    } catch (error) {
      window.alert(error);
    }
  };
  return (
    <div className="font-lufga flex justify-between items-center box-border">
      <div className="flex gap-4 items-center">
        <div onClick={() => navigate("/")}>
          <img src={HospitalSettlemedLogo} alt="logo" height={32} width={32} />
        </div>
        <div onClick={() => navigate("/")}>
          <img
            src={HospitalSettlemedText}
            alt="settlemed-text"
            height={32}
            width={112}
          />
        </div>
        <div className="text-sm 2xl:text-[16px]">
          The future of claim processing
        </div>
      </div>
      <div className="flex gap-[18px] 2xl:gap-[30px] items-center">
        {/* <div
          style={{
            background: "rgba(255,255,255,0.5)",
            border: "1px solid #FFFFFF",
          }}
          className="flex justify-between items-center xl:w-[300px] 2xl:w-[500px] px-5 py-[15px] rounded-full"
        >
          <input
            type="text"
            placeholder="SEARCH ANYTHING"
            className="w-[80%] bg-transparent outline-none border-none"
          />
          <img src={SearchIcon} alt="search-icon" />
        </div> */}
        {user?.hospital && (
          <div
            style={{
              border: "1px solid #017C71",
              background: "rgba(255,255,255,0.5)",
            }}
            className="flex items-center gap-[10px] text-sm 2xl:text-[16px] xl:px-3 2xl:px-5 xl:py-4 2xl:py-[18.5px] uppercase rounded-full cursor-pointer"
            onClick={handleNewCaseMapping}
          >
            New Case Mapping
            <img src={CaseMappingIcon} alt="arrow" height={20} width={20} />
          </div>
        )}
        {/* <div
          style={{
            border: "1px solid #FFFFFF",
            background: "rgba(255,255,255,0.5)",
          }}
          className="flex items-center gap-[10px] text-sm 2xl:text-[16px] xl:px-3 2xl:px-5 xl:py-4 2xl:py-[18.5px] uppercase rounded-full cursor-pointer"
        >
          Create abha id
          <img src={RotatedArrow} alt="arrow" height={12} width={12} />
        </div> */}
        {user?.hospital && (
          <div className="flex gap-1 items-center">
            {/* <div className="rounded-full cursor-pointer">
            <img src={HospitalCallIcon} alt="call-icon" />
          </div> */}
            <div
              className="relative rounded-full cursor-pointer"
              onClick={() => navigate("/dashboard/notifications")}
            >
              <img src={HospitalBellIcon} alt="notification-icon" />

              <div className="absolute w-[25px] h-[25px] flex justify-center items-center -top-1 -right-2  text-[10px]  text-white text-center  bg-red-500 rounded-full ">
                {notificationCount > 99 ? "99+" : notificationCount}
              </div>
            </div>
          </div>
        )}
        {user?.hospital && (
          <div
            className={`relative bg-[#1b1b1b] p-1 ${
              expandProfile ? "rounded-t-[27px]" : "rounded-full"
            } cursor-pointer box-border`}
          >
            <div
              className="flex items-center"
              onClick={() => setExpandProfile(!expandProfile)}
            >
              <div className="w-[45px] h-[45px] flex items-center justify-center bg-white rounded-full">
                <img
                  src={MaleProfileIcon}
                  alt="profile-icon"
                  width={29}
                  height={29}
                />
              </div>
              {user?.hospital && (
                <div className="ml-[11px] text-white">
                  <div className="text-xs 2xl:text-sm uppercase leading-[15px] -tracking-[0.5px]">
                    {user?.user?.fullName}
                  </div>{" "}
                  <div className="text-[10px] 2xl:text-xs leading-[18px]">
                    {user?.roles[0]?.role?.roleName}
                  </div>
                </div>
              )}
              <div
                className="ml-7 mr-4"
                onClick={() => setExpandProfile(!expandProfile)}
              >
                <img
                  src={DownArrow}
                  alt="down-arrow"
                  className={`transition-all duration-300 ease-in-out ${
                    expandProfile ? "rotate-180" : "rotate-0"
                  }`}
                />
              </div>
            </div>
            <div
              className={`absolute left-0 w-full bg-[#1B1B1B] py-[5px] px-1 rounded-b-[27px] box-border transition-all duration-300 ease-in-out ${
                expandProfile
                  ? "max-h-[200px] opacity-100"
                  : "max-h-0 opacity-0"
              } overflow-hidden`}
            >
              {/* <div className="text-xs leading-[15.66px] -tracking-[3%] bg-[#017C71] text-white px-5 py-[18.5px] rounded-full">
              PROFILE
            </div> */}
              <div
                className="text-xs text-center leading-[15.66px] -tracking-[3%] bg-white text-black px-5 py-[18.5px] rounded-full mt-[5px]"
                onClick={() => {
                  logOut();
                }}
              >
                LOGOUT
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
