import React, { useEffect } from "react";
import { Field, useFormikContext } from "formik";
import Select from "react-select";
import "./AddCaseProcedure.scss";

import { useState } from "react";
import SuggestionBoxUtil from "../../../utils/SuggestionBoxUtil";
import { CaseServices } from "../../../services/CaseServices";
import { logOut } from "../../../config/user";
import { HospitalServices } from "../../../services/HospitalServices";
import EditProcedure from "./EditProcedure";
import EditIcon from "../../../assets/editIcon.svg";
import DeleteIcon from "../../../assets/deleteIcon.svg";

const AddCaseProcedure = ({
  errors,
  touched,
  index,
  procdure,
  patientDetails,
  values,
  remove,
  addProcedure,
}) => {
  let staratificationEnums = [
    "ICUWITHVENTILATOR",
    "ICUWITHOUTVENTILATOR",
    "HDU",
    "GENERALWARD",
  ];
  const [selectedProcedureId, setSelectedProcedureId] = useState(null);
  const [compositId, setCompositId] = useState(
    procdure?.compositId ? procdure.compositId : null
  );
  const [doctorList, setDoctorList] = useState([]);
  const [implants, setImplants] = useState([]);
  const [tierCost, setTierCost] = useState(null);
  const [packageCost, setPackageCost] = useState(null);
  const [procedureInputValue, setProcedureInputValue] = useState(null);
  const [specialityInputValue, setSpecialityInputValue] = useState(null);
  const [doctorName, setDoctorName] = useState(procdure.doctorName);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const { setFieldValue } = useFormikContext();
  const handleStratificationChange = (event, newValue) => {
    setFieldValue(`caseProcedures.${index}.stratification`, newValue);
  };

  const handleAutoSuggestion = (selected, e) => {
    setFieldValue(`caseProcedures.${index}.${e.name}`, selected.value);

    if (e.name === "procedure") {
      setFieldValue(
        `caseProcedures.${index}.procedureId`,
        selected.procedureId
      );
      setFieldValue(`caseProcedures.${index}.compositId`, selected.id);
      setCompositId(selected.id);
      setSelectedProcedureId(selected.procedureId);
    } else if (e.name === "speciality") {
      setFieldValue(
        `caseProcedures.${index}.specialityId`,
        selected.specialityId
      );
    }
  };
  // const getProcedureTypeList = async () => {
  //   try {
  //     const response = await CaseServices.getDefaultProcedureTypeList(
  //       selectedProcedureId
  //     );
  //     setProcedureTypeLIst(response?.data?.list);
  //   } catch (error) {
  //     window.alert(error);
  //   }
  // };
  const getProcedureCostList = async () => {
    try {
      const response = await CaseServices.getProcedureCostList(
        compositId,
        patientDetails.city
      );
      setFieldValue("tier", response?.data?.procedureCost?.tier);
      if (response?.data?.procedureCost?.implants) {
        setImplants(response?.data?.procedureCost?.implants);
      } else {
        setImplants([]);
      }
      if (Array.isArray(response?.data?.procedureCost?.tierCost)) {
        setTierCost(response?.data?.procedureCost?.tierCost);
        setPackageCost(null);
        setFieldValue(`totalPackageCost`, null);
        setFieldValue(`caseProcedures.${index}.packageCost`, null);
      } else {
        setTierCost(null);
        setFieldValue(
          `caseProcedures.${index}.packageCost`,
          response?.data?.procedureCost?.tierCost
        );
        // setFieldValue(
        //   "totalPackageCost",
        //   Number(values.totalPackageCost) +
        //     Number(response?.data?.procedureCost?.tierCost)
        // );
        setPackageCost(response?.data?.procedureCost?.tierCost);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
  };
  const getDoctorList = async () => {
    try {
      const response = await HospitalServices.getDoctorList(
        "",
        1,
        40,
        patientDetails.hospitalId
      );
      setDoctorList(response.data.list);
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
  };

  useEffect(() => {
    getDoctorList();
  }, []);
  useEffect(() => {
    if (compositId) {
      getProcedureCostList();
    }
  }, [compositId]);
  useEffect(() => {
    if (packageCost) {
      const totalCost = values?.caseProcedures?.reduce(
        (accumulator, currentValue) =>
          Number(accumulator) + Number(currentValue.packageCost),
        0
      );
      setFieldValue("totalPackageCost", totalCost);
    }
  }, [packageCost]);
  return (
    <div className="add-case-procedure-container">
      <div className="flex justify-between items-center">
        <div className="sub-heading">
          Procedure {index + 1 > 0 ? index + 1 : ""}
        </div>

        <div
          style={{ border: "1px solid #017C57" }}
          className="flex gap-2 text-[#017C57] items-center h-fit py-1 xl:py-2 px-5 rounded-full cursor-pointer"
          onClick={() => setIsEditOpen(true)}
        >
          <div>
            <img src={EditIcon} alt="edit-icon" />
          </div>{" "}
          Edit
        </div>

        {values?.caseProcedures?.length > 1 && (
          <div
            className="ml-2 cursor-pointer"
            onClick={() => addProcedure(remove)}
          >
            <img src={DeleteIcon} alt="" />
          </div>
        )}
      </div>
      {isEditOpen && (
        <EditProcedure
          setIsEditOpen={setIsEditOpen}
          specialityParam={procdure.speciality}
          specialityIdParam={procdure.specialityId}
          procedureParam={procdure.procedure}
          procedureIdParam={procdure.procedureId}
          compositIdParam={procdure.compositId}
          type="EDIT"
        />
      )}
      <div className="case-form-container1">
        <label className="label1">
          <div className="text-content">Speciality</div>
          <SuggestionBoxUtil
            className="input-field"
            api={CaseServices.defaultSpecialityList}
            selectedValue={procdure.speciality}
            name="speciality"
            handleChange={handleAutoSuggestion}
            setInputValue={setSpecialityInputValue}
          />
          {touched &&
            touched.caseProcedures &&
            touched.caseProcedures[`${index}`] &&
            touched.caseProcedures[`${index}`].speciality &&
            errors &&
            errors.caseProcedures &&
            errors?.caseProcedures[`${index}`]?.speciality && (
              <div className="field-error">
                {errors?.caseProcedures[`${index}`]?.speciality}
              </div>
            )}
        </label>
        <label className="label1">
          <div className="text-content">Procedure Id & Name</div>
          <SuggestionBoxUtil
            className="input-field"
            searchParams={`${procdure.speciality}`}
            api={CaseServices.defaultProcedureNameList}
            selectedValue={procdure.procedure}
            name="procedure"
            handleChange={handleAutoSuggestion}
            setInputValue={setProcedureInputValue}
          />
          {touched &&
            touched.caseProcedures &&
            touched.caseProcedures[`${index}`] &&
            touched.caseProcedures[`${index}`].procedure &&
            errors &&
            errors.caseProcedures &&
            errors?.caseProcedures[`${index}`]?.procedure && (
              <div className="field-error">
                {errors?.caseProcedures[`${index}`]?.procedureId}
              </div>
            )}
        </label>
        {/* <label className="label1">
          <div className="text-content">Procedure Type</div>
          <Select
            options={procedureTypeList}
            getOptionLabel={(options) => {
              return options["procedureType"];
            }}
            getOptionValue={(options) => {
              return options["compositKey"];
            }}
            placeholder="Select Procedure Type"
            onChange={(item) => {
              setFieldValue(
                `caseProcedures.${index}.procedureType`,
                item.procedureType
              );
              setFieldValue(`caseProcedures.${index}.compositId`, item.id);
              setCompositId(item.id);
            }}
          />

          {touched &&
            touched.caseProcedures &&
            touched.caseProcedures[`${index}`] &&
            touched.caseProcedures[`${index}`].ichiCode &&
            errors &&
            errors.caseProcedures &&
            errors?.caseProcedures[`${index}`]?.ichiCode && (
              <div className="field-error">
                {errors?.caseProcedures[`${index}`]?.ichiCode}
              </div>
            )}
        </label> */}
        <label className="label1">
          <div className="text-content">ICHI Code/Description</div>
          <Field
            /*as='select'*/ className="input-field"
            name={`caseProcedures.${index}.ichiCode`}
          />
          {touched &&
            touched.caseProcedures &&
            touched.caseProcedures[`${index}`] &&
            touched.caseProcedures[`${index}`].ichiCode &&
            errors &&
            errors.caseProcedures &&
            errors?.caseProcedures[`${index}`]?.ichiCode && (
              <div className="field-error">
                {errors?.caseProcedures[`${index}`]?.ichiCode}
              </div>
            )}
        </label>
        {/* Showing stratification only when there is tier cost array */}
        {Array.isArray(tierCost) && (
          <label className="label1">
            <div className="text-content">Stratification</div>
            <Select
              options={tierCost}
              getOptionLabel={(options) => {
                return options["name"];
              }}
              getOptionValue={(options) => {
                return options["name"];
              }}
              placeholder="Select Stratification"
              value={tierCost.filter(
                (option) => option.name === procdure.stratification
              )}
              onChange={(item) => {
                setPackageCost(item.price);
                // values.caseProcedures[`${index}`].packageCost
                //   ? setFieldValue(
                //       "totalPackageCost",
                //       Number(values.totalPackageCost) -
                //         Number(values.caseProcedures[`${index}`].packageCost) +
                //         Number(item.price)
                //     )
                //   : setFieldValue(
                //       "totalPackageCost",
                //       Number(values.totalPackageCost) + Number(item.price)
                //     );
                setFieldValue(
                  `caseProcedures.${index}.packageCost`,
                  Number(item.price)
                );

                setFieldValue(
                  `caseProcedures.${index}.stratification`,
                  item.name
                );
              }}
            />
            {touched &&
              touched.caseProcedures &&
              touched.caseProcedures[`${index}`] &&
              touched.caseProcedures[`${index}`].stratification &&
              errors &&
              errors.caseProcedures &&
              errors?.caseProcedures[`${index}`]?.stratification && (
                <div className="field-error">
                  {errors?.caseProcedures[`${index}`]?.stratification}
                </div>
              )}
          </label>
        )}
        {implants && implants.length > 0 && (
          <label className="label1">
            <div className="text-content">Implants</div>

            <Select
              options={implants}
              getOptionLabel={(options) => {
                return options["name"] + "-" + options["price"];
              }}
              getOptionValue={(options) => {
                return options["price"];
              }}
              placeholder="Select Implants"
              onChange={(item) => {
                setPackageCost(Number(packageCost) + Number(item.price));
                setFieldValue(`caseProcedures.${index}.implants`, item.name);
                setFieldValue(
                  `caseProcedures.${index}.packageCost`,
                  Number(packageCost) + Number(item.price)
                );
                // setFieldValue(
                //   `totalPackageCost`,
                //   Number(values.totalPackageCost) + Number(item.price)
                // );
              }}
            />
            {touched &&
              touched.caseProcedures &&
              touched.caseProcedures[`${index}`] &&
              touched.caseProcedures[`${index}`].implants &&
              errors &&
              errors.caseProcedures &&
              errors?.caseProcedures[`${index}`]?.implants && (
                <div className="field-error">
                  {errors?.caseProcedures[`${index}`]?.implants}
                </div>
              )}
          </label>
        )}
        <label className="label1">
          <div className="text-content">Package Cost</div>
          <Field
            className="input-field"
            disabled
            name={`caseProcedures.${index}.packageCost`}
            placeholder="Package Cost"
          />
          {touched &&
            touched.caseProcedures &&
            touched.caseProcedures[`${index}`] &&
            touched.caseProcedures[`${index}`].packageCost &&
            errors &&
            errors.caseProcedures &&
            errors?.caseProcedures[`${index}`]?.packageCost && (
              <div className="field-error">
                {errors?.caseProcedures[`${index}`]?.packageCost}
              </div>
            )}
        </label>
        <label className="label1">
          <div className="text-content">Doctor Name</div>
          <Select
            options={doctorList}
            getOptionLabel={(options) => {
              return options["fullName"];
            }}
            getOptionValue={(options) => {
              return options["id"];
            }}
            placeholder="Select Doctor"
            value={doctorList.filter((option) => {
              return option.fullName === doctorName;
            })}
            onChange={(item) => {
              setFieldValue(
                `caseProcedures.${index}.doctorName`,
                item.fullName
              );
              setFieldValue(
                `caseProcedures.${index}.doctorId`,
                item.doctorhospitalmappings[0].doctorId
              );
              setDoctorName(item.fullName);
            }}
          />
          {touched &&
            touched.caseProcedures &&
            touched.caseProcedures[`${index}`] &&
            touched.caseProcedures[`${index}`].doctorName &&
            errors &&
            errors.caseProcedures &&
            errors?.caseProcedures[`${index}`]?.doctorName && (
              <div className="field-error">
                {errors?.caseProcedures[`${index}`]?.doctorName}
              </div>
            )}
        </label>
      </div>
    </div>
  );
};

export default AddCaseProcedure;
