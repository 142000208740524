import { useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginSignUpModal from "./LoginSignUpModal";
import RotatedArrow from "../assets/rotatedArrow.svg";
const LandingPageHeader = () => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const navigate = useNavigate();
  const handleLoginButtonClick = () => {
    navigate("/login-signup");
  };
  return (
    <div>
      <div className="relative w-screen h-[50vh]">
        <video
          autoPlay
          loop
          muted
          playsInline
          className={`absolute w-full h-full bg-[url("https://cdn.prod.website-files.com/65c73cab42d05747958d759e/65c8d90e8e01ed41a9c688d0_hero-back-video-poster-00001.jpg")] bg-cover object-cover bg-[50%] overflow-hidden -z-10`}
        >
          <source
            src="https://cdn.prod.website-files.com/65c73cab42d05747958d759e/65c8d90e8e01ed41a9c688d0_hero-back-video-transcode.mp4"
            type="video/mp4"
          />
          <source
            src="https://cdn.prod.website-files.com/65c73cab42d05747958d759e/65c8d90e8e01ed41a9c688d0_hero-back-video-transcode.webm"
            type="video/webm"
          />
        </video>
        <div className="relative h-full flex flex-col justify-between items-center pt-8 pb-12">
          <div className="absolute top-2 right-8">
            <div
              className="flex items-center gap-2 bg-white hover:bg-neutral-200 px-5 py-3 uppercase rounded-full cursor-pointer"
              onClick={() => setShowLoginModal(true)}
            >
              Login / Signup{" "}
              <img
                src="https://cdn.prod.website-files.com/65c73cab42d05747958d759e/65c8d63dc254bb0f97c01a3a_icon-arrow-right.svg"
                loading="lazy"
                alt="arrow-icon"
              />
            </div>
            <div
              style={{
                border: "1px solid #FFFFFF",
              }}
              className="flex items-center gap-[10px] text-sm 2xl:text-[16px] xl:px-3 2xl:px-5 py-3 bg-white hover:bg-neutral-200 uppercase rounded-full cursor-pointer mt-2"
            >
              Create abha id
              <img src={RotatedArrow} alt="arrow" height={12} width={12} />
            </div>
            <div
              style={{
                border: "1px solid #FFFFFF",
              }}
              className="flex items-center gap-[10px] text-sm 2xl:text-[16px] xl:px-3 2xl:px-5 py-3 bg-white hover:bg-neutral-200 uppercase rounded-full cursor-pointer mt-2"
              onClick={() => navigate("/survey")}
            >
              Survey Form
              <img src={RotatedArrow} alt="arrow" height={12} width={12} />
            </div>
          </div>
          <div className=" flex justify-center overflow-hidden">
            <img
              width="172"
              loading="lazy"
              alt=""
              src="https://cdn.prod.website-files.com/65c73cab42d05747958d759e/65c8d63d6f05ec6d1bb016dc_Group%201171274802.svg"
              //   class="settlemed-logo-hero"
            />
          </div>
          <div className="w-[80%]">
            <p className="text-xl text-white max-w-[325px]">
              Where the people, policies, & insights of innovation come together
            </p>
          </div>
        </div>
      </div>
      <div className="bg-black px-[3%]">
        <div className="max-w-[1600px] mx-auto pt-[60px] pb-[80px]">
          <div className="flex justify-between items-end gap-[50px]">
            <div className="text-[64px] text-white capitalize max-w-[1097px] leading-none -tracking-[0.08em]">
              Settlemed AI<sup>TM</sup> , Transforming Ayushman Mediclaim for
              Effortless Operations and Financial Excellence with Patient
              Satisfaction!
            </div>
            <div className="flex">
              <div className="text-xl text-white font-light border border-white rounded-full py-5 px-14">
                Contact Us
              </div>
              <div className=" w-[100px] h-[74px] flex items-center justify-center bg-white rounded-full">
                <img
                  src="https://cdn.prod.website-files.com/65c73cab42d05747958d759e/65c8d63dc254bb0f97c01a3a_icon-arrow-right.svg"
                  loading="lazy"
                  alt="arrow-icon"
                />
              </div>
            </div>
          </div>
          <div className="max-w-[1550px] mx-auto my-10 overflow-hidden">
            <img
              src="https://cdn.prod.website-files.com/65c73cab42d05747958d759e/65ca2b265f4a77c5a3ec69ae_div.Process_process__border__rYio1.webp"
              loading="lazy"
              style={{
                transform:
                  "translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d",
              }}
              data-w-id="00944d5e-306b-ed01-a1e6-cee3367888aa"
              alt=""
            />
          </div>
          <div className="flex justify-between items-start gap-[50px]">
            <div className="max-w-[420px]">
              <div className="text-[#00B582] mb-[30px] text-[1.3rem]">2024</div>
              <img
                src="https://cdn.prod.website-files.com/65c73cab42d05747958d759e/65c8d63d3582e0a4b917d0da_circle%20%20icons.svg"
                loading="lazy"
                width="351"
                style={{
                  opacity: 1,
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  transformStyle: "preserve-3d",
                }}
                alt=""
                data-w-id="66b016f8-6bc5-ad5a-ab93-dbfc24ae7a7c"
                className="max-w-[420px]"
              />
            </div>
            <div>
              <div className="flex justify-end items-start gap-[10px] mb-[90px]">
                <a
                  href="https://www.linkedin.com/company/settlemed1/"
                  target="_blank"
                  class="social-link w-inline-block"
                  className="w-[50px] h-[50px] flex justify-center items-center border border-white z-[50] rounded-full"
                >
                  <img
                    src="https://cdn.prod.website-files.com/65c73cab42d05747958d759e/65c8ea345dcfd3322ae0ebb0_linkedln.svg"
                    loading="lazy"
                    alt=""
                  />
                </a>
                <a
                  href="https://www.instagram.com/settlemed_/"
                  target="_blank"
                  className="w-[50px] h-[50px] flex justify-center items-center border border-white z-[50] rounded-full"
                >
                  <img
                    src="https://cdn.prod.website-files.com/65c73cab42d05747958d759e/65c8ea3541307db45d44b8f5_ig.svg"
                    loading="lazy"
                    alt=""
                  />
                </a>
                <a
                  href="https://twitter.com/settleMed_"
                  target="_blank"
                  className="w-[50px] h-[50px] flex justify-center items-center border border-white z-[50] rounded-full"
                >
                  <img
                    src="https://cdn.prod.website-files.com/65c73cab42d05747958d759e/65f6e2587d17b07b8964a828_Screenshot_Capture_-_2024-03-17_-_17-23-42-removebg-preview.png"
                    loading="lazy"
                    width="25"
                    alt=""
                  />
                </a>
              </div>
              <div className="max-w-[510px] text-[1.3rem] text-left text-white leading-none">
                The Power of AI to Automate Mediclaim Processing, Streamlining
                and Accelerating the Often Costly, Time-Consuming, and
                Error-Prone Claims Settlement Processes
              </div>
            </div>
          </div>
        </div>
      </div>
      {showLoginModal && <LoginSignUpModal setOpen={setShowLoginModal} />}
    </div>
  );
};

export default LandingPageHeader;
