import React from "react";

const Solutions = () => {
  return (
    <div className="bg-black  text-white px-[3%]">
      <div className="max-w-[1600px] mx-auto pt-[60px] pb-[140px]">
        <div className="text-[#00B582] text-[1.3rem] font-light mb-[30px]">
          Solutions
        </div>
        <div className="max-w-[1307px] text-[4rem] -tracking-[0.08em] capitalize leading-none">
          Mediclaim where <br /> AI is optimizing the processes
        </div>
        <img
          src="https://cdn.prod.website-files.com/65c73cab42d05747958d759e/65c8d63de5e3d33f4b7beeb1_approach-line.webp"
          width="1550"
          alt=""
          style={{
            transform:
              "translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d",
          }}
          sizes="94vw"
          srcSet="https://cdn.prod.website-files.com/65c73cab42d05747958d759e/65c8d63de5e3d33f4b7beeb1_approach-line-p-500.webp 500w, https://cdn.prod.website-files.com/65c73cab42d05747958d759e/65c8d63de5e3d33f4b7beeb1_approach-line-p-800.webp 800w, https://cdn.prod.website-files.com/65c73cab42d05747958d759e/65c8d63de5e3d33f4b7beeb1_approach-line-p-1080.webp 1080w, https://cdn.prod.website-files.com/65c73cab42d05747958d759e/65c8d63de5e3d33f4b7beeb1_approach-line-p-1600.webp 1600w, https://cdn.prod.website-files.com/65c73cab42d05747958d759e/65c8d63de5e3d33f4b7beeb1_approach-line-p-2000.webp 2000w, https://cdn.prod.website-files.com/65c73cab42d05747958d759e/65c8d63de5e3d33f4b7beeb1_approach-line.webp 3100w"
          className="my-[5rem] mx-auto"
        />
        <div className="text-[#00B582] text-[1.3rem] font-light mb-[30px]">
          why settlemed?
        </div>
        <p
          data-w-id="6c0c8784-c0d8-9364-0982-e0cbddbf17bf"
          style={{
            opacity: 1,
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d",
          }}
          className="text-white text-[4rem] font-light leading-none -tracking-[0.08em]"
        >
          The primary objective of the National Health Authority (NHA) is to
          streamline claim processing. In alignment with this goal, Settlemed
          offers solutions with{" "}
          <strong className="text-[#00B582] font-medium">
            Code Pilot AI<sup>TM</sup>
          </strong>{" "}
          and{" "}
          <strong className="text-[#00B582] font-medium">
            Claim Denial Prediction AI<sup>TM</sup>
          </strong>
          , which ensure accurate patient admission coding. Traditionally, this
          task relies on certified professional coders and specialist doctors,
          but engaging these highly skilled professionals incurs significant
          upfront costs for hospitals. Additionally, it diverts medical
          professionals' focus from patient care to repetitive paperwork,
          leading to inefficiencies.
          <br />
          <br />
          The real-time availability of specialist doctors for claim submission
          can be challenging.{" "}
          <strong className="text-[#00B582] font-medium">
            Claim Denial Prediction AI<sup>TM</sup>
          </strong>{" "}
          addresses this issue by allowing hospitals to check the chances of
          claim denial before submission. This facilitates the initiation of the
          correct documentation in real time, while maintaining accuracy and
          transparency in the admission process,
          <span className="text-[#00B582] font-medium">
            {" "}
            resulting in clean claims
          </span>
        </p>
      </div>
    </div>
  );
};

export default Solutions;
