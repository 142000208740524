import React, { useState } from "react";
import { Loginpage } from "../login/loginpage";
import { Signuppage } from "../signup/signuppage";
import { ForgotpasswordSteps } from "../forgotpassword/ForgotPasswordSteps";
import { Privacypolicy } from "../privacypolicy/Privacypolicy";
import "./homepage.scss"; // Import your stylesheet
import background from "../../background2.jpg";

const Homepage = () => {
  const [signup, setSignup] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(true);

  return (
    <div className="home-container">
      <div
        className="background-image hidden xl:block"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="yellow-overlay"></div>
        <div className="content  h-[100%] flex items-center">
          <div className="wholecontent">
            <div className="hidden xl:flex flex-col justify-between">
              <text
                style={{
                  fontSize: 14,
                  color: "#FFFFFF",
                  fontFamily: "Inter",
                  marginBottom: 10,
                }}
              >
                Settlemed Helps You Simplify Your Medical Claim Processing
              </text>
              <div className="texts">
                <h2>1,000+ Hospitals</h2>
                <h2>50,000+ Happy Patients</h2>
                <h2>75,000+ Claim Settlements</h2>
                <hr className="horizontal-line" />
              </div>
            </div>

            <div className="mt-10">
              {signup ? (
                <Signuppage setSignup={setSignup} />
              ) : forgotPassword ? (
                <ForgotpasswordSteps />
              ) : (
                <Loginpage
                  setSignup={setSignup}
                  setForgotPassword={setForgotPassword}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="xl:hidden flex items-center  bg-[]">
        <div className="mt-[134px] bg-white">
          {signup ? (
            <Signuppage setSignup={setSignup} />
          ) : forgotPassword ? (
            <ForgotpasswordSteps />
          ) : (
            <Loginpage
              setSignup={setSignup}
              setForgotPassword={setForgotPassword}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Homepage;
