import { useState, useEffect } from "react";
import Select from "react-select";
import { Formik, Form, Field } from "formik";
import { number, object, string, date } from "yup";
import { CaseServices } from "../../../services/CaseServices";
import { logOut } from "../../../config/user";
import { CircularProgress } from "@mui/material";
import CloseIcon from "../../../assets/hospitalCloseIcon.svg";
import RightArrow from "../../../assets/rightArrowWhite.svg";
const errorValidation = object({
  days: number().required("Required Field"),
  fromDate: date().required("Required Field"),
  toDate: date().required("Required Field"),
  ward: string().required("Required Field"),
});
const EnhancementFormHospitalPersona = ({
  totalPackageCost,
  setAddEnhancement,
  caseDetails,
  caseId,
  admissionDate,
  AllCases,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [latestEnhancement, setLatestEnhancement] = useState(null);
  const [tierCost, setTierCost] = useState([]);
  const initialValues = {
    days: "",
    fromDate: "",
    toDate: "",
    ward: "",
    procedureId: "",
  };
  const daysOptions = {
    primary: [
      { value: "1", label: "1" },
      { value: "2", label: "2" },
    ],
    secondary: [
      { value: "1", label: "1" },
      { value: "2", label: "2" },
      { value: "3", label: "3" },
    ],
    tertiary: [
      { value: "1", label: "1" },
      { value: "2", label: "2" },
      { value: "3", label: "3" },
      { value: "4", label: "4" },
      { value: "5", label: "5" },
    ],
  };
  const wardOptions = [
    { value: "ICU with ventilator", label: "ICU with ventilator" },
    { value: "ICU without ventilator", label: "ICU without ventilator" },
    { value: "HDU", label: "HDU" },
    { value: "General Ward", label: "General Ward" },
    { value: "SNCU/NICU", label: "SNCU/NICU" },
  ];
  const handleDaysChange = (item, setFieldValue, values) => {
    setFieldValue("days", item.value);
    if (values["fromDate"]) {
      const toDate = new Date(values["fromDate"]);
      toDate.setDate(
        new Date(values["fromDate"]).getDate() + Number(item.value) - 1
      );
      setFieldValue("toDate", toDate.toISOString().split("T")[0]);
    }
  };
  const handleDateChange = (e, setFieldValue, values) => {
    {
      const toDate = new Date(e.target.value);
      toDate.setDate(
        new Date(e.target.value).getDate() + Number(values["days"]) - 1
      );

      setFieldValue("fromDate", e.target.value);
      setFieldValue("toDate", toDate.toISOString().split("T")[0]);
    }
  };

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    const data = [
      {
        detailType: "ENHANCEMENT",
        caseId: caseId,
        details: {
          procedureId: values.procedureId,
          enhancementDate: new Date(),
          fromDate: values.fromDate,
          toDate: values.toDate,
          wardType: values.ward,
          enhancementDays: values.days,
          cost: totalPackageCost * Number(values.days),
          enhancementPerDayCost: totalPackageCost,
        },
        status: 1,
        version: 0,
      },
    ];

    try {
      await CaseServices.addEnhancement(data);
      await AllCases();
      setAddEnhancement(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else if (error?.response?.status === 420) {
        window.alert(
          "Enhancement documents are not yet mapped. Please perform Initiate Enhancement process when documents are mapped."
        );
      } else if (error?.response?.data?.message) {
        window.alert(error?.response?.data?.message);
      } else {
        window.alert(error);
      }
    }
    setIsSubmitting(false);
  };
  const getLatestCaseStage = async () => {
    try {
      const response = await CaseServices.getLatestCaseStage(caseId);
      if (response?.data?.currnetSubMasterStage[0]) {
        //   setLatestStage(response?.data?.currnetSubMasterStage[0]?.hsaStage);

        setLatestEnhancement(response?.data?.latestEnhancement);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
  };
  const getProcedureCostList = async () => {
    try {
      const response = await CaseServices.getProcedureCostList(
        caseDetails?.caseprocedures[0]?.compositId,
        caseDetails?.patient?.city
      );
      console.log("response procedure cost list:", response.data);
      if (response?.data?.procedureCost?.tierCost) {
        setTierCost(response?.data?.procedureCost?.tierCost);
      }
    } catch (error) {
      window.alert(error);
    }
  };
  useEffect(() => {
    document.body.style.overflow = "hidden";
    getLatestCaseStage();
    getProcedureCostList();
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  console.log("latest enhancement:", latestEnhancement);
  return (
    <div className="fixed top-0 left-0 w-screen h-screen flex justify-center items-center bg-[#017C7180]">
      <div className="relative h-fit w-[50%] bg-white p-[30px] rounded-[30px]">
        <div
          className="absolute -top-14 -right-14 cursor-pointer"
          onClick={() => setAddEnhancement(false)}
        >
          <img src={CloseIcon} alt="close-icon" />
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={errorValidation}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, setFieldValue, values }) => (
            <Form>
              <div className="flex justify-between items-center">
                <div className="text-[40px] font-medium text-black -tracking-[7%]">
                  Enhancement
                </div>
              </div>
              <div
                style={{ borderTop: "1px solid #969696", marginBlock: "25px" }}
              />
              <div className="flex items-end">
                <div className="w-[70%] flex flex-wrap gap-y-10 ">
                  <div className="w-full">
                    <div className="text-sm text-[#0B0B0B] leading-[21.7px] -tracking-[3%] mb-4">
                      1. In which ward would you like to take the enhancement?
                    </div>
                    <Select
                      name="ward"
                      placeholder="Select Ward"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderRadius: "100px",
                        }),
                      }}
                      options={tierCost}
                      getOptionLabel={(options) => options["name"]}
                      getOptionValue={(options) => options["price"]}
                      onChange={(item) => {
                        console.log("item", item);
                        setFieldValue("ward", item.name);
                        setFieldValue("price", item.price);
                      }}
                    />
                    {touched.ward && errors.ward && (
                      <div className="field-error">{errors.ward}</div>
                    )}
                  </div>
                  {console.log("errors:", errors)}
                  <div className="w-full ">
                    <div className="text-sm text-[#0B0B0B] leading-[21.7px] -tracking-[3%] mb-4">
                      {" "}
                      2. For how many days would you like to initiate the
                      enhancement?
                    </div>

                    <Select
                      name="days"
                      placeholder="Select Days"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderRadius: "100px",
                        }),
                      }}
                      options={
                        caseDetails?.hospital?.tmsVersion === "1.0"
                          ? daysOptions?.primary
                          : values.ward?.toLowerCase().includes("ventilator")
                          ? daysOptions?.secondary
                          : daysOptions?.tertiary
                      }
                      onChange={(item) =>
                        handleDaysChange(item, setFieldValue, values)
                      }
                    />
                    {touched.days && errors.days && (
                      <div className="field-error">{errors.days}</div>
                    )}
                  </div>
                  <div className="w-full ">
                    <div className="text-sm text-[#0B0B0B] leading-[21.7px] -tracking-[3%] mb-4">
                      {" "}
                      3. From which date to which date should the enhancement
                      last?
                    </div>

                    <div className="grid grid-cols-2 gap-6 mr-2">
                      <div>
                        <Field
                          type="date"
                          name="fromDate"
                          placeholder="--/--/---"
                          min={
                            new Date(
                              latestEnhancement?.length > 0
                                ? latestEnhancement[0]?.details.toDate
                                : admissionDate
                            )
                              .toISOString()
                              .split("T")[0]
                          }
                          max={new Date().toISOString().split("T")[0]}
                          style={{ border: "1px solid #E4E4E4", width: "100%" }}
                          className="rounded-full py-[10px] px-2"
                          onChange={(e) =>
                            handleDateChange(e, setFieldValue, values)
                          }
                        />
                        {touched.fromDate && errors.fromDate && (
                          <div className="field-error">{errors.fromDate}</div>
                        )}
                      </div>
                      <div>
                        <Field
                          type="date"
                          name="toDate"
                          placeholder="--/--/---"
                          disabled
                          style={{ border: "1px solid #E4E4E4", width: "100%" }}
                          className="rounded-full py-[10px] px-2"
                        />
                        {touched.toDate && errors.toDate && (
                          <div className="field-error">{errors.toDate}</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-[30%] flex justify-end">
                  {isSubmitting ? (
                    <CircularProgress />
                  ) : (
                    <button
                      type="submit"
                      style={{ border: "1px solid #017C71" }}
                      className="w-fit flex items-center gap-2 bg-[#017C71] text-white rounded-full mt-12 px-5 py-4 border-0 cursor-pointer uppercase"
                    >
                      SAVE DETAILS <img src={RightArrow} alt="" />
                    </button>
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EnhancementFormHospitalPersona;
