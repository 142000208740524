import React, { useState, useEffect, useRef } from "react";
import { logOut } from "../config/user";
import { CircularProgress } from "@mui/material";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import SearchIcon from "@mui/icons-material/Search";
import Animation from "../assets/checkcirclepng.png";
import RedAnimation from "../assets/RedAnimation.gif";
import "./ManageRpa.scss";
import CustomPagination from "../utils/paginationUtils";
import { RpaServices } from "../services/RpaServices";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Select from "react-select";
import { HospitalServices } from "../services/HospitalServices";
import warnicon from "../assets/warnorange.png";
import yellow from "../assets/yellow.png";
import Close from "@mui/icons-material/Close";
import { fetchNewCaseNotificationCount } from "../redux/notificationSlice";
import { useDispatch, useSelector } from "react-redux";
const ManagedRpaLogs = () => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(null);
  const [rpaLogs, setRpaLogs] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [newStatus, setnewStatus] = useState("NEW");
  const [selectedUser, setSelectedUser] = useState(null);
  const [hospitalId, setHospitalId] = useState("");
  const [selectedApplication, setSelectedApplication] = useState("");
  const [requestStatus, setRequestStatus] = useState("NEW");
  const [caseStatus, setCaseStatus] = useState("NEW_CASE");
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isViewDetailPopupVisible, setIsViewDetailPopupVisible] =
    useState(false);
  const [isLogPopupVisible, setIsLogPopupVisible] = useState(false);
  const searchTimeRef = useRef(null);
  const limit = 10;
  const [applicationId, setApplicationId] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRpaLog, setSelectedRpaLog] = useState(null);
  const [hospitalList, setHospitalList] = useState([]);
  const [startDate, setStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 60))
  );
  const [endDate, setEndDate] = useState(new Date());
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [formData, setFormData] = useState({
    hospitalId: "",
    caseStatus: "NEW_CASE",
    pmjayCaseId: "",
    requestType: "NEW",
    statusRemark: "Manually Added",
  });
  const [selectedTab, setSelectedTab] = useState("Pending");
  const { newCaseNotificationCount } = useSelector(
    (store) => store.notificationReducer
  );

  const dispatch = useDispatch();
  const togglePasswordVisibility = (hospitalId) => {
    setIsPasswordVisible((prevId) =>
      prevId === hospitalId ? null : hospitalId
    );
  };

  /*  const handleSearch = (e) => {
    if (searchTimeRef.current) {
      clearInterval(searchTimeRef.current);
    }
    searchTimeRef.current = setTimeout(() => {
      setSearchKey(e.target.value);
    }, 500);
  }; */

  const handleButtonClick = () => {
    setPopupOpen(!isPopupOpen);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await RpaServices.addRpaRequest(formData);
      console.log("API Response:", response);

      if (response?.status === 201) {
        alert("Request added successfully!");
        setPopupOpen(false);
        setFormData({
          hospitalId: "",
          caseStatus: "",
          pmjayCaseId: "",
          requestType: null,
          statusRemark: "Manually Added",
        });
      } else {
        alert(response?.data?.message || "Request failed. Please try again.");
      }
    } catch (error) {
      console.error("API Error Details:", error);
      if (error.response) {
        alert(
          error.response.data?.message || "Error: " + error.response.status
        );
      } else if (error.request) {
        alert("No response from server. Please check your connection.");
      } else {
        alert("Error while registering device: " + error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  /*  const fetchrpaLogs = async () => {
    setIsLoading(true);
    try {
      const res = await RpaServices.manageRpaLogs(
        applicationId,
        page,
        limit,
        startDate,
        endDate,
        hospitalId,
        requestStatus
      );
      console.log(res?.data)
      if (res?.data?.requests) {
        setRpaLogs(res.data.requests);
        setTotalPages(res.data.totalPages || 1);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        console.log(error?.response?.data?.error || "An error occurred");
      }
    } finally {
      setIsLoading(false);
    }
  }; */

  const fetchrpaLogs = async (selection) => {
    setIsLoading(true);
    setRpaLogs([]);
    console.log(selection);
    try {
      let res;
      if (selection === "Pending") {
        res = await RpaServices.manageRpaLogs(
          applicationId,
          page,
          limit,
          startDate,
          endDate,
          hospitalId,
          requestStatus,
          caseStatus
        );
      } else {
        res = await RpaServices.manageRpaProcessedLogs(
          applicationId,
          page,
          limit,
          startDate,
          endDate,
          hospitalId,
          requestStatus,
          caseStatus
        );
      }
      if (selection === "Pending") {
        if (res?.data?.requests) {
          setRpaLogs(res?.data?.requests);
          setTotalPages(res?.data?.totalPages || 1);
          setTotal(res?.data?.total || 0);
        }
      } else {
        if (res?.data?.processedRequests) {
          setRpaLogs(res.data.processedRequests);
          setTotalPages(res.data.totalPages || 1);
          setTotal(res?.data?.total || 0);
        }
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        console.log(error?.response?.data?.error || "An error occurred");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleMenuOpen = (event, rpalog) => {
    setAnchorEl(event.currentTarget);
    setSelectedRpaLog(rpalog);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRpaLog(null);
  };

  const handleStatusUpdate = () => {
    if (selectedRpaLog) {
      setSelectedUser(selectedRpaLog);
      setIsPopupVisible(true);
    }
    handleMenuClose();
  };

  const handleViewDetail = () => {
    if (selectedRpaLog) {
      setSelectedUser(selectedRpaLog);
      setIsViewDetailPopupVisible(true);
    }
    handleMenuClose();
  };

  const handleLog = () => {
    console.log("Selected RPA Log for Log:", selectedRpaLog);
    if (selectedRpaLog) {
      setSelectedUser(selectedRpaLog);
      setIsLogPopupVisible(true);
    }
    handleMenuClose();
  };

  const getHospitalList = async () => {
    try {
      const response = await HospitalServices.gethospitals("", 1, 1500);
      setHospitalList(response.data.list);
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
  };

  const formatDate = (date) => {
    date = new Date(date);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleSaveChanges = async () => {
    const data = {
      requestId: selectedUser?.id,
      requestStatus: newStatus,
      oldStatus: selectedUser?.requestStatus,
      statusRemark: "Manually Updated",
    };

    try {
      setIsLoading(true);
      const response = await RpaServices.updateRpaRequest(data);
      console.log("Senfing data", data);
      if (response?.status === 200) {
        alert("Changes saved successfully!");
        setIsPopupVisible(false);
        await fetchrpaLogs(selectedTab);
        dispatch(fetchNewCaseNotificationCount());
      } else {
        alert("Failed to update rpa details.");
      }
    } catch (error) {
      alert("Error updating rpa details: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchrpaLogs(selectedTab);
  }, [
    applicationId,
    page,
    limit,
    startDate,
    endDate,
    hospitalId,
    requestStatus,
    selectedTab,
    caseStatus,
    newCaseNotificationCount,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getHospitalList();
  }, []);

  const changeSelectedTab = (selection) => {
    setSelectedTab(selection);
    fetchrpaLogs(selection);
  };

  const dateOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  };

  return (
    <div className="initiateadmission-box">
      <span className="path_line">
        <WidgetsOutlinedIcon style={{ height: "24px" }} /> &nbsp;/ &nbsp;
        Dashboard &nbsp;/ &nbsp; RPA Request Management
      </span>

      <div className="flex justify-between items-center">
        <div className="heading flex items-end">
          RPA Request Management{" "}
          <span className="font-medium ml-1">{`(${total})`}</span>{" "}
        </div>

        <div className="flex gap-4 mb-4">
          <button
            className={`text-sm font-semibold px-6 py-2 rounded-full ${
              selectedTab === "Pending"
                ? "bg-[#017C57] text-white"
                : "bg-white text-[#017C57] border border-[#017C57]"
            }`}
            onClick={() => changeSelectedTab("Pending")}
          >
            Pending
          </button>
          <button
            className={`text-sm font-semibold px-6 py-2 rounded-full ${
              selectedTab === "Processed"
                ? "bg-[#017C57] text-white"
                : "bg-white text-[#017C57] border border-[#017C57]"
            }`}
            onClick={() => changeSelectedTab("Processed")}
          >
            Processed
          </button>
        </div>
      </div>
      <hr style={{ color: "#E7E7E7", marginBottom: "20px" }} />

      {/* Add Filters Here */}
      <div className="flex justify-between items-center">
        <div className="flex gap-3 mb-4">
          <div>
            <label htmlFor="start-date">Start Date:</label>
            <input
              type="date"
              id="start-date"
              value={formatDate(startDate)}
              onChange={handleStartDateChange}
              max={formatDate(new Date())}
              style={{ border: "1px solid #E5E5E5" }}
              className="text-[#191919] appearance-none p-2 rounded-[8px]"
            />
          </div>

          <div>
            <label htmlFor="end-date">End Date:</label>
            <input
              type="date"
              id="end-date"
              value={formatDate(endDate)}
              onChange={handleEndDateChange}
              max={formatDate(new Date())}
              style={{ border: "1px solid #E5E5E5" }}
              className="text-[#191919] appearance-none p-2 rounded-[8px]"
            />
          </div>

          <Select
            name="hospitalId"
            isClearable
            options={hospitalList}
            getOptionLabel={(options) => options["name"]}
            getOptionValue={(options) => options["id"]}
            onChange={(item) => {
              setPage(1);
              item ? setHospitalId(item.id) : setHospitalId("");
            }}
            value={hospitalList.filter((item) => item.id === hospitalId)}
            placeholder="FILTER HOSPITAL WISE"
          />

          <Select
            name="status"
            isClearable
            options={[
              { label: "New", value: "NEW" },
              { label: "Hold", value: "HOLD" },
              { label: "Completed", value: "COMPLETED" },
            ]}
            getOptionLabel={(options) => options["label"]}
            getOptionValue={(options) => options["value"]}
            onChange={(item) => {
              setPage(1);
              item ? setRequestStatus(item.value) : setRequestStatus("");
            }}
            value={[
              { label: "New", value: "NEW" },
              { label: "Hold", value: "HOLD" },
              { label: "Completed", value: "COMPLETED" },
            ].filter((option) => option.value === requestStatus)}
            placeholder="FILTER STATUS WISE"
          />
          <Select
            name="caseStatus"
            isClearable
            options={[
              { label: "New Case", value: "NEW_CASE" },
              { label: "Status Update", value: "STATUS_UPDATE" },
              { label: "Upload Document", value: "UPLOAD_DOCUMENT" },
            ]}
            getOptionLabel={(options) => options["label"]}
            getOptionValue={(options) => options["value"]}
            onChange={(item) => {
              setPage(1);
              item ? setCaseStatus(item.value) : setCaseStatus("");
            }}
            value={[
              { label: "New Case", value: "NEW_CASE" },
              { label: "Status Update", value: "STATUS_UPDATE" },
              { label: "Upload Document", value: "UPLOAD_DOCUMENT" },
            ].filter((option) => option.value === caseStatus)}
            placeholder="FILTER REQUEST TYPE WISE"
          />
        </div>
        {/*       <div className="flex gap-3 mb-4">
      <div
          style={{ border: "1px solid #E4E4E4" }}
          className="flex gap-2 px-2 py-2 rounded-[8px]"
        >
          <SearchIcon />
          <input
            type="text"
            className="outline-none border-0"
            placeholder="Search Anything"
            onChange={handleSearch}
          />
        </div>
      </div> */}
        <div className="flex gap-3 mb-4">
          <button
            className="text-sm text-[#017C57] font-semibold px-10 py-2 rounded-full bg-white border border-[#017C57]"
            onClick={handleButtonClick}
          >
            Add Request
          </button>
          {isPopupOpen && (
            <div className="fixed z-20 inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center">
              <div
                className="bg-white p-6 rounded-lg shadow-lg relative"
                style={{ width: "640px", marginTop: "50px" }}
              >
                <div className="px-0 pt-0 pb-4 border-b-2 border-s-2 border-gray-100 flex justify-between">
                  <div className="left-10 top-6 cursor-pointer font-semibold text-[18px]">
                    Add Request
                  </div>
                  <div className="right-12 top-6 cursor-pointer">
                    <Close
                      className="absolute top-6 right-6 font-semibold text-xl hover:text-gray-700"
                      alt="close-icon"
                      onClick={() => {
                        handleButtonClick();
                      }}
                    />
                  </div>
                </div>

                <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                  <div>
                    <label className="block font-medium mb-1">Hospital</label>
                    <Select
                      options={hospitalList.map((hospital) => ({
                        value: hospital?.id,
                        label: hospital?.id + "(" + hospital?.name + ")",
                      }))}
                      onChange={(selectedOption) => {
                        setFormData((prev) => ({
                          ...prev,
                          ["hospitalId"]: selectedOption.value,
                        }));
                      }}
                      placeholder="Select Hospital"
                    />
                  </div>

                  <div>
                    <label className="block font-medium mb-1">
                      Request Type
                    </label>

                    <Select
                      options={[
                        { value: "STATUS_UPDATE", label: "STATUS UPDATE" },
                        // { value: "UPLOAD_DOC", label: "UPLOAD DOCUMENT" },
                        { value: "NEW_CASE", label: "NEW CASE" },
                      ]}
                      onChange={(selectedOption) => {
                        setFormData((prev) => ({
                          ...prev,
                          ["caseStatus"]: selectedOption.value,
                        }));
                      }}
                      value={[
                        { value: "STATUS_UPDATE", label: "STATUS UPDATE" },
                        // { value: "UPLOAD_DOC", label: "UPLOAD DOCUMENT" },
                        { value: "NEW_CASE", label: "NEW CASE" },
                      ].filter((item) => item.value === formData.caseStatus)}
                      placeholder="Select Request Type"
                    />
                  </div>

                  {formData.caseStatus === "STATUS_UPDATE" && (
                    <div>
                      <label className="block font-medium mb-1">
                        Request Sub Type
                      </label>

                      <Select
                        name="requestType"
                        options={[
                          { value: "PREAUTH", label: "PREAUTH" },
                          { value: "CLAIM", label: "CLAIM" },
                        ]}
                        onChange={(selectedOption) => {
                          setFormData((prev) => ({
                            ...prev,
                            ["requestType"]: selectedOption.value,
                          }));
                        }}
                        placeholder="Select Request Sub Type"
                      />
                    </div>
                  )}

                  <div>
                    <label className="block font-medium mb-1">
                      PmjayCase ID
                    </label>
                    <input
                      type="text"
                      name="pmjayCaseId"
                      value={formData.pmjayCaseId}
                      onChange={handleInputChange}
                      className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-[#017C57]"
                      placeholder="Enter pmjayCaseId"
                      required
                    />
                  </div>

                  <button
                    type="submit"
                    className="text-sm text-white bg-[#017C57] py-2 px-4 mt-2 rounded-full border-none cursor-pointer"
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="Second-container">
        <div className="flex justify-between">
          {!isLoading ? (
            rpaLogs?.length > 0 ? (
              <section className="mt-1">
                <div className="grid grid-cols-7 text-[#191919] font-semibold px-8 py-1 gap-2">
                  <div className="text-[18px]">Request Status</div>
                  <div className="text-[18px]">Request Time</div>
                  {/* <div className="text-[18px]">RPA Request ID</div> */}
                  <div className="text-[18px]">RPA Request Type</div>
                  <div className="text-[18px]">Request By</div>
                  <div className="text-[18px]">Request To (Application ID)</div>
                  <div className="text-[18px]">Status Update Time</div>
                  <div className="text-[18px] text-center">Action</div>
                </div>

                {rpaLogs.map((rpalog, idx) => (
                  <article
                    key={idx}
                    className={`py-6 px-8 rounded-[16px] ${
                      idx % 2 === 0 ? "bg-white" : "bg-[#F9F9F9]"
                    }`}
                  >
                    <div className="grid grid-cols-7 text-[#797979] text-[18px] gap-2">
                      <div className="text-[18px]">
                        <img
                          src={
                            rpalog?.requestStatus == "NEW"
                              ? yellow
                              : rpalog?.requestStatus == "COMPLETED"
                              ? Animation
                              : RedAnimation
                          }
                          alt={
                            rpalog?.requestStatus == "NEW"
                              ? "NEW CASE"
                              : "STATUS UPDATE"
                          }
                          title={
                            rpalog?.requestStatus == "NEW"
                              ? "NEW CASE"
                              : "STATUS UPDATE"
                          }
                          className="w-6 h-6"
                        />
                        <div className="text-[12px]">
                          {rpalog?.rpaUpdates
                            ? rpalog?.rpaUpdates?.statusRemark
                            : "--"}
                        </div>
                      </div>
                      <div className="text-[18px]">
                        {rpalog?.createdAt
                          ? new Date(rpalog.createdAt).toLocaleString(
                              "en-GB",
                              dateOptions
                            )
                          : "NA"}
                      </div>
                      {/* <div className="text-[18px]">{rpalog?.id || "--"}</div> */}
                      <div className="text-[18px]">
                        {rpalog?.caseStatus == "NEW_CASE"
                          ? "NEW CASE"
                          : rpalog?.requestType
                          ? (rpalog?.requestType).replace("_", " ") || "--"
                          : "--"}
                      </div>
                      <div className="text-[18px]">
                        {rpalog?.hospitalId
                          ? hospitalList.filter(
                              (item) => item.id === rpalog?.hospitalId
                            )[0]?.name
                          : "--"}
                      </div>
                      <div className="text-[18px]">
                        {rpalog?.hospital?.applicationId
                          ? rpalog?.hospital?.applicationId
                          : "---"}
                      </div>
                      <div className="text-[18px]">
                        {rpalog?.completionDate
                          ? new Date(rpalog?.completionDate).toLocaleString(
                              "en-GB",
                              {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                                hour12: true,
                              }
                            ) +
                            " " +
                            rpalog?.rpaUpdates?.statusRemark
                          : "--"}
                      </div>
                      <div className="flex justify-center items-center gap-2">
                        <MoreVertIcon
                          onClick={(event) => handleMenuOpen(event, rpalog)}
                          className="cursor-pointer"
                        />
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleMenuClose}
                        >
                          {selectedTab == "Pending" ? (
                            <MenuItem onClick={handleStatusUpdate}>
                              Status Update
                            </MenuItem>
                          ) : (
                            ""
                          )}
                          <MenuItem onClick={handleViewDetail}>
                            View Detail
                          </MenuItem>
                          <MenuItem onClick={handleLog}>Log</MenuItem>
                        </Menu>
                      </div>
                    </div>
                  </article>
                ))}
              </section>
            ) : (
              <div className="w-full h-full flex justify-center items-center font-semibold text-xl text-[#191919]">
                No Data Found
              </div>
            )
          ) : (
            <div className="w-full h-[500px] flex justify-center items-center">
              <CircularProgress />
            </div>
          )}
        </div>
      </div>

      {isPopupVisible && (
        <div className="popup-overlay">
          <div className="popup-container relative h-[300px] w-[328px] xl:w-[50%] bg-white rounded-[16px] pb-0 xl:p-0 z-[1200]">
            <div className="absolute right-12 top-8 cursor-pointer">
              <Close
                alt="close-icon"
                onClick={() => {
                  setIsPopupVisible(false);
                }}
              />
            </div>

            <div className="space-y-4">
              <div className="flex gap-[16px] pt-6 pl-10 gap-2">
                <div className="w-[776px] p-[16px] border border-[rgba(228,228,228,1)] rounded-[8px] flex flex-col gap-[16px]">
                  <div className="flex flex-col gap-4">
                    <label className="text-[#191919] text-[18px] font-semibold">
                      Update Request Status
                    </label>
                    <div className="flex flex-col gap-2">
                      <label className="flex items-center gap-2">
                        <input
                          type="radio"
                          name="requestStatus"
                          value="NEW"
                          checked={newStatus === "NEW"}
                          onChange={(e) => setnewStatus(e.target.value)}
                        />
                        <span>NEW</span>
                      </label>
                      <label className="flex items-center gap-2">
                        <input
                          type="radio"
                          name="requestStatus"
                          value="HOLD"
                          checked={newStatus === "HOLD"}
                          onChange={(e) => setnewStatus(e.target.value)}
                        />
                        <span>HOLD</span>
                      </label>
                      <label className="flex items-center gap-2">
                        <input
                          type="radio"
                          name="requestStatus"
                          value="COMPLETED"
                          checked={newStatus === "COMPLETED"}
                          onChange={(e) => setnewStatus(e.target.value)}
                        />
                        <span>COMPLETED</span>
                      </label>
                    </div>
                  </div>

                  <div className="flex justify-center mt-9">
                    <div className="text-white text-xs font-semibold bg-[#017C57] rounded-full py-1 px-2 cursor-pointer">
                      <button
                        onClick={handleSaveChanges}
                        className="save-button"
                      >
                        Save Changes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {isViewDetailPopupVisible && (
        <div className="popup-overlay">
          <div className="popup-container relative h-[400px] w-[328px] xl:w-[50%] bg-white rounded-[16px] pb-0 xl:p-0 z-[1200]">
            <div className="absolute right-12 top-8 cursor-pointer">
              <Close
                alt="close-icon"
                onClick={() => {
                  setIsViewDetailPopupVisible(false);
                }}
              />
            </div>

            <div className="space-y-4 p-6">
              <h2 className="text-[#191919] text-[24px] font-semibold">
                View Details
              </h2>
              <div className="flex flex-col gap-4">
                <div>
                  <label className="text-[#191919] text-[18px] font-semibold">
                    Request Status:
                  </label>
                  <p>{selectedUser?.requestStatus || "No data available"}</p>
                </div>
                <div>
                  <label className="text-[#191919] text-[18px] font-semibold">
                    Request Time:
                  </label>
                  <p>
                    {new Date(selectedUser?.createdAt).toLocaleString(
                      "en-GB",
                      dateOptions
                    )}
                  </p>
                </div>
                <div>
                  <label className="text-[#191919] text-[18px] font-semibold">
                    Request Type:
                  </label>
                  <p>
                    {selectedUser?.requestType
                      ? selectedUser?.requestType?.replace("_", " ")
                      : "-"}
                  </p>
                </div>
                <div>
                  <label className="text-[#191919] text-[18px] font-semibold">
                    Hospital Details
                  </label>
                  <p>
                    <b>Name :</b>
                    {selectedUser?.hospitalId
                      ? hospitalList.find(
                          (item) => item.id === selectedUser?.hospitalId
                        )?.name
                      : "--"}
                  </p>
                  <p>
                    <b>Code :</b>{" "}
                    {selectedUser?.hospitalId
                      ? hospitalList.find(
                          (item) => item.id === selectedUser?.hospitalId
                        )?.code
                      : "--"}
                  </p>
                  <p>
                    <b>City :</b>{" "}
                    {selectedUser?.hospitalId
                      ? hospitalList.find(
                          (item) => item.id === selectedUser?.hospitalId
                        )?.city
                      : "--"}
                  </p>
                </div>
                <div>
                  <label className="text-[#191919] text-[18px] font-semibold">
                    Request To (Application ID):
                  </label>
                  <p>{selectedUser?.applicationId}</p>
                </div>
                <div>
                  <label className="text-[#191919] text-[18px] font-semibold">
                    Status Update Time:
                  </label>
                  <p>
                    {selectedUser?.completionDate
                      ? new Date(selectedUser?.completionDate).toLocaleString(
                          "en-GB",
                          dateOptions
                        )
                      : "NA"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {isLogPopupVisible && (
        <div className="popup-overlay">
          <div className="popup-container relative h-[400px] w-[328px] xl:w-[50%] bg-white rounded-[16px] pb-0 xl:p-0 z-[1200]">
            <div className="absolute right-12 top-8 cursor-pointer">
              <Close
                alt="close-icon"
                onClick={() => {
                  setIsLogPopupVisible(false);
                }}
              />
            </div>

            <div className="space-y-4 p-6">
              <h2 className="text-[#191919] text-[24px] font-semibold">
                Log Details
              </h2>
              <div className="flex flex-col gap-4">
                <div>
                  <label className="text-[#191919] text-[18px] font-semibold">
                    Request ID:
                  </label>
                  <p>{selectedUser?.id}</p>
                </div>
                <div>
                  <label className="text-[#191919] text-[18px] font-semibold">
                    Request Type:
                  </label>
                  <p>
                    {selectedUser?.requestType
                      ? selectedUser?.requestType?.replace("_", " ")
                      : "-"}
                  </p>
                </div>
                <div>
                  <label className="text-[#191919] text-[18px] font-semibold">
                    Request By:
                  </label>
                  <p>
                    {selectedUser?.hospitalId
                      ? hospitalList.find(
                          (item) => item.id === selectedUser?.hospitalId
                        )?.name
                      : "--"}
                  </p>
                </div>
                <div>
                  <label className="text-[#191919] text-[18px] font-semibold">
                    Request To (Application ID):
                  </label>
                  <p>{selectedUser?.applicationId}</p>
                </div>
                <div>
                  <label className="text-[#191919] text-[18px] font-semibold">
                    Remarks:
                  </label>
                  <ul>
                    {selectedUser?.rpaUpdates?.map((update, index) => (
                      <li key={index}>{update.remark}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="flex justify-center mt-4">
        <CustomPagination
          page={page}
          setPage={setPage}
          totalPages={totalPages}
        />
      </div>
    </div>
  );
};

export default ManagedRpaLogs;
