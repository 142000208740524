import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchHoldHospitalCount = createAsyncThunk(
  "holdHospitals/totalHospitals",
  async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/rpa-hold-hospital-count`
    );
    return response.data.holdHospitals;
  }
);

// Redux Slice
const holdHospitalSlice = createSlice({
  name: "holdHospitals",
  initialState: { count: 0, loading: false },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHoldHospitalCount.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchHoldHospitalCount.fulfilled, (state, action) => {
        state.loading = false;
        state.count = action.payload || 0;
      })
      .addCase(fetchHoldHospitalCount.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default holdHospitalSlice.reducer;
