import Icon from "../assets/roadmapicon.svg";
const Roadmap = () => {
  return (
    <div className="px-[3%] box-border">
      <div
        style={{ overflow: "hidden" }}
        className="max-w-[1600px] mx-auto pt-[70px] pb-[140px] "
      >
        {" "}
        <div className="max-w-[1116px] text-[4rem] -tracking-[0.08em] capitalize leading-none">
          Claim submission to payout process that brings AI Results in weeks,
          not years
        </div>
        <img
          src="https://cdn.prod.website-files.com/65c73cab42d05747958d759e/65ca2b265f4a77c5a3ec69ae_div.Process_process__border__rYio1.webp"
          loading="lazy"
          style={{
            transform:
              "translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d",
          }}
          data-w-id="6f13a9b0-923e-39cb-f2d1-c2fcef20bfff"
          alt=""
          className="my-[5rem]"
        />
        <h2 className="text-[#00b582] text-[64px] mb-[40px]">Roadmap</h2>
        <div className="flex justify-center  w-[100%] ">
          <div className="max-w-[170px] flex justify-center items-center">
            <div className="w-[300px] ml-[40px]">
              <img src={Icon} alt="icon-svg" />
            </div>
          </div>
          <img
            src="https://cdn.prod.website-files.com/65c73cab42d05747958d759e/65ca2c51b2eed79dd11b3759_span.svg"
            loading="lazy"
            alt=""
            class="max-w-[40px]"
          />
          <div className="group relative w-[300px] h-[500px]  transition-all duration-500 ease-in-out hover:w-[400px] bg-[#5A5A5A] px-[20px] pt-[60px] pb-[40px] rounded-[20px]">
            <div className="w-full  transition-all duration-500 ease-in-out group-hover:scale-100  ">
              <div class="max-w-[76px]">
                <img
                  src="https://cdn.prod.website-files.com/65c73cab42d05747958d759e/65c8d63dd32125eeee929ff5_01..svg"
                  loading="lazy"
                  alt=""
                  class="process-number"
                />
              </div>
              <h3 class="text-white text-[2.5rem] uppercase leading-[80%] mt-[25px] ">
                Executive <br />
                Training
              </h3>

              <div
                data-w-id="7aecafd2-0fb7-64c9-9068-03a192c30c78"
                class="process-paragrpah"
                className="hidden h-[225px] text-white capitalize max-w-[400px] mt-10 text-[1.25rem] font-light leading-[90%] group-hover:flex group-hover:flex-col justify-between "
              >
                <strong>
                  Uplift the potential of your executives in just 2 hours with
                  Settlemed's dynamic training program
                </strong>
                <strong>
                  <p>(2 hrs.)</p>
                </strong>
              </div>
            </div>
          </div>
          <div className="group relative w-[300px] h-[500px]  transition-all duration-500 ease-in-out hover:w-[400px] bg-[url(https://cdn.prod.website-files.com/65c73cab42d05747958d759e/65c8d63d89964e74036abd20_02-bg.webp)] bg-center bg-cover px-[20px] pt-[60px] pb-[40px] rounded-[20px]">
            <div className="w-full  transition-all duration-500 ease-in-out group-hover:scale-100  ">
              <div class="max-w-[76px]">
                <img
                  src="https://cdn.prod.website-files.com/65c73cab42d05747958d759e/65c8d63d7023d69385fb3b3f_02..svg"
                  loading="lazy"
                  alt=""
                  class="process-number"
                />
              </div>

              <h3 class="text-white text-[2.5rem] uppercase leading-[80%] mt-[25px] ">
                Onboard Settlemed
                <sup className="text-[0.875rem]  -top-[.5em] ">TM</sup> <br />
                Technology
              </h3>
              <div
                data-w-id="7aecafd2-0fb7-64c9-9068-03a192c30c78"
                class="process-paragrpah"
                className="hidden text-white capitalize max-w-[400px] mt-10 text-[1.25rem] font-light leading-[90%] group-hover:flex group-hover:flex-col justify-between "
              >
                <strong>
                  Activate your Admin/Subadmin app and set up a proficient team
                  exclusively of your hospital for settlemed mediclam services
                  in 72 hrs d's dynamic training program
                </strong>
                <br />
                <br />
                <br />
                <br />

                <strong>
                  <p>(2-3 Days)</p>
                </strong>
              </div>
            </div>
          </div>
          <div className="group relative w-[300px] h-[500px]  transition-all duration-500 ease-in-out hover:w-[400px] bg-[#00523b] px-[20px] pt-[60px] pb-[40px] rounded-[20px]">
            <div className="w-full  transition-all duration-500 ease-in-out group-hover:scale-100  ">
              <div class="max-w-[76px]">
                <img
                  src="https://cdn.prod.website-files.com/65c73cab42d05747958d759e/65c8d63dbf7b6f26de680e0d_03..svg"
                  loading="lazy"
                  alt=""
                  class="process-number"
                />
              </div>
              <h3 class="text-white text-[2.5rem] uppercase leading-[80%] mt-[25px] ">
                Settlemed
                <sup className="text-[0.875rem]  -top-[.5em] ">TM</sup> <br />
                Trial <br />
                Period
              </h3>

              <div
                data-w-id="7aecafd2-0fb7-64c9-9068-03a192c30c78"
                class="process-paragrpah"
                className="hidden  text-white capitalize max-w-[400px] mt-10 text-[1.25rem] font-light leading-[90%] group-hover:flex group-hover:flex-col justify-between "
              >
                <strong>
                  Experience the confidence of our services risk-free with a
                  settlemed trial period. If, within 8 weeks, you find yourself
                  unsatisfied, we guarantee a prompt and hassle-free full amount
                  refund. Your satisfaction is our priority
                </strong>
                <br />
                <br />
                <br />

                <strong>
                  <p> (upto 8 weeks)</p>
                </strong>
              </div>
            </div>
          </div>
          <div className="group relative w-[300px] h-[500px]  transition-all duration-500 ease-in-out hover:w-[400px] bg-[#00b582] px-[20px] pt-[60px] pb-[40px] rounded-[20px]">
            <div className="w-full  transition-all duration-500 ease-in-out group-hover:scale-100  ">
              <div class="max-w-[76px]">
                <img
                  src="https://cdn.prod.website-files.com/65c73cab42d05747958d759e/65c8d63dbf7b6f26de680e3c_04..svg"
                  loading="lazy"
                  alt=""
                  class="process-number"
                />
              </div>
              <h3 class="text-white text-[2.5rem] uppercase leading-[80%] mt-[25px] ">
                Unlock <br /> Financial <br /> Excellence <br /> Production
              </h3>

              <div
                data-w-id="7aecafd2-0fb7-64c9-9068-03a192c30c78"
                class="process-paragrpah"
                className="hidden text-white capitalize max-w-[400px] mt-10 text-[1.25rem] font-light leading-[90%] group-hover:flex group-hover:flex-col justify-between "
              >
                <strong>
                  Experience a transformative journey towards financial
                  excellence with Settlemed's AI-powered solution which helps in
                  acceleration and enhancement of your cash flow
                </strong>
                <br />
                <br />
                <strong>
                  <p>(3-12 months)</p>
                </strong>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between mt-[60px]">
          <p class=" max-w-[336px] text-[1.3rem] leading-[100%] capitalize">
            Upon agreeing on the
            <br />
            roadmap, we sign a contract and set up a Settlemed Team
          </p>
          <div className="flex">
            <div className="text-xl text-black font-light border border-black rounded-full py-5 px-14">
              Contact Us
            </div>
            <div className=" w-[100px] h-[74px] flex items-center justify-center bg-black rounded-full">
              <img
                src="https://cdn.prod.website-files.com/65c73cab42d05747958d759e/65c8d63d7393895d5f5d2c22_icon-arrow-right-whitee.svg"
                loading="lazy"
                alt=""
                class="button_arrow"
              />
            </div>
          </div>
        </div>
        <div className="flex mt-[100px]">
          <div className="max-w-[584px] bg-[#d3d4d6] rounded-[20px] py-[50px] px-[60px] ">
            <h3 class="text-[#7C7C7C] -tracking-[0.08em] capitalize max-w-[470px] text-[4rem] leading-none">
              <span className="text-[#00b582]">Beneficiary Result </span>of AI
              in Ayushman Mediclaim
            </h3>
            <img
              src="https://cdn.prod.website-files.com/65c73cab42d05747958d759e/65c8d63eb02e7eb54f7940d2_mediclaim-img.webp"
              loading="lazy"
              width="222"
              alt=""
              className="max-w-[22rem] mt-[7rem] mx-auto mb-0"
            />
          </div>
          <div className="bg-[url(https://cdn.prod.website-files.com/65c73cab42d05747958d759e/65c8d63f15a8106a1ddea3b1_laptop-bg.webp)] bg-cover bg-center rounded-[20px] w-[100%] max-w-[494px] px-[24px] pt-[45px] pb-[50px]">
            <div className="relative text-[#00b582] flex justify-center text-[3.375rem] leading-[80%] z-50">
              ₹{" "}
              <span id="costs" data-purecounter-duration="0">
                30000000
              </span>
            </div>
            <div
              data-poster-url="https://cdn.prod.website-files.com/65c73cab42d05747958d759e/65ca3cd75d9e0d16d35b385a_laptop-poster-00001.jpg"
              data-video-urls="https://cdn.prod.website-files.com/65c73cab42d05747958d759e/65ca3cd75d9e0d16d35b385a_laptop-transcode.mp4,https://cdn.prod.website-files.com/65c73cab42d05747958d759e/65ca3cd75d9e0d16d35b385a_laptop-transcode.webm"
              data-autoplay="true"
              data-loop="true"
              data-wf-ignore="true"
              className="relative text-white h-[500px]  max-w-[446px] mx-auto -top-[58px] overflow-hidden rounded-[47px]"
            >
              <video
                id="00d33319-d45b-a4e4-f745-74228a493097-video"
                autoPlay
                loop
                style={{
                  backgroundImage:
                    "url('https://cdn.prod.website-files.com/65c73cab42d05747958d759e/65ca3cd75d9e0d16d35b385a_laptop-poster-00001.jpg')",
                }}
                muted
                playsInline
                data-wf-ignore="true"
                data-object-fit="cover"
                className="absolute  object-cover  bg-center bg-cover w-[100%] h-[100%] "
              >
                <source
                  src="https://cdn.prod.website-files.com/65c73cab42d05747958d759e/65ca3cd75d9e0d16d35b385a_laptop-transcode.mp4"
                  data-wf-ignore="true"
                />
                <source
                  src="https://cdn.prod.website-files.com/65c73cab42d05747958d759e/65ca3cd75d9e0d16d35b385a_laptop-transcode.webm"
                  data-wf-ignore="true"
                />
              </video>
            </div>
            <p className="text-white text-[2rem] -tracking-[0.08em] capitalize max-w-[20vw] mt-[3.5rem] mx-auto font-light leading-none">
              Saved operational Costs due to AI
            </p>
          </div>
          <div className="flex flex-col justify-between max-w-[436px]">
            <div className="bg-[#00b582] rounded-[20px] flex flex-col justify-between flex-1 max-w-[436px] p-[45px]">
              <h4
                id="count-solution"
                className="z-[3] text-white mt-0 mb-0 font-sulphur-point text-[3.375rem] font-normal leading-[80%] block"
              >
                ₹
                <span class="solution" data-purecounter-duration="0">
                  96000000
                </span>
              </h4>
              <p class="text-white text-left tracking-[-0.08em] capitalize max-w-[285px] mt-0 mb-0 font-lufga text-[2rem] leading-none">
                Generated revenue by AI Solutions
              </p>
            </div>
            <div className="bg-[#00b58200] border border-black rounded-[20px] flex flex-col justify-between flex-1 max-w-[436px] p-[45px]">
              <h4 className="text-[#00b582] z-[3] tracking-[-0.08em] mt-0 mb-0 font-sulphur-point text-[3rem] font-normal leading-none block">
                <span id="count-hours" data-purecounter-duration="0">
                  9880
                </span>
                <span> </span>hrs.
              </h4>
              <p className=" text-left tracking-[-0.08em] capitalize max-w-[285px] mt-0 mb-0 font-lufga text-[2rem] font-normal leading-none">
                medical professionals time saved with help of AI
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Roadmap;
