import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { docUploadRefreshContext } from "../../dashboard/cases/contexts/CaseContexts";
import AdmissionDocuments from "./AdmissionDocuments";
import EnhancementDocuments from "./EnhancementDocuments";
import DischargeDocuments from "./DischargeDocuments";
import { CaseServices } from "../../services/CaseServices";
import { logOut } from "../../config/user";
const CaseDetailsHospitalPersona = () => {
  const [caseDetails, setCaseDetails] = useState(null);
  const [selectedTab, setSelectedTab] = useState("admission");
  const [step, setStep] = useState(1);
  const [isLoading, setIsloading] = useState(false);
  const [procedureDocuments, setProcedureDocuments] = useState({
    ADMISSIONDOCUMENT: [],
    DURINGTREATMENTDOCUMENT: [],
    ONDISCHARGEDOCUMENT: [],
  });
  const [enhancementDocuments, setEnhancementDocuments] = useState(null);
  const { id } = useParams();
  const getDate = (dateString) => {
    const date = new Date(dateString);

    // Get the day, month, and year
    const day = String(date.getDate()).padStart(2, "0"); // Add leading zero if single digit
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed, so add 1
    const year = date.getFullYear();

    // Format the date as DD/MM/YYYY
    return `${day}/${month}/${year}`;
  };
  const getCaseDetails = async () => {
    try {
      let res = await CaseServices.getCaseDetails(id);
      setCaseDetails(res?.data?.caseDetails);
    } catch (error) {
      window.alert(error);
    }
  };
  async function getMappedDocuments() {
    try {
      setIsloading(true);
      let documents = await CaseServices.getMappedDocuments(id);
      if (documents?.data?.mappedDocuments) {
        setProcedureDocuments(documents?.data?.mappedDocuments);
        //Below code is for getting all the enhancements done
        const enhancementDocumentsData = [];
        for (let i in documents?.data?.mappedDocuments) {
          if (i.toLowerCase().includes("enhancement")) {
            enhancementDocumentsData.push({
              key: i,
              value: documents?.data?.mappedDocuments[i],
            });
          }
        }
        //sort the enhancement detail info based on enhancement number in descending order
        enhancementDocumentsData?.sort((a, b) => {
          const numA = parseInt(a.key.replace(/[^\d]/g, ""));
          const numB = parseInt(b.key.replace(/[^\d]/g, ""));

          return numB - numA;
        });
        setEnhancementDocuments(enhancementDocumentsData);
      }
      setIsloading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
      setIsloading(false);
    }
  }
  useEffect(() => {
    getCaseDetails();
    getMappedDocuments();
  }, []);
  useEffect(() => {
    if (caseDetails) {
      caseDetails?.submasterstagestatuses[0].hsaStage
        .toLowerCase()
        .includes("admission in progress")
        ? setStep(1)
        : caseDetails?.submasterstagestatuses[0].hsaStage
            .toLowerCase()
            .includes("admission approved on tms")
        ? setStep(2)
        : caseDetails?.submasterstagestatuses[0].hsaStage
            .toLowerCase()
            .includes("admission")
        ? setStep(1)
        : caseDetails?.submasterstagestatuses[0].hsaStage
            .toLowerCase()
            .includes("enhancement in progress")
        ? setStep(3)
        : caseDetails?.submasterstagestatuses[0].hsaStage
            .toLowerCase()
            .includes("enhancement approved on tms")
        ? setStep(4)
        : caseDetails?.submasterstagestatuses[0].hsaStage
            .toLowerCase()
            .includes("enhancement")
        ? setStep(3)
        : caseDetails?.submasterstagestatuses[0].hsaStage
            .toLowerCase()
            .includes("claim in progress")
        ? setStep(6)
        : caseDetails?.submasterstagestatuses[0].hsaStage
            .toLowerCase()
            .includes("claim approved on tms")
        ? setStep(7)
        : caseDetails?.submasterstagestatuses[0].hsaStage
            .toLowerCase()
            .includes("claim")
        ? setStep(6)
        : setStep(0);
    }
  }, [caseDetails]);
  useEffect(() => {
    step <= 2
      ? setSelectedTab("admission")
      : step <= 4
      ? setSelectedTab("enhancement")
      : setSelectedTab("discharge");
  }, [step]);
  return (
    <div>
      <div
        style={{ color: "rgba(0,0,0,0.5)" }}
        className="text-[11px] 2xl:text-sm leading-[18.27px] -tracking-[3%]"
      >
        Home / Case Status / Case Details
      </div>
      <div className="w-fit text-[30px] 2xl:text-[50px] leading-[65.26px] -tracking-[7%]  mt-[12px] 2xl:mt-[30px]">
        Case Details
      </div>
      <div
        id="case-list"
        style={{ background: "rgba(255,255,255,0.5)" }}
        className="p-4 2xl:p-8 mt-1 2xl:mt-4 rounded-[30px] min-h-[70vh]"
      >
        <div>
          <div
            style={{
              background: `linear-gradient(to right, #EDFF8C 0%, #EDFF8C ${
                (step / 6) * 100 - 5
              }%, white ${(step / 6) * 100}%, white 100%)`,
              border: "2px solid #ffffff",
            }}
            className="w-full  rounded-full"
          >
            <div className="w-full grid grid-cols-6">
              <div
                style={{ border: "2px solid #000000" }}
                className={`w-[17px] h-[17px] rounded-full ${
                  step >= 1 ? "bg-black" : "bg-transparent"
                }`}
              />
              <div
                style={{ border: "2px solid #000000CC" }}
                className={`w-[17px] h-[17px] rounded-full ${
                  step >= 2 ? "bg-black" : "bg-transparent"
                }`}
              />

              <div
                style={{ border: "2px solid #000000B2" }}
                className={`w-[17px] h-[17px] rounded-full ${
                  step >= 3 ? "bg-black" : "bg-transparent"
                }`}
              />
              <div
                style={{ border: "2px solid #00000099" }}
                className={`w-[17px] h-[17px] rounded-full ${
                  step >= 4 ? "bg-black" : "bg-transparent"
                }`}
              />
              {/* <div
                style={{ border: "2px solid #00000080" }}
                className={`w-[17px] h-[17px] rounded-full ${
                  step >= 5 ? "bg-black" : "bg-transparent"
                }`}
              /> */}
              <div
                style={{ border: "2px solid #00000066" }}
                className={`w-[17px] h-[17px] rounded-full ${
                  step >= 6 ? "bg-black" : "bg-transparent"
                }`}
              />
              <div
                style={{ border: "2px solid #00000033" }}
                className={`w-[17px] h-[17px] rounded-full ${
                  step >= 7 ? "bg-black" : "bg-transparent"
                }`}
              />
            </div>
          </div>
          <div className="w-full grid grid-cols-6 text-sm 2xl:text-[16px] font-medium mt-2">
            <div
              className={`p-[15px] ${
                step === 1
                  ? "text-black bg-[#EDFF8C] rounded-[15px]"
                  : "text-[#00000080]"
              }`}
            >
              Initiate Admission
            </div>
            <div
              className={`p-[15px] ${
                step === 2
                  ? "text-black bg-[#EDFF8C] rounded-[15px]"
                  : "text-[#00000080]"
              }`}
            >
              Admission Approved
            </div>
            <div
              className={` p-[15px] ${
                step === 3
                  ? "text-black bg-[#EDFF8C] rounded-[15px]"
                  : "text-[#00000080]"
              }`}
            >
              Initiate Enhancement
            </div>
            <div
              className={`p-[15px] ${
                step === 4
                  ? "text-black bg-[#EDFF8C] rounded-[15px]"
                  : "text-[#00000080]"
              }`}
            >
              Enhancement Approved
            </div>
            {/* <div
              className={`p-[15px] ${
                step === 5
                  ? "text-black bg-[#EDFF8C]  rounded-[15px]"
                  : "text-[#00000080]"
              }`}
            >
              Discharge Initiated
            </div> */}
            <div
              className={`p-[15px] ${
                step === 6
                  ? "text-black bg-[#EDFF8C] rounded-[15px]"
                  : "text-[#00000080]"
              }`}
            >
              Claim Initiated
            </div>
            <div
              className={`p-[15px] ${
                step === 7
                  ? "text-black bg-[#EDFF8C] rounded-[15px]"
                  : "text-[#00000080]"
              }`}
            >
              Claim Approved
            </div>
          </div>
        </div>
        <div
          style={{ border: "1px solid #ffffff" }}
          className="my-[24px] 2xl:my-[30px]"
        />
        <div className="grid grid-cols-7 gap-y-8 2xl:gap-y-10 bg-white rounded-[15px] p-[24px] 2xl:p-[30px]">
          <div>
            <div className="text-[16px] font-medium leading-[26.1px] -tracking-[7%]">
              Patient Name
            </div>
            <div className=" text-[#00000080] text-[14px] 2xl:text-[16px] capitalize mt-[5px]">
              {caseDetails?.patient?.patientName}
            </div>
          </div>
          <div>
            <div className="text-[16px] font-medium leading-[26.1px] -tracking-[7%]">
              Age
            </div>
            <div className=" text-[#00000080] text-[14px] 2xl:text-[16px] capitalize mt-[5px]">
              {caseDetails?.patient?.age}
            </div>
          </div>
          <div>
            <div className="text-[16px] font-medium leading-[26.1px] -tracking-[7%]">
              Beneficiary No
            </div>
            <div className=" text-[#00000080] text-[14px] 2xl:text-[16px] mt-[5px]">
              {caseDetails?.patient?.beneficiaryNo}
            </div>
          </div>
          <div>
            <div className="text-[16px] font-medium leading-[26.1px] -tracking-[7%]">
              Package Cost
            </div>
            <div className=" text-[#00000080] text-[14px] 2xl:text-[16px] capitalize mt-[5px]">
              {caseDetails?.caseprocedures[0]?.packageCost || "--"}
            </div>
          </div>
          <div>
            <div className="text-[16px] font-medium leading-[26.1px] -tracking-[7%]">
              Doctor Name
            </div>
            <div className=" text-[#00000080] text-[14px] 2xl:text-[16px] capitalize mt-[5px]">
              {caseDetails?.caseprocedures[0]?.doctorName || "--"}
            </div>
          </div>
          <div>
            <div className="text-[16px] font-medium leading-[26.1px] -tracking-[7%]">
              ICHI CODE
            </div>
            <div className=" text-[#00000080] text-[14px] 2xl:text-[16px] capitalize mt-[5px]">
              {caseDetails?.caseprocedures[0]?.ichiCode || "--"}
            </div>
          </div>
          <div>
            <div className="text-[16px] font-medium leading-[26.1px] -tracking-[7%]">
              Gender
            </div>
            <div className=" text-[#00000080] text-[14px] 2xl:text-[16px] capitalize mt-[5px]">
              {caseDetails?.patient?.gender}
            </div>
          </div>
          <div>
            <div className="text-[16px] font-medium leading-[26.1px] -tracking-[7%]">
              Primary Diagnosis
            </div>
            <div className=" text-[#00000080] text-[14px] 2xl:text-[16px] capitalize mt-[5px]">
              {caseDetails?.primaryDiagnosis}
            </div>
          </div>{" "}
          <div>
            <div className="text-[16px] font-medium leading-[26.1px] -tracking-[7%]">
              Registered Date
            </div>
            <div className=" text-[#00000080] text-[14px] 2xl:text-[16px] capitalize mt-[5px]">
              {getDate(caseDetails?.patient?.registeredDate)}
            </div>
          </div>
          <div>
            <div className="text-[16px] font-medium leading-[26.1px] -tracking-[7%]">
              Procedure ID
            </div>
            <div className=" text-[#00000080] text-[14px] 2xl:text-[16px] capitalize mt-[5px]">
              {caseDetails?.primaryDiagnosis}
            </div>
          </div>
          <div>
            <div className="text-[16px] font-medium leading-[26.1px] -tracking-[7%]">
              Stratifications
            </div>
            <div className=" text-[#00000080] text-[14px] 2xl:text-[16px] capitalize mt-[5px]">
              {caseDetails?.caseprocedures[0]?.stratification || "--"}
            </div>
          </div>
          <div>
            <div className="text-[16px] font-medium leading-[26.1px] -tracking-[7%]">
              Implants
            </div>
            <div className=" text-[#00000080] text-[14px] 2xl:text-[16px] capitalize mt-[5px]">
              {caseDetails?.caseprocedures[0]?.implants || "--"}
            </div>
          </div>
          <div>
            <div className="text-[16px] font-medium leading-[26.1px] -tracking-[7%]">
              Admission Type
            </div>
            <div className=" text-[#00000080] text-[14px] 2xl:text-[16px] capitalize mt-[5px]">
              {caseDetails?.admissionType || "--"}
            </div>
          </div>
        </div>

        <div className="flex justify-between items-center text-[16px] 2xl:text-[18px] leading-[27.9px] -tracking-[5%] uppercase mt-[30px] 2xl:mt-[60px]">
          <div
            className={` transition-all duration-300 ease-in-out cursor-pointer py-3 2xl:py-4 ${
              selectedTab.includes("admission")
                ? "text-white bg-black py-3 2xl:py-4 px-4 2xl:px-5 rounded-full"
                : "text-[#797979]"
            }`}
            onClick={() => setSelectedTab("admission")}
          >
            Admission
          </div>
          <div
            className={`transition-all duration-300 ease-in-out cursor-pointer ${
              selectedTab.includes("enhancement")
                ? "text-white bg-black py-3 2xl:py-4 px-4 2xl:px-5 rounded-full"
                : "text-[#797979]"
            }`}
            onClick={() => setSelectedTab("enhancement")}
          >
            Enhancement
          </div>
          <div
            className={`transition-all duration-300 ease-in-out cursor-pointer ${
              selectedTab.includes("discharge")
                ? "text-white bg-black py-3 2xl:py-4 px-4 2xl:px-5 rounded-full"
                : "text-[#797979]"
            }`}
            onClick={() => setSelectedTab("discharge")}
          >
            Discharge / Claim
          </div>
          {/* <div
            className={`transition-all duration-300 ease-in-out cursor-pointer ${
              selectedTab.includes("claim")
                ? "text-white bg-black py-4 px-5 rounded-full"
                : "text-[#797979]"
            }`}
            onClick={() => setSelectedTab("claim")}
          >
            Claim
          </div> */}
          {/* <div
            className={`transition-all duration-300 ease-in-out cursor-pointer ${
              selectedTab.includes("case history")
                ? "text-white bg-black py-3 2xl:py-4 px-4 2xl:px-5 rounded-full"
                : "text-[#797979]"
            }`}
            onClick={() => setSelectedTab("case history")}
          >
            Case History
          </div> */}
        </div>
        <div style={{ border: "1px solid #FFFFFF" }} className="mt-[5px]" />
        <docUploadRefreshContext.Provider value={{ getMappedDocuments }}>
          {selectedTab.includes("admission") ? (
            <AdmissionDocuments
              documents={procedureDocuments["ADMISSIONDOCUMENT"]}
              getDate={getDate}
            />
          ) : selectedTab.includes("enhancement") ? (
            <EnhancementDocuments
              documents={enhancementDocuments}
              getDate={getDate}
            />
          ) : selectedTab.includes("discharge") ? (
            <DischargeDocuments
              documents={procedureDocuments["ONDISCHARGEDOCUMENT"]}
              getDate={getDate}
            />
          ) : selectedTab.includes("case history") ? (
            <>Case history</>
          ) : (
            ""
          )}
        </docUploadRefreshContext.Provider>
      </div>
    </div>
  );
};

export default CaseDetailsHospitalPersona;
