import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { CaseServices } from "../services/CaseServices";
export const fetchActiveCasesCount = createAsyncThunk(
  "caseDetails/fetchACtiveCasesCount",
  async () => {
    const response = await CaseServices.allCases(
      "",
      "",
      "",
      "active",
      "",
      "",
      "",
      "",
      ""
    );
    return response?.data?.totalFilteredCases;
  }
);
export const caseDetailSlice = createSlice({
  name: "caseDetail",
  initialState: {
    caseId: "",
    pageNo: "",
    member: "",
    status: "",
    subStatus: "",
    selectedHospitalId: "",
    activeCasesCount: "",
  },
  reducers: {
    updateCaseId: (state, action) => {
      state.caseId = action.payload;
    },
    updatePageNumber: (state, action) => {
      state.pageNo = action.payload;
    },
    updateMember: (state, action) => {
      state.member = action.payload;
    },
    updateStatus: (state, action) => {
      state.status = action.status;
    },
    updateSubStatus: (state, action) => {
      state.subStatus = action.payload;
    },
    updateSelectedHospital: (state, action) => {
      state.selectedHospitalId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchActiveCasesCount.pending, (state) => {
        // state.status = "loading";
      })
      .addCase(fetchActiveCasesCount.fulfilled, (state, action) => {
        // state.status = "succeeded";
        state.activeCasesCount = action.payload; // Update notification count with API data
      })
      .addCase(fetchActiveCasesCount.rejected, (state, action) => {
        // state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const {
  updateCaseId,
  updatePageNumber,
  updateMember,
  updateStatus,
  updateSubStatus,
  updateSelectedHospital,
} = caseDetailSlice.actions;

export default caseDetailSlice.reducer;
