import { useEffect, useState } from "react";
import axios from "axios";
import { SurveyServices } from "../services/SurveyServices";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";

const SurveyForm = () => {
  const [surveyQuestions, setSurveyQuestions] = useState(null);
  const [validationSchema, setValidationSchema] = useState(null);
  const [location, setLocation] = useState("");
  const [geoLocation, setGeoLocation] = useState("");
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const handleSave = async (values) => {
    setSaving(true);

    const resultArray = Object.keys(values)
      .filter(
        (key) =>
          ![
            "name",
            "email",
            "contactNumber",
            "designation",
            "healthcareEntityName",
            "surveyorId",
            "contact",
          ].includes(key)
      )
      .map((key) => ({
        questionId: parseInt(key),
        answer: values[key],
      }));

    let postData = {
      participantDetails: {
        name: values.name,
        designation: values.designation,
        healthcareEntityName: values.healthcareEntityName,
        email: values.email,
        contactNumber: values.contactNumber,
        surveyorId: values.surveyorId,
        location: location,
        latitude: geoLocation.latitude,
        longitude: geoLocation.longitude,
      },
      responses: resultArray,
    };

    try {
      const response = await SurveyServices.saveSurveyDetails(postData);
      window.alert(response.data.message);
      navigate("/");
    } catch (error) {
      window.alert(error);
    }
    setSaving(false);
  };
  const getLocationDetails = async (latitude, longitude) => {
    try {
      const url = `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json&addressdetails=1`;

      const response = await axios.get(url);

      if (response.data && response.data.address) {
        const { city, state, country } = response.data.address;

        setLocation({ city: city, state: state, country: country });
      } else {
        setError("Location not found.");
      }
    } catch (err) {
      setError("Error fetching location details.");
    }
  };

  // Function to get user's current latitude and longitude
  const getUserLocation = () => {
    if (navigator.geolocation) {
      // Get the user's current position
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          // Call the function to get location details based on the coordinates
          setGeoLocation({ latitude: latitude, longitude: longitude });
          getLocationDetails(latitude, longitude);
        },
        (err) => {
          setError("Error getting location: " + err.message);
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  };
  const getSurveyQuestions = async () => {
    try {
      const response = await SurveyServices.getSurveyQuestions();
      setSurveyQuestions(response.data.questions);
    } catch (error) {
      window.alert(error);
    }
  };

  useEffect(() => {
    getSurveyQuestions();
    getUserLocation();
  }, []);

  useEffect(() => {
    if (surveyQuestions) {
      const validation = Yup.object().shape({
        name: Yup.string().required("Name is required"),
        email: Yup.string()
          .email("Invalid email address")
          .required("Email is required"),
        contactNumber: Yup.string()
          .matches(/^[0-9]{10}$/, "Contact number must be a 10-digit number")
          .required("Contact number is required"),
        designation: Yup.string().required("Designation is required"),
        healthcareEntityName: Yup.string().required(
          "Healthcare Entity Name is required"
        ),
        surveyorId: Yup.string().required("Surveyor ID is required"),

        // Add validations for each survey question
        ...surveyQuestions.reduce((acc, question) => {
          acc[question.id] = Yup.string().required("This field is required");
          return acc;
        }, {}),
      });

      setValidationSchema(validation);
    }
  }, [surveyQuestions]);

  if (!surveyQuestions) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className="text-[50px] font-medium -tracking-[7%]">Survey</div>
      <div className="mt-[50px]">
        <Formik
          initialValues={{
            name: "",
            email: "",
            contactNumber: "",
            designation: "",
            healthcareEntityName: "",
            surveyorId: "",
            healthcareEntityName: "",

            // Dynamically initialize survey questions with empty strings
            ...surveyQuestions?.reduce((acc, question) => {
              acc[question.id] = ""; // Initialize with empty values for each question
              return acc;
            }, {}),
          }}
          validationSchema={validationSchema}
          onSubmit={handleSave}
        >
          {({ values, handleChange, handleBlur, setFieldValue, errors }) => (
            <Form>
              {surveyQuestions?.map((question, idx) => {
                return (
                  <div
                    key={idx}
                    className="mb-6 p-8 rounded-[30px] bg-[#FFFFFFB3] "
                  >
                    <div className="text-xl -tracking-[7%]">
                      {`${idx + 1}. ${question.question}`}
                    </div>
                    <div
                      style={{
                        border: "1px solid #E8E8E8",
                        marginBlock: "16px",
                      }}
                    />
                    <div className="flex flex-wrap gap-2">
                      {question?.options?.map((option, index) => {
                        return (
                          <div key={index} className="w-[30%] mt-3">
                            <Field
                              type="radio"
                              name={String(question.id)}
                              value={option}
                              checked={values[question.id] === option}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <label htmlFor={option} className="ml-2">
                              {option}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                    <ErrorMessage
                      name={String(question.id)}
                      component="div"
                      style={{ color: "red", marginTop: "4px" }}
                    />
                  </div>
                );
              })}
              <div className="mb-8 px-10 pt-9 pb-12 rounded-[30px] bg-[#FFFFFFB3] ">
                <div className="text-[16px] -tracking-[7%]">
                  Please provide your details before proceeding
                </div>
                <div
                  style={{
                    border: "1px solid #E8E8E8",
                    marginBlock: "30px",
                  }}
                />

                <div className="flex items-center gap-x-[5px] gap-y-[10px] flex-wrap">
                  <div>
                    <Field
                      type="text"
                      name="name"
                      style={{ border: "1px solid #ffffff" }}
                      className="w-[340px] text-[15px] text-[#666666] rounded-full bg-[#ffffff80] p-5"
                      placeholder="Name"
                    />{" "}
                    <ErrorMessage
                      name="name"
                      component="div"
                      style={{
                        color: "red",
                        marginTop: "4px",
                        zIndex: "10",
                        marginBlock: "2px",
                        marginLeft: "10px",
                      }}
                    />
                  </div>
                  <div>
                    <Field
                      type="text"
                      name="designation"
                      style={{ border: "1px solid #ffffff" }}
                      className="w-[340px] text-[15px] text-[#666666] rounded-full bg-[#ffffff80] p-5"
                      placeholder="Designation"
                    />{" "}
                    <ErrorMessage
                      name="designation"
                      component="div"
                      style={{
                        color: "red",
                        marginTop: "4px",
                        zIndex: "10",
                        marginBlock: "2px",
                        marginLeft: "10px",
                      }}
                    />
                  </div>
                  <div>
                    <Field
                      type="text"
                      name="healthcareEntityName"
                      style={{ border: "1px solid #ffffff" }}
                      className="w-[340px] text-[15px] text-[#666666] rounded-full bg-[#ffffff80] p-5"
                      placeholder="Healthcare Entity Name"
                    />{" "}
                    <ErrorMessage
                      name="healthcareEntityName"
                      component="div"
                      style={{
                        color: "red",
                        marginTop: "4px",
                        zIndex: "10",
                        marginBlock: "2px",
                        marginLeft: "10px",
                      }}
                    />
                  </div>
                  <div>
                    <Field
                      type="text"
                      name="email"
                      style={{ border: "1px solid #ffffff" }}
                      className="w-[340px] text-[15px] text-[#666666] rounded-full bg-[#ffffff80] p-5"
                      placeholder="Email"
                    />{" "}
                    <ErrorMessage
                      name="email"
                      component="div"
                      style={{
                        color: "red",
                        marginTop: "4px",
                        zIndex: "10",
                        marginBlock: "2px",
                        marginLeft: "10px",
                      }}
                    />
                  </div>
                  <div>
                    <Field
                      type="text"
                      name="contactNumber"
                      style={{ border: "1px solid #ffffff" }}
                      className="w-[340px] text-[15px] text-[#666666] rounded-full bg-[#ffffff80] p-5"
                      placeholder="Contact Number"
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^\d*$/.test(value) && value.length <= 10) {
                          setFieldValue("contactNumber", value);
                        }
                      }}
                    />{" "}
                    <ErrorMessage
                      name="contactNumber"
                      component="div"
                      style={{
                        color: "red",
                        marginTop: "4px",
                        zIndex: "10",
                        marginBlock: "2px",
                        marginLeft: "10px",
                      }}
                    />
                  </div>
                  <div>
                    <Field
                      type="text"
                      name="surveyorId"
                      style={{ border: "1px solid #ffffff" }}
                      className="w-[340px] text-[15px] text-[#666666] rounded-full bg-[#ffffff80] p-5"
                      placeholder="Surveyor ID"
                    />{" "}
                    <ErrorMessage
                      name="surveyorId"
                      component="div"
                      style={{
                        color: "red",
                        marginTop: "4px",
                        zIndex: "10",
                        marginBlock: "2px",
                        marginLeft: "10px",
                      }}
                    />
                  </div>
                  {/* <div>
                    <Field
                      type="text"
                      name="date"
                      style={{ border: "1px solid #ffffff" }}
                      className="w-[340px] text-[15px] text-[#666666] rounded-full bg-[#ffffff80] p-5"
                      placeholder="Date"
                    />{" "}
                    <ErrorMessage
                      name="date"
                      component="div"
                      style={{
                        color: "red",
                        marginTop: "4px",
                        zIndex: "10",
                        marginBlock: "2px",
                        marginLeft: "10px",
                      }}
                    />
                  </div>
                  <div>
                    <Field
                      type="text"
                      name="time"
                      style={{ border: "1px solid #ffffff" }}
                      className="w-[340px] text-[15px] text-[#666666] rounded-full bg-[#ffffff80] p-5"
                      placeholder="Time"
                    />{" "}
                    <ErrorMessage
                      name="time"
                      component="div"
                      style={{
                        color: "red",
                        marginTop: "4px",
                        zIndex: "10",
                        marginBlock: "2px",
                        marginLeft: "10px",
                      }}
                    />
                  </div> */}
                </div>
              </div>
              <div className="flex justify-center  items-center">
                {saving ? (
                  <CircularProgress />
                ) : (
                  <button
                    type="submit"
                    className="text-white font-semibold uppercase bg-[#017C71] px-8 py-3 rounded-full border-none cursor-pointer"
                  >
                    Submit
                  </button>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default SurveyForm;
