import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import CaseListV2 from "./caseListV2";
import { useDispatch } from "react-redux";
import { setOutsideUser } from "../../redux/roleSlice";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { CaseServices } from "../../services/CaseServices";
import { InterUserMangementServices } from "../../services/InternalUserMangementServices";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import "./manageCases.scss";
import { CircularProgress } from "@mui/material";
import { updatePageNumber } from "../../redux/caseDetailsSlice";
import { RpaServices } from "../../services/RpaServices";
import { HospitalServices } from "../../services/HospitalServices";
const ActiveCasesDashboardV2 = () => {
  const [caseStatus, setCaseStatus] = useState("");
  const [stageColor, setStageColor] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);
  const [caseStageReport, setCaseStageReport] = useState(null);
  const [selectedStage, setSelectedStage] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [openAccordion, setAccordion] = useState(null);
  const [assignedUserStages, setAssignedUserStages] = useState([]);

  const [hospitalNames, setHospitalNames] = useState([]);
  const [rpaHospitalList, setRpaHospitalList] = useState(null);
  const [rpadata, setRpaData] = useState("");
  const storeCaseId = useSelector((state) => state.caseDetails.caseId);
  const selectedStageRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const id = searchParams.get("caseId");
  const token = searchParams.get("token");
  if (token) {
    dispatch(setOutsideUser(true));
    const userDetails = JSON.stringify({
      session: {
        token: atob(decodeURIComponent(token)),
        updatedAt: "2024-09-12T11:40:46.539Z",
        createdAt: "2024-09-12T11:40:46.539Z",
      },
      hospital: {},
    });
    localStorage.setItem("userDetails", userDetails);
  }

  const getPendingTime = (caseDate) => {
    let date1 = new Date(caseDate); // oldest case's date in this stage
    let date2 = new Date(); // current date

    // Calculate the difference in milliseconds
    let differenceInMilliseconds = date2 - date1;

    // Calculate the difference in days, hours, minutes, and seconds
    let days = Math.floor(differenceInMilliseconds / (1000 * 3600 * 24));
    let hours = Math.floor(
      (differenceInMilliseconds % (1000 * 3600 * 24)) / (1000 * 3600)
    );
    let minutes = Math.floor(
      (differenceInMilliseconds % (1000 * 3600)) / (1000 * 60)
    );
    let seconds = Math.floor((differenceInMilliseconds % (1000 * 60)) / 1000);
    return `${days}D ${hours}H ${minutes}M ${
      !days && !hours && !minutes ? seconds + "S" : ""
    }`;
  };
  const getStageColor = (stageStatus, oldestCaseTime, bgColor) => {
    let date1 = oldestCaseTime ? new Date(oldestCaseTime) : new Date(); // oldest case's date in this stage
    let date2 = new Date(); // current date

    // Calculate the difference in milliseconds
    let differenceInMilliseconds = date2 - date1;
    // let minutes = Math.floor(differenceInMilliseconds / (1000 * 60));
    const filteredData = stageStatus?.filter(
      (stage) =>
        stage.endTime === "" ||
        stage.endTime === null ||
        (differenceInMilliseconds >= Number(stage.startTime) * 60 * 1000 &&
          differenceInMilliseconds <= Number(stage.endTime) * 60 * 1000)
    );
    if (bgColor) {
      return filteredData?.length > 0 ? filteredData[0].bgColor : "#FFFFFF";
    }

    return filteredData?.length > 0
      ? filteredData[0].bgColor.includes("#D32F2F")
        ? "#FFFFFF"
        : filteredData[0]?.textColor
      : "#000000";
  };
  const getStageCaseReport = async () => {
    try {
      const response = await CaseServices.stageCaseReport(assignedUserStages);
      setCaseStageReport(response?.data?.customReport);
      setStageColor(response?.data?.stageStatusTimeColor);
    } catch (error) {
      window.alert(error);
    }
  };
  const getUserAssignedStages = async () => {
    try {
      const response = await InterUserMangementServices.getUserAssignedStages();

      setAssignedUserStages(response.data.assignedCommandCenterStages);
    } catch (error) {
      window.alert(error);
    }
  };
  const handleCaseStageSelection = (stage) => {
    if (selectedStage?.id === stage?.stageStatusId) {
      setSelectedStage(null);
    } else {
      let stageArray = [stage.mainStage];
      for (let subStage of stage.subStages) {
        stageArray.push(subStage.stage);
      }
      selectedStageRef.current.scrollIntoView({ behavior: "smooth" });
      dispatch(updatePageNumber(1));
      setSelectedStage({ id: stage.stageStatusId, stages: stageArray });
    }
  };
  const fetchRpaRequests = async () => {
    try {
      const response = await RpaServices.rpaRequests();

      if (response.status === 200 && response.data) {
        return response.data; // Ensure correct return
      } else {
        alert("Failed to fetch RPA Requests. Please try again.");
        return null;
      }
    } catch (error) {
      console.error("Error fetching RPA Requests:", error);
      alert("An error occurred while fetching RPA Requests.");
      return null;
    }
  };

  const fetchHospitalNames = async () => {
    try {
      const res = await HospitalServices.gethospitals("", 1, 1500);
      setHospitalNames(res?.data?.list);
    } catch (error) {
      console.error("Error fetching hospital names:", error);
    }
  };

  const getRpaRunningHospitals = async () => {
    try {
      const res = await RpaServices.rpaRunningHospitals();
      setRpaHospitalList(res?.data?.RpaRunningHospitals);
    } catch (error) {
      window.alert(error);
    }
  };

  const handleAddNewCase = () => {
    navigate("/addcases");
  };

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    getUserAssignedStages();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    getStageCaseReport();
  }, [assignedUserStages, storeCaseId]);

  useEffect(() => {
    const getRpaData = async () => {
      const data = await fetchRpaRequests();
      if (data) {
        setRpaData(data);
      }
    };

    getRpaData();
    getRpaRunningHospitals();
  }, []);

  useEffect(() => {
    fetchHospitalNames();
  }, []);

  return (
    <div className="manage-box">
      <div className="flex items-center space-x-9">
        <span className="path_line flex items-center space-x-2">
          <WidgetsOutlinedIcon style={{ height: "24px" }} />
          <span>/Dashboard</span>
          <span className="whitespace-nowrap">/ Manage Cases</span>
        </span>
        <div className="w-full z-20 p-4 flex flex-col items-start ml-7">
          {/* <h2 className="text-red-600 font-semibold mb-3">
            Please do not log in to these hospitals for some time, as the RPA is
            registering new cases.
          </h2> */}
          {/* 
          {mappedRequests.length > 0 ? (
            <div className="flex space-x-2 self-center bg-gray-100 p-3 max-h-32 overflow-x-auto w-full">
              {mappedRequests.map((request, index) => (
                <div key={index} className="p-2 text-s">
                  {request.hospitalName || "Unknown"}
                </div>
              ))}
            </div>
          ) : (
            <p>Loading...</p>
          )} */}
        </div>
      </div>

      {caseStageReport?.filter(
        (stage) =>
          stage?.mainStage?.toLowerCase().includes("admission") ||
          stage?.mainStage?.toLowerCase().includes("reference") ||
          stage?.stageText?.toLowerCase().includes("admission")
      ).length > 0 && (
        <Accordion
          expanded={openAccordion === 1}
          style={{ marginBottom: "4px", borderRadius: "12px" }}
        >
          <AccordionDetails>
            <div className="flex gap-4 items-start">
              <div className="w-[140px] text-xl text-[#191919] font-semibold">
                Admission
              </div>
              {caseStageReport ? (
                <div className="w-[90%] grid xl:grid-cols-5 2xl:grid-cols-6 gap-3">
                  {caseStageReport
                    ?.filter(
                      (stage) =>
                        stage?.mainStage
                          ?.toLowerCase()
                          ?.includes("admission") ||
                        stage?.mainStage
                          ?.toLowerCase()
                          ?.includes("reference") ||
                        stage?.stageText?.toLowerCase()?.includes("admission")
                    )
                    .map((stage, idx) => {
                      return (
                        <div
                          key={idx}
                          style={{
                            backgroundColor: getStageColor(
                              stageColor[stage.stageStatusId],
                              stage.oldestCaseDate,
                              true
                            ),

                            border:
                              selectedStage?.id === stage?.stageStatusId &&
                              "2px solid #999999",
                            boxShadow:
                              selectedStage?.id === stage?.stageStatusId &&
                              "0 1px 20px rgba(0, 0, 0, 0.7)",
                            transform:
                              selectedStage?.id === stage?.stageStatusId &&
                              "scale(1.1, 1.2)",
                          }}
                          className={`flex flex-col justify-between h-[90px] py-2 px-[5] rounded-[8px] cursor-pointer hovernow`}
                          onClick={() => handleCaseStageSelection(stage)}
                        >
                          <div
                            style={{
                              color: getStageColor(
                                stageColor[stage.stageStatusId],
                                stage.oldestCaseDate,
                                false
                              )?.slice(0, 7),
                            }}
                            className="text-xs text-[#191919] font-semibold text-center"
                          >
                            {stage.stageText || stage.mainStage}
                          </div>
                          <div
                            style={{
                              color: getStageColor(
                                stageColor[stage.stageStatusId],
                                stage.oldestCaseDate,
                                false
                              )?.slice(0, 7),
                            }}
                            className="text-sm text-center font-semibold"
                          >
                            {stage.totalNewCases || "0"}/{stage.totalCaseCount}
                          </div>
                          <div
                            style={{
                              backgroundColor:
                                getStageColor(
                                  stageColor[stage.stageStatusId],
                                  stage.oldestCaseDate,
                                  false
                                )?.slice(0, 7) + "15",
                              color: getStageColor(
                                stageColor[stage.stageStatusId],
                                stage.oldestCaseDate,
                                false
                              )?.slice(0, 7),
                            }}
                            className="bg-white text-xs text-[#191919] font-semibold text-center py-[2px]"
                          >
                            From:{" "}
                            <span>
                              {stage?.oldestCaseDate
                                ? getPendingTime(stage.oldestCaseDate)
                                : "---"}
                            </span>
                          </div>{" "}
                        </div>
                      );
                    })}
                </div>
              ) : (
                <div className="w-[90%] flex justify-center">
                  {" "}
                  <CircularProgress />
                </div>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
      )}
      {caseStageReport?.filter(
        (stage) =>
          stage?.mainStage?.toLowerCase()?.includes("enhancement") ||
          stage?.mainStage?.toLowerCase()?.includes("enhacement")
      ).length > 0 && (
        <Accordion
          expanded={openAccordion === 2}
          style={{ marginBottom: "4px", borderRadius: "12px" }}
        >
          <AccordionDetails>
            <div className="flex gap-4 items-start ">
              <div className="w-[140px] text-xl text-[#191919] font-semibold">
                Enhancement
              </div>
              {caseStageReport ? (
                <div className="w-[90%] grid xl:grid-cols-5 2xl:grid-cols-6 gap-3">
                  {caseStageReport
                    ?.filter(
                      (stage) =>
                        stage?.mainStage
                          ?.toLowerCase()
                          ?.includes("enhancement") ||
                        stage?.mainStage?.toLowerCase()?.includes("enhacement")
                    )
                    .map((stage, idx) => {
                      return (
                        <div
                          key={idx}
                          style={{
                            backgroundColor: getStageColor(
                              stageColor[stage.stageStatusId],
                              stage.oldestCaseDate,
                              true
                            ),
                            border:
                              selectedStage?.id === stage?.stageStatusId &&
                              "2px solid #999999",
                            boxShadow:
                              selectedStage?.id === stage?.stageStatusId &&
                              "0 1px 20px rgba(0, 0, 0, 0.7)",
                            transform:
                              selectedStage?.id === stage?.stageStatusId &&
                              "scale(1.1, 1.2)",
                          }}
                          className="flex flex-col justify-between h-[90px] py-2 px-[5] rounded-[8px] cursor-pointer hovernow"
                          onClick={() => handleCaseStageSelection(stage)}
                        >
                          <div
                            style={{
                              color: getStageColor(
                                stageColor[stage.stageStatusId],
                                stage.oldestCaseDate,
                                false
                              )?.slice(0, 7),
                            }}
                            className="text-xs text-[#191919] font-semibold text-center"
                          >
                            {stage.stageText || stage.mainStage}
                          </div>
                          <div
                            style={{
                              color: getStageColor(
                                stageColor[stage.stageStatusId],
                                stage.oldestCaseDate,
                                false
                              )?.slice(0, 7),
                            }}
                            className="text-sm text-center font-semibold"
                          >
                            {stage.totalNewCases || "0"}/{stage.totalCaseCount}{" "}
                          </div>
                          <div
                            style={{
                              backgroundColor:
                                getStageColor(
                                  stageColor[stage.stageStatusId],
                                  stage.oldestCaseDate,
                                  false
                                )?.slice(0, 7) + "15",

                              color: getStageColor(
                                stageColor[stage.stageStatusId],
                                stage.oldestCaseDate,
                                false
                              )?.slice(0, 7),
                            }}
                            className="bg-white text-xs text-[#191919] font-semibold text-center py-[2px]"
                          >
                            From:{" "}
                            <span>
                              {stage?.oldestCaseDate
                                ? getPendingTime(stage.oldestCaseDate)
                                : "---"}
                            </span>
                          </div>{" "}
                        </div>
                      );
                    })}
                </div>
              ) : (
                <div className="w-[90%] flex justify-center">
                  <CircularProgress />
                </div>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
      )}
      {caseStageReport?.filter(
        (stage) =>
          stage?.mainStage?.toLowerCase()?.includes("discharge") ||
          stage?.mainStage?.toLowerCase()?.includes("claim")
      ).length > 0 && (
        <Accordion
          expanded={openAccordion === 3}
          style={{ marginBottom: "12px", borderRadius: "12px" }}
        >
          <AccordionDetails>
            <div className="flex gap-4 items-start">
              <div className="w-[140px] text-xl text-[#191919] font-semibold">
                Claim
              </div>
              {caseStageReport ? (
                <div className="w-[90%] grid xl:grid-cols-5 2xl:grid-cols-6 gap-3">
                  {caseStageReport
                    // .slice(0, 9)
                    ?.filter(
                      (stage) =>
                        stage?.mainStage
                          ?.toLowerCase()
                          ?.includes("discharge") ||
                        stage?.mainStage?.toLowerCase()?.includes("claim")
                    )
                    .map((stage, idx) => {
                      return (
                        <div
                          key={idx}
                          style={{
                            backgroundColor: getStageColor(
                              stageColor[stage.stageStatusId],
                              stage.oldestCaseDate,
                              true
                            ),
                            border:
                              selectedStage?.id === stage?.stageStatusId &&
                              "2px solid #999999",
                            boxShadow:
                              selectedStage?.id === stage?.stageStatusId &&
                              "0 1px 20px rgba(0, 0, 0, 0.7)",
                            transform:
                              selectedStage?.id === stage?.stageStatusId &&
                              "scale(1.1, 1.2)",
                          }}
                          className="flex flex-col justify-between h-[90px] py-2 px-[5] rounded-[8px] cursor-pointer hovernow"
                          onClick={() => handleCaseStageSelection(stage)}
                        >
                          <div
                            style={{
                              color: getStageColor(
                                stageColor[stage.stageStatusId],
                                stage.oldestCaseDate,
                                false
                              )?.slice(0, 7),
                            }}
                            className="text-xs text-[#191919] font-semibold text-center"
                          >
                            {stage.stageText || stage.mainStage}
                          </div>
                          <div
                            style={{
                              color: getStageColor(
                                stageColor[stage.stageStatusId],
                                stage.oldestCaseDate,
                                false
                              )?.slice(0, 7),
                            }}
                            className="text-sm text-center font-semibold"
                          >
                            {stage.totalNewCases || "0"}/{stage.totalCaseCount}{" "}
                          </div>
                          <div
                            style={{
                              backgroundColor:
                                getStageColor(
                                  stageColor[stage.stageStatusId],
                                  stage.oldestCaseDate,
                                  false
                                )?.slice(0, 7) + 15,

                              color: getStageColor(
                                stageColor[stage.stageStatusId],
                                stage.oldestCaseDate,
                                false
                              )?.slice(0, 7),
                            }}
                            className="text-xs text-[#191919] font-semibold text-center py-[2px]"
                          >
                            From:{" "}
                            <span>
                              {stage?.oldestCaseDate
                                ? getPendingTime(stage.oldestCaseDate)
                                : "---"}
                            </span>
                          </div>{" "}
                        </div>
                      );
                    })}
                </div>
              ) : (
                <div className="w-[90%] flex justify-center">
                  <CircularProgress />
                </div>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
      )}

      <hr
        style={{
          color: "#E7E7E7",
          marginBlock: "32px",
        }}
      />
      <div
        style={{
          display: width > 640 ? "flex" : "block",
          marginBlock: "8px",
        }}
        className="heading"
      >
        <div>
          Manage Cases{" "}
          <span ref={selectedStageRef} className="text-[16px]">
            :{` ${(selectedStage && selectedStage.stages[0]) || ""}`}
          </span>
        </div>
        <div className="btn-container">
          <button
            className="outlined-btn"
            style={caseStatus === "ACTIVE" ? {} : { background: "none" }}
            onClick={() => setCaseStatus("ACTIVE")}
          >
            ACTIVE CASE
          </button>
          <button
            className="outlined-btn"
            style={caseStatus === "INACTIVE" ? {} : { background: "none" }}
            onClick={() => setCaseStatus("INACTIVE")}
          >
            INACTIVE CASE
          </button>
          <button
            className="outlined-btn"
            style={caseStatus === "INACTIVE" ? {} : { background: "none" }}
            // onClick={() => setCaseStatus("INACTIVE")}
          >
            DELETE
          </button>
          <button
            style={{ cursor: "pointer" }}
            className="filled-btn"
            onClick={handleAddNewCase}
          >
            + ADD CASE
          </button>
        </div>
      </div>

      <>
        {id ? (
          <CaseListV2
            caseId={atob(decodeURIComponent(id))}
            selectedStage={selectedStage?.stages}
            getStageCaseReport={getStageCaseReport}
            getStageColor={getStageColor}
          />
        ) : (
          <CaseListV2
            selectedStage={selectedStage?.stages}
            getStageCaseReport={getStageCaseReport}
            getStageColor={getStageColor}
          />
        )}
      </>
    </div>
  );
};

export default ActiveCasesDashboardV2;
