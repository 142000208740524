import { useState, useEffect, useRef } from "react";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import DownloadIcon from "../assets/downloadIcon.svg";
import CustomPagination from "../utils/paginationUtils";
import SearchIcon from "../assets/searchIcon.svg";
import { getInactiveHospitals } from "../services/HospitalServices";
import CrossIcon from "../assets/crossIcon.svg";
import { approveHospital } from "../services/HospitalServices";
import { logOut } from "../config/user";
import { CircularProgress } from "@mui/material";
const ApproveHospital = () => {
  const [submitting, setSubmitting] = useState(false);
  const [hospitals, setHospitals] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [page, setPage] = useState(1);
  const [approveModal, setApproveModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [selectedHospitalId, setSelectedHospitalId] = useState(null);
  let searchTimeRef = useRef();
  const limit = 10;

  const handleSearch = (e) => {
    //debouncing structure
    if (searchTimeRef.current) {
      clearInterval(searchTimeRef.current);
    }
    searchTimeRef.current = setTimeout(() => {
      setSearchKey(e.target.value);
    }, 500);
  };

  const handleHospitalApprove = async (hospitalId, status) => {
    setSubmitting(true);
    try {
      const response = await approveHospital(hospitalId, status);
      setApproveModal(false);
      setRejectModal(false);
      window.alert(
        `Hospital ${status === "ACTIVE" ? "approved" : "rejected"} successfully`
      );
      window.location.reload();
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
    setSubmitting(false);
  };
  const getList = async () => {
    try {
      const result = await getInactiveHospitals(searchKey, page, limit);
      setHospitals(result.data.hospitals);
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
  };
  useEffect(() => {
    getList();
  }, [page, searchKey]);
  return (
    <div
      style={{
        fontFamily: "Inter",
        padding: 32,
        marginTop: 93,
        width: "100%",
      }}
    >
      <span className="path_line">
        <WidgetsOutlinedIcon style={{ height: "24px" }} /> &nbsp; &nbsp;/ &nbsp;
        &nbsp; Dashboard &nbsp; &nbsp;/ &nbsp; &nbsp; Approve Hospital
      </span>
      <div className="heading">Approve Hospital</div>

      <div
        style={{
          height: "1px",
          border: "1px solid #E7E7E7",
          margin: "24px 0",
        }}
      />

      <div
        style={{ border: "1px solid #E4E4E4" }}
        className="p-6 rounded-[16px]"
      >
        <div className="flex justify-between items-center">
          <div className="text-xl text-[#191919] font-semibold">
            Manage Approval
          </div>
          <div
            style={{ border: "1px solid #E4E4E4" }}
            className="flex items-center gap-2 rounded-[8px] px-4 py-[6px]"
          >
            <img src={SearchIcon} alt="search-icon" />
            <input
              type="text"
              placeholder="Search anything"
              style={{ border: "0" }}
              className="outline-none"
              onChange={handleSearch}
            />
          </div>
          <div className="flex items-center gap-4">
            <div className="text-sm text-[#797979]">Sort by</div>
            <div>
              <select
                name="documentStatus"
                id="documentStatus"
                style={{ border: "1px solid #017C57" }}
                className="rounded-full text-[#017C57] bg-white py-2 px-4"
              >
                <option value="">Document Status</option>
                <option value="ready for approval">Ready For Approval</option>
                <option value="rejected">Rejected</option>
                <option value="pending">Pending</option>
              </select>
            </div>
          </div>
        </div>
        <div className="mt-16">
          <div className="grid grid-cols-5 font-semibold text-[#191919] px-2">
            <div>Status</div>
            <div>Hospital Name</div>
            <div>Hospital Email</div>
            <div>Location</div>
            <div>Serial Number</div>
          </div>
        </div>

        {hospitals.length ? (
          <div className="mt-7">
            {hospitals?.map((hospital, idx) => {
              return (
                <div
                  className={` ${
                    idx % 2 == 0 ? "bg-white" : "bg-[#F9F9F9]"
                  } rounded-[12px]`}
                  key={idx}
                >
                  <div className="grid grid-cols-5 px-2">
                    <div>
                      <div
                        className={`w-fit text-xs font-semibold rounded-full px-4 py-1 uppercase mt-4 ${
                          hospital.status.toLowerCase() === "inactive"
                            ? "text-[#BCA03C] bg-[#FFF3C6]"
                            : hospital.status.toLowerCase() === "active"
                            ? "text-[#017C57] bg-[#CDFAF4]"
                            : hospital.status.toLowerCase() === "rejected"
                            ? "text-[#CF3636] bg-[#FFD6DB]"
                            : ""
                        } `}
                      >
                        {hospital.status}
                      </div>
                    </div>
                    <div>
                      <div className="text-sm text-[#797979] mt-4">
                        {hospital.name}
                      </div>
                    </div>
                    <div>
                      <div className="text-sm text-[#797979] mt-4">
                        placeholder.mail@gmail.com
                      </div>
                    </div>
                    <div>
                      <div className="text-sm text-[#797979] mt-4">
                        Apollo Hospitals Rd, Block E, Sector 26, Noida, Uttar
                        Pradesh 201301
                      </div>
                    </div>
                    <div>
                      <div className="text-sm text-[#797979] mt-4">
                        {hospital.id}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end items-center gap-[10px] text-xs p-4">
                    {hospital.status.toLowerCase() === "rejected" && (
                      <div className="flex gap-1 text-[#797979] text-sm">
                        <div className="font-semibold">
                          Rejected on : 16/05/2024{" "}
                        </div>
                        - Re-applied
                      </div>
                    )}
                    <div
                      style={{
                        border: `1px solid ${
                          hospital.businessDocumentUrl ? "#017C57" : "#EEEEEE"
                        }`,
                      }}
                      className={`cursor-pointer ${
                        hospital.businessDocumentUrl
                          ? "text-[#017C57] "
                          : "text-[#EEEEEE] "
                      } rounded-full px-4 py-2 `}
                      onClick={() => {
                        setSelectedHospitalId(hospital.id);
                        setRejectModal(true);
                      }}
                    >
                      Reject
                    </div>
                    <div
                      style={{
                        border: `1px solid ${
                          hospital.businessDocumentUrl ? "#017C57" : "#EEEEEE"
                        }`,
                      }}
                      className={`text-white  rounded-full px-4 py-2 cursor-pointer ${
                        hospital.businessDocumentUrl
                          ? "bg-[#017C57] "
                          : "bg-[#EEEEEE] "
                      }`}
                      onClick={() => {
                        setSelectedHospitalId(hospital.id);
                        setApproveModal(true);
                      }}
                    >
                      Approve
                    </div>

                    <a
                      href={hospital.businessDocumentUrl}
                      download
                      style={{
                        border: `1px solid ${
                          hospital.businessDocumentUrl ? "#017C57" : "#EEEEEE"
                        }`,
                        textDecoration: "none",
                      }}
                      className={`flex items-center gap-2 text-white rounded-full px-4 py-2 cursor-pointer ${
                        hospital.businessDocumentUrl
                          ? "bg-[#017C57]"
                          : "bg-[#EEEEEE]"
                      }`}
                    >
                      <img src={DownloadIcon} alt="download-icon" />
                      Download License{" "}
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="flex justify-center items-center mt-12">
            <CircularProgress />
          </div>
        )}
      </div>
      <div className="flex justify-center mt-8">
        <CustomPagination page={page} setPage={setPage} />
      </div>

      {approveModal && (
        <div className="fixed top-0 left-0 h-screen w-screen flex justify-center items-center bg-[#017c5780] z-30 ">
          <div className="relative h-[340px] w-[720px] flex justify-center items-center bg-white rounded-[16px] ">
            <div className="w-full">
              <div className="text-2xl font-semibold text-[#191919] text-center">
                Are you sure you want to approve?
              </div>
              <div className="text-[#797979] text-center mt-4">
                Lorem ipsum dolor sit amet
              </div>

              {submitting ? (
                <div className="flex justify-center mt-14">
                  <CircularProgress />
                </div>
              ) : (
                <div className="flex justify-center items-center gap-5 font-semibold text-xs mt-14">
                  <div
                    style={{ border: "1px solid #017C57" }}
                    className="text-white bg-[#017C57] rounded-full px-8 py-1 cursor-pointer"
                    onClick={() =>
                      handleHospitalApprove(selectedHospitalId, "ACTIVE")
                    }
                  >
                    Yes
                  </div>
                  <div
                    style={{ border: "1px solid #017C57" }}
                    className="text-[#017C57] px-8 py-1 rounded-full cursor-pointer"
                    onClick={() => setApproveModal(false)}
                  >
                    No
                  </div>
                </div>
              )}
              <div className="absolute right-12 top-8 cursor-pointer">
                <img
                  src={CrossIcon}
                  alt="close-popup-icon"
                  onClick={() => setApproveModal(false)}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {rejectModal && (
        <div className="fixed top-0 left-0 h-screen w-screen flex justify-center items-center bg-[#017c5780] z-30 ">
          <div className="relative h-[340px] w-[720px] flex justify-center items-center bg-white rounded-[16px] ">
            <div className="w-full">
              <div className="text-2xl font-semibold text-[#191919] text-center">
                Are you sure you want to reject?
              </div>
              {/* <div className="text-[#797979] text-center mt-4">
                Lorem ipsum dolor sit amet
              </div> */}

              {submitting ? (
                <div className="flex justify-center mt-14">
                  <CircularProgress />
                </div>
              ) : (
                <div className="flex justify-center items-center gap-5 font-semibold text-xs mt-14">
                  <div
                    className="text-white bg-[#017C57] rounded-full px-8 py-1 cursor-pointer"
                    onClick={() =>
                      handleHospitalApprove(selectedHospitalId, "REJECTED")
                    }
                  >
                    Yes
                  </div>
                  <div
                    style={{ border: "1px solid #017C57" }}
                    className="text-[#017C57] px-8 py-1 rounded-full cursor-pointer"
                    onClick={() => setRejectModal(false)}
                  >
                    No
                  </div>
                </div>
              )}
              <div className="absolute right-12 top-8 cursor-pointer">
                <img
                  src={CrossIcon}
                  alt="close-popup-icon"
                  onClick={() => setRejectModal(false)}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ApproveHospital;
