import { useState, useEffect, useRef } from "react";
import { AuthServices } from "../../../services/AuthServices";
import { CaseServices } from "../../../services/CaseServices";
import { PreinitiateServices } from "../../../services/PreinitiateServices";
import CloseIcon from "../../../assets/hospitalCloseIcon.svg";
import uploadIconGreen from "../../../assets/uploadIcon.svg";
import { CircularProgress } from "@mui/material";
import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
const UploadAdmissionReferenceDoc = ({
  setOpen,
  selectedCase,
  subStatusList,
  getDocumentsData,
  AllCases,
  referenceDocuments,
}) => {
  const [pdfDocument, setPdfDocument] = useState(null);
  const [images, setImages] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);
  const [selectAll, setSelectAll] = useState(true);
  const [percentage, setPercentage] = useState(0);
  const [description, setDescription] = useState("");
  const fileInputRef = useRef();

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  //Method to upload the pdf doc
  async function uploadDocument(event) {
    setProcessing(true);
    try {
      const file = event.target.files[0];
      const fileType = file.type.split("/")[0];
      if (fileType === "application" && file.name.endsWith(".pdf")) {
        setDescription(event.target.files[0].name.slice(0, -4));
        const uri = URL.createObjectURL(file);
        const _PDF_DOC = await pdfjs.getDocument(uri).promise;
        setPdfDocument(_PDF_DOC);
      } else if (fileType === "image") {
        const imageBlob = URL.createObjectURL(file);
        setImages([...images, { isSelected: true, documentUrl: imageBlob }]);
        setSelectedCount((prev) => prev + 1);
        setProcessing(false);
      }
    } catch (error) {
      alert(error.message);
    }
  }
  //Method to get the image list from the uploaded pdf document
  async function getImageList() {
    const imagesList = [...images];
    const canvas = document.createElement("canvas");
    canvas.setAttribute("class", "canv");
    try {
      for (let i = 1; i <= pdfDocument.numPages; i++) {
        const page = await pdfDocument.getPage(i);
        const viewport = page.getViewport({ scale: 3 });
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        const renderContext = {
          canvasContext: canvas.getContext("2d"),
          viewport: viewport,
        };

        await page.render(renderContext).promise;
        canvas.toBlob((blob) => {
          if (blob) {
            const blobUrl = URL.createObjectURL(blob);
            imagesList.push({ isSelected: true, documentUrl: blobUrl });
            setSelectedCount((prev) => prev + 1);
            if (i === pdfDocument.numPages) {
              setImages([...imagesList]);
            }
          }
        }, "image/jpeg");
      }
    } catch (error) {
      console.error("Error getting image list:", error);
    }
    setSelectedCount(imagesList.length);
    setProcessing(false);
  }
  //This method  to save the newly uploaded document's url to settlemed database
  const addDocument = async (docUrl) => {
    if (docUrl) {
      const sequence =
        referenceDocuments.length > 0
          ? referenceDocuments[referenceDocuments.length - 1].sequence + 1
          : 1; //here we are generating sequence for the newly added document
      const modifiedData = docUrl?.map((url, idx) => {
        return {
          caseId: selectedCase.id,
          documentUrl: url,
          sequence: sequence + idx,
          description: `${description}_${idx}`,
          documentType: "ADMISSIONDOCUMENT",
        };
      });
      try {
        await PreinitiateServices.addReferenceDocuments(modifiedData);
        window.alert("Documents Added Successfully ");
        await getDocumentsData();
        await AllCases();
        setOpen(false);
      } catch (error) {
        window.alert(error);
      }
      setDescription(null);
    }
    setPercentage(0);
    setUploadStatus(false);
  };
  //Method to upload the image list's blob data to s3 bucket
  const uploadImages = async () => {
    if (selectedCount > 0) {
      setUploadStatus(true);
      let data = new FormData();

      for (let index = 0; index < images.length; index++) {
        if (images[index].isSelected) {
          let image = images[index].documentUrl;
          if (!(image instanceof Blob)) {
            const response = await fetch(image);
            image = await response.blob();
          }
          data.append("images", image, `${description + "_" + index}.jpeg`);
        }
      }
      try {
        let res = await AuthServices.uploadPdfToImage(data, setPercentage);
        console.log("Image data resonse from apis", res.data.urls);
        //   setDocUrl(res?.data?.urls);
        await addDocument(res?.data?.urls);
        setPdfDocument(null);
        setImages([]);
        // window.alert("Image uploaded successfully");
      } catch (error) {
        console.error("Upload failed:", error);
      }
    } else {
      window.alert("Please Select Atleast One Image");
    }
  };

  const handleSelection = (index) => {
    const modifiedData = images.map((document, idx) => {
      if (idx === index) {
        if (document.isSelected) {
          setSelectedCount(selectedCount - 1);
        } else {
          setSelectedCount(selectedCount + 1);
        }
        return { ...document, isSelected: !document.isSelected };
      } else {
        return document;
      }
    });
    // setDocumentsUrl(modifiedData);
    setImages(modifiedData);
  };
  const handleSelectAll = () => {
    if (selectAll) {
      const modifiedData = images.map((document) => {
        return { ...document, isSelected: false };
      });
      setSelectedCount(0);
      setImages([...modifiedData]);
    } else {
      const modifiedData = images.map((document) => {
        return { ...document, isSelected: true };
      });
      setImages([...modifiedData]);
      setSelectedCount(images.length);
    }
  };
  useEffect(() => {
    //This is done to check selectAll check box when all documents are selected
    if (selectedCount && selectedCount === images?.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedCount]);
  useEffect(() => {
    pdfDocument && getImageList();
  }, [pdfDocument]);

  console.log("images:", images);
  return (
    <div className="fixed top-0 left-0 h-screen w-screen flex justify-center items-center bg-[#017C7180] ">
      <div className="relative h-[80%] w-[90%] 2xl:w-[80%] bg-white rounded-[30px] p-10">
        <div
          className="absolute top-3 right-4 cursor-pointer"
          onClick={() => setOpen(false)}
        >
          <img src={CloseIcon} alt="close-icon" height={53} width={52} />
        </div>
        <div className="flex justify-between items-end mr-8">
          <div className="text-[24px] 2xl:text-[30px]">Upload Document </div>
          {images?.length > 0 && (
            <div>
              <label htmlFor="selectAll">Select All</label>{" "}
              <input
                type="checkbox"
                id="selectAll"
                checked={selectAll}
                className="h-[16px] w-[16px]"
                onClick={handleSelectAll}
              />
            </div>
          )}
        </div>

        <div
          style={{ border: "1px solid #E4E4E4" }}
          className="w-[100%] h-[90%] flex justify-center items-start  gap-2 flex-wrap overflow-y-scroll mt-4 p-1  box-border rounded-[20px]"
        >
          {" "}
          {images.length > 0 ? (
            images.map((image, idx) => {
              return (
                <div
                  key={idx}
                  style={{ border: "1px solid #E4E4E4" }}
                  className="relative bg-white rounded-[17px] p-1"
                >
                  <div className="flex justify-between px-2">
                    <div>{idx + 1}</div>
                    <div>
                      <input
                        type="checkbox"
                        checked={image.isSelected}
                        onClick={() => handleSelection(idx)}
                        //   className="h-[20px] w-[20px]"
                      />
                    </div>
                  </div>
                  <img
                    src={image?.documentUrl}
                    alt=""
                    width={248}
                    height={249}
                    style={{ border: "1px solid #E4E4E4" }}
                    className="object-fit rounded-[15px]"
                  />
                  <div className="absolute bottom-0.5 left-0  w-full ">
                    <div
                      style={{
                        background: "#00000060",
                        backdropFilter: "blur(3.6976048946380615px)",
                      }}
                      className="flex w-[94%] justify-between items-center rounded-[14px] py-2 mx-auto px-1"
                    >
                      {/* <div className="text-sm text-white leading-[18.27px] -tracking-[3%] text-ellipsis whitespace-nowrap overflow-hidden">
                      {document.description}
                    </div> */}
                      <div className="flex items-center gap-4">
                        {/* <div
                        className="cursor-pointer"
                        onClick={() => deleteDocument(document.id)}
                      >
                        <img src={HospitalDelIcon} alt="" />
                      </div> */}
                        {/* <div>
                        {" "}
                        <img
                          src={RightArrow}
                          alt="arrow-icon"
                          height={12}
                          width={12}
                          className="-rotate-45 cursor-pointer"
                        />
                      </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="h-full w-full flex justify-center items-center">
              {processing ? (
                <CircularProgress />
              ) : (
                <div
                  style={{ border: "1px solid #017C71" }}
                  className="flex items-center gap-3 py-3 px-5 text-[#017C71] rounded-full cursor-pointer"
                  onClick={handleButtonClick}
                >
                  Upload Files <img src={uploadIconGreen} alt="upload-icon" />
                </div>
              )}
            </div>
          )}
        </div>
        <input
          type="file"
          onChange={(e) => uploadDocument(e)}
          style={{ display: "none" }}
          ref={fileInputRef}
          accept=".pdf,.png,.jpg,.jpeg"
        />
        {uploadStatus || processing ? (
          <div className="flex justify-end">
            <CircularProgress />
          </div>
        ) : (
          images?.length > 0 && (
            <div className="flex justify-end items-center gap-3 text-xs 2xl:text-sm text-white -tracking-[3%] uppercase mt-1">
              <div
                className="py-2 2xl:py-3 px-4 2xl:px-5 bg-[#017C71] rounded-full cursor-pointer"
                onClick={handleButtonClick}
              >
                Add More Files
              </div>

              <div
                className="py-2 2xl:py-3 px-7 2xl:px-8 bg-[#017C71] rounded-full cursor-pointer"
                onClick={uploadImages}
              >
                Save
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default UploadAdmissionReferenceDoc;
