import { useState, useEffect } from "react";
import { Loginpage } from "../login/loginpage";
import { Signuppage } from "../signup/signuppage";
import { ForgotpasswordSteps } from "../forgotpassword/ForgotPasswordSteps";
import CloseIcon from "../assets/hospitalCloseIcon.svg";
const LoginSignUpModal = ({ setOpen }) => {
  const [signup, setSignup] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  return (
    <div className="fixed top-0 left-0 h-screen w-screen flex justify-center items-center bg-[#017C7180]">
      <div className="relative">
        {signup ? (
          <Signuppage setSignup={setSignup} />
        ) : forgotPassword ? (
          <ForgotpasswordSteps
            setSignup={setSignup}
            setForgotPassword={setForgotPassword}
          />
        ) : (
          <Loginpage
            setSignup={setSignup}
            setForgotPassword={setForgotPassword}
          />
        )}
        <div
          className="absolute top-1 right-2 cursor-pointer"
          onClick={() => setOpen(false)}
        >
          <img src={CloseIcon} alt="close-icon" />
        </div>
      </div>
    </div>
  );
};

export default LoginSignUpModal;
