import { useState, useEffect, useContext } from "react";
import DocumentDetail from "./DocumentDetail";
import { docUploadRefreshContext } from "../../dashboard/cases/contexts/CaseContexts";

const AdmissionDocuments = ({ documents, getDate }) => {
  const referenceContext = useContext(docUploadRefreshContext);
  useEffect(() => {
    referenceContext.getMappedDocuments();
  }, []);
  return (
    <div>
      <div className="text-[32px] 2xl:text-[50px] leading-[65.26px] -tracking-[7%] my-[20px] 2xl:my-[50px]">
        Upload Documents
      </div>
      {documents?.length > 0 ? (
        <div className="bg-white py-5 px-[15px] rounded-[15px]">
          <div className="flex items-center text-[#1B1B1B] text-sm font-semibold leading-[21.7px] -tracking-[3%]">
            <div className="w-[60%] ">Document Name</div>

            <div className="w-[20%]"></div>
            <div className="w-[10%] ">Upload Time</div>
            <div className="w-[5%]">Status</div>
            <div className="w-[5%] flex justify-center">Action</div>
          </div>
          <div style={{ border: "1px solid #0000000D", marginBlock: "20px" }} />
          {documents?.map((document, idx) => {
            return (
              <div key={idx} className="first:mt-0 mt-[10px]">
                {" "}
                <DocumentDetail document={document} getDate={getDate} />
              </div>
            );
          })}
        </div>
      ) : (
        <div className="text-xl text-center"> No Documents Mapped</div>
      )}
    </div>
  );
};

export default AdmissionDocuments;
