import React from "react";
import RightArrow from "../assets/arrow-right.svg";
const PrivacyPolicy = ({ setStep, setSignup }) => {
  return (
    <div
      style={{
        height: "100%",
      }}
    >
      <div>
        <h2>Privacy Policy</h2>
      </div>
      <div style={{ border: "1px solid #E4E4E4", marginBlock: "20px" }} />
      <div
        style={{
          overflowY: "scroll",
          height: "70%",
          color: "#797979",
          fontSize: "14px",
          letterSpacing: "-0.4px",
        }}
      >
        <p>
          We at https://www.settlemed.in (the “Website”, “We”, and “Us”) are
          committed to protecting your privacy. We have prepared this Privacy
          Policy to describe to you our practices regarding the personal data we
          collect from users of our Website.
        </p>{" "}
        <p>
          {" "}
          This document is an electronic record in terms of Information
          Technology Act, 2000 and rules there under as applicable and the
          provisions pertaining to electronic records in various statutes as
          amended by the Information Technology Act, 2000. This electronic
          record is generated by a computer system and does not require any
          physical or digital signatures to make the terms of this policy
          binding.
        </p>{" "}
        <p>
          By using the Website, users consent to the collection and use of their
          Personal Data by us. You also represent to us that you have any and
          all authorizations necessary to use these Services including using
          them to process Personal Data. We collect and use the information you
          provide to us, including information obtained from your use of the
          Services. Also, we may use the information that we collect for our
          internal purposes to develop, tune, enhance, and improve our Services,
          and for advertising and marketing consistent with this Privacy Policy.
        </p>{" "}
        <p>
          This privacy policy has been compiled to better serve those who are
          concerned with how their 'Personal Data’ is being used
          online. Personal data means data which relate to a living individual
          who can be identified – (a) from those data, or (b) from those data
          and other information which is in the possession of, or is likely to
          come into the possession of, the data controller, and includes any
          expression of opinion about the individual and any indication of the
          intentions of the data controller or any other person in respect of
          the individual.
        </p>
         
        <p>
          {" "}
          We are committed to ensuring privacy and security of health-related
          information that is collected through our services. We have
          implemented measures to protect personal health information. Please
          read our privacy policy carefully to get a clear understanding of how
          our Website collects, uses, protects or otherwise handles users’
          Personal Data.
        </p>{" "}
        <p>
          This Privacy Policy is intended to inform users about how our Website
          treats Personal Data that it processes about users. If users do not
          agree to any part of this Privacy Policy, then we cannot provide its
          Services to users and users should stop accessing our services.
        </p>{" "}
        <p>
          {" "}
          By using the Services, You acknowledge, consent and agree that we may
          collect, process, and use the information that you provide to us and
          that such information shall only be used by us or third parties acting
          under our direction, pursuant to confidentiality agreements, to
          develop, tune, enhance, and improve the Services. Although we may
          attempt to notify you when changes are made to this Privacy Policy,
          you are responsible for periodically reviewing any changes which may
          be made to the Policy. We may, in our sole discretion, modify or
          revise the Policy at any time, and you agree to be bound by such
          modifications or revisions.
        </p>{" "}
        <p> 1. INFORMATION WE COLLECT: </p>
        <p>
          1.1. When you contact us, you provide us information about yourself
          –name, email address, hospital details, designation and other
          significant protected health information which is used for claim our
          services. If you correspond with us by e-mail, we may retain the
          content of your e-mail messages, your e-mail address, and our
          responses. We also collect general information about your use of our
          services.{" "}
        </p>
        <p>
          {" "}
          2. INFORMATION WE COLLECT AUTOMATICALLY WHEN YOU USE OUR SERVICES:
        </p>
        <p>
          1.2. When you access or use our Services, we automatically collect
          information about you, including:
        </p>{" "}
        <p>
          {" "}
          1.2.1. Device Information: We may collect information about the device
          you use to access our Website, including the hardware model, operating
          system and version, unique device identifier, phone number,
          International Mobile Equipment Identity ("IMEI") and mobile network
          information.
        </p>
         {" "}
        <p>
          1.2.2. Geo-location Information- Upon using the Website, you will be
          asked to turn on location If you turn these features on, we may
          collect your device's geo-location data and save your device's
          coordinates to offer certain features to you. We may also use your
          device's geo-location information to personalize the Platform and make
          it easier for you to interact with us. You can control your location
          information settings in your Account settings and switch them off if
          you want to. Even if you have disabled location services, we may still
          determine your city, state, and country location based on your IP
          address (but not your exact location).
        </p>{" "}
        <p>
          {" "}
          1.2.3. Information Collected by Cookies and Other Tracking
          Technologies: We use various technologies to collect information, and
          this may include sending cookies to you. Accepting a cookie does not
          provide us access to your device or any Personal Data about you, other
          than the information you choose to share. Other servers cannot read
          them, nor can they be used to deliver a virus. Most browsers
          automatically accept cookies, but you can usually adjust yours
          (Microsoft Internet Explorer, Firefox or Google Chrome) to notify you
          of cookie placement requests, refuse certain cookies, or decline
          cookies completely. 
        </p>{" "}
        <p>
          1.2.4. “Web beacons” or clear .gifs are small pieces of code placed on
          a Web page to monitor behavior and collect data about the visitors
          viewing a Web page. For example, Web beacons or similar technology can
          be used to count the users who visit the Website or to deliver a
          cookie to the browser of a visitor viewing that page. We may use Web
          beacons or similar technology on our Services from time to time for
          this and other purposes.{" "}
        </p>
        <p>
          1.2.5. We gather certain information automatically and stores it in
          log files. This information includes internet protocol addresses as
          well as browser, internet service provider, referring/exit pages,
          search terms, operating system, date/time stamp, and click stream
          data. Occasionally, we may connect Personal Information to information
          gathered in our log files, as necessary to improve the Service for
          individual users. Otherwise, we generally use this information as we
          would any Usage Data, to analyze trends, administer and maintain the
          Service, or track usage of various features within the Service.
        </p>{" "}
        <p>2. HOW WE USE YOUR PERSONAL INFORMATION:</p>{" "}
        <p>
          2.1. We use the personal information we collect to fulfill your
          requests for claim process, communication facilitation, improve our
          services and contact you.
        </p>
        <p>
          2.2. By providing us your e-mail address, you consent to us using the
          e-mail address to send you our Website and services related notices,
          including any notices required by law, in lieu of communication by
          postal mail. You also agree that we may send notifications of activity
          on our Website to the e-mail address you give us, in accordance with
          any applicable privacy settings. We may use your e-mail address to
          send you other messages, such as newsletters, changes to our features,
          or other information. If you do not want to receive optional e-mail
          messages, you may modify your settings to opt out.
        </p>{" "}
        <p>
          2.3. Our settings may also allow you to adjust your communications
          preferences. If you do not wish to receive promotional email messages
          from us, you may opt out by following the unsubscribe instructions in
          those emails. If you opt out, you will still receive non-promotional
          emails from us about your account and our Services.
        </p>{" "}
        <p>
          2.4. Following termination or deactivation of your services, we may
          (but are under no obligation to) retain your information for archival
          purposes. We will not publicly disclose any of your personal data
          other than as described in this Privacy Policy.
        </p>{" "}
        <p>
          {" "}
          2.5. At our sole discretion, for any reason or no reason at all, we
          reserve the right to remove any content or messages, if we believe
          that such action is necessary
        </p>{" "}
        <p>
          (a) to conform to the law, comply with legal process served on us, or
          investigate, prevent, or take action regarding suspected or actual
          illegal activities;{" "}
        </p>
        <p>
          (b) to enforce this policy, to take precautions against liability, to
          investigate and defend ourselves against any third-party claims or
          allegations, to assist government enforcement agencies, or to protect
          the security or integrity of our Website; or
        </p>{" "}
        <p>
          {" "}
          (c) to exercise or protect the rights, property, or personal safety of
          the Website, our users, or others.{" "}
        </p>
        <p>
          2.6. To send other messages that help us provide our services on the
          Site;{" "}
        </p>
        <p>2.7. We never show any users where the other user lives.</p>{" "}
        <p>
          2.8. We do not share your email address with other users, however
          certain authenticated users may access your email address for the
          purpose of personal and professional networking;
        </p>{" "}
        <p>
          2.9. Third Party Service Providers:  We employ other companies and
          individuals to perform functions on our behalf. Examples may include
          providing marketing assistance, providing search results and links
          (including paid listings and links), processing credit card payments
          and providing customer service. In connection with their performance
          of functions on our behalf, we may share personally identifiable
          information with such companies and individuals as needed for them to
          perform their functions, but we do not permit such entities and
          individuals to use personally identifiable information for other
          purposes. In addition, we may provide non-personally-identifiable
          information to advertisers and other third parties for their use in
          marketing efforts for us, themselves, or others. If you are not
          interested in receiving targeted advertisements or other marketing
          information or materials as a result of such efforts, you may adjust
          the settings on your mobile devices to prevent the use of your
          non-personally-identifiable information in this manner.
        </p>{" "}
        <p>
          2.10. Protection of Our Services and Others: We reserve the right to
          release personally identifiable information to unaffiliated third
          parties when we believe its release is appropriate to comply with the
          law, enforce or apply our Terms and Conditions and other agreements,
          or protect the rights, property or safety of us, our users or others.
          This includes exchanging information with other unaffiliated third
          parties in connection with fraud protection and credit risk
          reduction. 
        </p>{" "}
        <p>
          2.11. With Your Consent: Other than as set out above, you will receive
          notice and have the opportunity to withhold consent when personally
          identifiable information about you might be shared with unaffiliated
          third parties.
        </p>{" "}
        <p>3. INFORMATION SHARED DURING TRANSACTION</p>{" "}
        <p>
          3.1. You agree that you will enter into transactions with third
          parties through our Website and will share your Personal Information
          with them for easy completion of the transaction. You hereby expressly
          agree that we shall not be involved or held liable for any breach of
          the privacy or security of that data. The said breach, if any, shall
          be a matter of dispute between you and the third party and we shall
          not be held liable or be issued a notice for the same.
        </p>{" "}
        <p>
          {" "}
            3.2. We strongly recommend that you should be careful and vigilant
          while disclosing your Personal Information with your transaction
          partner. Please do not disclose your bank and account details to
          anyone through our Website or to any individual whom you have met
          through our Website.
        </p>{" "}
         
        <p>
          {" "}
          3.3. We take credit card/debit card or other bank details on our
          Website. For payment you will be directed to sign in through the
          respective payment gateways integrated on our website and the
          transaction would be completed therein. It is to be noted that we will
          be storing Bank related information on our records and none of our
          staffs will hold or be exposed to this information.
        </p>{" "}
        <p>4. HOW WE SHARE YOUR INFORMATION: </p>
        <p>
          4.1. As a matter of policy, we will not sell or rent information about
          you and we will not disclose information about you in a manner
          inconsistent with this Privacy Policy except as required by law or
          government regulation. We cooperate with law enforcement inquiries, as
          well as other third parties, to enforce laws such as those regarding
          intellectual property rights, fraud and other personal rights.
        </p>{" "}
        <p>
          4.2. We will not share the personal information we collect about you
          with any third party for their own marketing purposes without your
          consent. We have contract with third parties prohibiting them from
          sharing your personal data.
        </p>{" "}
        <p>
          4.3. If you do not agree with our Privacy Policy, Terms of Service or
          other policies on our Website please discontinue use of our Service;
          your continued usage of the Service will signify your assent to and
          acceptance of our Privacy Policy and Terms of Service.
        </p>{" "}
        <p>
          4.4. WE CAN (AND YOU AUTHORIZE US TO) DISCLOSE ANY INFORMATION ABOUT
          YOU TO LAW ENFORCEMENT, OTHER GOVERNMENT OFFICIALS, ANY LAWSUIT OR ANY
          OTHER THIRD PARTY THAT WE, IN OUR SOLE DISCRETION, BELIEVE NECESSARY
          OR APPROPRIATE IN CONNECTION WITH AN INVESTIGATION OF FRAUD,
          INTELLECTUAL PROPERTY INFRINGEMENT, OR OTHER ACTIVITY THAT IS ILLEGAL
          OR MAY EXPOSE US, OR YOU, TO LIABILITY.{" "}
        </p>
        <p>5. ENSURING INFORMATION IS ACCURATE AND UP-TO-DATE</p>{" "}
        <p>
          5.1. We take reasonable precautions to ensure that the Personal Data
          we Collect, Use and Disclose is complete, relevant and up-to-date.
          However, the accuracy of that information depends to a large extent on
          the information you provide. That's why we recommend that you:{" "}
        </p>
        <p>
          {" "}
          5.2. Let us know if there are any errors in your Personal Data; and
        </p>
        <p>
          {" "}
          5.3. Keep us up-to-date with changes to your Personal Data such as
          your name or address. 
        </p>{" "}
        <p>
          6. RIGHT TO ACCESS SPECIFIC INFORMATION AND DATA PORTABILITY RIGHT
        </p>
        <p>
          6.1. The Right to Access grants individuals to obtain copies of their
          own health information. This may include medical history, billing
          records and insurance information. We are committed to protecting the
          confidentiality, integrity, and availability of all sensitive
          information collected from users. To safeguard this information, we
          have implemented appropriate technical, administrative, and physical
          security measures, including encryption, access controls, regular
          security assessments, and staff training on data protection best
          practices. 
        </p>
        <p>
          6.2. You may have the right under the Information Technology Act, 2000
          to request that we disclose certain information to you about our
          collection and use of your Personal Information over the past twelve
          (12) months. We understand the importance of individuals having access
          to their own health information. Therefore, upon request, we provide
          users with access to the sensitive information collected from them,
          including health-related data. Users may request access to their
          information by contacting us. We strive to respond to such requests
          promptly and in accordance with applicable laws and regulations.
        </p>
        <p>
          6.3. Once we receive and confirm your verifiable consumer request, we
          will disclose to you:
        </p>{" "}
        <p>
          i. The categories of Personal Information that we have collected about
          you.{" "}
        </p>
        <p>
          ii. The categories of sources for the Personal Information that we
          have collected about you;
        </p>{" "}
        <p>
          iii. The categories of third parties with whom we share that Personal
          Information.
        </p>{" "}
        <p>
          iv. The specific pieces of Personal Information that we have collected
          about you (also called a data portability request).
        </p>{" "}
        <p>
          v. If we disclosed your Personal Information for a Business Purpose,
          the Business Purpose for which such Personal Information was
          disclosed, and the Personal Information categories that each category
          of recipient obtained.
        </p>{" "}
        <p>
          vi. If applicable, (1) the categories of your Personal Information
          that we have made available for valuable consideration; (2) the
          categories of third parties to whom such Personal Information was made
          available; and (3) the category or categories of Personal Information
          that we have made available to each category of third parties.
        </p>{" "}
        <p>7.1 Right to Delete</p>{" "}
        <p>
          A. You may have the right under the HIPAA to request that we delete
          any of your Personal Health Information that we have collected from
          you and retained. Once we receive and confirm your verifiable consumer
          request, we will delete (and direct our service providers to delete)
          your Personal Health Information from our records, unless an exception
          applies.
        </p>{" "}
        <p>
          B. We may deny your deletion request if retaining the information is
          necessary for us or our service provider(s) to:
        </p>{" "}
        <p>
          i. Complete the transaction for which we collected the Personal
          Information, provide a good or service that you requested, take
          actions reasonably anticipated within the context of our ongoing
          business relationship with you, or otherwise perform our contract with
          you.
        </p>
        <p>
          ii. Detect security incidents, protect against malicious, deceptive,
          fraudulent, or illegal activity, or prosecute those responsible for
          such activities.
        </p>{" "}
        <p>iii. Comply with Information Technology Act, 2000 </p>{" "}
        <p>
          iv. Enable solely internal uses that are reasonably aligned with
          consumer expectations based on your relationship with us.
        </p>{" "}
        <p>v. Comply with a legal obligation or legal order. </p>
        <p>
          vi. Make other internal and lawful uses of that information that are
          compatible with the context in which you provided it.
        </p>{" "}
        <p>7.2 Exercising Your Rights</p>
        <p>
          {" "}
          To exercise the access, data portability, and deletion rights
          described above, please submit a verifiable consumer request to us by:
        </p>{" "}
        <p>i. Sending us an e-mail at Hi@settlemed.in</p>{" "}
        <p>
          ii. Only you, or a person that you authorize to act on your behalf,
          may make a verifiable consumer request related to your Personal
          Information. You may also make a verifiable consumer request on behalf
          of your minor child.
        </p>{" "}
        <p>
          iii. You may only make such a request for access or data portability
          twice within a twelve (12) month period. The verifiable consumer
          request must provide sufficient information that allows us to
          reasonably verify you are the person about whom we collected Personal
          Information or an authorized representative, and describe your request
          with sufficient detail that allows us to properly understand,
          evaluate, and respond to it.
        </p>{" "}
        <p>
          iv. We cannot respond to your request or provide you with Personal
          Information if we cannot verify your identity or authority to make the
          request and confirm the Personal Information relates to you. Making a
          verifiable consumer request does not require you to create an account
          with us. We will only use Personal Information provided in a
          verifiable consumer request to verify the requestor’s identity or
          authority to make the request.
        </p>{" "}
        <p>
          v. We do not charge a fee to process or respond to your verifiable
          consumer request unless it is excessive, repetitive, or manifestly
          unfounded. If we determine that the request warrants a fee, we will
          tell you why we made that decision and provide you with a cost
          estimate before completing your request.{" "}
        </p>
        <p>7.3 Non-Discrimination</p>{" "}
        <p>
          We will not discriminate against you for exercising any of your
          rights. We will not:{" "}
        </p>
        <p>i. Deny you services.</p>{" "}
        <p>
          ii. Charge you different prices or rates for goods or services,
          including through granting discounts or other benefits, or imposing
          penalties.{" "}
        </p>
        <p>iii. Provide you a different level of services.</p>{" "}
        <p>7. HOW WE PROTECT YOUR INFORMATION:</p>{" "}
        <p>
          7.1. We are very concerned about safeguarding the confidentiality of
          your personal data or protected health data. We employ administrative,
          physical and electronic measures designed to protect your information
          from unauthorized access.
        </p>
        <p>
          7.2. By using this Website or the Services or providing Personal
          Information to us, you agree that we can communicate with you
          electronically regarding security, privacy, and administrative issues
          relating to your use of this Website or Services.
        </p>{" "}
        <p>
          7.3. We use commercially reasonable physical, managerial, and
          technical safeguards to preserve the integrity and security of your
          Personal Health Information. We cannot, however, ensure or warrant the
          security of any information you transmit to us and you do so at your
          own risk. Once we receive your transmission of information, we make
          commercially reasonable efforts to ensure the security of our systems.
          However, please note that this is not a guarantee that such
          information may not be accessed, disclosed, altered, or destroyed by
          breach of any of our physical, technical, or managerial safeguards. If
          we learn of a security systems breach, then we may attempt to notify
          you electronically so that you can take appropriate protective steps.
        </p>
        <p>
          7.4. Notwithstanding anything to the contrary in this Policy, we may
          preserve or disclose your information if we believe that it is
          reasonably necessary to comply with a law, regulation or legal
          request; to protect the safety of any person; to address fraud,
          security or technical issues; or to protect our rights or property.
          However, nothing in this Policy is intended to limit any legal
          defenses or objections that you may have to a third party, including a
          government’s, request to disclose your information.
        </p>{" "}
        <p>
          7.5. However, under the following circumstances, we may still be
          required to share your personal information:
        </p>{" "}
        <p>
          {`a) if we are responding to court orders or legal process, or if we
          need to establish or exercise our legal rights or defend against legal
          claims.`}
        </p>
        <p>{`b) If we believe it is necessary to share information in order to
        investigate, prevent or take action regarding illegal activities,
        suspected fraud, situations involving potential threats to the physical
        safety of any person, violations of our Terms of Use or as otherwise
        required by law.`}</p>{" "}
        <p>{`c) If we believe it is necessary to restrict or inhibit any user from
        using any of our Website, including, without limitation, by means of
        "hacking" or defacing any portion thereof.`}</p>{" "}
        <p>8. RIGHTS OF THE DATA SUBJECT</p> <p>Right of confirmation</p>
        <p>
          {" "}
          Users have the right to know and confirm the personal information as
          to whether or not their personal information is being processed.
        </p>{" "}
        <p> Right to rectification.</p>
        <p>
          {" "}
          Users have the right to rectify the incorrect details or updating
          their incomplete personal information data.
        </p>{" "}
        <p>Right to withdraw data protection consent</p>{" "}
        <p>
          If the data subject wishes to exercise the right to withdraw the
          consent, he or she may, at any time, contact any of our employees.
        </p>{" "}
        <p>Right to request access </p>
        <p>
          {" "}
          You also have a right to access information we hold about you. We are
          happy to provide you with details of your Personal Information that we
          hold or process. To protect your personal information, we follow set
          storage and disclosure procedures, which mean that we will require
          proof of identity from you prior to disclosing such information. You
          can exercise this right at any time by contacting us on the above
          details.
        </p>{" "}
        <p> Right to withdraw consent</p>
        <p>
          {" "}
          Where the legal basis for processing your personal information is your
          consent, you have the right to withdraw that consent at any time by
          contacting us on the above details.
        </p>
        <p>9. YOUR CHOICES ABOUT YOUR INFORMATION:</p>{" "}
        <p>
          9.1. You have several choices regarding use of information on our
          Services:
        </p>{" "}
        <p>
          9.1.1. Email Communications: We will periodically send you free
          newsletters and e-mails that directly promote the use of our Website,
          or Services. When you receive newsletters or promotional
          communications from us, you may indicate a preference to stop
          receiving further communications from us and you will have the
          opportunity to “opt-out” by following the unsubscribe instructions
          provided in the e-mail you receive or by contacting us directly.
          Despite your indicated e-mail preferences, we may send you service
          related communications, including notices of any updates to our Terms
          of Use or Privacy Policy.
        </p>
        vi. Changing or Deleting Your Personal Health Data: You may change any
        of your personal information by visiting the Website and emailing us
        at Hi@settlemed.in .Upon initiating the account deletion process, all
        associated personal data is permanently removed from our records. 
        <p>
          9.2. You may, of course, decline to submit personal data through the
          Website, in which case, we may not be able to provide our services to
          you. You can review and correct the information about you that we keep
          on Website by editing your account settings.{" "}
        </p>
        <p>10. CHILDREN’S PRIVACY:</p>{" "}
        <p>
          Protecting the privacy of young children is especially important. Thus
          any person under the age of 18 can only use our services under the
          supervision of their parents and the parents shall be held liable and
          responsible in case of any loss suffered by us. In the event that we
          learn that we have collected personal data from a child under age 18
          who was not authorized by its parents then we will delete that
          information as quickly as possible. If you believe that we might have
          any information from or about a child under 18, please contact us.
        </p>{" "}
        <p>11. MERGER AND ACQUISITIONS:</p>{" "}
        <p>
          In case of a merger or acquisition, we reserve the right to transfer
          all the information, including personal data, stored with us to the
          new entity or thus formed. Any change in the Website’s policies and
          standing will be notified to you through email. 
        </p>
        <p>12. NOTIFICATION PROCEDURES:</p>{" "}
        <p>
          It is our policy to provide notifications, whether such notifications
          are required by law or are for marketing or other business related
          purposes, to you via e-mail notice, written or hard copy notice, or
          through conspicuous posting of such notice on the Website, as
          determined by us in our sole discretion. We reserve the right to
          determine the form and means of providing notifications to you,
          provided that you may opt out of certain means of notification as
          described in this Privacy Policy.
        </p>{" "}
        <p>13. DO NOT TRACK:</p>{" "}
        <p>
          13.1. Do Not Track (“DNT”) is a privacy preference that users can set
          in certain web browsers. DNT is a way for users to inform Website and
          services that they do not want certain information about their webpage
          visits collected over time and across Websites or online services.{" "}
        </p>
        <p>
          {" "}
          13.2. We are committed to providing you with meaningful choices about
          the information collected on our Website for third party purposes, and
          that is why we provide the opt-out links. However, we do not recognize
          or respond to browser-initiated DNT signals, as the Internet industry
          is currently still working toward defining exactly what DNT means,
          what it means to comply with DNT, and a common approach to responding
          to DNT.
        </p>
        <p>14. PHISHING OR FALSE EMAILS:</p>{" "}
        <p>
          If you receive an unsolicited email that appears to be from us or one
          of our members that requests personal data (such as your credit card,
          login, or password), or that asks you to verify or confirm your
          account or other personal information by clicking on a link, or to
          provide your personal information to any other user that email was
          likely to have been sent by someone trying to unlawfully obtain your
          information, sometimes referred to as a "phisher" or "spoofer." We do
          not ask for this type of information in an email. Do not provide the
          information or click on the link.  Please contact us on the contact
          details provided on the Website if you get an email like this.{" "}
        </p>
        <p>15. CHANGES TO OUR PRIVACY POLICY: </p>
        <p>
          15.1. We may update this Privacy Policy and information security
          procedures from time to time. If these privacy and/or information
          security procedures materially change at any time in the future, we
          will post the new changes conspicuously on the Website to notify you
          and provide you with the ability to opt out in accordance with the
          provisions set forth above.
        </p>{" "}
        <p>
          15.2. Continued use of our Website and Service, following notice of
          such changes shall indicate your acknowledgement of such changes and
          agreement to be bound by the terms and conditions of such changes.
        </p>
        <p>
          16. BREACH OF PRIVACY POLICY We reserve the right to terminate or
          suspend any account or delete certain contents from any profile or
          public domain within the ambit of this Website if the said account or
          content is found to be in violation of our privacy policy. We
          sincerely request you to respect privacy and secrecy concerns of
          others. The jurisdiction of any breach or dispute shall be determined
          in accordance with the terms of use of the Website.
        </p>{" "}
        <p>
          17. NO RESERVATIONS: We do not accept any reservation or any type of
          limited acceptance of our privacy policy. You expressly agree to each
          and every term and condition as stipulated in this policy without any
          exception whatsoever.
        </p>
          <p>18. NO CONFLICT: </p>
        <p>
          The policy constitutes a part of the user terms. We have taken utmost
          care to avoid any inconsistency or conflict of this policy with any
          other terms, agreements or guidelines available on our Website. In
          case there exists a conflict, we request you to kindly contact us for
          the final provision and interpretation.
        </p>{" "}
          <p>19. CONTACT US:</p>{" "}
        <p>
          If you have any questions about this Privacy Policy, our practices
          relating to the Website, or your dealings with us, please contact us
          at Hi@settlemed.in{" "}
        </p>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          marginTop: "16px",
          paddingRight: "16px",
        }}
      >
        <div
          style={{ border: "1px solid #017C57" }}
          className="w-fit text-[#017C57] flex gap-2 py-2 px-4 rounded-full cursor-pointer"
          onClick={() => setStep("5")}
        >
          <div className="text-xs font-semibold">AGREE AND CONTINUE</div>
          <img src={RightArrow} alt="arrow-icon" className="w-[16px] " />
        </div>
      </div>
      <div
        className="inline-container"
        style={{
          marginTop: "10px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        Already have an account?
        <span
          style={{ fontWeight: "bold" }}
          onClick={() => setSignup(false)}
          className="text-[#017C71] cursor-pointer ml-1"
        >
          Sign In here.
        </span>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
