import { useState, useEffect } from "react";
import "./signup.scss";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { object, string, number, ref } from "yup";
import { Country, State, City } from "country-state-city";
import Select from "react-select";
import { passwordRegex } from "../utils/formUtils";
import TermsAndConditions from "./TermsAndConditions";
import PrivacyPolicy from "./PrivacyPolicy";
import ReactCountryFlag from "react-country-flag";
import { emailRegex } from "../utils/formUtils";
import * as Yup from "yup";
import {
  AuthServices,
  Passwordverify,
  emailotpservice,
  emailotpverify,
  phoneOtpService,
  resendPhoneOtpService,
  phoneotpverify,
} from "../services/AuthServices";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";

import FileUploadIcon from "@mui/icons-material/FileUpload";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const hospitalNames = ["Hospital Registration Certificate", "Pan Card"];

function getStyles(name, selectedName, theme) {
  return {
    fontWeight:
      selectedName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export const Signuppage = ({ setSignup, setForgotPassword }) => {
  const [step, setStep] = useState("1");
  const [pass, setPass] = useState("");
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const initialValues = {
    fullName: "",
    email: "",
    mob_number: "",
    phone_otp: "",
    email_otp: "",
    server_email_otp: "",
    server_phone_otp: "",
    password: "",
    confirm_password: "",
    position: "",
    otherPosition: "",
    hospitalName: "",
    country: "",
    state: "",
    city: "",
    pincode: "",
    businessDocumentType: "",
    businessDocumentNumber: "",
    file: "",
    businessDocumentUrl: "hell.jpg",
    pjmyid: "",
    pjmypassword: "",
  };

  const documentTypeOptions = [
    { value: "PAN", label: "Pan Card" },
    {
      value: "HRC",
      label: "Hospital Registration Certificate",
    },
  ];
  const getEmailOtp = async (e, email, setFieldValue, error) => {
    if (error) {
      window.alert("Enter the Valid Email First");
      return;
    }
    try {
      const response = await emailotpservice(email);
      setFieldValue("server_email_otp", Number(response.data.otp));
      window.alert(`OTP sent to ${email}`);
    } catch (error) {
      window.alert(`Error Sending OTP: ${error?.response?.data?.message}`);
    }
  };

  const getPhoneOtp = async (e, mob_number, setFieldValue, error) => {
    if (error) {
      window.alert("Please enter exactly 10 digits for mobile number.");
      return;
    }
    try {
      const response = await phoneOtpService(mob_number);
      let userDetails = {
        session: { token: response?.data?.session?.token },
      };
      userDetails = JSON.stringify(userDetails);
      localStorage.setItem("signUpUserDetails", userDetails);
      window.alert(`OTP sent to ${mob_number}`);
      setFieldValue(
        "server_phone_otp",
        Number(response.data.Userdata.phoneOtp)
      );
    } catch (error) {
      window.alert(`Error Sending OTP: ${error?.response?.data?.error}`);
    }
  };

  const getResendPhoneOtp = async (e, mob_number, setFieldValue) => {
    try {
      const response = await resendPhoneOtpService(mob_number);
      window.alert(`OTP sent to ${mob_number}`);
      setFieldValue("server_phone_otp", Number(response.data.phoneOtp));
    } catch (error) {
      window.alert(`Error Sending OTP: ${error?.response?.data?.error}`);
    }
  };
  const handleVerifyNumber = async (values) => {
    try {
      const response = await phoneotpverify(values.phone_otp);
      window.alert("Mobile verified successfully");
      setStep("2");
    } catch (error) {
      window.alert("Error occurred while verifing otp");
    }
  };

  const handleVerifyEmail = async (values) => {
    try {
      const response = await emailotpverify(values.email_otp);
      window.alert("Email verified successfully");
      setStep("3");
    } catch (error) {
      if (error.response.data.error) {
        window.alert(error.response.data.error);
      } else {
        window.alert("Error occurred while verifying otp");
      }
    }
  };

  const handlePass = async (values) => {
    if (!privacyPolicyAccepted) {
      window.alert("Please accept Terms and Conditions and Privacy Policy");
      return;
    }
    try {
      const response = await Passwordverify(values.password);
      window.alert("Onboarding complete");
      setStep("6");
    } catch (error) {
      window.alert(error);
    }
  };
  let errorValidation;
  if (step === "1") {
    errorValidation = object({
      mob_number: string()
        .matches(/^\d{0,10}$/, {
          message: "Mobile number must be exactly 10 digits!",
          excludeEmptyString: true,
        })
        .matches(/^\d+$/, "Mobile number must only contain digits")
        .required("Required Field"),
      phone_otp: number().required("OTP is required"),
    });
  }
  if (step === "2") {
    errorValidation = object({
      email: string().matches(emailRegex).required("Required Field !"),
      email_otp: number().required("OTP is required !"),
    });
  }
  if (step === "5") {
    errorValidation = object({
      password: string()
        .matches(
          passwordRegex,
          "Password must contain one uppercase,one lowercase ,one alphanumeric,one numeric character and password must be 8 or more characters long."
        )
        .required("Password is required !"),
      confirm_password: string()
        .oneOf([ref("password"), null], "Passwords must match !")
        .required("Confirm Password is required !"),
    });
  }
  if (step === "6") {
    errorValidation = object().shape({
      fullName: string().required("Full Name is required!"),
      position: string().required("Designation is Required"),
      hospitalName: string().required("Hospital Name is required!"),
      country: string().required("Country is required!"),
      state: string().required("State is required!"),
      city: string().required("City is required!"),
      pincode: string()
        .max(6, "Pincode must be exactly 6 digits")
        .min(6, "Pincode must be exacly 6 digits")
        .required("Pincode is required!"),
      businessDocumentType: string().required(
        "Business Document Type is required!"
      ),
      businessDocumentNumber: string().required(
        "Business Document Number is required!"
      ),
      file: string().required("File is required!"),
      businessDocumentUrl: string().required(
        "Business Document URL is required!"
      ),
      // pjmyid: string().required('PJM YID is required!'),
      // pjmypassword: string().required('PJM Password is required!'),
    });
  }
  const handleSubmit = async (values) => {
    if (step == "1") {
      handleVerifyNumber(values);
    } else if (step == "2") {
      handleVerifyEmail(values);
    } else if (step == "5") {
      handlePass(values);
    } else if (step == "6") {
      try {
        if (!values.file) {
          window.alert("Upload the required document");
          return;
        }
        let data = new FormData();
        data.append("image", values.file);
        const uploadResponse = await AuthServices.uploadImage(data);
        values.businessDocumentUrl = uploadResponse.data.url;
        let response = await AuthServices.createHospital(values);
        if (response.data) {
          let userDetails = JSON.stringify(response.data);
          localStorage.setItem("signUpUserDetails", userDetails);
          navigate("/dashboard");
          window.alert("Sign up successful");
        }
      } catch (error) {
        console.error("Error:", error);
        if (error.response && error.response.status === 420) {
          window.alert("User already registered");
        } else {
          window.alert("Fill the required Field");
        }
      }
    }
  };

  const theme = useTheme();
  const [selectedHospital, setSelectedHospital] = useState([]);

  const handleDropdownChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedHospital(typeof value === "string" ? value.split(",") : value);
  };

  const handlePasswordChange = (e) => {
    setPass(e.target.value);
  };
  useEffect(() => {
    setStateList(State?.getStatesOfCountry(selectedCountry?.isoCode));
  }, [selectedCountry]);

  useEffect(() => {
    setCityList(
      City.getCitiesOfState(selectedState?.countryCode, selectedState?.isoCode)
    );
  }, [selectedState]);

  return (
    <div className="main">
      <Formik
        initialValues={initialValues}
        validationSchema={errorValidation}
        validateOnChange={true}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, setFieldValue, values }) => (
          <Form>
            {step === "1" && (
              <div className="container">
                <div className="form-container">
                  <h2>Mobile verification required</h2>
                  <span>Welcome to Settlemed</span>
                  <div className="label">
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "rgba(25, 25, 25, 1)",
                      }}
                    >
                      Mobile Verification
                    </span>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        position: "relative",
                      }}
                    >
                      <Field
                        name="mob_number"
                        type="text"
                        className="input"
                        maxLength="10"
                      />

                      <button
                        type="button"
                        className="text-button"
                        onClick={(e) =>
                          getPhoneOtp(
                            e,
                            values.mob_number,
                            setFieldValue,
                            errors?.mob_number
                          )
                        }
                      >
                        SEND
                      </button>
                    </div>
                    {touched.mob_number && errors.mob_number && (
                      <span className="error">{errors.mob_number}</span>
                    )}
                  </div>
                  <div className="label">
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "rgba(25, 25, 25, 1)",
                      }}
                    >
                      Enter Code
                    </span>
                    <Field
                      name="phone_otp"
                      className="input"
                      placeholder="Enter Code"
                      maxLength={6}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      }}
                    />
                    {touched.phone_otp && errors.phone_otp && (
                      <span className="error">{errors.phone_otp}</span>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <button
                      type="submit"
                      style={{ cursor: "pointer" }}
                      className="submit-button"
                    >
                      Verify Number
                    </button>
                  </div>
                </div>
                <div className="resend-code">
                  Didn't get the code?{" "}
                  <span
                    onClick={(e) =>
                      getResendPhoneOtp(e, values.mob_number, setFieldValue)
                    }
                  >
                    Resend Code
                  </span>
                </div>
                <div
                  className="inline-container"
                  style={{
                    marginBlock: "20px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Already have an account?
                  <span
                    style={{ fontWeight: "bold" }}
                    onClick={() => setSignup(false)}
                    className="text-[#017C71] cursor-pointer ml-1"
                  >
                    Sign In here.
                  </span>
                </div>
              </div>
            )}

            {step === "2" && (
              <div className="container">
                <div className="form-container">
                  <h2>Email verification required</h2>
                  <span>Welcome to Settlemed</span>
                  <div className="label">
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "rgba(25, 25, 25, 1)",
                        width: "198px",
                        height: "20px",
                        gap: "12px",
                      }}
                    >
                      Mobile Number{" "}
                    </span>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Field name="mob_number" className="input" disabled />
                    </div>
                  </div>
                  <div className="label">
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "rgba(25, 25, 25, 1)",
                        width: "100%",
                        height: "20px",
                        gap: "12px",
                      }}
                    >
                      Email Address
                    </span>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        position: "relative",
                      }}
                    >
                      <Field name="email" type="email" className="input" />
                      <button
                        type="button"
                        className="text-button"
                        onClick={(e) =>
                          getEmailOtp(
                            e,
                            values.email,
                            setFieldValue,
                            errors.email
                          )
                        }
                      >
                        SEND
                      </button>
                    </div>
                    {touched.email && errors.email && (
                      <span className="error">{errors.email}</span>
                    )}
                  </div>
                  <div className="label">
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "rgba(25, 25, 25, 1)",
                        width: "500",
                        fontSize: "14px",
                        lineHeight: "20px",
                        letterSpacing: "-0.4px",
                      }}
                    >
                      Enter Code
                    </span>
                    <Field
                      name="email_otp"
                      className="input"
                      placeholder="Enter Code"
                      maxLength={6}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      }}
                    />
                    {touched.email_otp && errors.email_otp && (
                      <span className="error">{errors.email_otp}</span>
                    )}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <button
                      type="submit"
                      style={{ cursor: "pointer" }}
                      className="submit-button"
                    >
                      Verify Email
                    </button>
                  </div>
                </div>
                <div className="resend-code">
                  Didn't get the code?{" "}
                  <span
                    onClick={(e) =>
                      getEmailOtp(e, values.email, setFieldValue, errors.email)
                    }
                  >
                    Resend Code
                  </span>
                </div>
                <div
                  className="inline-container"
                  style={{
                    marginBlock: "20px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Already have an account?
                  <span
                    style={{ fontWeight: "bold" }}
                    onClick={() => setSignup(false)}
                    className="text-[#017C71] cursor-pointer ml-1"
                  >
                    Sign In here.
                  </span>
                </div>
              </div>
            )}
            {step === "3" && (
              <TermsAndConditions setStep={setStep} setSignup={setSignup} />
            )}
            {step === "4" && (
              <PrivacyPolicy setStep={setStep} setSignup={setSignup} />
            )}
            {step === "5" && (
              <div className="form-container form-container-step3">
                <h2>Create Password</h2>
                <span>Welcome to Settlemed</span>
                <hr
                  style={{
                    width: "458px",
                    height: "1px",
                    color: "rgba(228, 228, 228, 1)",
                    marginTop: "20px",
                  }}
                />
                <div className="label">
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "rgba(25, 25, 25, 1)",
                    }}
                  >
                    Mobile number
                  </span>
                  <Field name="mob_number" className="input" disabled />
                </div>
                <div className="label">
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "rgba(25, 25, 25, 1)",
                    }}
                  >
                    Email Address
                  </span>
                  <Field name="email" className="input" disabled />
                </div>
                <div className="label">
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "rgba(25, 25, 25, 1)",
                    }}
                  >
                    Password
                  </span>
                  <Field
                    name="password"
                    type="password"
                    className="input"
                    placeholder="*****"
                    maxLength={16}
                    minLength={8}
                  />

                  {touched.password && errors.password && (
                    <span className="error">{errors.password}</span>
                  )}
                </div>
                <div className="label">
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "rgba(25, 25, 25, 1)",
                    }}
                  >
                    Re-Enter Password
                  </span>
                  <Field
                    name="confirm_password"
                    type="password"
                    className="input"
                    placeholder="******"
                    maxLength={16}
                  />

                  {touched.confirm_password && errors.confirm_password && (
                    <span className="error">{errors.confirm_password}</span>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "start",
                    gap: "4px",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Field
                    type="checkbox"
                    name="terms"
                    checked={privacyPolicyAccepted}
                    onChange={() =>
                      setPrivacyPolicyAccepted(!privacyPolicyAccepted)
                    }
                  />
                  <text style={{ fontSize: 12, color: "#797979" }}>
                    By checking this box you are agreeing to our
                    <span
                      style={{
                        color: "#017C57",
                        fontWeight: "bold",
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                      onClick={() => setStep("3")}
                    >
                      {" "}
                      Terms and Conditions{" "}
                    </span>
                    as well as our
                    <span
                      style={{
                        color: "#017C57",
                        fontWeight: "bold",
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                      onClick={() => setStep("4")}
                    >
                      {" "}
                      Privacy Policy
                    </span>
                    .
                  </text>
                  {touched.terms && errors.terms && (
                    <span className="error">{errors.terms}</span>
                  )}
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button type="submit" className="submit-button">
                    Get Started
                  </button>
                </div>

                <div
                  className="inline-container"
                  style={{
                    marginBlock: "20px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Already have an account?
                  <span
                    style={{ fontWeight: "bold" }}
                    onClick={() => setSignup(false)}
                    className="text-[#017C71] cursor-pointer ml-1"
                  >
                    Sign In here.
                  </span>
                </div>
              </div>
            )}

            {step === "6" && (
              <div className="form-container form-container-step4">
                <h2>Enter Hospital details</h2>
                <span>Welcome to Settlemed</span>
                <hr
                  style={{
                    width: "458px",
                    height: "1px",
                    color: "rgba(228, 228, 228, 1)",
                    marginTop: "20px",
                  }}
                />
                {/* Input fields placed above the checkboxes */}
                <div className="label">
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "rgba(25, 25, 25, 1)",
                    }}
                  >
                    Email Id
                  </span>
                  <Field
                    name="email"
                    className="input"
                    style={{ color: "rgba(188, 188, 188, 1)" }}
                    disabled
                  />
                  {touched.email && errors.email && (
                    <span className="error">{errors.email}</span>
                  )}
                </div>
                <div className="label">
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "rgba(25, 25, 25, 1)",
                    }}
                  >
                    Mobile
                  </span>
                  <Field
                    name="mob_number"
                    className="input"
                    style={{ color: "rgba(188, 188, 188, 1)" }}
                    disabled
                  />
                  {touched.mobile && errors.mobile && (
                    <span className="error">{errors.mobile}</span>
                  )}
                </div>
                <div className="label">
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "rgba(25, 25, 25, 1)",
                    }}
                  >
                    Enter Your Full Name
                  </span>
                  <Field
                    name="fullName"
                    className="input"
                    style={{ color: "rgba(25, 25, 25, 1)" }}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(
                        /[^A-Za-z\s]/g,
                        ""
                      );
                    }}
                    validate={(value) => {
                      let error;
                      if (value.length < 3) {
                        error = "Full Name must be at least 3 characters long";
                      } else if (value.length > 30) {
                        error = "Full Name must not exceed 30 characters";
                      }
                      return error;
                    }}
                  />
                  {touched.fullName && errors.fullName && (
                    <span className="error">{errors.fullName}</span>
                  )}
                </div>

                <div className="label">
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "rgba(25, 25, 25, 1)",
                    }}
                  >
                    Choose Designation
                  </span>
                  <div className="flex gap-4 mt-2 font-normal">
                    <div className="flex gap-2">
                      <Field type="radio" name="position" value="Director" />
                      Director
                    </div>
                    <div className="flex gap-2">
                      <Field type="radio" name="position" value="Promoter" />
                      Promoter
                    </div>
                    <div className="flex gap-2">
                      <Field type="radio" name="position" value="Other" />
                      Other
                    </div>
                  </div>
                  {values.position !== "Director" &&
                    values.position !== "Promoter" && (
                      <Field
                        name="otherPosition"
                        className="input"
                        placeholder="Specify Other"
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^A-Za-z\s]/g,
                            ""
                          );
                        }}
                      />
                    )}
                  {touched.position && errors.position && (
                    <span className="error">{errors.position}</span>
                  )}
                </div>

                <hr
                  style={{
                    width: "458px",
                    height: "1px",
                    color: "rgba(228, 228, 228, 1)",
                    marginTop: "20px",
                  }}
                />
                <div className="label">
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "rgba(25, 25, 25, 1)",
                    }}
                  >
                    Hospital Name{" "}
                  </span>
                  <Field
                    name="hospitalName"
                    className="input"
                    placeholder="Hospital name"
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(
                        /[^A-Za-z\s]/g,
                        ""
                      );
                    }}
                  />
                  {touched.hospitalName && errors.hospitalName && (
                    <span className="error">{errors.hospitalName}</span>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "16px",
                    fontWeight: "bold",
                    width: "100%",
                  }}
                >
                  <div style={{ flex: "50%" }}>
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "rgba(25, 25, 25, 1)",
                      }}
                    >
                      Country
                    </span>
                    {/* <Field
                        name="country"
                        className="input"
                        placeholder="Country"
                      /> */}
                    <Select
                      name="country"
                      options={Country.getAllCountries()}
                      getOptionLabel={(options) => {
                        return options["name"];
                      }}
                      getOptionValue={(options) => {
                        return options["name"];
                      }}
                      value={selectedCountry}
                      onChange={(item) => {
                        setSelectedCountry(item);
                        setFieldValue("country", item.name);
                      }}
                      placeholder="Select Country"
                    />
                    {touched.country && errors.country && (
                      <span className="error">{errors.country}</span>
                    )}
                  </div>
                  <div style={{ flex: "50%" }}>
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "rgba(25, 25, 25, 1)",
                      }}
                    >
                      State
                    </span>
                    {/* <Field
                        name="state"
                        className="input"
                        placeholder="State"
                      /> */}
                    <Select
                      options={stateList}
                      getOptionLabel={(options) => {
                        return options["name"];
                      }}
                      getOptionValue={(options) => {
                        return options["name"];
                      }}
                      value={selectedState}
                      onChange={(item) => {
                        setSelectedState(item);
                        setFieldValue("state", item.name);
                      }}
                      placeholder="Select State"
                    />
                    {touched.state && errors.state && (
                      <span className="error">{errors.state}</span>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "16px",
                    fontWeight: "bold",
                    width: "100%",
                    marginTop: "10px",
                  }}
                >
                  <div style={{ flex: "50%" }}>
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "rgba(25, 25, 25, 1)",
                      }}
                    >
                      City
                    </span>
                    {/* <Field name="city" className="input" placeholder="City" /> */}
                    <Select
                      options={cityList}
                      getOptionLabel={(options) => {
                        return options["name"];
                      }}
                      getOptionValue={(options) => {
                        return options["name"];
                      }}
                      value={selectedCity}
                      onChange={(item) => {
                        setSelectedCity(item);
                        setFieldValue("city", item.name);
                      }}
                      placeholder="Select City"
                    />
                    {touched.country && errors.country && (
                      <span className="error">{errors.city}</span>
                    )}
                  </div>
                  <div style={{ flex: "50%" }}>
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "rgba(25, 25, 25, 1)",
                        marginLeft: "7px",
                      }}
                    >
                      Pincode
                    </span>
                    <Field
                      name="pincode"
                      className="input"
                      placeholder="Pincode"
                      onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                    {touched.pincode && errors.pincode && (
                      <span className="error">{errors.pincode}</span>
                    )}
                  </div>
                </div>
                <div className="label">
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "rgba(25, 25, 25, 1)",
                    }}
                  >
                    Business License
                  </span>
                  <FormControl sx={{ m: 1, width: 440 }}>
                    <Select
                      options={documentTypeOptions}
                      onChange={(item) => {
                        setFieldValue("businessDocumentType", item.value);
                      }}
                      placeholder="Select Business Document Type"
                    />
                  </FormControl>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <Field
                          name="businessDocumentNumber"
                          className="input"
                          placeholder="Document Number"
                          onKeyPress={(e) => {
                            // Allow only alphanumeric characters
                            if (!/[a-zA-Z0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          validate={(value) => {
                            // Validate the length and character constraints
                            if (!/^[a-zA-Z0-9]*$/.test(value)) {
                              return "Certificate number should not contain special characters";
                            }
                            if (value.length < 5) {
                              return "Certificate number should be between 5 to 20 characters";
                            }
                            if (value.length > 20) {
                              return "Certificate number should be between 5 to 20 characters";
                            }
                            return null;
                          }}
                        />
                        {/* Displaying error messages */}
                        {touched.businessDocumentNumber &&
                          errors.businessDocumentNumber && (
                            <span className="error">
                              {errors.businessDocumentNumber}
                            </span>
                          )}
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="input-file"
                        className="submit-button"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: 110,
                          fontSize: 12,
                          fontFamily: "Inter",
                        }}
                      >
                        <FileUploadIcon style={{ fontSize: 16 }} />
                        Upload
                      </label>
                      <input
                        type="file"
                        id="input-file"
                        style={{ display: "none" }}
                        onChange={(event) => {
                          const file = event.currentTarget.files[0];
                          const allowedTypes = [
                            "image/png",
                            "image/jpeg",
                            "application/pdf",
                          ];
                          const maxSizeMB = 20; // Maximum file size in MB
                          const isFileTypeValid = file
                            ? allowedTypes.includes(file.type)
                            : true;
                          const isFileSizeValid = file
                            ? file.size <= maxSizeMB * 1024 * 1024
                            : true;

                          if (file && isFileTypeValid && isFileSizeValid) {
                            setSelectedFile(file);
                            setFieldValue("file", file);
                          } else {
                            // Handle invalid file type or size
                            if (!isFileTypeValid) {
                              alert(
                                "Invalid file type. Please upload a PNG, JPEG, or PDF file."
                              );
                            } else if (!isFileSizeValid) {
                              alert(
                                "File size exceeds 20 MB. Please upload a smaller file."
                              );
                            }
                            // Optionally clear the file input
                            event.currentTarget.value = null;
                          }
                        }}
                      />
                      {!selectedFile && (
                        <p
                          style={{
                            color: "#797979",
                            fontSize: 12,
                            fontFamily: "Inter",
                          }}
                        >
                          Files Supported: .pdf, .png, .jpg (Max size: 20 MB)
                        </p>
                      )}
                      {selectedFile && (
                        <p
                          style={{
                            color: "#797979",
                            fontSize: 12,
                            fontFamily: "Inter",
                          }}
                        >
                          Selected file: {selectedFile.name}
                        </p>
                      )}
                    </div>
                  </div>
                  <div></div>
                </div>
                {touched.file && errors.file && (
                  <span className="error">{errors.file}</span>
                )}
                <div className="label">
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "rgba(25, 25, 25, 1)",
                    }}
                  >
                    PMJAY ID (optional)
                  </span>
                  <Field name="pjmyid" className="input" />
                </div>
                <div className="label">
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "rgba(25, 25, 25, 1)",
                    }}
                  >
                    PMJAY Password (optional)
                  </span>
                  <Field name="pjmypassword" className="input" />
                </div>
                <hr
                  style={{
                    width: "458px",
                    height: "1px",
                    color: "rgba(228, 228, 228, 1)",
                    marginTop: "20px",
                  }}
                />
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button type="submit" className="submit-button">
                    Register and Proceed
                  </button>
                </div>
                <div
                  className="inline-container"
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Already have an account?
                  <span
                    style={{ fontWeight: "bold" }}
                    onClick={() => setSignup(false)}
                    className="text-[#017C71] cursor-pointer ml-1"
                  >
                    Sign In here.
                  </span>
                </div>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};
