import CaseListV2 from "../caseListV2";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import "./MyAssignedCases.scss";
const MyAssignedCases = () => {
  return (
    <div className="manage-box">
      <span className="path_line">
        <WidgetsOutlinedIcon style={{ height: "24px" }} /> &nbsp; &nbsp;/ &nbsp;
        &nbsp; Dashboard &nbsp; &nbsp;/ &nbsp; &nbsp; My Assigned Cases
      </span>

      <div className="heading">My Assigned Cases</div>
      <hr
        style={{ width: "100%", border: "1px solid rgba(228, 228, 228, 1)" }}
      />
      <CaseListV2
        assignedTo={JSON.parse(localStorage.getItem("userDetails"))?.user?.id}
      />
    </div>
  );
};

export default MyAssignedCases;
