import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { NotificationServices } from "../services/NotificationServices";
import { RpaServices } from "../services/RpaServices";

export const fetchNotificationCount = createAsyncThunk(
  "notification/fetchNotificationCount",
  async () => {
    const response = await NotificationServices.getNotifications(
      false,
      1000,
      1,
      ""
    );
    return response?.data?.total;
  }
);
export const fetchNewCaseNotificationCount = createAsyncThunk(
  "notification/fetchNewCaseNotificationCount",
  async () => {
    const response = await RpaServices.getHoldApplication();

    return response.data.rpaRequests;
  }
);
const NotificationSlice = createSlice({
  name: "notification",
  initialState: {
    notificationCount: 0,
    status: "idle",
    error: null,
    newCaseNotificationCount: 0,
    rpaPendingRequests: 0,
    newCaseStatus: "idle",
    newCaseError: null,
    newOtpMessage: "",
  },
  reducers: {
    updateNewOtpMessage: (state, action) => {
      state.newOtpMessage = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchNotificationCount.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchNotificationCount.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.notificationCount = action.payload; // Update notification count with API data
      })
      .addCase(fetchNotificationCount.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchNewCaseNotificationCount.pending, (state) => {
        state.newCaseStatus = "loading";
      })
      .addCase(fetchNewCaseNotificationCount.fulfilled, (state, action) => {
        state.newCaseStatus = "succeeded";
        state.newCaseNotificationCount = action.payload.newCase.NEW; // Update notification count with API data
        state.rpaPendingRequests = action.payload.NEW + action.payload.HOLD;
      })
      .addCase(fetchNewCaseNotificationCount.rejected, (state, action) => {
        state.newCaseStatus = "failed";
        state.newCaseError = action.error.message;
      });
  },
});
export const { updateNewOtpMessage } = NotificationSlice.actions;
export default NotificationSlice.reducer;
