import axios from "axios";
import { getAuthorizationToken } from "../config/user";
const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

function managerpa(caseId, data) {
  return axios({
    method: "put",
    url: `${REACT_APP_API_URL}/update-hospital-details/:hospitalid${caseId}`,
    data: data,
    headers: getAuthorizationToken(),
  });
}

function manageApplicationId() {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/rpa-application-list`,
    headers: getAuthorizationToken(),
  });
}

function checkNewCaseAdded(hospitalId) {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/rpa-requests?hospitalId=${hospitalId}&requestStatus=NEW&caseStatus=NEW_CASE`,
    headers: getAuthorizationToken(),
  });
}

function manageRpaLogs(
  applicationid,
  page,
  limit,
  startDate,
  endDate,
  hospitalId,
  requestStatus,
  caseStatus
) {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/rpa-requests?startDate=${new Date(
      startDate
    ).toISOString()}&endDate=${new Date(
      endDate
    ).toISOString()}&hospitalId=${hospitalId}&applicationId=${applicationid}&page=${page}&requestStatus=${requestStatus}&caseStatus=${caseStatus}&limit=${limit}`,
    headers: getAuthorizationToken(),
  });
}

function manageRpaHospitalLogs(page, limit, hospitalId) {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/rpa-requests?hospitalId=${hospitalId}&page=${page}&limit=${limit}`,
    headers: getAuthorizationToken(),
  });
}

function manageRpaProcessedLogs(
  applicationid,
  page,
  limit,
  startDate,
  endDate,
  hospitalId,
  requestStatus,
  caseStatus
) {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/rpa-processed-requests?startDate=${new Date(
      startDate
    ).toISOString()}&endDate=${new Date(
      endDate
    ).toISOString()}&hospitalId=${hospitalId}&applicationId=${applicationid}&page=${page}&requestStatus=${requestStatus}&caseStatus=${caseStatus}&limit=${limit}`,
    headers: getAuthorizationToken(),
  });
}

function updateRpaRequest(data) {
  return axios({
    method: "post",
    url: `${REACT_APP_API_URL}/update-rpa-request-status`,
    headers: getAuthorizationToken(),
    data: data,
  });
}

function getHospitalsFromId(applicationid) {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/rpa-app-hospital-list?applicationId=${applicationid}&page=${1}`,
    headers: getAuthorizationToken(),
  });
}
function getHoldHospital() {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/rpa-hold-hospital-count`,
    headers: getAuthorizationToken(),
  });
}
function getHoldApplication() {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/rpa-hold-application-count`,
    headers: getAuthorizationToken(),
  });
}
function updateRpaDeviceDetails(data) {
  return axios({
    method: "put",
    url: `${REACT_APP_API_URL}/update-rpa-devices-details`,
    headers: getAuthorizationToken(),
    data: data,
  });
}
function updateRpaHospital(data) {
  return axios({
    method: "put",
    url: `${REACT_APP_API_URL}/update-rpa-hospital-mapping`,
    headers: getAuthorizationToken(),
    data: data,
  });
}

function getTmsQueries(id) {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/case-queries/${id}`,
    headers: getAuthorizationToken(),
  });
}
function manageQueries(status, page, limit, searchKey, hospitalId) {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/all-queries?status=${status}&page=${page}&limit=${limit}&hospitalId=${hospitalId}&searchKey=${searchKey}`,
    headers: getAuthorizationToken(),
  });
}
function rpaRequests(requestStatus, limit, caseStatus) {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/rpa-requests?page=1&requestStatus=${requestStatus}&limit=${limit}&caseStatus=${caseStatus}`,
    headers: getAuthorizationToken(),
  });
}
function rpaStatus(page, hospitalId, requestStatus) {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/rpa-requests?page=1&hospitalId=702ae8de-25e6-4f7b-bb11-6087f515a7f4&requestStatus=${requestStatus}&hospitalId=${hospitalId}&page=${page}`,
    headers: getAuthorizationToken(),
  });
}

function updateTmsQueriesStatus(queryId, status) {
  return axios({
    method: "put",
    url: `${REACT_APP_API_URL}/case-query-status-update?queryId=${queryId}&status=${status}`,
    headers: getAuthorizationToken(),
  });
}
function addRpaRequest(data) {
  return axios({
    method: "post",
    url: `${REACT_APP_API_URL}/add-rpa-request`,
    headers: getAuthorizationToken(),
    data: data,
  });
}
function handleSubmitApplication(data) {
  return axios({
    method: "post",
    url: `${REACT_APP_API_URL}/register-rpa-devices`,
    headers: getAuthorizationToken(),
    data: data,
  });
}
function rpaRunningHospitals() {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/rpa-running-hospitals`,
    headers: getAuthorizationToken(),
  });
}
export const RpaServices = {
  managerpa,
  manageApplicationId,
  getHospitalsFromId,
  updateRpaDeviceDetails,
  getTmsQueries,
  updateTmsQueriesStatus,
  manageQueries,
  rpaRequests,
  rpaStatus,
  updateRpaHospital,
  getHoldHospital,
  getHoldApplication,
  addRpaRequest,
  handleSubmitApplication,
  manageRpaLogs,
  updateRpaRequest,
  manageRpaProcessedLogs,
  manageRpaHospitalLogs,
  rpaRunningHospitals,
  checkNewCaseAdded,
};
