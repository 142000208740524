import React, { useEffect, useState } from "react";
import { RpaServices } from "../../services/RpaServices";
import DatePicker from "react-datepicker";
import EditIcon from "../../assets/editIcon.svg";
import UpdateTmsQueries from "./UpdateTmsQueries";

function TmsQueries(caseId) {
  const [queries, setQueries] = useState([]);
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
  const [queryId, setQueryId] = useState(null);

  const getAllQueries = async () => {
    try {
      const response = await RpaServices.getTmsQueries(caseId.caseId);
      setQueries(response.data.queries);
      console.log(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAllQueries();
  }, []);

  return (
    <div className="p-6 w-full">
      <h2 className="text-xl font-semibold mb-4">TMS Queries</h2>

      <div className="grid md:grid-cols-[3fr_5fr_3fr_1fr] text-[#191919] font-semibold px-8 py-1 gap-4 justify-between w-full">
        <div>Date</div>
        <div>Query Message</div>
        <div>Query Status</div>
        <div>Actions</div>
      </div>

      {queries.length > 0 ? (
        queries.map((query, index) => (
          <div
            key={index}
            className={`grid md:grid-cols-[3fr_5fr_3fr_1fr] p-4 text-[#797979] text-[16px] w-full ${
              index % 2 === 0 ? "bg-white" : "bg-gray-50"
            }`}
          >
            <div>
              <DatePicker
                selected={new Date(query.createdAt)}
                className="border-none bg-transparent w-full"
                timeInputLabel="Time:"
                dateFormat="dd/MM/yyyy h:mm aa"
                showTimeInput
                disabled
              />
            </div>
            <div>{query.queryMessage}</div>
            <div>{query.status}</div>
            <div>
              <img
                src={EditIcon}
                alt="edit-icon"
                className="cursor-pointer w-5 h-5"
                onClick={() => {
                  setQueryId(query.id);
                  setIsUpdatingStatus(true);
                }}
              />
            </div>
          </div>
        ))
      ) : (
        <div className="p-4 text-center text-[#797979]">
          No queries available
        </div>
      )}

      {queryId && isUpdatingStatus && (
        <UpdateTmsQueries
          queryId={queryId}
          isOpen={setQueryId}
          getAllQueries={getAllQueries}
        />
      )}
    </div>
  );
}

export default TmsQueries;
