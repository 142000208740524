import { useRef, useState, useEffect, useContext } from "react";
import { docUploadRefreshContext } from "../../dashboard/cases/contexts/CaseContexts";
import { CaseServices } from "../../services/CaseServices";
import { AuthServices } from "../../services/AuthServices";
import { logOut } from "../../config/user";
import { CircularProgress } from "@mui/material";
import CloseIcon from "../../assets/hospitalCloseIcon.svg";
import UploadDocumentsHospitalPersona from "./UploadDocumentsHospitalPersona";
import ReuploadImageIcon from "../../assets/reUploadImageIcon.png";
import ReuploadIcon from "../../assets/reuploadIcon.svg";
const ReUploadDocumentsHospitalPersona = ({ documentDetails }) => {
  const [open, setOpen] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [docUrl, setDocUrl] = useState(null);
  const [modified, setModified] = useState(0);

  const [selectedDocument, setSelectedDocument] = useState({});
  const [documentsUrl, setDocumentsUrl] = useState(
    documentDetails.documenturlmasters
  );
  const refreshFunction = useContext(docUploadRefreshContext);
  const fileInputRef = useRef();
  console.log("documentDetails:", documentDetails);
  const handleButtonClick = (image) => {
    setSelectedDocument(image);
    fileInputRef?.current?.click();
  };
  const saveUrl = async () => {
    try {
      await CaseServices.saveDocumentUrl(documentDetails.id, documentsUrl);
      window.alert("Document saved");

      refreshFunction.getMappedDocuments();
      setDocumentsUrl(documentDetails?.documenturlmasters);
      setOpen(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
  };
  const uploadImage = async (e) => {
    try {
      let data = new FormData();
      data.append("image", e.target.files[0]);
      let res = await AuthServices.uploadImage(data);
      setDocUrl(res.data.url);
      setModified(modified + 1);
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert("Error occurred while uploading image");
      }
    }
  };
  useEffect(() => {
    if (docUrl) {
      const formattedData = documentsUrl?.map((document) => {
        if (document.id === selectedDocument.id) {
          let newDocument = {
            ...document,
            documentUrl: docUrl,
            urlId: document.id,
          };
          return newDocument;
        } else {
          return document;
        }
      });
      setDocumentsUrl(formattedData);
    }
  }, [docUrl]);
  useEffect(() => {
    open
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "auto");

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [open]);
  console.log("documentsUrl:", documentsUrl);
  return (
    <div>
      <div onClick={() => setOpen(true)}>
        {" "}
        <img
          src={ReuploadIcon}
          alt="re-upload-icon"
          className="cursor-pointer"
        />
      </div>
      {open && (
        <div className="fixed top-0 left-0 h-screen w-screen flex justify-center items-center bg-[#017C7180] ">
          <div className="relative h-[80%] w-[80%] bg-white rounded-[30px] p-10">
            <div
              className="absolute top-3 right-4 cursor-pointer"
              onClick={() => setOpen(false)}
            >
              <img src={CloseIcon} alt="close-icon" height={53} width={52} />
            </div>
            <div className="flex gap-5 items-end mr-8">
              <div className="text-[30px]">Rejected Documents </div>{" "}
              <div className="text-[16px]  text-[#B53030] max-w-[80%] text-wrap">
                {"*"}
                {documentDetails?.rejectionReason}
              </div>
            </div>

            {documentDetails?.completeDocRejected ? (
              <div
                style={{ border: "1px solid #E4E4E4" }}
                className="w-[100%] h-[90%] flex justify-center items-center gap-2 flex-wrap overflow-y-scroll mt-4 p-1  box-border rounded-[20px]"
              >
                <div className="text-[30px]">Re-Upload Whole Document</div>
                <div>
                  <UploadDocumentsHospitalPersona
                    documentDetails={documentDetails}
                    sequencePrev={0}
                    completeDocReupload={true}
                  />
                </div>
              </div>
            ) : (
              <div
                style={{ border: "1px solid #E4E4E4" }}
                className="w-[100%] h-[90%] flex justify-center items-start  gap-2 flex-wrap overflow-y-scroll mt-4 p-1  box-border rounded-[20px]"
              >
                {" "}
                {documentsUrl?.map((image, idx) => {
                  return (
                    image.status === "REJECTED" && (
                      <div
                        key={idx}
                        style={{ border: "1px solid #E4E4E4" }}
                        className="relative bg-[#FFA9A9] rounded-[17px] p-1"
                      >
                        <div className="flex justify-between px-2">
                          <div>{idx + 1}</div>
                        </div>
                        <img
                          src={image?.documentUrl}
                          alt=""
                          width={248}
                          height={249}
                          style={{ border: "1px solid #E4E4E4" }}
                          className="object-fit rounded-[15px]"
                        />
                        <div className="absolute bottom-0.5 left-0  w-full ">
                          {/* <div
                            style={{
                              background: "#00000060",
                              backdropFilter: "blur(3.6976048946380615px)",
                            }}
                            className="flex w-[94%] justify-between items-center rounded-[14px] py-2 mx-auto px-1"
                          /> */}
                        </div>
                        <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center bg-transparent">
                          <img
                            src={ReuploadImageIcon}
                            alt="re-upload-image"
                            className="cursor-pointer"
                            onClick={() => handleButtonClick(image)}
                          />
                        </div>
                        <input
                          type="file"
                          style={{ display: "none" }}
                          ref={fileInputRef}
                          onChange={uploadImage}
                          accept=".png,.jpg,.jpeg ."
                        />
                      </div>
                    )
                  );
                })}
              </div>
            )}

            <div className="flex justify-end gap-2 items-center">
              <div
                style={{ border: "1px solid #017C71" }}
                className=" text-[#017C71] bg-white font-semibold rounded-full px-5 py-3 cursor-pointer"
                onClick={() =>
                  modified > 0
                    ? saveUrl()
                    : window.alert("Please Re-upload documents")
                }
              >
                SAVE
              </div>
              <div>
                {uploadStatus ? (
                  <div className="flex justify-end">
                    <CircularProgress />
                  </div>
                ) : (
                  <div className="flex justify-end items-center gap-3 text-sm text-white -tracking-[3%] uppercase mt-1">
                    <UploadDocumentsHospitalPersona
                      documentDetails={documentDetails}
                      sequencePrev={documentDetails?.documenturlmasters?.length}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReUploadDocumentsHospitalPersona;
