import { useEffect, useState, useffect } from "react";
import { NotificationServices } from "../../services/NotificationServices";
import HospitalCustomPaginaion from "../../utils/HospitalPaginationUtil";
import ChevronDown from "../../assets/chevron-down.svg";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchNotificationCount } from "../../redux/notificationSlice";
const NotificationHospitalPersona = () => {
  const [selectedTab, setSelectedTab] = useState("new");
  const [notificationCategory, setNotificationCategory] = useState("new");
  const [notificationsList, setNotificationsList] = useState(null);
  const [notificationCount, setNotificationCount] = useState(null);
  const [readStatus, setReadStatus] = useState("all");
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedNotificationList, setSelectedNotificationList] =
    useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const limit = 10;
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSelectAll = () => {
    let modifiedData = [];
    if (selectAll) {
      modifiedData = notificationsList?.map((notification) => {
        return {
          ...notification,
          isSelected: false,
        };
      });
    } else {
      modifiedData = notificationsList?.map((notification) => {
        return {
          ...notification,
          isSelected: true,
        };
      });
    }
    setNotificationsList([...modifiedData]);
    setSelectAll(!selectAll);
  };
  const handleSelection = (notificationId) => {
    const modifiedData = notificationsList?.map((notification) => {
      if (notification.id === notificationId) {
        return { ...notification, isSelected: !notification.isSelected };
      }
      return notification;
    });
    const selectedCount = modifiedData?.filter(
      (notification) => notification?.isSelected
    ).length;
    if (selectedCount && selectedCount === modifiedData.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
    console.log("selected count:", selectedCount);
    setNotificationsList(modifiedData);
  };

  const getNotifications = async () => {
    try {
      const res = await NotificationServices.getNotifications(
        readStatus,
        limit,
        page,
        notificationCategory
      );
      const modifiedData = res?.data?.UserNotifcations?.map((notification) => ({
        ...notification,
        isSelected: false,
      }));
      setNotificationsList(modifiedData);

      setTotalPages(Math.ceil(res.data.total / limit));
    } catch (error) {
      window.alert(error);
    }
  };
  const getUserNotificationCounts = async () => {
    try {
      const res = await NotificationServices.getUserNotificationCounts();
      setNotificationCount(res?.data);
      console.log("res:", res.data);
    } catch (error) {
      window.alert(error);
    }
  };
  const markNotificationStatus = async (notificationId, status) => {
    try {
      const response = await NotificationServices.markNotificationReadUnread(
        [notificationId],
        status
      );
      dispatch(fetchNotificationCount());
      getUserNotificationCounts();
      getNotifications();
    } catch (error) {
      window.alert(error);
    }
  };
  const handleMarkReadUnread = async (isRead) => {
    const postData = notificationsList
      ?.filter((notification) => notification.isSelected)
      ?.map((notification) => notification.id);
    try {
      const res = await NotificationServices?.markNotificationReadUnread(
        postData,
        isRead
      );
      await getNotifications();
      await getUserNotificationCounts();
      dispatch(fetchNotificationCount());
      window.alert(res.data.message);
    } catch (error) {
      window.alert(error);
    }
  };
  const getDate = (dateString) => {
    const date = new Date(dateString);

    // Get the day, month, year, hour, and minute
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    // Format as "hh:mm dd/mm/yyyy"
    return `${hours}:${minutes}   ${day}/${month}/${year}`;
  };

  useEffect(() => {
    getNotifications();
    getUserNotificationCounts();
  }, [page, notificationCategory]);
  return (
    <div>
      <div
        style={{ color: "rgba(0,0,0,0.5)" }}
        className="text-[11px] 2xl:text-sm leading-[18.27px] -tracking-[3%]"
      >
        Home / Case Status / Notification
      </div>
      <div className="w-fit text-[30px] 2xl:text-[50px] leading-[65.26px] -tracking-[7%]  mt-[12px] 2xl:mt-[30px]">
        Notifications
      </div>
      <div
        id="case-list"
        style={{ background: "rgba(255,255,255,0.5)" }}
        className="p-4 2xl:p-8 mt-1 2xl:mt-4 rounded-[30px] min-h-[70vh]"
      >
        <div className="flex justify-between items-center bg-white rounded-full">
          <div className="w-[60%] flex items-center gap-2 justify-between ml-1">
            <div
              className={`relative flex gap-[6px] items-center py-[12px] 2xl:py-[15px] px-6 2xl:px-8 rounded-full cursor-pointer transition-all ease-in-out duration-200 ${
                selectedTab.includes("new")
                  ? "text-white text-[16px] 2xl:text-[20px] bg-black font-semibold"
                  : "font-normal text-[12px] 2xl:text-[16px] text-[#666666] bg-[#F5F5F5]"
              }`}
              onClick={() => {
                setSelectedTab("new");
                setNotificationCategory("New");
                setPage(1);
              }}
            >
              NEW
              <div>
                {notificationCount?.New?.read +
                  notificationCount?.New?.unread || 0}
              </div>
              <div
                style={{ border: "3px solid #ffffff" }}
                className="absolute w-[20px] h-[20px] flex justify-center items-center -top-1 -right-1  text-[9px]  text-white text-center  bg-red-500 rounded-full "
              >
                {notificationCount?.New?.unread}
              </div>
            </div>
            <div
              className={`relative flex gap-[6px] items-center py-[12px] 2xl:py-[15px] px-6 2xl:px-8 rounded-full cursor-pointer transition-all ease-in-out duration-200 ${
                selectedTab.includes("rejected")
                  ? "text-white text-[16px] 2xl:text-[20px] bg-black font-semibold"
                  : "font-normal text-[12px] 2xl:text-[16px] text-[#666666] bg-[#F5F5F5]"
              }`}
              onClick={() => {
                setSelectedTab("rejected");
                setNotificationCategory("Rejected");
                setPage(1);
              }}
            >
              REJECTED
              <div>
                {notificationCount?.Rejected?.read +
                  notificationCount?.Rejected?.unread || 0}
              </div>
              <div
                style={{ border: "3px solid #ffffff" }}
                className="absolute w-[20px] h-[20px] flex justify-center items-center -top-1 -right-1  text-[9px]  text-white text-center  bg-red-500 rounded-full "
              >
                {notificationCount?.Rejected?.unread}
              </div>
            </div>
            <div
              className={`relative flex gap-[6px] items-center py-[12px] 2xl:py-[15px] px-6 2xl:px-8 rounded-full cursor-pointer transition-all ease-in-out duration-200 ${
                selectedTab.includes("approved")
                  ? "text-white text-[16px] 2xl:text-[20px] bg-black font-semibold"
                  : "font-normal text-[12px] 2xl:text-[16px] text-[#666666] bg-[#F5F5F5]"
              }`}
              onClick={() => {
                setSelectedTab("approved");
                setNotificationCategory("Approved");
                setPage(1);
              }}
            >
              APPROVED
              <div>
                {notificationCount?.Approved?.read +
                  notificationCount?.Approved?.unread || 0}
              </div>
              <div
                style={{ border: "3px solid #ffffff" }}
                className="absolute w-[20px] h-[20px] flex justify-center items-center -top-1 -right-1  text-[9px]  text-white text-center  bg-red-500 rounded-full "
              >
                {notificationCount?.Approved?.unread}
              </div>
            </div>
            <div
              className={`relative flex gap-[6px] items-center py-[12px] 2xl:py-[15px] px-6 2xl:px-8 rounded-full cursor-pointer transition-all ease-in-out duration-200 ${
                selectedTab.includes("other")
                  ? "text-white text-[16px] 2xl:text-[20px] bg-black font-semibold"
                  : "font-normal text-[12px] 2xl:text-[16px] text-[#666666] bg-[#F5F5F5]"
              }`}
              onClick={() => {
                setSelectedTab("other");
                setNotificationCategory("Other");
                setPage(1);
              }}
            >
              OTHER
              <div>
                {notificationCount?.Other?.read +
                  notificationCount?.Other?.unread || 0}
              </div>
              <div
                style={{ border: "3px solid #ffffff" }}
                className="absolute w-[20px] h-[20px] flex justify-center items-center -top-1 -right-1  text-[9px]  text-white text-center  bg-red-500 rounded-full "
              >
                {notificationCount?.Other?.unread}
              </div>
            </div>
            <div
              className={`relative flex gap-[6px] items-center py-[12px] 2xl:py-[15px] px-6 2xl:px-8 rounded-full cursor-pointer  transition-all ease-in-out duration-200 ${
                selectedTab.includes("all")
                  ? "text-white text-[16px] 2xl:text-[20px] bg-black font-semibold"
                  : "font-normal text-[12px] 2xl:text-[16px] text-[#666666] bg-[#F5F5F5]"
              }`}
              onClick={() => {
                setSelectedTab("all");
                setNotificationCategory("");
                setPage(1);
              }}
            >
              ALL
              <div>{notificationCount?.totalNotifications || 0}</div>
              <div
                style={{ border: "3px solid #ffffff" }}
                className="absolute w-[20px] h-[20px] flex justify-center items-center -top-1 -right-1 text-[9px]  text-white text-center  bg-red-500 rounded-full "
              >
                {notificationCount?.totalUnreadNotifications}
              </div>
            </div>
          </div>
          <div className="flex items-center gap-3 2xl:gap-5 p-1">
            {/* <div className="flex gap-2 text-sm">
              <input type="checkbox" />
              Unread
            </div> */}
            <div
              style={{ border: "1px solid #017C71" }}
              className="text-[12px] 2xl:text-sm -tracking-[3%] rounded-full px-3 2xl:px-4 py-2 2xl:py-3 cursor-pointer"
              onClick={() => handleMarkReadUnread(true)}
            >
              Mark As Read
            </div>
            <div
              style={{ border: "1px solid #017C71" }}
              className="text-[12px] 2xl:text-sm -tracking-[3%] rounded-full px-3 2xl:px-4 py-2 2xl:py-3 cursor-pointer"
              onClick={() => handleMarkReadUnread(false)}
            >
              Mark As Unread
            </div>
          </div>
        </div>
        <div
          style={{ border: "1px solid #FFFFFF" }}
          className=" my-[28px] 2xl:my-[37px]"
        />
        {notificationsList?.length > 0 && (
          <div className="flex items-center gap-2 text-sm px-5 mb-5">
            <input
              type="checkbox"
              checked={selectAll}
              onClick={handleSelectAll}
            />
            Select All
          </div>
        )}
        <div>
          {notificationsList?.map((notification, idx) => {
            return (
              <div
                key={idx}
                className={` mt-2 px-5 py-3 transition-all duration-200 ease-in-out ${
                  selectedNotification === idx
                    ? "rounded-[15px]"
                    : "rounded-full"
                } ${notification?.read ? "bg-[#FFFFFFCC]" : "bg-[#E9E9E9]"}`}
                onClick={() => {
                  setSelectedNotification(idx);
                  markNotificationStatus(notification.id, "true");
                }}
              >
                <div
                  className={`flex items-center justify-between transition-all duration-200 ease-in-out ${
                    selectedNotification === idx
                      ? "text-[16px] 2xl:text-[18px] "
                      : "text-sm"
                  }`}
                >
                  {" "}
                  <div>
                    <input
                      type="checkbox"
                      className="mr-2"
                      checked={notification?.isSelected}
                      onClick={() => handleSelection(notification.id)}
                    />
                    {notification?.patientName || "Name"}{" "}
                    <span className={`text-xs text-[#00000080] font-bold `}>
                      {" - " + notification?.title}
                    </span>
                  </div>
                  <div className="flex items-center gap-5 text-[13px] 2xl:text-[15px]  leading-[15px] -tracking-[5%]">
                    <div className="text-[12px] 2xl:text-[16px] text-[#666666] ">
                      {notification?.beneficiaryNo}
                    </div>
                    <div>{getDate(notification?.createdAt)}</div>
                    <img
                      src={ChevronDown}
                      alt="arrow-icon"
                      className={`transition-all duration-200 ease-in-out ${
                        selectedNotification === idx
                          ? "rotate-180 "
                          : "rotate-0"
                      }`}
                    />
                  </div>
                </div>
                <div
                  className={`text-sm leading-[16.94px] -tracking-[0.36px] transition-all duration-200 ease-in-out cursor-pointer ${
                    selectedNotification === idx
                      ? "h-5 mt-4 "
                      : "h-0 overflow-hidden"
                  }`}
                  onClick={() =>
                    navigate(`/dashboard/case-status/${notification?.caseId}`)
                  }
                >
                  {notification?.body}
                </div>
              </div>
            );
          })}
        </div>
        <div className="flex justify-center items-center mt-10">
          <HospitalCustomPaginaion
            page={page}
            totalPages={totalPages}
            setPage={setPage}
          />
        </div>
      </div>
    </div>
  );
};

export default NotificationHospitalPersona;
