import React from "react";
import AddDischargeForm from "./AddDischargeForm";
const AddDischarge = ({ caseDetails, setValue }) => {
  const dischargeOptions = [
    { value: "Death", label: "Death" },
    { value: "Discharge", label: "Discharge" },
    { value: "Lama", label: "Lama" },
    { value: "Refer", label: "Refer" },
  ];
  return (
    <div>
      <div className="flex justify-between items-center">
        <div className="text-2xl font-semibold text-[#191919]">Discharge</div>
      </div>
      <div
        style={{
          border: "1px solid #E4E4E4",
          marginTop: "24px",
          marginBottom: "40px",
        }}
      />
      <AddDischargeForm
        dischargeOptions={dischargeOptions}
        caseId={caseDetails.id}
        setValue={setValue}
      />
    </div>
  );
};

export default AddDischarge;
