import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import ManageHistoryOutlinedIcon from "@mui/icons-material/ManageHistoryOutlined";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import DeveloperBoardIcon from "@mui/icons-material/DeveloperBoard";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import MedicalServicesOutlinedIcon from "@mui/icons-material/MedicalServicesOutlined";
import EditNotificationsOutlinedIcon from "@mui/icons-material/EditNotificationsOutlined";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import PhoneInTalkOutlinedIcon from "@mui/icons-material/PhoneInTalkOutlined";
import MarkChatUnreadOutlinedIcon from "@mui/icons-material/MarkChatUnreadOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import MarkUnreadChatAltOutlinedIcon from "@mui/icons-material/MarkUnreadChatAltOutlined";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { useNavigate } from "react-router-dom";
import { getUser } from "../config/user";
import { RpaServices } from "../services/RpaServices";
import ManageHospitals from "../assets/manageHospitals.svg";
import HospitalList from "../assets/hospitalList.svg";
import AddHospital from "../assets/addHospital.svg";
import ApproveHospital from "../assets/approveHospital.svg";
import GracePeriod from "../assets/gracePeriod.svg";
import ManageProcedure from "../assets/manageProcedure.svg";
import ReportsIcon from "../assets/reportsIcon.svg";
import ManageApplication from "../managerpa/ManageApplication";
import { fetchHoldHospitalCount } from "../redux/holdHospitalSlice";
import { fetchActiveCasesCount } from "../redux/caseDetailsSlice";
import { TryRounded, WbIncandescentTwoTone } from "@mui/icons-material";

const Sidebarorg = () => {
  const [selectedNavigation, setSelectedNavigation] = useState(null);
  const [holdHospitalInfo, setHoldHospitalInfo] = useState(null);
  const [holdApplicationInfo, setHoldApplicationInfo] = useState(null);
  const dispatch = useDispatch();
  const { rpaPendingRequests } = useSelector(
    (store) => store.notificationReducer
  );
  const { activeCasesCount } = useSelector((store) => store.caseDetails);
  function sidebarElements(navigtiondata) {
    let elements = {};
    if (navigtiondata && navigtiondata.length > 0) {
      navigtiondata.forEach((nd) => {
        elements[`${nd?.navigation?.navigationName}`] =
          nd?.navigation?.navigationUrl;
      });
      return elements;
    }
  }
  let user = getUser();
  let navElement = sidebarElements(user.navigationdata);
  let role = user?.roles[0]?.role?.roleName;
  let roleId = user?.roles[0]?.roleId;

  useEffect(() => {
    // Function to call API
    const fetchHospitalData = async () => {
      const res = await RpaServices.getHoldHospital();
      // console.log("re hold:", res.data);
      setHoldHospitalInfo(res.data);
    };

    const fetchApplicationData = async () => {
      const res = await RpaServices.getHoldApplication();
      console.log("rpa hold application count:", res.data);
      setHoldApplicationInfo(res.data);
    };
    dispatch(fetchActiveCasesCount());

    // Call initially
    fetchHospitalData();
    fetchApplicationData();

    // Set interval to call every 15 minutes (900,000 ms)
    const intervalId = setInterval(fetchHospitalData, 900000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const Navigate = useNavigate();
  return (
    <div
      style={{
        minHeight: "100vh",
        height: "inherit",
        fontFamily: "Inter",
        fontWeight: "400px",
        overflow: "scroll",
        backgroundColor: "white",
      }}
    >
      <Sidebar className="app">
        <Menu>
          <p
            style={{
              marginLeft: "20px",
              color: "#797979",
              fontSize: "11px",
              lineHeight: "24px",
            }}
          >
            MAIN
          </p>
          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#CDFAF4",
              width: "188px",
              height: "32px",
              borderRadius: "8px",
              marginLeft: "20px",
            }}
          >
            <GridViewOutlinedIcon
              style={{ color: "#017C57", marginRight: "10px" }}
            />
            <p
              style={{
                margin: 0,
                fontSize: "12px",
                color: "#017C57",
                fontSize: "12px",
              }}
            >
              Username Dashboard
            </p>
          </div>
          <div
            style={{
              width: "188px",
              height: "1px",
              marginTop: "16px",
              marginLeft: "16px",
              backgroundColor: "#E4E4E4",
            }}
          ></div> */}
          {/* ; */}
          &nbsp;
          {navElement["Hospital Dashboard"] !== undefined && (
            <MenuItem
              onClick={() => {
                user.user.hospitalId
                  ? Navigate("/dashboard")
                  : Navigate("/Dashboard/ManageCases");
              }}
              icon={<GridViewOutlinedIcon style={{ color: "#017C57" }} />}
              color="red"
            >
              {" "}
              Dashboard{" "}
              {user.user.hospitalId && (
                <div className="text-[10px] text-[#017C57]">Coming Soon</div>
              )}
            </MenuItem>
          )}
          <div
            style={{
              width: "188px",
              height: "1px",
              marginTop: "16px",
              marginLeft: "16px",
              backgroundColor: "#E4E4E4",
            }}
          ></div>
          <p
            style={{
              marginLeft: "20px",
              color: "#797979",
              fontSize: "11px",
              lineHeight: "24px",
            }}
          >
            MANAGEMENT
          </p>
          {navElement["Manage Hospitals"] !== undefined && (
            <SubMenu
              label="Manage Hospitals"
              icon={
                <img
                  src={ManageHospitals}
                  alt="manage-hospitals-icon"
                  height={20}
                  width={20}
                />
              }
            >
              {navElement["Hospital List"] !== undefined && (
                <MenuItem
                  onClick={() => Navigate("/dashboard/hospitallist")}
                  icon={
                    <img
                      src={HospitalList}
                      height={20}
                      width={20}
                      alt="hospital-list-icon"
                    />
                  }
                >
                  {" "}
                  Hospial List{" "}
                </MenuItem>
              )}
              {navElement["Add Hospital"] !== undefined && (
                <MenuItem
                  onClick={() => Navigate("/dashboard/addhospital")}
                  icon={
                    <img
                      src={AddHospital}
                      height={20}
                      width={20}
                      alt="add-hospital-icon"
                    />
                  }
                >
                  {" "}
                  Add Hospital{" "}
                </MenuItem>
              )}
              {navElement["Approve Hospital"] !== undefined && (
                <MenuItem
                  onClick={() => Navigate("/dashboard/approvehospital")}
                  icon={
                    <img
                      src={ApproveHospital}
                      height={20}
                      width={20}
                      alt="approve-hospital-icon"
                    />
                  }
                >
                  {" "}
                  Approve Hospital{" "}
                </MenuItem>
              )}
              {navElement["Approve Hospital"] !== undefined && (
                <MenuItem
                  // onClick={() => Navigate("/dashboard/approvehospital")}
                  icon={
                    <img
                      src={GracePeriod}
                      height={20}
                      width={20}
                      alt="grace-period-icon"
                    />
                  }
                >
                  {" "}
                  Grace Period{" "}
                  <div className="text-[10px] text-[#017C57]">Coming Soon</div>
                </MenuItem>
              )}
            </SubMenu>
          )}
          {navElement["ManageSubAdmin"] !== undefined && (
            <SubMenu
              label="Manage staff"
              icon={<PeopleAltOutlinedIcon style={{ color: "#017C57" }} />}
            >
              {navElement["Sub Admin List"] !== undefined && (
                <MenuItem
                  onClick={() => Navigate("/stafflist")}
                  icon={<ListOutlinedIcon style={{ color: "#017C57" }} />}
                >
                  {" "}
                  Staff List{" "}
                </MenuItem>
              )}
              {navElement["Add Sub Admin"] !== undefined && (
                <MenuItem
                  onClick={() => Navigate("/addstaff")}
                  icon={<AddCircleOutlineIcon style={{ color: "#017C57" }} />}
                >
                  Add Staff
                </MenuItem>
              )}
              {navElement["Edit Sub Admin"] !== undefined && (
                <MenuItem
                  onClick={() => Navigate("/dashboard/editsubadmin")}
                  icon={<EditNoteIcon style={{ color: "#017C57" }} />}
                >
                  Edit Staff
                </MenuItem>
              )}
            </SubMenu>
          )}
          {(roleId === 1 || roleId === 2) && (
            <MenuItem
              onClick={() => {
                setSelectedNavigation("manage cases");
                Navigate("/Dashboard/ManageCases");
              }}
              icon={
                <ManageHistoryOutlinedIcon
                  style={{
                    color: "#017C57",
                  }}
                />
              }
              className={`${
                selectedNavigation?.includes("manage cases")
                  ? "bg-neutral-200"
                  : "bg-white"
              }`}
            >
              Manage Cases
            </MenuItem>
          )}
          {roleId !== 1 &&
            roleId !== 2 &&
            navElement["Manage Cases"] !== undefined && (
              <SubMenu
                label="Manage Cases"
                onClick={() => {
                  setSelectedNavigation("manage cases");
                  Navigate("/Dashboard/ManageCases");
                }}
                icon={
                  <ManageHistoryOutlinedIcon
                    style={{
                      color: "#017C57",
                    }}
                  />
                }
                className={`${
                  selectedNavigation?.includes("manage cases")
                    ? "bg-neutral-200"
                    : "bg-white"
                }`}
              >
                {navElement["Active Cases"] !== undefined && roleId !== "1" && (
                  <MenuItem
                    onClick={() => {
                      setSelectedNavigation("active cases");
                      Navigate("/dashboard/ActiveCases");
                    }}
                    icon={<ListOutlinedIcon style={{ color: "#017C57" }} />}
                    className={`${
                      selectedNavigation?.includes("active cases")
                        ? "bg-neutral-200"
                        : "bg-white"
                    }`}
                  >
                    Active Cases{" "}
                    <span style={{ fontSize: "12px" }}>
                      ({activeCasesCount})
                    </span>
                  </MenuItem>
                )}
                {roleId !== 1 && roleId !== 2 && (
                  <MenuItem
                    onClick={() => Navigate("/dashboard/my-assigned-cases")}
                    icon={
                      <ManageAccountsOutlinedIcon
                        style={{ color: "#017C57" }}
                      />
                    }
                    title="My Cases"
                  >
                    My Cases
                  </MenuItem>
                )}

                {navElement["Add Case"] !== undefined && (
                  <MenuItem
                    onClick={() => Navigate("/addcases")}
                    icon={<NoteAddOutlinedIcon style={{ color: "#017C57" }} />}
                  >
                    Add Case
                  </MenuItem>
                )}
                {navElement["Map Documents"] !== undefined && (
                  <MenuItem
                    onClick={() => Navigate("/dashboard/mapdocuments")}
                    icon={
                      <DescriptionOutlinedIcon style={{ color: "#017C57" }} />
                    }
                  >
                    Map Documents
                  </MenuItem>
                )}
                {roleId !== 1 &&
                  roleId !== 2 &&
                  navElement["Upload Documents"] !== undefined && (
                    <MenuItem
                      icon={
                        <FileUploadOutlinedIcon style={{ color: "#017C57" }} />
                      }
                    >
                      Upload Documents
                    </MenuItem>
                  )}
                {navElement["Verify Documents"] !== undefined && (
                  <MenuItem
                    icon={<TaskAltOutlinedIcon style={{ color: "#017C57" }} />}
                  >
                    Verify Documents
                  </MenuItem>
                )}

                {roleId !== 1 && roleId !== 2 && (
                  <MenuItem
                    onClick={() => Navigate("/Dashboard/processed-query-cases")}
                    icon={<NoteAddOutlinedIcon style={{ color: "#017C57" }} />}
                    title="Processed Query Cases"
                  >
                    Processed Query Cases
                  </MenuItem>
                )}
              </SubMenu>
            )}
          {roleId !== 1 && roleId !== 2 && (
            <MenuItem
              onClick={() => Navigate("/dashboard/manualNotification")}
              icon={
                <MarkUnreadChatAltOutlinedIcon style={{ color: "#017C57" }} />
              }
              title="Send Manual Notification"
            >
              Send Manual Notification
            </MenuItem>
          )}
          {roleId !== 1 && roleId !== 2 && (
            <MenuItem
              onClick={() => Navigate("/dashboard/editdocuments")}
              icon={<PictureAsPdfOutlinedIcon style={{ color: "#017C57" }} />}
            >
              My PDF
            </MenuItem>
          )}
          {roleId !== 1 && roleId !== 2 && (
            <MenuItem
              onClick={() => Navigate("/dashboard/notificationsStructure")}
              icon={
                <EditNotificationsOutlinedIcon style={{ color: "#017C57" }} />
              }
            >
              Manage Notification
            </MenuItem>
          )}
          {roleId !== 1 && roleId !== 2 && (
            <MenuItem
              onClick={() => Navigate("/dashboard/manageprocedure")}
              // icon={<ListOutlinedIcon style={{ color: "#017C57" }} />}
              icon={<img src={ManageProcedure} alt="manage-procedure-icon" />}
            >
              Manage Procedure
            </MenuItem>
          )}
          {/* {roleId !== 1 && roleId !== 2 && (
            <MenuItem
              onClick={() => Navigate("/dashboard/managedocument")}
              icon={<NoteAltOutlinedIcon style={{ color: "#017C57" }} />}
            >
              Manage Document
            </MenuItem>
          )} */}
          {roleId !== 1 && roleId !== 2 && (
            <MenuItem
              onClick={() => Navigate("/dashboard/addDoctor")}
              icon={
                <MedicalServicesOutlinedIcon style={{ color: "#017C57" }} />
              }
            >
              Add Doctor
            </MenuItem>
          )}
          <div
            style={{
              width: "188px",
              height: "1px",
              marginTop: "16px",
              marginLeft: "16px",
              backgroundColor: "#E4E4E4",
            }}
          ></div>
          &nbsp;
          <SubMenu
            label=" Manage User"
            icon={<ManageAccountsOutlinedIcon style={{ color: "#017C57" }} />}
          >
            <MenuItem
              onClick={() => Navigate("/dashboard/internal-user-management")}
              icon={<PersonAddAltOutlinedIcon style={{ color: "#017C57" }} />}
            >
              Add User
            </MenuItem>
            <MenuItem
              onClick={() => Navigate("/dashboard/internal-user-list")}
              icon={<PeopleAltOutlinedIcon style={{ color: "#017C57" }} />}
            >
              Internal Users
            </MenuItem>
          </SubMenu>
          <MenuItem
            onClick={() => Navigate("/dashboard/manage-query")}
            icon={<ManageSearchIcon style={{ color: "#017C57" }} />}
          >
            Manage Queries
          </MenuItem>
          <SubMenu
            label="Manage RPA"
            icon={<ManageAccountsOutlinedIcon style={{ color: "#017C57" }} />}
          >
            <MenuItem
              onClick={() => {
                setSelectedNavigation("manage rpa");
                Navigate("/dashboard/manage-rpa");
              }}
              icon={<DeveloperBoardIcon style={{ color: "#017C57" }} />}
              title="Hospital Manage"
              className={`${
                selectedNavigation?.includes("manage rpa")
                  ? "bg-neutral-200"
                  : "bg-white"
              }`}
            >
              Hospital Manage{" "}
              <div className="text-[10px]">
                {holdHospitalInfo &&
                  Number(holdHospitalInfo.totalHospitals) -
                    Number(holdHospitalInfo.holdHospitals)}
                / {holdHospitalInfo && holdHospitalInfo.totalHospitals}
              </div>{" "}
            </MenuItem>

            {/* Displaying the Hold Hospital Count */}
            <MenuItem
              onClick={() => {
                setSelectedNavigation("manage applicationid");
                Navigate("/dashboard/manage-applicationid");
              }}
              icon={<DeveloperBoardIcon style={{ color: "#017C57" }} />}
              title="Application Manage"
              className={`${
                selectedNavigation?.includes("manage applicationid")
                  ? "bg-neutral-200"
                  : "bg-white"
              }`}
            >
              Application{" "}
              <div className="text-[10px]">
                {holdApplicationInfo &&
                  Number(holdApplicationInfo.totalHospitals) -
                    Number(holdApplicationInfo.holdHospitals)}
                /{" "}
                {holdApplicationInfo &&
                  holdApplicationInfo.pmjayActiveHostipals}
              </div>{" "}
            </MenuItem>

            <MenuItem
              onClick={() => {
                setSelectedNavigation("manage logs");
                Navigate("/dashboard/manage-rpa-logs");
              }}
              icon={<DeveloperBoardIcon style={{ color: "#017C57" }} />}
              title="RPA Logs"
              className={`${
                selectedNavigation?.includes("manage logs")
                  ? "bg-neutral-200"
                  : "bg-white"
              }`}
            >
              RPA Request
              <div className="text-[10px]">{rpaPendingRequests} Pending</div>
            </MenuItem>
          </SubMenu>
          <MenuItem
            onClick={() => Navigate("/dashboard/digital-medco-otp")}
            icon={<ManageSearchIcon style={{ color: "#017C57" }} />}
          >
            Digital Medco OTP
          </MenuItem>
          <div
            style={{
              width: "188px",
              height: "1px",
              marginTop: "16px",
              marginLeft: "16px",
              backgroundColor: "#E4E4E4",
            }}
          ></div>
          &nbsp;
          {roleId !== 1 && roleId !== 2 && (
            <SubMenu
              label="Reports"
              icon={<img src={ReportsIcon} alt="reports-icon" />}
            >
              <MenuItem
                onClick={() => Navigate("/report/daily-case-tracking")}
                icon={<AssessmentOutlinedIcon style={{ color: "#017C57" }} />}
              >
                Daily Case Tracking Report
              </MenuItem>
            </SubMenu>
          )}
          {roleId !== 1 && roleId !== 2 && (
            <div
              style={{
                width: "188px",
                height: "1px",
                marginTop: "16px",
                marginLeft: "16px",
                backgroundColor: "#E4E4E4",
              }}
            ></div>
          )}
          &nbsp;
          {navElement["Approve Cases"] !== undefined && (
            <>
              <MenuItem
                icon={
                  <CheckCircleOutlineOutlinedIcon
                    style={{ color: "#017C57" }}
                  />
                }
              >
                Approve Cases
              </MenuItem>
              <div
                style={{
                  width: "188px",
                  height: "1px",
                  marginTop: "16px",
                  marginLeft: "16px",
                  backgroundColor: "#E4E4E4",
                }}
              ></div>
            </>
          )}
          &nbsp;
          <p
            style={{
              marginLeft: "20px",
              color: "#797979",
              fontSize: "11px",
              lineHeight: "24px",
            }}
          >
            SCHEDULES
          </p>
          {navElement["Meeting Schedule"] !== undefined && (
            <MenuItem
              icon={<CalendarTodayOutlinedIcon style={{ color: "#017C57" }} />}
            >
              {" "}
              Meeting Schedule{" "}
              <div className="text-[10px] text-[#017C57]">Coming Soon</div>
            </MenuItem>
          )}
          {navElement["Meeting List"] !== undefined && (
            <MenuItem icon={<ListOutlinedIcon style={{ color: "#017C57" }} />}>
              Meeting List{" "}
              <div className="text-[10px] text-[#017C57]">Coming Soon</div>
            </MenuItem>
          )}
          {navElement["Schedule Call"] !== undefined && (
            <MenuItem
              icon={<PhoneInTalkOutlinedIcon style={{ color: "#017C57" }} />}
            >
              Scheduled Calls{" "}
              <div className="text-[10px] text-[#017C57]">Coming Soon</div>
            </MenuItem>
          )}
          {navElement["Schedule Chat"] !== undefined && (
            <>
              <MenuItem
                icon={
                  <MarkChatUnreadOutlinedIcon style={{ color: "#017C57" }} />
                }
              >
                Scheduled Chats{" "}
                <div className="text-[10px] text-[#017C57]">Coming Soon</div>
              </MenuItem>
              <div
                style={{
                  width: "188px",
                  height: "1px",
                  marginTop: "16px",
                  marginLeft: "16px",
                  backgroundColor: "#E4E4E4",
                }}
              ></div>
            </>
          )}
          &nbsp;
          <p
            style={{
              marginLeft: "20px",
              color: "#797979",
              fontSize: "11px",
              lineHeight: "24px",
            }}
          >
            CONTACT
          </p>
          {navElement["Contact Settlemed"] !== undefined && (
            <>
              <MenuItem
                icon={<PhoneInTalkOutlinedIcon style={{ color: "#017C57" }} />}
              >
                Contact Settlemed{" "}
                <div className="text-[10px] text-[#017C57]">Coming Soon</div>
              </MenuItem>
              <div
                style={{
                  width: "188px",
                  height: "1px",
                  height: "1px",
                  marginTop: "16px",
                  marginLeft: "16px",
                  backgroundColor: "#E4E4E4",
                }}
              ></div>
            </>
          )}
          &nbsp;
          {navElement["Notifications"] !== undefined && (
            <MenuItem
              icon={
                <NotificationsNoneOutlinedIcon style={{ color: "#017C57" }} />
              }
            >
              Notifications{" "}
              <div className="text-[10px] text-[#017C57]">Coming Soon</div>
            </MenuItem>
          )}
        </Menu>
      </Sidebar>
    </div>
  );
};
export default Sidebarorg;
