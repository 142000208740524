import { useState } from "react";

const Challenges = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
    setTimeout(() => setIsHovered(false), 1500);
  };
  const handleMouseLeave = () => {
    setIsHovered(true);
    setTimeout(() => setIsHovered(false), 1500);
  };
  return (
    <div className="max-w-[1600px]  px-[3%]">
      <div className="mx-auto pt-[30px] pb-[111px]">
        <div className="flex items-start">
          <div className="max-w-[50vw] text-[5rem] leading-[90%] -tracking-[0.08em] uppercase">
            Challenges
          </div>
          <div className="relative h-[747px]">
            <div
              style={{
                opacity: 1,
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d",
              }}
            >
              <img
                src="https://cdn.prod.website-files.com/65c73cab42d05747958d759e/65c8f1bf23ab16435f760e5d_under%20settled%20claim.svg"
                loading="lazy"
                alt=""
                className={`translate-x-[430px] translate-y-[503px] rotate-[75deg] ${
                  isHovered ? "animate-ballDrop" : ""
                }`}
              />
            </div>
            <div
              className="mb-[90px]"
              style={{
                opacity: 1,
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d",
              }}
            >
              <img
                src="https://cdn.prod.website-files.com/65c73cab42d05747958d759e/66dc4fb68e6e09e9b272acdf_COMMUNICATION%20GAP.png"
                loading="lazy"
                sizes="(max-width: 479px) 200.00001525878906px, 330.998291015625px"
                alt=""
                width="331"
                srcSet="https://cdn.prod.website-files.com/65c73cab42d05747958d759e/66dc4fb68e6e09e9b272acdf_COMMUNICATION%20GAP-p-500.png 500w, https://cdn.prod.website-files.com/65c73cab42d05747958d759e/66dc4fb68e6e09e9b272acdf_COMMUNICATION%20GAP-p-800.png 800w, https://cdn.prod.website-files.com/65c73cab42d05747958d759e/66dc4fb68e6e09e9b272acdf_COMMUNICATION%20GAP.png 1168w"
                className={`rotate-[0deg]   ${
                  isHovered ? "animate-ballDrop" : ""
                }`}
              />
            </div>
            <div
              className="challenges-content-box claim-rejection"
              style={{
                opacity: 1,
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d",
              }}
            >
              <img
                src="https://cdn.prod.website-files.com/65c73cab42d05747958d759e/65c8f1be879f622b3cacb26f_claim-rejection.svg"
                loading="lazy"
                alt=""
                className={`translate-x-[259px] translate-y-[111px] rotate-[37deg] ${
                  isHovered ? "animate-ballDrop" : ""
                }`}
              />
            </div>
            <div
              className="challenges-content-box late-submsion"
              style={{
                opacity: 1,
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d",
              }}
            >
              <img
                src="https://cdn.prod.website-files.com/65c73cab42d05747958d759e/65c8f1bf325d4879779d707e_documentation%20error.svg"
                loading="lazy"
                alt=""
                className={`-translate-x-[47px] -translate-y-[94%] -rotate-[27deg] ${
                  isHovered ? "animate-ballDrop" : ""
                }`}
              />
            </div>
            <div
              className="mb-[20px]"
              style={{
                opacity: 1,
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d",
              }}
            >
              <img
                src="https://cdn.prod.website-files.com/65c73cab42d05747958d759e/65c8f1beb980349c04bb1f21_approval-delay.svg"
                loading="lazy"
                alt=""
                className={`-rotate-[4deg]   ${
                  isHovered ? "animate-ballDrop" : ""
                }`}
              />
            </div>
            <div
              className="max-w-[600px] mb-[7px]"
              style={{
                opacity: 1,
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d",
              }}
            >
              <img
                src="https://cdn.prod.website-files.com/65c73cab42d05747958d759e/65c8f1bec9ba448924249e6d_claim-processing.svg"
                loading="lazy"
                alt=""
                className={`${isHovered ? "animate-ballDrop" : ""}`}
              />
            </div>
            <div
              style={{
                opacity: 1,
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d",
              }}
            >
              <img
                src="https://cdn.prod.website-files.com/65c73cab42d05747958d759e/65c8f1bf9228b8c85847e25d_revenue%20leakage.svg"
                loading="lazy"
                alt=""
                className={` ${isHovered ? "animate-ballDrop" : ""}`}
              />
            </div>
            <div
              className="mt-[7px] pl-[70px]"
              style={{
                opacity: 1,
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d",
              }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <img
                src="https://cdn.prod.website-files.com/65c73cab42d05747958d759e/65c8f1bfef0c29c770ac1411_late-claim.svg"
                loading="lazy"
                alt=""
                className={` ${isHovered ? "animate-ballDrop" : ""}`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Challenges;
