import axios from "axios";
import { getAuthorizationToken } from "../config/user";
const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

function getSurveyQuestions() {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/survey-questions`,
  });
}
function saveSurveyDetails(data) {
  return axios({
    method: "post",
    url: `${REACT_APP_API_URL}/submit-survey-detail`,
    data: data,
  });
}
export const SurveyServices = {
  getSurveyQuestions,
  saveSurveyDetails,
};
