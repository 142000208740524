import React, { useEffect, useState, useRef } from "react";
import "./Managequery.scss";
import Select from "react-select";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import { RpaServices } from "../services/RpaServices";
import { CircularProgress } from "@mui/material";
import CustomPagination from "../utils/paginationUtils";
import { HospitalServices } from "../services/HospitalServices";
import SearchIcon from "../../components/assets/searchIcon.svg";
import { useFormik } from "formik";
import EditStatusModal from "../dashboard/cases/editCases/EditStatusModal";

import { logOut } from "../config/user";

function Managequery() {
  const [queryData, setQueryData] = useState([]);
  const [totalPages, setTotalPages] = useState(10);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [hospitalInput, setHospitalInput] = useState("");
  const [selectedQuery, setSelectedQuery] = useState(null);
  const [caseId, setCaseId] = useState(null);
  const [hospitalList, setHospitalList] = useState([]);
  const [hospitalId, setHospitalId] = useState("");
  let searchTimeRef = useRef();
  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validate: (values) => {
      const errors = {};
      if (!values.name) {
        errors.name = "Hospital name is required";
      }
      return errors;
    },
    onSubmit: (values) => {
      console.log("Selected Hospital:", values.name);
    },
  });

  const fetchQueries = async () => {
    setIsLoading(true);
    try {
      const response = await RpaServices.manageQueries(
        "ACTIVE",
        page,
        10,
        searchKey,
        hospitalId
      );
      console.log("API Response:", response.data);

      if (Array.isArray(response.data.queries)) {
        const formattedData = response.data.queries.map((query) => ({
          id: query.id,
          patientName: query.case.patient.patientName,
          hospitalId: query?.case?.hospital?.code,
          hospitalName: query.case.hospital.name,
          pmjayId: query.case.pmjayId,
          status: query.status,
          queryMessage: query.queryMessage,
          caseId: query?.case?.id,
        }));
        setQueryData(formattedData);
        setTotalPages(response.data.totalPages || 1);
      } else {
        console.error("Queries data is not an array:", response.data.queries);
      }
    } catch (error) {
      console.error("Error fetching queries:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getHospitalList = async () => {
    try {
      const response = await HospitalServices.gethospitals("", 1, 1500);
      setHospitalList(response.data.list);
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
  };

  useEffect(() => {
    fetchQueries();
  }, [page, searchKey, hospitalInput, hospitalId]);

  useEffect(() => {
    getHospitalList();
  }, []);

  const handleProceedClick = (query) => {
    if (query?.caseId) {
      setSelectedQuery(query);
      setCaseId(query?.caseId);
    } else {
      console.error("Case ID is missing in the selected query:", query);
      alert("The selected query does not have a valid case ID.");
    }
  };
  console.log(hospitalId);
  const handleSearch = (e) => {
    if (searchTimeRef.current) {
      clearInterval(searchTimeRef.current);
    }
    searchTimeRef.current = setTimeout(() => {
      setSearchKey(e.target.value);
    }, 500);
  };
  return (
    <div className="manage-querybox">
      <span className="pathline">
        <WidgetsOutlinedIcon style={{ marginTop: "3px" }} /> &nbsp;
        &nbsp;/&nbsp; &nbsp; Dashboard &nbsp; &nbsp;/ &nbsp; &nbsp; Manage query
      </span>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Select
            name="hospitalId"
            isClearable
            options={hospitalList}
            getOptionLabel={(options) => options["name"]}
            getOptionValue={(options) => options["id"]}
            onChange={(item) => {
              setPage(1);
              item ? setHospitalId(item.id) : setHospitalId("");
            }}
            value={hospitalList.filter((item) => item.id === hospitalId)}
            placeholder="FILTER HOSPITAL WISE"
          />
        </div>
        <div
          class="flex gap-2 px-2 py-2 rounded-[8px] bg-white "
          style={{ border: "1px solid rgb(228, 228, 228)" }}
        >
          <img src={SearchIcon} alt="search-icon" />
          <input
            type="text"
            className="outline-none border-none"
            onChange={handleSearch}
            placeholder="Search Anything"
          />
        </div>
      </div>
      <hr className="my-8" />
      <div className="query-list">
        <div className="w-full">
          {isLoading ? (
            <div className="w-full h-full flex justify-center items-center">
              <CircularProgress />
            </div>
          ) : queryData.length > 0 ? (
            <section className="mt-1">
              <div className="grid grid-cols-5 md:grid-cols-[2fr_2fr_2fr_2fr_4fr_1fr] text-[#191919] font-semibold px-8 py-1 gap-2 justify-end">
                <div className="text-[18px]">Patient Name</div>
                <div className="text-[18px]">Hospital ID</div>
                <div className="text-[18px]">Hospital Name</div>
                <div className="text-[18px]">PMJAY ID</div>
                <div className="text-[18px]">Query Message</div>
                <div className="text-[18px] text-right">Action</div>
              </div>

              {queryData.map((query, idx) => (
                <article
                  key={idx}
                  className={`py-6 px-8 rounded-[16px] ${
                    idx % 2 === 0 ? "bg-white" : "bg-[#F9F9F9]"
                  }`}
                >
                  <div className="grid grid-cols-5 md:grid-cols-[2fr_2fr_2fr_2fr_4fr_1fr] text-[16px] gap-2">
                    <div className="text-[#797979] text-[18px]">
                      {query.patientName}
                    </div>
                    <div className="text-[#797979] text-[18px]">
                      {query.hospitalId}
                    </div>
                    <div className="text-[#797979] text-[18px]">
                      {query.hospitalName}
                    </div>
                    <div
                      className="text-[#797979] text-[18px] overflow-hidden whitespace-nowrap text-ellipsis"
                      title={query.pmjayId}
                    >
                      {query.pmjayId}
                    </div>
                    <div className="text-[#797979] text-[18px]">
                      {query.queryMessage.split("\n").map(
                        (msg, index) =>
                          msg && (
                            <div key={index}>
                              {index + 1}) {msg}
                            </div>
                          )
                      )}
                    </div>

                    <div className="text-[#797979] text-[18px] text-right">
                      <button
                        className="proceed-button"
                        onClick={() => handleProceedClick(query)}
                      >
                        Proceed
                      </button>
                    </div>
                  </div>
                  {selectedQuery?.id === query.id && (
                    <div className="modal-overlay">
                      <div className="modal-content">
                        <EditStatusModal
                          setEditStatus={setSelectedQuery}
                          caseId={caseId}
                          queryId={selectedQuery?.id}
                        />
                      </div>
                    </div>
                  )}
                </article>
              ))}
            </section>
          ) : (
            <div className="text-center font-semibold mt-8">
              No active Records found
            </div>
          )}
        </div>
      </div>

      <div className="flex justify-center mt-4">
        <CustomPagination
          page={page}
          setPage={setPage}
          totalPages={totalPages}
        />
      </div>
    </div>
  );
}

export default Managequery;
