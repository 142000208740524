import { useState, useEffect, useRef } from "react";
import { PreinitiateServices } from "../../../services/PreinitiateServices";
import { AuthServices } from "../../../services/AuthServices";
import { logOut } from "../../../config/user";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { CircularProgress } from "@mui/material";
import UploadAdmissionReferenceDoc from "./UploadAdmissionReferenceDoc";
import CloseIcon from "../../../assets/hospitalCloseIcon.svg";
import HospitalDelIcon from "../../../assets/hospitalDelete.svg";
import RightArrow from "../../../assets/rightArrowWhite.svg";
import PdfIcon from "../../../assets/pdfIcon.png";
import BlurScreen from "../../../assets/blur_screen.png";
import ReuploadImageIcon from "../../../assets/reUploadImageIcon.png";
import { CaseServices } from "../../../services/CaseServices";
import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
const HospitalReferenceDocument = ({ setOpen, selectedCase, AllCases }) => {
  const [referenceDocuments, setReferenceDocuments] = useState(null);
  const [groupedReferenceDocuments, setGroupedReferenceDocuments] =
    useState(null);
  const [uploadAdmission, setUploadAdmission] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [description, setDescription] = useState("");
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [pdfDocument, setPdfDocument] = useState(null);
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [images, setImages] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);
  const [documentType, setDocumentType] = useState("ADMISSIONDOCUMENT");
  const [isLoading, setIsLoading] = useState(false);

  const [reUploading, setReuploading] = useState(false);
  // let description = "";
  const fileInputRef = useRef();
  const reUploadFileRef = useRef();
  const handleReUploadButtonClick = () => {
    reUploadFileRef.current.click();
  };
  const handleClose = async () => {
    await AllCases();
    setOpen(false);
  };
  const getDocumentsData = async () => {
    setIsLoading(true);

    try {
      const response = await PreinitiateServices.getReferenceDocuments(
        selectedCase.id,
        documentType
      );
      console.log("response", response.data);
      let tempData = response.data;
      let modifiedData = [];
      let groupedDocs = [];
      let rejectedDocCount = 0;
      console.log("temp data:", tempData);
      for (let doc of tempData) {
        if (doc.parentId) {
          if (groupedDocs.length === 0) {
            groupedDocs.push(doc);
            rejectedDocCount =
              doc?.status === "REJECTED"
                ? rejectedDocCount + 1
                : rejectedDocCount;
          } else {
            if (doc.parentId === groupedDocs[0].parentId) {
              groupedDocs.push(doc);
              rejectedDocCount =
                doc?.status === "REJECTED"
                  ? rejectedDocCount + 1
                  : rejectedDocCount;
            } else {
              modifiedData.push({
                parentId: groupedDocs[0].parentId,
                docs: groupedDocs,
                rejectedDocCount: rejectedDocCount,
              });
              groupedDocs = [];

              groupedDocs.push(doc);
              rejectedDocCount = doc?.status === "REJECTED" ? 1 : 0;
            }
          }
        } else {
          rejectedDocCount = doc?.status === "REJECTED" ? 1 : 0;
          modifiedData.push({
            parentId: null,
            docs: [doc],
            rejectedDocCount: rejectedDocCount,
          });
        }
      }

      // Ensure to add remaining grouped docs
      if (groupedDocs.length > 0) {
        modifiedData.push({
          parentId: groupedDocs[0].parentId,
          docs: groupedDocs,
          rejectedDocCount: rejectedDocCount,
        });
      }
      console.log("modifiedData:", modifiedData);
      setReferenceDocuments(response.data);
      setGroupedReferenceDocuments(modifiedData);
    } catch (error) {
      window.alert(error);
    }
    setIsLoading(false);
  };
  //This is called to delete a selected document
  const deleteDocument = async (docId) => {
    try {
      const response = await PreinitiateServices.deleteReferenceDocument(docId);

      getDocumentsData();
    } catch (error) {
      window.alert(error);
    }
  };

  async function uploadEnhancementOrClaimDoc(event) {
    setProcessing(true);
    try {
      const file = event.target.files[0];
      const fileType = file.type.split("/")[0];
      if (fileType === "application" && file.name.endsWith(".pdf")) {
        setDescription(event.target.files[0].name.slice(0, -4));
        // description = event.target.files[0].name.slice(0, -4);
        const uri = URL.createObjectURL(file);
        const _PDF_DOC = await pdfjs.getDocument(uri).promise;
        setPdfDocument(_PDF_DOC);
        console.log("pdf doc:", _PDF_DOC);
      } else if (fileType === "image") {
        const imageBlob = URL.createObjectURL(file);
        setImages([...images, { isSelected: true, documentUrl: imageBlob }]);
        setSelectedCount((prev) => prev + 1);
        setProcessing(false);
      }
    } catch (error) {
      alert(error.message);
    }
  }
  //Method to get the image list from the uploaded pdf document
  async function getImageList() {
    const imagesList = [...images];
    const canvas = document.createElement("canvas");
    canvas.setAttribute("class", "canv");
    try {
      for (let i = 1; i <= pdfDocument.numPages; i++) {
        const page = await pdfDocument.getPage(i);
        const viewport = page.getViewport({ scale: 3 });
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        const renderContext = {
          canvasContext: canvas.getContext("2d"),
          viewport: viewport,
        };

        await page.render(renderContext).promise;
        canvas.toBlob((blob) => {
          if (blob) {
            const blobUrl = URL.createObjectURL(blob);
            imagesList.push({ isSelected: true, documentUrl: blobUrl });
            setSelectedCount((prev) => prev + 1);
            if (i === pdfDocument.numPages) {
              setImages([...imagesList]);
            }
          }
        }, "image/jpeg");
      }
    } catch (error) {
      console.error("Error getting image list:", error);
    }
    setSelectedCount(imagesList.length);
    setProcessing(false);
  }
  //This method  to save the newly uploaded document's url to settlemed database
  const addDocument = async (docUrl) => {
    if (docUrl) {
      const sequence =
        referenceDocuments.length > 0
          ? referenceDocuments[referenceDocuments.length - 1].sequence + 1
          : 1; //here we are generating sequence for the newly added document
      const modifiedData = docUrl?.map((url, idx) => {
        return {
          parentId: `${description?.slice(0, 4)}${new Date().getTime()}${
            referenceDocuments.length > 0
              ? referenceDocuments[referenceDocuments.length - 1].sequence + 1
              : 1 + docUrl.length
          }${docUrl.length}`,
          caseId: selectedCase.id,
          documentUrl: url,
          sequence: sequence + idx,
          description: `${description}_${idx}`,
          documentType: documentType,
        };
      });
      try {
        await PreinitiateServices.addReferenceDocuments(modifiedData);
        setPercentage(100);
        setTimeout(() => {
          window.alert("Documents Added Successfully");
        }, 200);

        await getDocumentsData();
        await AllCases();
        // setOpen(false);
      } catch (error) {
        window.alert(error);
      }
      setDescription(null);
      // description = null;
    }
    setPercentage(0);
    setUploadStatus(false);
  };
  //Method to upload the image list's blob data to s3 bucket
  const uploadImages = async () => {
    if (selectedCount > 0) {
      setUploadStatus(true);
      let data = new FormData();

      for (let index = 0; index < images.length; index++) {
        if (images[index].isSelected) {
          let image = images[index].documentUrl;
          if (!(image instanceof Blob)) {
            const response = await fetch(image);
            image = await response.blob();
          }
          data.append("images", image, `${description + "_" + index}.jpeg`);
        }
      }
      try {
        let res = await AuthServices.uploadPdfToImage(data, setPercentage);
        console.log("Image data resonse from apis", res.data.urls);
        //   setDocUrl(res?.data?.urls);
        await addDocument(res?.data?.urls);
        setPdfDocument(null);
        setImages([]);
        // window.alert("Image uploaded successfully");
      } catch (error) {
        console.error("Upload failed:", error);
      }
    } else {
      window.alert("Please Select Atleast One Image");
    }
  };
  const reUploadImage = async (e) => {
    // setReuploading(true);
    try {
      let data = new FormData();
      data.append("image", e.target.files[0]);
      let res = await AuthServices.uploadImage(data);

      await PreinitiateServices.updateReferenceDocument(
        {
          documentUrl: res?.data?.url,
        },
        selectedDocument.id
      );
      window.alert("Image uploaded successfully");
      await getDocumentsData();
      // setReuploadedDocUrl(res?.data?.url);
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
    setReuploading(false);
  };
  useEffect(() => {
    getDocumentsData();
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [documentType]);
  useEffect(() => {
    pdfDocument && getImageList();
  }, [pdfDocument]);
  useEffect(() => {
    images.length > 0 && uploadImages();
  }, [images]);
  return (
    <div className="fixed top-0 left-0 h-screen w-screen flex justify-center items-center bg-[#01716780] z-10 ">
      <div
        style={{ backgroundImage: `url(${BlurScreen})` }}
        className="relative h-[85%] 2xl:h-[90%] w-[90%] 2xl:w-[95%] bg-center bg-cover rounded-[30px] p-7 2xl:p-10"
      >
        <div
          className="absolute top-3 right-4 cursor-pointer"
          onClick={handleClose}
        >
          <img src={CloseIcon} alt="close-icon" height={53} width={52} />
        </div>
        <div className="text-[30px] 2xl:text-[50px] font-medium leading-[40px] 2xl:leading-[65.26px] -tracking-[7%]">
          Reference Document
        </div>
        <div className="h-[80%] bg-[#FFFFFF80] rounded-[20px] p-[24px] 2xl:p-[30px]">
          <div className="flex items-center gap-5">
            <div className="text-[24px] 2xl:text-[32px] leading-[40px] 2xl:leading-[65.26px] -tracking-[7%]">
              Uploaded Documents
            </div>
            <div className="flex items-center gap-2">
              <div
                style={{ border: "1px solid #017C71" }}
                className={` px-6  py-2 2xl:py-4 text-[12px] 2xl:text-[16px] rounded-full cursor-pointer transition-all duration-300 ease-in-out ${
                  documentType.includes("ADMISSIONDOCUMENT")
                    ? "text-[#017C71] bg-white"
                    : "text-white bg-[#017C71]"
                }`}
                onClick={() => setDocumentType("ADMISSIONDOCUMENT")}
              >
                Admission
              </div>
              <div
                style={{ border: "1px solid #017C71" }}
                className={` px-6  py-2 2xl:py-4 text-[12px] 2xl:text-[16px] rounded-full cursor-pointer transition-all duration-300 ease-in-out ${
                  documentType.includes("ENHANCEMENTDOCUMENT")
                    ? "text-[#017C71] bg-white"
                    : "text-white bg-[#017C71]"
                }`}
                onClick={() => setDocumentType("ENHANCEMENTDOCUMENT")}
              >
                Enhancement
              </div>
              <div
                style={{ border: "1px solid #017C71" }}
                className={` px-6  py-2 2xl:py-4 text-[12px] 2xl:text-[16px] rounded-full cursor-pointer transition-all duration-300 ease-in-out ${
                  documentType.includes("ONDISCHARGEDOCUMENT")
                    ? "text-[#017C71] bg-white"
                    : "text-white bg-[#017C71]"
                }`}
                onClick={() => setDocumentType("ONDISCHARGEDOCUMENT")}
              >
                Claim
              </div>
            </div>
          </div>
          <div style={{ border: "1px solid #FFFFFF" }} className="my-3" />
          <div className="h-[85%] 2xl:h-[90%] overflow-y-scroll">
            <div className="h-full flex items-start  gap-[10px] flex-wrap">
              {groupedReferenceDocuments?.length > 0 ? (
                groupedReferenceDocuments?.map((document, idx) => {
                  return (
                    <div
                      key={idx}
                      style={{ border: "1px solid #E4E4E4" }}
                      className={`relative rounded-[17px] p-1 ${
                        document.rejectedDocCount > 0
                          ? "bg-[#FFA9A9]"
                          : "bg-white"
                      }`}
                    >
                      <div className="flex gap-2 pl-2">
                        {idx + 1}{" "}
                        {document.rejectedDocCount > 0 && (
                          <div
                            className="max-w-[230px] text-sm overflow-hidden text-ellipsis whitespace-nowrap "
                            title={document.docs[0]?.rejectionReason}
                          >
                            {document.docs[0]?.rejectionReason}
                          </div>
                        )}
                      </div>
                      {document?.parentId && document.docs.length > 1 ? (
                        <div>
                          <img
                            src={PdfIcon}
                            alt=""
                            width={248}
                            height={249}
                            style={{ border: "1px solid #E4E4E4" }}
                            className="object-fit rounded-[15px]"
                            onClick={() => setSelectedPdf(idx)}
                          />
                        </div>
                      ) : (
                        <img
                          src={document?.docs[0].documentUrl}
                          alt=""
                          width={248}
                          height={249}
                          style={{ border: "1px solid #E4E4E4" }}
                          className="object-fit rounded-[15px]"
                        />
                      )}
                      {document?.docs[0]?.status === "REJECTED" &&
                        document.docs.length === 1 && (
                          <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center bg-transparent">
                            {reUploading &&
                            selectedDocument.id === document?.docs[0]?.id ? (
                              <CircularProgress color="inherit" />
                            ) : (
                              <img
                                src={ReuploadImageIcon}
                                alt="re-upload-image"
                                className="cursor-pointer"
                                onClick={() => {
                                  setSelectedDocument(document.docs[0]);
                                  handleReUploadButtonClick();
                                }}
                              />
                            )}
                          </div>
                        )}
                      <div className="absolute bottom-0.5 left-0  w-full ">
                        <div
                          style={{
                            background: "#00000060",
                            backdropFilter: "blur(3.6976048946380615px)",
                          }}
                          className="flex w-[94%] justify-between items-center rounded-[14px] py-2 mx-auto px-1"
                        >
                          <div className="text-sm text-white leading-[18.27px] -tracking-[3%] text-ellipsis whitespace-nowrap overflow-hidden">
                            {document?.docs[0]?.description?.slice(0, -2)}
                          </div>
                          <div className="text-white text-sm mr-2">
                            {/* <div
                              className="cursor-pointer"
                              onClick={() => deleteDocument(document.id)}
                            >
                              <img src={HospitalDelIcon} alt="" />
                            </div> */}
                            {document?.docs && (
                              <div>
                                {document?.docs?.length}
                                {/* <img
                                src={RightArrow}
                                alt="arrow-icon"
                                height={12}
                                width={12}
                                className="-rotate-45 cursor-pointer"
                              /> */}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="h-full w-full flex justify-center items-center text-2xl font-bold">
                  No Documents Uploaded Yet
                </div>
              )}
            </div>
          </div>

          {processing ? (
            <div className="flex justify-end mt-4 2xl:mt-5">
              <CircularProgress />
            </div>
          ) : uploadStatus ? (
            <div className="w-[95%] flex items-center mt-1">
              <div
                style={{ width: `${percentage}%`, backgroundColor: "#017C71" }}
                className="rounded-full h-[20px] mr-1"
              />
              <div>{percentage}%</div>
            </div>
          ) : (
            <div className="flex text-xs 2xl:text-sm text-white justify-end items-center gap-2 mt-4 2xl:mt-5">
              {documentType.toLowerCase().includes("admission") ? (
                <div
                  className="flex gap-2 items-center bg-[#017C71] py-2 px-4 rounded-full cursor-pointer"
                  onClick={() => setUploadAdmission(true)}
                >
                  Upload Admission{" "}
                  <FileUploadOutlinedIcon
                    style={{ width: "16px", height: "16px" }}
                    color="#FFFFFF"
                  />
                </div>
              ) : documentType.toLowerCase().includes("enhancement") ? (
                <div
                  className="flex gap-2 items-center bg-[#017C71] py-2 px-4 rounded-full cursor-pointer"
                  onClick={() => {
                    // setDocumentType("ENHANCEMENTDOCUMENT");
                    fileInputRef.current.value = null;
                    fileInputRef.current.click();
                  }}
                >
                  Upload Enhancement{" "}
                  <FileUploadOutlinedIcon
                    style={{ width: "16px", height: "16px" }}
                    color="#FFFFFF"
                  />
                </div>
              ) : documentType.toLowerCase().includes("discharge") ? (
                <div
                  className="flex gap-2 items-center bg-[#017C71] py-2 px-4 rounded-full cursor-pointer"
                  onClick={() => {
                    // setDocumentType("ONDISCHARGEDOCUMENT");
                    fileInputRef.current.value = null;
                    fileInputRef.current.click();
                  }}
                >
                  Upload Claim{" "}
                  <FileUploadOutlinedIcon
                    style={{ width: "16px", height: "16px" }}
                    color="#FFFFFF"
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
      </div>

      {uploadAdmission && (
        <UploadAdmissionReferenceDoc
          selectedCase={selectedCase}
          setOpen={setUploadAdmission}
          referenceDocuments={referenceDocuments}
          getDocumentsData={getDocumentsData}
          AllCases={AllCases}
        />
      )}
      {selectedPdf && (
        <div className="fixed h-screen w-screen top-0 left-0 flex justify-center items-center bg-[#017C5180]">
          {" "}
          <div className="relative w-[50%] h-[80%] bg-white rounded-[20px] p-5">
            <div
              className="absolute top-2 right-3 cursor-pointer"
              onClick={() => setSelectedPdf(null)}
            >
              <img src={CloseIcon} alt="close-icon" height={53} width={52} />
            </div>
            <div className="overflow-y-scroll flex flex-wrap gap-2   h-[90%] mt-12">
              {groupedReferenceDocuments[selectedPdf]?.docs?.map((doc, idx) => {
                return (
                  <div
                    key={idx}
                    style={{
                      border: "1px solid #e4e4e4",
                      width: "292px",
                      height: "300px",
                      marginTop: "5px",
                    }}
                    className="relative rounded-[15px]"
                  >
                    <img
                      src={doc?.documentUrl}
                      alt={`${doc?.description}`}
                      className="w-[100%] h-[100%] rounded-[15px]"
                    />

                    {doc.status === "REJECTED" && (
                      <div className="absolute h-[100%] w-[100%] top-0 left-0 flex items-center justify-center bg-[#FFD6DB80] rounded-[15px]">
                        {reUploading === idx ? (
                          <CircularProgress />
                        ) : (
                          <img
                            src={ReuploadImageIcon}
                            alt="re-upload-image"
                            className="cursor-pointer"
                            onClick={() => {
                              setSelectedDocument(doc);
                              setReuploading(idx);
                              handleReUploadButtonClick(idx);
                            }}
                          />
                        )}
                      </div>
                    )}
                    {doc.status === "REJECTED" && (
                      <div
                        className="absolute w-[100%] top-1 left-1 overflow-hidden whitespace-nowrap text-ellipsis"
                        title={doc.rejectionReason}
                      >
                        {doc.rejectionReason}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
      <input
        type="file"
        onChange={uploadEnhancementOrClaimDoc}
        style={{ display: "none" }}
        ref={fileInputRef}
        accept=".pdf,.png,.jpg,.jpeg"
      />
      <input
        type="file"
        onChange={(e) => reUploadImage(e)}
        style={{ display: "none" }}
        ref={reUploadFileRef}
        accept=".jpg,.jpeg,.png,"
      />
    </div>
  );
};

export default HospitalReferenceDocument;
