import { useState, useEffect } from "react";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { mapSubscriptionPlan } from "../services/SubscriptionServices";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SubscriptionCard from "./SubscriptionCard";
import { getSubscriptionPlans } from "../services/SubscriptionServices";
import RazorpayPayment from "../payment/RazorpayPayment";
import {
  addHospitalDetails,
  addHospitalUser,
} from "../../components/services/HospitalServices";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Select from "react-select";
import { Country, State, City } from "country-state-city";
import * as Yup from "yup";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { logOut } from "../config/user";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Addhospital.scss";
import UploadImage from "./UploadImage";
import CrossIcon from "../assets/crossIcon.svg";

const hospitalNameRegex =
  /^[A-Za-z0-9\s&/]+(?:[A-Za-z0-9\s&/]*[A-Za-z]+[A-Za-z0-9\s&/]*|[A-Za-z0-9\s&/]*\d+[A-Za-z0-9\s&/]*)(?<![@#%^*()])$/;
const hospitalDetailsValidationSchema = Yup.object({
  name: Yup.string()
    .matches(
      hospitalNameRegex,
      "Hospital name must contain a mix of letters, numbers, spaces, and allowed special characters (&, /), but no invalid characters (@, #, %, ^, *, (, ))"
    )
    .required("Required"),
  country: Yup.string().required("Required"),
  state: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  pincode: Yup.string().required("Required"),
  businessDocumentType: Yup.string().required("Required"),
  businessDocumentUrl: Yup.string().required("Required"),
  businessDocumentNumber: Yup.string().required("Required"),
  pincode: Yup.string()
    .length(6, "Pincode must be exactly 6 digits")
    .required("Pincode is required")
    .matches(/^[0-9]+$/, "Pincode must contain only digits"),

  businessDocumentNumber: Yup.string()
    .matches(
      /^(?![\d]+$)(?![^\w\d]+$)[\w\d]{8,20}$/,
      "Business Document Number must contain a mix of letters and numbers, no spaces, and between 8 and 20 characters"
    )
    .required("Business Document Number is required"),
  tmsVersion: Yup.string().required("TMS Version Required"),
});
const hospitalCredentialsSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email format")
    .matches(/[a-zA-Z]/, "Email must contain at least one letter")
    .matches(/\.com$/, "Email must end with '.com'")
    .required("Email is required"),
});

const subscriptionDetailsValidationSchema = Yup.object({});

const AddHospital = () => {
  const [subscriptionPlans, setSubscriptionPlans] = useState();
  const [selectedDuration, setSelectedDuration] = useState("quarterly");
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [filteredPlan, setFilteredPlan] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [documentType, setDocumentType] = useState(null);
  const [hospitalId, setHospitalId] = useState("");
  const [hospitalName, setHospitalName] = useState("");
  const [showSubscriptionForm, setShowSubscriptionForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedHospital, setSelectedHospital] = useState({});
  const [subscriptionIds, setSubscriptionIds] = useState([]);
  const [orderId, setOrderId] = useState(null);
  const [amountToPay, setAmountToPay] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState({
    name: "",
    phone: "",
    email: "",
  });
  const [payment, setPayment] = useState({
    subtotal: "",
    discount: "",
    total: "",
  });

  const documentTypeOptions = [
    { value: "PAN", label: "PAN" },
    { value: "HRC", label: "HRC" },
  ];

  // var settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 300,
  //   slidesToShow: 7,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   autoplaySpeed: 2000,
  //   arrows: false,
  //   variableWidth: true,
  // };
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    // responsive: [
    //   {
    //     breakpoint: 1024,
    //     settings: {
    //       slidesToShow: 3,
    //       slidesToScroll: 1,
    //       infinite: true,
    //       dots: true,
    //       autoplay: true,
    //       autoplaySpeed: 2000,
    //     },
    //   },
    //   {
    //     breakpoint: 600,
    //     settings: {
    //       slidesToShow: 2,
    //       slidesToScroll: 1,
    //       initialSlide: 2,
    //       autoplay: true,
    //       autoplaySpeed: 5000,
    //     },
    //   },
    //   {
    //     breakpoint: 480,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //       autoplay: true,
    //       autoplaySpeed: 5000,
    //     },
    //   },
    // ],
  };

  const SubscriptionPlans = async () => {
    try {
      const res = await getSubscriptionPlans();
      setSubscriptionPlans(res.data);
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
  };

  const handleSubscriptionMapping = async () => {
    setIsLoading(true);
    const data = {
      subscriptionIds: subscriptionIds,
      payment: payment,
      global: {
        couponCode: "",
        hospitalId: hospitalId,
      },
    };
    try {
      const response = await mapSubscriptionPlan(data);
      setOrderId(response.data.id);
      setAmountToPay(response.data.amount / 100);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      window.alert(error);
    }
  };
  const handlePaymentDetails = (name, value) => {
    setPaymentDetails((prev) => ({ ...prev, [name]: value }));
  };
  const handleSubmit = async (values) => {
    try {
      setHospitalName(values.name);
      const res = await addHospitalDetails(values);
      setHospitalId(res.data.hospitalId);
      window.alert("Hospital details added sucessfully");
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
  };
  const handleAddHospitalUser = async (values) => {
    values = { ...values, name: hospitalName, hospitalId: hospitalId };
    try {
      const res = addHospitalUser(values);
      window.alert("Hospital Added Successfully");
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
  };
  useEffect(() => {
    SubscriptionPlans();
    setCountryList(Country.getAllCountries());
  }, []);

  useEffect(() => {
    const filteredData = subscriptionPlans?.filter(
      (plan) => plan.planType.toLowerCase() === selectedDuration
    );
    setFilteredPlan(filteredData);
  }, [subscriptionPlans, selectedDuration]);

  useEffect(() => {
    setStateList(State?.getStatesOfCountry(selectedCountry?.isoCode));
  }, [selectedCountry]);

  useEffect(() => {
    setCityList(
      City.getCitiesOfState(selectedState?.countryCode, selectedState?.isoCode)
    );
  }, [selectedState]);
  useEffect(() => {
    if (selectedPlan) {
      setSubscriptionIds([selectedPlan.id]);
      setPayment({
        ...payment,
        subtotal: selectedPlan.totalWithGst,
        total: selectedPlan.totalWithGst,
      });
    }
  }, [selectedPlan]);

  return (
    <div
      style={{
        fontFamily: "Inter",
        padding: 32,
        marginTop: 93,
        width: "100%",
      }}
    >
      <span className="path_line">
        <WidgetsOutlinedIcon style={{ height: "24px" }} /> &nbsp; &nbsp;/ &nbsp;
        &nbsp; Profile &nbsp; &nbsp;/ &nbsp; &nbsp; Add Hospital
      </span>
      <div className="heading">Add Hospital</div>
      <hr
        style={{
          color: "#E7E7E7",
        }}
      />
      <div>
        <Accordion className="accordionbox" style={{ borderRadius: "16px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                whiteSpace: "nowrap",
              }}
            >
              <span className="personal-details">
                Hospital Details
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <Formik
              initialValues={{
                name: "",
                country: "",
                state: "",
                city: "",
                pincode: "",
                businessDocumentType: "",
                businessDocumentUrl: "",
                businessDocumentNumber: "",
                tmsVersion: "",
              }}
              // validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                handleSubmit(values);
                setSubmitting(false);
              }}
              validateOnChange={true}
              validateOnBlur={false}
              validationSchema={hospitalDetailsValidationSchema}
            >
              {({ isSubmitting, values, setFieldValue }) => (
                <Form>
                  <div className="case-form-container1">
                    <label htmlFor="name" className="label1">
                      <div className="text-content">Hospital Name</div>
                      <Field
                        type="text"
                        name="name"
                        className="input-field"
                        placeholder="Enter Hospital Name"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="field-error"
                      />
                    </label>
                    <label htmlFor="country" className="label1">
                      <div className="text-content">Country</div>
                      <Select
                        name="country"
                        options={Country.getAllCountries()}
                        getOptionLabel={(options) => {
                          return options["name"];
                        }}
                        getOptionValue={(options) => {
                          return options["name"];
                        }}
                        value={selectedCountry}
                        onChange={(item) => {
                          setSelectedCountry(item);
                          setFieldValue("country", item.name);
                        }}
                        placeholder="Select Country"
                      />
                      <ErrorMessage
                        name="country"
                        component="div"
                        className="field-error"
                      />
                    </label>
                  </div>
                  <div className="case-form-container1">
                    <label htmlFor="state" className="label1">
                      <div className="text-content">State</div>
                      <Select
                        name="state"
                        options={stateList}
                        getOptionLabel={(options) => {
                          return options["name"];
                        }}
                        getOptionValue={(options) => {
                          return options["name"];
                        }}
                        value={selectedState}
                        onChange={(item) => {
                          setSelectedState(item);
                          setFieldValue("state", item.name);
                        }}
                        placeholder="Select State"
                      />
                      <ErrorMessage
                        name="state"
                        component="div"
                        className="field-error"
                      />
                    </label>
                    <label htmlFor="city" className="label1">
                      <div className="text-content">City</div>
                      <Select
                        name="city"
                        options={cityList}
                        getOptionLabel={(options) => {
                          return options["name"];
                        }}
                        getOptionValue={(options) => {
                          return options["name"];
                        }}
                        value={selectedCity}
                        onChange={(item) => {
                          setSelectedCity(item);
                          setFieldValue("city", item.name);
                        }}
                        placeholder="Select City"
                      />
                      <ErrorMessage
                        name="city"
                        component="div"
                        className="field-error"
                      />
                    </label>
                  </div>
                  <div className="case-form-container1">
                    <label htmlFor="pincode" className="label1">
                      <div className="text-content">Pincode</div>
                      <Field
                        type="text"
                        name="pincode"
                        maxLength="6"
                        className="input-field"
                        placeholder="Enter Pincode"
                      />
                      <ErrorMessage
                        name="pincode"
                        component="div"
                        className="field-error"
                      />
                    </label>
                    <label htmlFor="businessDocumentType" className="label1">
                      <div className="text-content">Business Document Type</div>

                      <Select
                        name="businessDocumentType"
                        options={documentTypeOptions}
                        defaultValue={documentType}
                        onChange={(item) => {
                          setDocumentType(item.value);
                          setFieldValue("businessDocumentType", item.value);
                        }}
                        placeholder="Select Business Document Type"
                      />
                      <ErrorMessage
                        name="businessDocumentType"
                        component="div"
                        className="field-error"
                      />
                    </label>
                  </div>
                  <div className="case-form-container1">
                    <label htmlFor="businessDocumentUrl" className="label1">
                      <div className="text-content">
                        Upload Business Document
                      </div>
                      <UploadImage setFieldValue={setFieldValue} />
                      <ErrorMessage
                        name="businessDocumentUrl"
                        component="div"
                        className="field-error"
                      />
                    </label>

                    <label htmlFor="businessDocumentNumber" className="label1">
                      <div className="text-content">
                        Business Document Number
                      </div>
                      <Field
                        type="text"
                        name="businessDocumentNumber"
                        className="input-field"
                        placeholder="Enter Business Document Number"
                      />
                      <ErrorMessage
                        name="businessDocumentNumber"
                        component="div"
                        className="field-error"
                      />
                    </label>
                  </div>
                  <div className="case-form-container1">
                    <label htmlFor="tmsVersion" className="label1">
                      <div className="text-content">TMS Version</div>
                      <Select
                        name="tmsVersion"
                        options={[
                          { value: "1.0", label: "1.0" },
                          { value: "2.0", label: "2.0" },
                        ]}
                        getOptionLabel={(options) => {
                          return options["label"];
                        }}
                        getOptionValue={(options) => {
                          return options["value"];
                        }}
                        onChange={(item) => {
                          setFieldValue("tmsVersion", item.value);
                        }}
                        placeholder="Select TMS Version"
                      />

                      <ErrorMessage
                        name="tmsVersion"
                        component="div"
                        className="field-error"
                      />
                    </label>
                  </div>

                  <div className="flex justify-center w-full mt-8">
                    <button
                      type="submit"
                      style={{
                        width: "125px",
                        height: "34px",
                        borderRadius: "32px",
                        padding: "8px, 16px, 8px, 16px",
                        gap: "8px",
                        backgroundColor: "rgba(1, 124, 87, 1)",
                        color: "white",
                        border: "none",
                      }}
                    >
                      SAVE DETAILS
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </AccordionDetails>
        </Accordion>

        <Accordion className="accordionbox" sx={{ borderRadius: "16px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <text className="personal-details">Subscription Details </text>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="flex justify-center cursor-pointer">
              <div
                className="grid grid-cols-2 w-[220px]  text-sm font-semibold text-[#797979] rounded-full relative overflow-hidden"
                style={{
                  border: "1px solid #E4E4E4",
                }}
              >
                <div
                  className={`
                 ${selectedDuration === "quarterly" ? "text-white" : ""} 
              rounded-full py-2 px-4 transition-all  duration-7500 ease-in-out bg-transparent z-10 text-center `}
                  onClick={() => setSelectedDuration("quarterly")}
                >
                  QUARTERLY
                </div>
                <div
                  className={`${
                    selectedDuration === "yearly" ? "text-white" : ""
                  }  rounded-full py-2 px-4 transition-all   duration-500 ease-in-out bg-transparent z-10 text-center `}
                  onClick={() => setSelectedDuration("yearly")}
                >
                  YEARLY
                </div>
                <div
                  className={`absolute top-0 -left-[50%] h-full w-full bg-[#017C57] rounded-full ${
                    selectedDuration === "yearly"
                      ? "translate-x-[100%]"
                      : "translate-x-[0%]"
                  } transition-all duration-500`}
                ></div>
              </div>
            </div>
            <div className=" max-w-[1500px]">
              <section className="block max-w-[100%] slider-container mt-8">
                <Slider {...settings}>
                  {filteredPlan?.map((plan, idx) => {
                    return (
                      <div
                        key={idx}
                        className="block"
                        onClick={() => setSelectedPlan(plan)}
                      >
                        <SubscriptionCard
                          subscriptionPrice={plan.monthlyFees}
                          subscriptionType={plan.name}
                          planFeatures={plan.features}
                          isSelected={selectedPlan?.id === plan?.id}
                        />
                      </div>
                    );
                  })}
                </Slider>
              </section>
            </div>

            {selectedPlan && (
              <div className="flex justify-center mt-10">
                <div
                  className="w-fit rounded-full uppercase text-white text-sm font-semibold bg-[#017C57] px-4 py-2 cursor-pointer"
                  onClick={() => {
                    setShowSubscriptionForm(true);
                    handleSubscriptionMapping();
                  }}
                >
                  SAVE DETAILS
                </div>
              </div>
            )}
            {showSubscriptionForm && orderId && (
              <div className="fixed top-0 left-0 w-screen h-screen bg-[#F9F9F9B3] flex justify-center items-center z-30">
                <div
                  className="relative rounded-16px  w-[50%] bg-white rounded-[16px] p-5"
                  style={{
                    border: "1px solid #E4E4E4",
                  }}
                >
                  <div
                    className="absolute top-[10px] right-[10px] cursor-pointer"
                    onClick={() => setShowSubscriptionForm(false)}
                  >
                    <img src={CrossIcon} alt="cross-icon" />
                  </div>
                  {/* <Formik
                    initialValues={{
                      hospitalId: "",
                      subscriptionId: "",
                      transactionId: "",
                      paymentType: "",
                      amount: "",
                      transactionDate: "",
                    }}
                    // validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      handleSubscriptionMapping(values);
                      setSubmitting(false);
                    }}
                    validateOnChange={false}
                    validateOnBlur={false}
                  >
                    {({ isSubmitting, values, setFieldValue }) => (
                      <Form>
                        <div>
                          <div className="case-form-container1">
                            <label htmlFor="transactionId" className="label1">
                              <div className="text-content">Transaction ID</div>
                              <Field
                                type="text"
                                name="transactionId"
                                className="input-field"
                                placeholder="Enter Transaction ID"
                              />

                              <ErrorMessage
                                name="transactionId"
                                component="div"
                                className="field-error"
                              />
                            </label>

                            <label htmlFor="paymentType" className="label1">
                              <div className="text-content">Payment Type</div>
                              <Field
                                type="text"
                                name="paymentType"
                                className="input-field"
                                placeholder="Enter Payment Type"
                              />

                              <ErrorMessage
                                name="paymentType"
                                component="div"
                                className="field-error"
                              />
                            </label>
                          </div>
                          <div className="case-form-container1">
                            <label htmlFor="amount" className="label1">
                              <div className="text-content">Enter Amount</div>
                              <Field
                                type="number"
                                name="amount"
                                className="input-field"
                                placeholder="Enter Amount"
                              />

                              <ErrorMessage
                                name="amount"
                                component="div"
                                className="field-error"
                              />
                            </label>

                            <label htmlFor="transactionDate" className="label1">
                              <div className="text-content">
                                Enter Transaction Date
                              </div>
                              <Field
                                type="date"
                                name="transactionDate"
                                className="input-field"
                                placeholder="Enter Transaction Date"
                              />

                              <ErrorMessage
                                name="transactionDate"
                                component="div"
                                className="field-error"
                              />
                            </label>
                          </div>
                          <div className="flex justify-center w-full mt-8">
                            <button
                              type="submit"
                              style={{
                                width: "125px",
                                height: "34px",
                                borderRadius: "32px",
                                padding: "8px, 16px, 8px, 16px",
                                gap: "8px",
                                backgroundColor: "rgba(1, 124, 87, 1)",
                                color: "white",
                                border: "none",
                              }}
                            >
                              SAVE DETAILS
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik> */}

                  <div>
                    <div className="flex flex-wrap gap-y-4 mt-4">
                      <label htmlFor="amountToPay" className="w-[33%]">
                        <div className="font-semibold mb-2">Amount</div>
                        <input
                          name="amountToPay"
                          type="text"
                          value={`₹${amountToPay}`}
                          disabled
                          style={{ border: "1px solid #e4e4e4" }}
                          className="h-[40px] text-[18px] text-[#797979] rounded-[8px]
            pl-[12px] w-[90%]"
                        />
                      </label>
                      <label htmlFor="name" className="w-[33%]">
                        <div className="font-semibold mb-2">Enter Name</div>
                        <input
                          name="name"
                          type="text"
                          style={{ border: "1px solid #e4e4e4" }}
                          className="h-[40px] text-[18px] text-[#797979] rounded-[8px]
            pl-[12px] w-[90%]"
                          onChange={(e) =>
                            handlePaymentDetails("name", e.target.value)
                          }
                        />
                      </label>
                      <label htmlFor="phone" className="w-[33%]">
                        <div className="font-semibold mb-2">
                          Enter Phone Number
                        </div>
                        <input
                          name="phone"
                          type="text"
                          style={{ border: "1px solid #e4e4e4" }}
                          className="h-[40px] text-[18px] text-[#797979] rounded-[8px]
            pl-[12px] w-[90%]"
                          onChange={(e) =>
                            handlePaymentDetails("phone", e.target.value)
                          }
                        />
                      </label>
                      <label htmlFor="email" className="w-[33%]">
                        <div className="font-semibold mb-2">Enter Email</div>
                        <input
                          name="email"
                          type="email"
                          style={{ border: "1px solid #e4e4e4" }}
                          className="h-[40px] text-[18px] text-[#797979] rounded-[8px]
            pl-[12px] w-[90%]"
                          onChange={(e) =>
                            handlePaymentDetails("email", e.target.value)
                          }
                        />
                      </label>
                    </div>
                    {paymentDetails.name &&
                      paymentDetails.phone &&
                      paymentDetails.email && (
                        <div className="flex justify-center mt-4">
                          <RazorpayPayment
                            amount={amountToPay}
                            orderId={orderId}
                            paymentDetails={paymentDetails}
                          />
                        </div>
                      )}
                  </div>
                </div>
              </div>
            )}
          </AccordionDetails>
        </Accordion>

        <Accordion
          className="accordionbox"
          style={{
            borderRadius: "16px",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3-content"
            id="panel3-header"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <text className="personal-details">
                Credentials&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                &nbsp;&nbsp; &nbsp;&nbsp;{" "}
              </text>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <Formik
              initialValues={{
                name: hospitalName,
                roleName: "hospital",
                phone: "",
                email: "",
                hospitalId: hospitalId,
                password: "",
                tmsVersion: "",
              }}
              validationSchema={hospitalCredentialsSchema}
              onSubmit={(values, { setSubmitting }) => {
                handleAddHospitalUser(values);
                setSubmitting(false);
              }}
              validateOnChange={true}
              validateOnBlur={false}
            >
              {({ isSubmitting, values, setFieldValue }) => (
                <Form>
                  <div className="case-form-container1">
                    <label htmlFor="email" className="label1">
                      <div className="text-content">Email</div>
                      <Field
                        type="email"
                        name="email"
                        className="input-field"
                        placeholder="Enter Email Address"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="field-error"
                      />
                    </label>

                    <label htmlFor="password" className="label1">
                      <div className="text-content">Password</div>
                      <Field
                        type="password"
                        name="password"
                        className="input-field"
                        placeholder="Enter Password"
                      />

                      <ErrorMessage
                        name="businessDocumentNumber"
                        component="div"
                        className="field-error"
                      />
                    </label>
                  </div>
                  <div className="case-form-container1">
                    <label htmlFor="phone" className="label1">
                      <div className="text-content">Enter Phone Number</div>
                      <Field
                        type="number"
                        name="phone"
                        className="input-field"
                        placeholder="Enter Phone Number"
                      />

                      <ErrorMessage
                        name="phone"
                        component="div"
                        className="field-error"
                      />
                    </label>
                  </div>
                  <div className="flex justify-center w-full mt-8">
                    <button
                      type="submit"
                      style={{
                        width: "125px",
                        height: "34px",
                        borderRadius: "32px",
                        padding: "8px, 16px, 8px, 16px",
                        gap: "8px",
                        backgroundColor: "rgba(1, 124, 87, 1)",
                        color: "white",
                        border: "none",
                      }}
                    >
                      SAVE DETAILS
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default AddHospital;
