import React, { useState, useEffect } from "react";
import { CircularProgress, Menu, MenuItem, FormControl, InputLabel } from "@mui/material";
import Select from "react-select";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import Animation from "../assets/Animation.gif";
import warnicon from "../assets/warnorange.png";
import SearchIcon from "@mui/icons-material/Search";
import { RpaServices } from "../services/RpaServices";
import CustomPagination from "../utils/paginationUtils";
import "./ManageRpa.scss";
import Close from "@mui/icons-material/Close";
import { Switch } from "@mui/material";
import EditIcon from "../assets/editIcon.svg";
import RedAnimation from "../assets/RedAnimation.gif";
import UpdateApplication from "./UpdateApplication";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { HospitalServices } from "../services/HospitalServices";
import Checkbox from "@mui/material/Checkbox";

const ManageApplication = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [rpaApplicationList, setRpaApplicationList] = useState([]);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isEditPopupVisible, setIsEditPopupVisible] = useState(false);
  const [isStatusPopupVisible, setIsStatusPopupVisible] = useState(false);
  const [popupOpenForApplication, setPopUpOpenForApplication] = useState([]);
  const [reloadPage, setReloadPage] = useState(false);
  const [page, setPage] = useState(1);
  const [application, setApplication] = useState([]);
  const [selectedhospitals, setSelectedHospitals] = useState({});
  const [applicationId, setApplicationId] = useState([]);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [holdHospitalCount, setHoldHospitalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [selectedApp, setSelectedApp] = useState({
    applicationID: "",
    tmsVersion: "",
    hospitalID: "",
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRpaApplication, setSelectedRpaApplication] = useState(null);
  const [isTransferPopupVisible, setIsTransferPopupVisible] = useState(false);
  const [transferData, setTransferData] = useState([]); // Stores hospital and selected application ID for transfer

  // Fetch hospital list for transfer popup
  const [hospitalList, setHospitalList] = useState([]);

  const fetchHospitalList = async () => {
    try {
      const response = await HospitalServices.gethospitals("", 1, 1500);
      setHospitalList(response.data.list);
    } catch (error) {
      console.error("Error fetching hospital list:", error);
    }
  };

  useEffect(() => {
    fetchHospitalList();
  }, []);

  const handleCheckboxChange = (app) => {
    setSelectedApp({
      applicationID: app.applicationID,
      tmsVersion: app.tmsVersion,
      hospitalID: app.hospitalID,
    });
  };

  const [formData, setFormData] = useState({
    userName: "",
    password: "",
    status: "ACTIVE",
    ownerName: "",
    ownerMobileNum: "",
    systemDetails: {
      systemId: "",
      workingHours: [],
    }
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "systemId") {
      setFormData((prev) => ({
        ...prev,
        systemDetails: {
          ...prev.systemDetails,
          systemId: value,
        },
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleAddTimeSlot = () => {
    setFormData((prev) => ({
      ...prev,
      systemDetails: {
        ...prev.systemDetails,
        workingHours: [
          ...prev.systemDetails.workingHours,
          { day: "", from: "", to: "" },
        ],
      },
    }));
  };
  
  const handleRemoveTimeSlot = (index) => {
    const updatedWorkingHours = formData.systemDetails.workingHours.filter((_, i) => i !== index);
    setFormData((prev) => ({
      ...prev,
      systemDetails: {
        ...prev.systemDetails,
        workingHours: updatedWorkingHours,
      },
    }));
  };
  
  const handleTimeSlotChange = (index, field, value) => {
    const updatedWorkingHours = [...formData.systemDetails.workingHours];
    updatedWorkingHours[index][field] = value;
    setFormData((prev) => ({
      ...prev,
      systemDetails: {
        ...prev.systemDetails,
        workingHours: updatedWorkingHours,
      },
    }));
  };

  const handleButtonClick = () => {
    setPopupOpen(!isPopupOpen);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
  
    const submissionData = {
      ...formData,
      systemDetails: {
        systemId: formData.systemDetails.systemId,
        workingHours: formData.systemDetails.workingHours,
      },
    };
  
    try {
      let response;
      if (selectedApplication) {
        // If editing, call the update API
        submissionData.applicationId = selectedApplication.applicationId;
        response = await RpaServices.updateRpaDeviceDetails(submissionData);
      } else {
        // If adding, call the submit API
        response = await RpaServices.handleSubmitApplication([submissionData]);
      }
  
      if (response?.status === 201) {
        alert(selectedApplication ? "Application updated successfully!" : "Device registered successfully!");
        setPopupOpen(false);
        setFormData({
          userName: "",
          password: "",
          status: "ACTIVE",
          ownerName: "",
          ownerMobileNum: "",
          systemDetails: {
            systemId: "",
            workingHours: [],
          },
        });
        setSelectedApplication(null); // Clear the selected application after update
        setReloadPage((prev) => !prev); // Refresh the page
      } else {
        alert(response?.data?.message || "Operation failed. Please try again.");
      }
    } catch (error) {
      console.error("API Error Details:", error);
      alert("Error while performing operation: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const getApplicationIdList = async () => {
    try {
      setIsLoading(true);
      const response = await RpaServices.manageApplicationId();
      console.log("Response is", response?.data);
      if (response?.data?.applications) {
        setRpaApplicationList(response.data.applications);
        setTotalPages(response.data.totalPages || 1);
      } else {
        setRpaApplicationList([]);
      }
    } catch (error) {
      console.error("Error fetching application data:", error);
      setRpaApplicationList([]);
    } finally {
      setIsLoading(false);
      setReloadPage(false);
    }
  };

  const getHoldHospitalCount = async () => {
    try {
      const response = await RpaServices.getHoldHospital();
      const count = response.data.count;
      setHoldHospitalCount(count);
      console.log("Hold Hospital Count:", count);
    } catch (error) {
      console.error("Error fetching hold hospital count:", error);
    }
  };

  useEffect(() => {
    getHoldHospitalCount();
  }, []);

  useEffect(() => {
    getApplicationIdList();
  }, [page, reloadPage]);

  const handleStatusChange = async (application, status) => {
    const updatedStatus = application?.status === "ACTIVE" ? "INACTIVE" : "ACTIVE";
    const updatedData = {
      applicationId: application.applicationId,
      status: updatedStatus,
    };
    try {
      const response = await RpaServices.updateRpaDeviceDetails(updatedData);
      console.log(response.status, response.data);
      if (response?.status === 201) {
        setReloadPage((prev) => !prev);
        console.log("Status updated", response.data);
      }
    } catch (error) {
      console.error("Error while changing status:", error);
      alert("Error while changing status", error);
    }
  };

  const handleUpdateRpa = async (applications) => {
    if (!Array.isArray(applications)) {
      console.error("handleUpdateRpa expected an array but got:", applications);
      return;
    }

    const updatedDataArray = applications.map((application) => ({
      hospitalId: application.hospitalId,
      applicationId: application.applicationId,
      tmsVersion: application.tmsVersion,
    }));

    try {
      const response = await RpaServices.updateRpaHospital(updatedDataArray);
      console.log(response.status, response.data);

      if (response?.status === 201) {
        setReloadPage((prev) => !prev);
        console.log("Status updated successfully", response.data);
      }
    } catch (error) {
      console.error("Error while changing status:", error);
      alert("Error while changing status. Please try again.");
    }
  };

  const getapplicationsForApplicationId = async (id) => {
    setIsPopupVisible(true);
    try {
      const response = await RpaServices.getHospitalsFromId(id);
      setPopUpOpenForApplication(response.data);
      console.log("applications for Application ID:", id, response);
    } catch (error) {
      console.error("Error fetching applications for application ID:", error);
    }
  };

  const getapplicationsTransferForApplicationId = async (id) => {
    try {
      const response = await RpaServices.getHospitalsFromId(id);
      setIsTransferPopupVisible(response.data);
      console.log("applications for Application ID:", id, response);
    } catch (error) {
      console.error("Error fetching applications for application ID:", error);
    }
  };

  const handleHospitalSelect = (id) => {
    setSelectedHospitals((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((hospitalId) => hospitalId !== id)
        : [...prevSelected, id]
    );
  };

  const handleApplicationChange = (index, value) => {
    setSelectedApplication((prev) => ({
      ...prev,
      [index]: value,
    }));
  };

  useEffect(() => {
    if (application) {
      handleUpdateRpa(application);
    }
  }, [application]);

  const handleMenuOpen = (event, application) => {
    setAnchorEl(event.currentTarget);
    setSelectedRpaApplication(application);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRpaApplication(null);
  };

  const handleEdit = () => {
    if (selectedRpaApplication) {
      setFormData({
        userName: selectedRpaApplication.userName,
        password: selectedRpaApplication.password,
        status: selectedRpaApplication.status,
        ownerName: selectedRpaApplication.ownerName,
        ownerMobileNum: selectedRpaApplication.ownerMobileNum,
        systemDetails: {
          systemId: selectedRpaApplication.systemDetails?.systemId || "",
          workingHours: selectedRpaApplication.systemDetails?.workingHours || [],
        },
      });
      setSelectedApplication(selectedRpaApplication);
      setPopupOpen(true);
    }
    handleMenuClose();
  };

  const generateTimeOptions = () => {
    const times = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const time = `${String(hour).padStart(2, "0")}:${String(minute).padStart(2, "0")}`;
        times.push({ value: time, label: time });
      }
    }
    return times;
  };

  const handleDelete = async () => {
    if (selectedRpaApplication) {
      const confirmDelete = window.confirm("Are you sure you want to delete this application?");
      if (confirmDelete) {
        try {
          const response = await RpaServices.handleDeleteApplication(selectedRpaApplication.applicationId);
          if (response?.status === 200) {
            alert("Application deleted successfully!");
            setReloadPage((prev) => !prev);
          } else {
            alert("Failed to delete application.");
          }
        } catch (error) {
          alert("Error deleting application: " + error.message);
        }
      }
    }
    handleMenuClose();
  };

  const handleTransfer = async() => {
    if (selectedRpaApplication) {
      setSelectedApplication(selectedRpaApplication);
      await getapplicationsTransferForApplicationId(
        selectedRpaApplication.applicationId
      )
    }
    handleMenuClose();
  };

  const handleTransferSubmit = async () => {
    try {
      const transferRequests = transferData.map((item) => ({
        hospitalId: item.hospital.id,
        applicationId: item.applicationId,
        tmsVersion : 1.0
      }));

      await RpaServices.updateRpaHospital(transferRequests);

      alert("Transfer completed successfully!");
      setIsTransferPopupVisible(false);
      setReloadPage((prev) => !prev); // Refresh the page
    } catch (error) {
      alert("Error during transfer: " + error.message);
    }
  };

  return (
    <div className="initiateadmission-box">
      <span className="path_line">
        <WidgetsOutlinedIcon style={{ height: "24px" }} /> &nbsp;/ &nbsp;
        Dashboard &nbsp;/ &nbsp; Manage application
      </span>

      <div className="flex justify-between items-center">
        <div className="heading">Manage application</div>

        <div className="flex items-center gap-2">
          <div
            style={{ border: "1px solid #E4E4E4" }}
            className="flex gap-2 px-2 py-2 rounded-[8px]"
          >
            <SearchIcon />
            <input
              type="text"
              className="outline-none border-0"
              placeholder="Search Anything"
            />
          </div>
          <div>
            <button
              className="text-sm text-[#017C57] font-semibold px-10 py-2 rounded-full bg-white border border-[#017C57]"
              onClick={handleButtonClick}
            >
              Add Application
            </button>
            {isPopupOpen && (
              <div className="fixed z-20 inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center">
                <div
                  className="bg-white p-6 rounded-lg shadow-lg relative"
                  style={{ width: "640px", marginTop: "50px" }}
                >
                  <div className="px-0 pt-0 pb-4 border-b-2 border-s-2 border-gray-100 flex justify-between">
                  <div className="left-10 top-6 cursor-pointer font-semibold text-[18px]">
                    {selectedApplication ? "Edit Application" : "Add Application"}
                  </div>
                    <div className="right-12 top-6 cursor-pointer">
                      <Close
                        className="absolute top-6 right-6 font-semibold text-xl hover:text-gray-700"
                        alt="close-icon"
                        onClick={() => {
                          handleButtonClick()
                          setSelectedApplication(null);
                        }}
                      />
                    </div>
                  </div>

                  <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                    <div>
                      <label className="block font-medium mb-1">
                        User Name
                      </label>
                      <input
                        type="text"
                        name="userName"
                        value={formData.userName}
                        onChange={handleInputChange}
                        className="w-full border border-gray-300 border-s rounded p-2 focus:outline-none focus:ring-2 focus:ring-[#017C57]"
                        placeholder="Enter your username"
                        required
                      />
                    </div>

                    <div>
                      <label className="block font-medium mb-1">Password</label>
                      <input
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleInputChange}
                        className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-[#017C57]"
                        placeholder="Enter your password"
                        required
                      />
                    </div>

                    <div>
                    <label className="block font-medium mb-1">Owner Name</label>
                    <input
                      type="text"
                      name="ownerName"
                      value={formData.ownerName}
                      onChange={handleInputChange}
                      className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-[#017C57]"
                      placeholder="Enter Owner Name"
                      required
                    />
                  </div>

                  <div>
                    <label className="block font-medium mb-1">Owner Mobile Number</label>
                    <input
                      type="text"
                      name="ownerMobileNum"
                      value={formData.ownerMobileNum}
                      onChange={handleInputChange}
                      className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-[#017C57]"
                      placeholder="Enter Owner Mobile Number"
                      required
                    />
                  </div>

                  <div>
                    <label className="block font-medium mb-1">Resource/System ID</label>
                    <input
                      type="text"
                      name="systemId"
                      value={formData.systemDetails.systemId}
                      onChange={handleInputChange}
                      className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-[#017C57]"
                      placeholder="Enter Resource/System ID"
                      required
                    />
                  </div>

                  <div>
                    <label className="block font-medium mb-1">Working Hours</label>
                    {formData.systemDetails.workingHours.map((detail, index) => (
                      <div key={index} className="flex gap-2 mb-2">
                        <Select
                          options={[
                            { value: "Sunday", label: "Sunday" },
                            { value: "Monday", label: "Monday" },
                            { value: "Tuesday", label: "Tuesday" },
                            { value: "Wednesday", label: "Wednesday" },
                            { value: "Thursday", label: "Thursday" },
                            { value: "Friday", label: "Friday" },
                            { value: "Saturday", label: "Saturday" },
                          ]}
                          value={{ value: detail.day, label: detail.day }}
                          className="w-48"
                          onChange={(selectedOption) =>
                            handleTimeSlotChange(index, "day", selectedOption.value)
                          }
                          placeholder="Select Day"
                        />
                        <Select
                          options={generateTimeOptions()}
                          value={{ value: detail.from, label: detail.from }}
                          onChange={(selectedOption) =>
                            handleTimeSlotChange(index, "from", selectedOption.value)
                          }
                          placeholder="From"
                          className="w-48"
                        />
                        <Select
                          options={generateTimeOptions()}
                          value={{ value: detail.to, label: detail.to }}
                          onChange={(selectedOption) =>
                            handleTimeSlotChange(index, "to", selectedOption.value)
                          }
                          placeholder="To"
                          className="w-48"
                        />
                        <button
                          type="button"
                          onClick={() => handleRemoveTimeSlot(index)}
                          className="text-red-500"
                        >
                          Remove
                        </button>
                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={handleAddTimeSlot}
                      className="text-sm text-[#017C57] font-semibold px-4 py-2 rounded-full bg-white border border-[#017C57]"
                    >
                      Add Time Slot
                    </button>
                  </div>

                    <button
                      type="submit"
                      className="text-sm text-white bg-[#017C57] py-2 px-4 mt-2 rounded-full border-none cursor-pointer"
                    >
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <hr style={{ color: "#E7E7E7", marginBottom: "20px" }} />

      <div className="w-full Second-container">
        <div className="flex justify-between">
          {isLoading ? (
            <div className="w-full h-[500px] flex justify-center items-center">
              <CircularProgress />
            </div>
          ) : rpaApplicationList.length > 0 ? (
            <section className="mt-1 w-full ">
              <div className="grid grid-cols-9 text-[#191919] font-semibold  gap-8">
                <div className="text-[18px]">Status</div>
                <div className="text-[18px]">Application <br/>Id</div>
                <div className="text-[18px]">Processed At</div>
                <div className="text-[18px]">System Id</div>
                <div className="text-[18px]">User Name</div>
                <div className="text-[18px]">Owner Name</div>
                <div className="text-[18px]">Phone no.</div>
                <div className="text-[18px]">Hospital(s)</div>
                <div className="text-[18px]">Action</div>
              </div>

              {rpaApplicationList?.length > 0 ? (
                rpaApplicationList.map((application, idx) => (
                  <article
                    key={idx}
                    className={`py-4 rounded-[16px] ${
                      idx % 2 === 0 ? "bg-gray" : "bg-[#F9F9F9]"
                    }`}
                  >
                    <div className="grid grid-cols-9 text-[#797979] text-[18px] gap-5">
                      <div className="mr-auto">
                        <div className="ml-4 relative group flex items-center">
                          <img
                            src={
                              application?.applicationLiveStatus === "Hold" ? warnicon : ( application?.applicationLiveStatus === "Active"
                                ? Animation
                                : RedAnimation)
                            }
                            alt="Status Animation"
                            className="w-6 h-6"
                          />
                          <span className="absolute left-8 bg-gray-900 text-white text-xs rounded-md px-2 py-1 opacity-0 group-hover:opacity-100 transition-opacity">
                            {application?.applicationLiveStatus ?? "NA"}
                          </span>
                        </div>
                      </div>

                      <div className="ml-0">
                        {application?.applicationId ?? "--"}
                      </div>

                      <div
                        className="overflow-hidden whitespace-nowrap text-ellipsis "
                        title={
                          application?.lastPorcessedRequest?.lastProcessedAt
                            ? new Date(
                                application.lastPorcessedRequest.lastProcessedAt
                              ).toLocaleString("en-GB", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                                hour12: true,
                              })
                            : "NA"
                        }
                      >
                        {application?.lastPorcessedRequest?.lastProcessedAt
                        ? (
                            <>
                              {new Date(application.lastPorcessedRequest.lastProcessedAt).toLocaleString("en-GB", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                              })}
                              <br />
                              {new Date(application.lastPorcessedRequest.lastProcessedAt).toLocaleString("en-GB", {
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                                hour12: true,
                              })}
                            </>
                          )
                        : "NA"}
                      </div>

                      <div className="ml-4">
                        {application?.systemDetails?.systemId ?? "NA"}
                      </div>

                      <div className="ml-10">
                        {application?.userName ?? "--"}
                      </div>

                      <div className="ml-10">
                        {application?.ownerName ?? "--"}
                      </div>

                      <div className="ml-10">
                       {application?.ownerMobileNum ?? "--"}
                      </div>

                      <div className="ml-10">
                        <span
                          className="hover-underline"
                          onClick={() =>
                            application?.applicationId &&
                            getapplicationsForApplicationId(
                              application.applicationId
                            )
                          }
                        >
                          {application?.mappedHospitals ?? "--"}
                        </span>
                      </div>

                      <div className="flex justify-center items-center">
                          <Switch
                                                    checked={application?.status === "ACTIVE"}
                                                    onChange={() => {
                                                      const newStatus = (application?.status === "ACTIVE" ? "INACTIVE" : "ACTIVE");
                                                      const statusText = (application?.status === "INACTIVE")
                                                        ? "activated"
                                                        : "deactivated";
                                                      if (
                                                        window.confirm(
                                                          `Are you sure you want to ${statusText} this Application?`
                                                        )
                                                      ) {
                                                        handleStatusChange(application, newStatus);
                                                      }
                                                    }}
                                                    inputProps={{ "aria-label": "toggle status" }}
                                                    style={{
                                                      color: (application?.status === "ACTIVE")
                                                        ? "#017C57"
                                                        : "#B0B0B0",
                                                    }}
                                                    sx={{
                                                      "& .MuiSwitch-thumb": {
                                                        backgroundColor: (application?.status === "ACTIVE")
                                                          ? "#017C57"
                                                          : "#D3D3D3",
                                                      },
                                                      "& .MuiSwitch-track": {
                                                        backgroundColor: (application?.status === "ACTIVE")
                                                          ? "#017C57"
                                                          : "#D3D3D3",
                                                      },
                                                    }}
                                                  />
                        <MoreVertIcon
                          onClick={(event) => handleMenuOpen(event, application)}
                          className="cursor-pointer"
                        />
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleMenuClose}
                        >
                          <MenuItem onClick={handleEdit}>Edit</MenuItem>
                          <MenuItem onClick={handleDelete}>Delete</MenuItem>
                          <MenuItem onClick={handleTransfer}>Hospital Remapping</MenuItem>
                        </Menu>
                      </div>
                    </div>
                  </article>
                ))
              ) : (
                <p className="text-center text-[#797979] py-4">
                  No applications available.
                </p>
              )}
            </section>
          ) : (
            <div className="w-full h-full flex justify-center items-center font-semibold text-xl text-[#191919]">
              No Data Found
            </div>
          )}
        </div>
      </div>

      {isPopupVisible && (
        <div className="popup-overlay">
          <div className="popup-container relative max-h-[92%] w-full bg-white rounded-[16px] p-6 xl:p-8 z-[1200] ">
            <div className="px-10 pt-7 pb-4 border-b-2 border-s-2 border-gray-100 flex justify-between border-solid">
              <div className="left-10 top-6 cursor-pointer font-semibold text-[18px]">Mapped hospital(s)</div>
              <div className="right-12 top-6 cursor-pointer">
                <Close
                  alt="close-icon"
                  onClick={() => {
                    setIsPopupVisible(false);
                  }}
                />
              </div>
            </div>

            {application.map((app) => (
              <div key={app.id} className="mt-4 flex items-center">
                <Checkbox
                  checked={!!selectedhospitals[app.applicationId]}
                  onChange={() => {
                    setSelectedHospitals((prev) => ({
                      ...prev,
                      [app.applicationId]: !prev[app.applicationId],
                    }));

                    alert(
                      `Hospital ${app.userName || "Unknown"} has been ${
                        selectedhospitals[app.applicationId]
                          ? "deselected"
                          : "selected"
                      }`
                    );
                  }}
                />
                <span>{app.userName || "--"}</span>
              </div>
            ))}

            {popupOpenForApplication?.hospitals &&
            popupOpenForApplication?.hospitals?.length > 0 ? (
              <table className="w-full text-left mt-4 ml-8">
                <thead>
                  <tr>
                    <th className="border p-2">Hospital Name</th>
                    <th className="border p-2">Admin Name</th>
                    <th className="border p-2">Phone Number</th>
                    <th className="border p-2">City</th>
                  </tr>
                </thead>
                <tbody>
                  {popupOpenForApplication.hospitals.map((hospital, index) => (
                    <tr key={index}>
                      <td className="border p-2">{hospital.name || "--"}</td>
                      <td className="border p-2">
                        {hospital.users && hospital.users.length > 0
                          ? hospital.users[0].fullName || "--"
                          : "--"}
                      </td>
                      <td className="border p-2">
                        {hospital.users && hospital.users.length > 0
                          ? hospital.users[0].phone || "--"
                          : "--"}
                      </td>
                      <td className="border p-2">{hospital.city || "--"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              (popupOpenForApplication?.hospitals &&
                popupOpenForApplication?.hospitals?.length == 0) ? 
              (<div className="text-gray-500 text-center mt-4">
                No hospital mapped
              </div>) : <div className="text-gray-500 text-center mt-4">
                Loading
              </div>
            )}
          </div>
        </div>
      )}

      {isTransferPopupVisible && (
        <div className="popup-overlay">
          <div className="popup-container relative max-h-[92%] w-full bg-white rounded-[16px] p-6 xl:p-8 z-[1200] ">
            <div className="px-10 pt-7 pb-4 border-b-2 border-s-2 border-gray-100 flex justify-between border-solid">
              <div className="left-10 top-6 cursor-pointer font-semibold text-[18px]">Hospital Remapping</div>
              <div className="right-12 top-6 cursor-pointer">
                <Close
                  alt="close-icon"
                  onClick={() => {
                    setIsTransferPopupVisible(false);
                  }}
                />
              </div>
            </div>

            <div className="mt-4">
              {isTransferPopupVisible?.hospitals?.map((hospital) => (
                <div key={hospital.id} className="pl-8 pr-8 flex items-center gap-4 mb-4">
                  <span className="min-w-44">{hospital?.name == "" ? "NA" : hospital?.name}</span>
                  <Select options={rpaApplicationList.map((applicationl) => ({
                            value: applicationl?.applicationId,
                            label: applicationl?.applicationId + "(" + applicationl?.userName + ")"
                      }))}
                    onChange={(selectedOption) => {
                      setTransferData((prev) => [
                        ...prev.filter((item) => item.hospital.id !== hospital.id),
                        { hospital, applicationId: selectedOption.value },
                      ]);
                    }}
                    placeholder="Select Application ID"
                  />
                </div>
              ))}
            </div>

            <div className="flex justify-end mt-4 px-8 py-4">
              <button
                className="text-sm text-white bg-[#017C57] py-2 px-4 rounded-full border-none cursor-pointer"
                onClick={handleTransferSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="flex justify-center mt-4">
        <CustomPagination
          page={page}
          setPage={setPage}
          totalPages={totalPages}
        />
      </div>
    </div>
  );
};

export default ManageApplication;