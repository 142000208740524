import { useState, useRef, useEffect } from "react";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { CircularProgress } from "@mui/material";
import { logOut } from "../config/user";
import downloadPdf from "../utils/downloadPdfUtil";
import { AuthServices } from "../services/AuthServices";
import ImagesGallery from "../dashboard/cases/preInitiateModal/ImagesGallery";
import FolderIcon from "../assets/folderImage.jpg";
import DownloadIcon from "../assets/downloadIcon.svg";
import DownloadIconGreen from "../assets/downloadIconGreen.svg";
import EyeIcon from "../assets/eyeIcon.svg";
import "./EditDocuments.scss";
import { pdfjs } from "react-pdf";
import jsPDF from "jspdf";
import imageCompression from "browser-image-compression";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const EditDocuments = () => {
  const [uploadStatus, setUploadStatus] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [images, setImages] = useState([]);
  const [pdfDocument, setPdfDocument] = useState(null);
  const [documentName, setDocumentName] = useState(null);

  const [percentage, setPercentage] = useState(0);
  const [netSpeed, setNetSpeed] = useState(0);
  const [elapsedTime, setElapsedTime] = useState(0); // State to store the elapsed time in seconds

  const [timer, setTimer] = useState(null); // State to store the timer interval
  const [selectAll, setSelectAll] = useState(true);
  const [selectNonDownloaded, setSelectNonDownloaded] = useState(false);
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const [openImagesGallery, setOpenImagesGallery] = useState(false);
  let startTime = 0;
  const fileInputRef = useRef(null);
  const dragDocument = useRef();
  const draggedOverDocument = useRef();

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setSelectNonDownloaded(false);
    const modifiedData = uploadedDocuments[selectedDoc]?.documentUrls?.map(
      (doc, idx) => {
        if (!selectAll) {
          return {
            ...doc,
            isSelected: true,
          };
        } else {
          return { ...doc, isSelected: false };
        }
      }
    );
    uploadedDocuments[selectedDoc].documentUrls = modifiedData;
    setUploadedDocuments(uploadedDocuments);
  };
  const handleSelectNonDownloaded = () => {
    setSelectNonDownloaded(!selectNonDownloaded);
    const modifiedData = uploadedDocuments[selectedDoc]?.documentUrls?.map(
      (doc, idx) => {
        if (!doc.isDownloaded) {
          if (selectNonDownloaded) {
            return {
              ...doc,
              isSelected: false,
            };
          } else {
            return {
              ...doc,
              isSelected: true,
            };
          }
        } else {
          return { ...doc, isSelected: false };
        }
      }
    );
    const totalSelected = modifiedData.filter((doc) => doc.isSelected);
    if (totalSelected.length === modifiedData.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
    uploadedDocuments[selectedDoc].documentUrls = modifiedData;
    setUploadedDocuments(uploadedDocuments);
  };
  const handleSelection = (idx) => {
    const modifiedData = uploadedDocuments[selectedDoc].documentUrls.map(
      (doc, index) => {
        if (idx === index) {
          return { ...doc, isSelected: !doc.isSelected };
        } else {
          return doc;
        }
      }
    );
    const totalSelected = modifiedData.filter((doc) => doc.isSelected);
    if (totalSelected.length === modifiedData.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
    uploadedDocuments[selectedDoc].documentUrls = modifiedData;
    setUploadedDocuments([...uploadedDocuments]);
  };
  const getTotalSelected = () => {
    const selectedData = uploadedDocuments[selectedDoc]?.documentUrls?.filter(
      (doc) => doc.isSelected
    );
    return selectedData?.length > 0 ? selectedData.length : 0;
  };
  const handleFolderSort = () => {
    let documentsClone = uploadedDocuments;
    if (dragDocument.current < draggedOverDocument.current) {
      documentsClone.splice(
        draggedOverDocument.current,
        0,
        documentsClone[dragDocument.current]
      );
      documentsClone.splice(dragDocument.current, 1);
    } else {
      documentsClone.splice(
        draggedOverDocument.current,
        0,
        documentsClone[dragDocument.current]
      );
      documentsClone.splice(dragDocument.current + 1, 1);
    }
    setUploadedDocuments(documentsClone);
  };
  const handleImagesSort = () => {
    let documentsClone = uploadedDocuments[selectedDoc].documentUrls;

    if (dragDocument.current < draggedOverDocument.current) {
      documentsClone.splice(
        draggedOverDocument.current,
        0,
        documentsClone[dragDocument.current]
      );
      documentsClone.splice(dragDocument.current, 1);
    } else {
      documentsClone.splice(
        draggedOverDocument.current,
        0,
        documentsClone[dragDocument.current]
      );
      documentsClone.splice(dragDocument.current + 1, 1);
    }
    let modifiedData = uploadedDocuments;
    modifiedData[selectedDoc].documentUrls = documentsClone;
    setUploadedDocuments([...modifiedData]);
  };

  //Method to upload the pdf doc
  async function uploadDocument(event) {
    setUploadStatus(true);
    startTime = performance.now(); // Capture the start time when upload starts
    setElapsedTime(0); // Reset elapsed time before starting the timer

    // Start the timer
    const intervalId = setInterval(() => {
      setElapsedTime((prev) => prev + 1); // Increase elapsed time by 1 second every second
    }, 1000); // 1000ms = 1 second

    setTimer(intervalId); // Store the intervalId to clear it later
    try {
      const file = event.target.files[0];
      const fileType = file.type.split("/")[0];
      if (fileType === "application" && file.name.endsWith(".pdf")) {
        setDocumentName(event.target.files[0].name.slice(0, -4));
        const uri = URL.createObjectURL(file);
        const _PDF_DOC = await pdfjs.getDocument(uri).promise;
        setPdfDocument(_PDF_DOC);
      } else if (fileType === "image") {
        const imageBlob = URL.createObjectURL(file);
        setImages([imageBlob]);
      }
    } catch (error) {
      alert(error.message);
    }
  }
  //Method to get the image list from the uploaded pdf document
  async function getImageList() {
    // const imagesList = [];
    // const canvas = document.createElement("canvas");
    // canvas.setAttribute("class", "canv");

    try {
      const imagesList = [];

      for (let i = 1; i <= pdfDocument.numPages; i++) {
        const page = await pdfDocument.getPage(i);
        const viewport = page.getViewport({ scale: 1 });

        // Create a new canvas for each page
        const canvas = document.createElement("canvas");
        canvas.width = viewport.width;
        canvas.height = viewport.height;

        const renderContext = {
          canvasContext: canvas.getContext("2d"),
          viewport: viewport,
        };

        // Render the page
        await page.render(renderContext).promise;

        // Convert to blob and push to imagesList
        const blobUrl = await new Promise((resolve) => {
          canvas.toBlob((blob) => {
            if (blob) {
              resolve(URL.createObjectURL(blob));
            } else {
              resolve(null);
            }
          }, "image/jpeg");
        });

        if (blobUrl) {
          imagesList.push(blobUrl);
        }

        console.log("Page processed:", i);
      }

      setImages(imagesList);
    } catch (error) {
      console.error("Error getting image list:", error);
    } finally {
    }
  }
  //Method to push the blob urls in uploadedDocuments array
  const uploadImages = async () => {
    let modifiedData = [];
    for (let index = 0; index < images.length; index++) {
      let image = images[index];
      modifiedData.push({
        documentUrl: image,
        isSelected: true,
        isDownloaded: false,
      });
    }
    try {
      let cloneData = [...uploadedDocuments];
      cloneData.push({ documentName, documentUrls: modifiedData });

      setUploadedDocuments([...cloneData]);
      setPdfDocument(null);
      setImages([]);
      // Capture the end time when uploadImages is complete
      const endTime = performance.now();
      const timeElapsed = ((endTime - startTime) / 1000).toFixed(2); // Convert to seconds
      setElapsedTime(timeElapsed); // Set the elapsed time in state
      window.alert("Image uploaded successfully");
    } catch (error) {
      console.error("Upload failed:", error);
    }

    // Clear the timer interval once upload is complete
    if (timer) {
      clearInterval(timer); // Stop the timer
    }
    setPercentage(0);
    setUploadStatus(false);
  };
  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };
  const compressImage = async (blob, blobUrls) => {
    const totalMaxSizeMB = 0.5; // Total allowed size for all images (500KB = 0.5MB)
    const maxSizeMBPerImage = totalMaxSizeMB / blobUrls.length; // Size per image
    console.log(
      "blobUrls length:",
      blobUrls.length,
      "max size per img",
      maxSizeMBPerImage
    );
    // Ensure the maximum size per image is at least a small value
    const options = {
      maxSizeMB: Math.max(maxSizeMBPerImage, 0.05),
      maxWidthOrHeight: 1024,
      useWebWorker: true,
    };

    return imageCompression(blob, options);
  };
  const generatePdf = async (compression, docUrls, docName) => {
    setIsDownloading(true);
    const doc = new jsPDF();
    const blobUrls = docUrls.filter((doc) => doc.isSelected);

    for (let i = 0; i < blobUrls.length; i++) {
      const blobUrl = blobUrls[i].documentUrl;
      // Fetch the blob URL as a Blob object
      const response = await fetch(blobUrl);
      const blob = await response.blob();
      // Create an image element to load the image and get its dimensions
      const img = new Image();
      const imgUrl = URL.createObjectURL(blob);
      img.src = imgUrl;
      // Wait for the image to load
      await new Promise((resolve) => {
        img.onload = resolve;
      });
      const imgWidth = img.naturalWidth;
      const imgHeight = img.naturalHeight;

      // If it's not the first image, add a new page
      if (i > 0) {
        doc.addPage();
      }
      let compressedBlob = blob;
      if (compression) {
        compressedBlob = await compressImage(blob, blobUrls);
      }
      // Convert the image (compressed or original) to base64
      const base64 = await blobToBase64(compressedBlob);

      // Set the page size to the image's natural size (for full-page images)
      const scale = Math.min(
        doc.internal.pageSize.width / imgWidth,
        doc.internal.pageSize.height / imgHeight
      );

      // Adjust the image's width and height based on the scale
      const imgScaledWidth = imgWidth * scale * 0.9;
      const imgScaledHeight = imgHeight * scale * 0.9;

      // Add the image to the PDF with the calculated dimensions
      doc.addImage(base64, "JPEG", 10, 10, imgScaledWidth, imgScaledHeight);
    }

    // Save the generated PDF with the document name
    doc.save(`${docName}.pdf`);
    if (selectedDoc) {
      const modifiedData = uploadedDocuments[selectedDoc]?.documentUrls?.map(
        (doc) => {
          if (doc.isSelected) {
            return {
              ...doc,
              isDownloaded: true,
            };
          }
          return doc;
        }
      );
      uploadedDocuments[selectedDoc].documentUrls = modifiedData;
    }
    setUploadedDocuments(uploadedDocuments);
    setIsDownloading(false);
  };

  const generateMergedPDF = () => {
    let imagesUrl = [];
    for (let doc of uploadedDocuments) {
      for (let image of doc?.documentUrls) {
        if (image.isSelected) {
          imagesUrl.push(image);
        }
      }
    }
    generatePdf(true, imagesUrl, "Merged Document");
  };
  const generateSinglePdf = (compression) => {
    generatePdf(
      compression,
      uploadedDocuments[selectedDoc]?.documentUrls,
      uploadedDocuments[selectedDoc]?.documentName
    );
  };
  //This use effect is to check or uncheck "Select All" checkbox when we select another document
  useEffect(() => {
    if (selectedDoc !== null) {
      const filteredData = uploadedDocuments[selectedDoc]?.documentUrls?.filter(
        (doc) => doc.isSelected
      );
      if (
        filteredData.length ===
        uploadedDocuments[selectedDoc]?.documentUrls?.length
      ) {
        setSelectAll(true);
      } else {
        setSelectAll(false);
      }
    }
  }, [selectedDoc]);
  useEffect(() => {
    pdfDocument && getImageList();
  }, [pdfDocument]);
  useEffect(() => {
    images.length > 0 && uploadImages();
  }, [images]);

  return (
    <div
      style={{
        fontFamily: "Inter",
        padding: 32,
        marginTop: 93,
        width: "100%",
      }}
    >
      <span className="path_line">
        <WidgetsOutlinedIcon style={{ height: "24px" }} /> &nbsp; &nbsp;/ &nbsp;
        &nbsp; Dashboard &nbsp; &nbsp;/ &nbsp; &nbsp; Manage Documents
      </span>
      <div className="heading">Manage Documents</div>
      <hr
        style={{ width: "100%", border: "1px solid rgba(228, 228, 228, 1)" }}
      />
      <div
        style={{ border: "1px solid #e4e4e4", marginTop: "24px" }}
        className="rounded-[16px] p-5 h-fit"
      >
        <div className="flex justify-between items-center">
          <div className="flex gap-1 items-end text-xl text-[#191919] font-semibold">
            Uploaded Documents{" "}
            <div className="text-sm font-semibold">
              {uploadedDocuments.length}
            </div>
          </div>
          <div className="flex gap-2 ">
            {uploadStatus ? (
              <div className="relative w-fit h-fit">
                <CircularProgress />
                <div className="absolute top-[25%] left-[25%] text-xs">
                  {elapsedTime}s
                </div>
              </div>
            ) : (
              <div
                className="flex items-center gap-2 text-white text-sm font-semibold bg-[#017C57] uppercase px-4 py-2 rounded-full cursor-pointer"
                onClick={() => handleButtonClick()}
              >
                <FileUploadOutlinedIcon fontSize="small" /> Upload Document
              </div>
            )}
            {isDownloading ? (
              <CircularProgress />
            ) : (
              uploadedDocuments.length > 0 && (
                <div
                  className="flex items-center gap-2 text-white text-sm font-semibold bg-[#017C57] uppercase px-4 py-2 rounded-full cursor-pointer"
                  onClick={generateMergedPDF}
                >
                  <img src={DownloadIcon} alt="download-icon " />
                  Merge and Download PDF
                </div>
              )
            )}
          </div>
          <input
            type="file"
            onChange={uploadDocument}
            style={{ display: "none" }}
            ref={fileInputRef}
          />
        </div>
        {/* {uploadStatus > 0 && (
          <div className="flex items-center gap-1">
            {" "}
            <div
              style={{ width: `${percentage}%` }}
              className="h-[15px] bg-blue-500 mt-2 rounded-full"
            />
            {percentage}%
          </div>
        )} */}
        {uploadedDocuments.length < 1 && (
          <div className="text-center text-xl font-semibold mt-8">
            {" "}
            Please Upload Documents
          </div>
        )}
        <div className="flex gap-4 flex-wrap my-8">
          {uploadedDocuments?.map((doc, idx) => {
            return (
              <div
                key={idx}
                style={{
                  border:
                    selectedDoc === idx
                      ? "1px solid #017C57"
                      : "1px solid #DEDEDE",
                }}
                className={`w-[200px] cursor-pointer rounded-[8px] py-2 px-4 ${
                  selectedDoc === idx ? "p-1 bg-[#f5f5f5] " : "p-1 bg-white"
                }`}
                onClick={() => setSelectedDoc(idx)}
                draggable
                onDragStart={() => (dragDocument.current = idx)}
                onDragEnter={() => (draggedOverDocument.current = idx)}
                onDragEnd={handleFolderSort}
              >
                <img
                  src={FolderIcon}
                  alt="folder-icon"
                  height={50}
                  width={50}
                />
                <div className="text-sm text-[#191919] font-medium my-1">
                  {doc.documentName}
                </div>
              </div>
            );
          })}
        </div>
        <div style={{ border: "1px solid #DEDEDE" }} className=" my-6" />
        {selectedDoc != null && (
          <div className="flex gap-2 items-center justify-end py-4">
            <div className="text-sm">
              Total Selected:{" "}
              <span className="font-semibold">{getTotalSelected()}</span>
            </div>
            <div className="flex items-center gap-1 text-sm ">
              <input
                type="checkbox"
                checked={selectAll}
                className="w-[18px] h-[18px]"
                onClick={handleSelectAll}
              />{" "}
              Select All
            </div>
            <div className="flex items-center gap-1 text-sm ">
              <input
                type="checkbox"
                checked={selectNonDownloaded}
                className="w-[18px] h-[18px]"
                onClick={handleSelectNonDownloaded}
              />{" "}
              Select Non-Downloaded
            </div>
            <div
              style={{ border: "1px solid #017C57" }}
              className=" w-fit flex item-center gap-2 text-xs text-[#017C57] font-semibold uppercase px-4 py-2 rounded-full cursor-pointer"
              onClick={() => {
                setOpenImagesGallery(true);
                setSelectedImage(0);
              }}
            >
              <img src={EyeIcon} alt="eye-icon" /> Gallery View
            </div>
          </div>
        )}
        {openImagesGallery && (
          <ImagesGallery
            setOpen={setOpenImagesGallery}
            data={uploadedDocuments[selectedDoc]?.documentUrls}
            selectedImage={selectedImage}
          />
        )}
        <div className="flex flex-wrap gap-3 mt-4">
          {selectedDoc !== null &&
            uploadedDocuments[selectedDoc]?.documentUrls?.map((image, idx) => {
              return (
                <div
                  key={idx}
                  style={{ border: "1px solid #D7D7D7" }}
                  className="relative h-[200px] w-[170px] flex items-center justify-center rounded-[8px] mx-1 cursor-pointer hovernow"
                  draggable
                  onDragStart={() => (dragDocument.current = idx)}
                  onDragEnter={() => (draggedOverDocument.current = idx)}
                  onDragEnd={handleImagesSort}
                >
                  <img
                    src={image.documentUrl}
                    alt="document-image"
                    height={180}
                    width={160}
                    onClick={() => {
                      setSelectedImage(idx);
                      setOpenImagesGallery(true);
                    }}
                  />
                  <div className="absolute top-1 right-1 w-[18px]">
                    <input
                      type="checkbox"
                      checked={image.isSelected}
                      onClick={() => handleSelection(idx)}
                    />
                  </div>
                </div>
              );
            })}
        </div>

        {uploadedDocuments.length > 0 && (
          <div className="flex justify-center mt-8">
            {isDownloading ? (
              <CircularProgress />
            ) : (
              selectedDoc !== null && (
                <div className="flex gap-2">
                  <div
                    style={{ border: "1px solid #017C57" }}
                    className="flex items-center gap-2 text-[#017C57] bg-white rounded-full px-4 py-2 cursor-pointer"
                    onClick={() => {
                      generateSinglePdf(true);
                    }}
                  >
                    <img src={DownloadIconGreen} alt="download-icon " />{" "}
                    Download Compressed PDF
                  </div>
                  <div
                    style={{ border: "1px solid #017C57" }}
                    className="flex items-center gap-2 text-[#017C57] bg-white rounded-full px-4 py-2 cursor-pointer"
                    onClick={() => {
                      generateSinglePdf(false);
                    }}
                  >
                    <img src={DownloadIconGreen} alt="download-icon " />{" "}
                    Download Original PDF
                  </div>
                </div>
              )
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default EditDocuments;
