import { useState, useEffect } from "react";
import { CaseServices } from "../../services/CaseServices";
import { RpaServices } from "../../services/RpaServices";
import { logOut401, logOut } from "../../config/user";
import { useNavigate } from "react-router-dom";
import CloseIcon from "../../assets/crossIcon.svg";
import { CircularProgress } from "@mui/material";
import { WbIncandescentTwoTone } from "@mui/icons-material";
const CaseStageUpdateConfirmation = ({
  setConfirmationPopup,
  updateStageMethodName,
  AllCases,
  getStageCaseReport,
  tmsVersion,
}) => {
  const [cas, setCas] = useState(updateStageMethodName?.case);
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const [tmsCaseId, setTmsCaseId] = useState(null);
  console.log("update stage method name:", updateStageMethodName);
  const updateStageMethods = {
    handleInitiateAdmission: async () => {
      setSubmitting(true);
      try {
        const res = await CaseServices.initiateAdmission(cas?.id);
        window.alert("Admission Initiated");
        setConfirmationPopup(false);
        await AllCases();
      } catch (error) {
        if (error?.response?.status === 401) {
          logOut();
        } else {
          window.alert(error?.response?.data?.error);
        }
      }
      setSubmitting(false);
    },
    handleInitiateOnTMS: async () => {
      setSubmitting(true);
      const postStageData = {
        hsaStage: cas?.submasterstagestatuses[0]?.commandCenterStage
          ?.toLowerCase()
          .includes("admission")
          ? "Admission Initiated on TMS"
          : cas?.submasterstagestatuses[0]?.commandCenterStage
              ?.toLowerCase()
              .includes("enhancement")
          ? "Enhancement Initiated on TMS"
          : cas?.submasterstagestatuses[0]?.commandCenterStage
              ?.toLowerCase()
              .includes("claim")
          ? "Claim Initiated on TMS"
          : "",
        commandCeneterStage: cas?.submasterstagestatuses[0]?.commandCenterStage
          ?.toLowerCase()
          .includes("admission")
          ? "Admission Initiated on TMS"
          : cas?.submasterstagestatuses[0]?.commandCenterStage
              ?.toLowerCase()
              .includes("enhancement")
          ? "Enhancement Initiated on TMS"
          : cas?.submasterstagestatuses[0]?.commandCenterStage
              ?.toLowerCase()
              .includes("claim")
          ? "Claim Initiated on TMS"
          : "",
        nextStepUser: cas?.submasterstagestatuses[0]?.commandCenterStage
          ?.toLowerCase()
          .includes("admission")
          ? "Internal team C"
          : cas?.submasterstagestatuses[0]?.commandCenterStage
              ?.toLowerCase()
              .includes("enhancement")
          ? "Internal Team E"
          : cas?.submasterstagestatuses[0]?.commandCenterStage
              ?.toLowerCase()
              .includes("claim")
          ? "Internal Team E"
          : "",
        caseDetails: [
          {
            query: "",
            queryResponse: "",
          },
        ],
      };
      try {
        const stageResponse = await CaseServices.updateSubMasterStage(
          cas.id,
          postStageData
        );
        window.alert(stageResponse.data.message);
        if (cas?.pmjayId || tmsCaseId) {
          await RpaServices.addRpaRequest({
            hospitalId: cas.hospitalId,
            caseStatus: "STATUS_UPDATE",
            requestType: cas?.submasterstagestatuses[0]?.commandCenterStage
              ?.toLowerCase()
              .includes("claim")
              ? "CLAIM"
              : "PREAUTH",
            caseId: cas.id,
            pmjayCaseId: cas?.pmjayId || tmsCaseId,
          });
        } else {
          window.alert("Provide Pmjay Case Id");
        }
        setConfirmationPopup(false);
        await AllCases();
        await getStageCaseReport();
      } catch (error) {
        if (error?.response?.status === 401) {
          logOut401();
          navigate("/");
        } else {
          window.alert(error);
        }
      }
      setSubmitting(false);
    },
    handleApprovedOnTMS: async () => {
      setSubmitting(true);
      const postStageData = {
        hsaStage: cas?.submasterstagestatuses[0]?.commandCenterStage
          ?.toLowerCase()
          .includes("admission")
          ? "Admission Approved on TMS"
          : cas?.submasterstagestatuses[0]?.commandCenterStage
              ?.toLowerCase()
              .includes("enhancement")
          ? "Enhancement Approved on TMS"
          : cas?.submasterstagestatuses[0]?.commandCenterStage
              ?.toLowerCase()
              .includes("claim")
          ? "Claim Approved on TMS"
          : "",
        commandCeneterStage: cas?.submasterstagestatuses[0]?.commandCenterStage
          ?.toLowerCase()
          .includes("admission")
          ? "Admission Approved on TMS"
          : cas?.submasterstagestatuses[0]?.commandCenterStage
              ?.toLowerCase()
              .includes("enhancement")
          ? "Enhancement Approved on TMS"
          : cas?.submasterstagestatuses[0]?.commandCenterStage
              ?.toLowerCase()
              .includes("claim")
          ? "Claim Approved on TMS"
          : "",
        nextStepUser: cas?.submasterstagestatuses[0]?.commandCenterStage
          ?.toLowerCase()
          .includes("admission")
          ? "Hospital Sub Admin (HSA)"
          : cas?.submasterstagestatuses[0]?.commandCenterStage
              ?.toLowerCase()
              .includes("enhancement")
          ? "Hospital Sub Admin (HSA)"
          : cas?.submasterstagestatuses[0]?.commandCenterStage
              ?.toLowerCase()
              .includes("claim")
          ? "Internal Team F"
          : "",
        caseDetails: [
          {
            query: "",
            queryResponse: "",
          },
        ],
      };
      try {
        const stageResponse = await CaseServices.updateSubMasterStage(
          cas.id,
          postStageData
        );
        window.alert(stageResponse.data.message);
        setConfirmationPopup(false);
        await AllCases();
        await getStageCaseReport();
      } catch (error) {
        if (error?.response?.status === 401) {
          logOut401();
          navigate("/");
        } else {
          window.alert(error);
        }
      }
      setSubmitting(false);
    },
    handleClaimPaidByBank: async () => {
      setSubmitting(true);
      const postStageData = {
        hsaStage: "Claim Paid",
        commandCeneterStage: "Claim Amount Paid by Bank on TMS",
        nextStepUser: "Hospital Sub Admin (HSA)",
        caseDetails: [
          {
            query: "",
            queryResponse: "",
          },
        ],
      };
      try {
        const stageResponse = await CaseServices.updateSubMasterStage(
          cas.id,
          postStageData
        );
        window.alert(stageResponse.data.message);
        setConfirmationPopup(false);
        await AllCases();
        await getStageCaseReport();
      } catch (error) {
        if (error?.response?.status === 401) {
          logOut401();
          navigate("/");
        } else {
          window.alert(error);
        }
      }
      setSubmitting(false);
    },
  };
  const handleTMSCaseIdSubmit = async () => {
    setSubmitting(true);
    if (tmsCaseId) {
      try {
        const response = CaseServices.updateCase(
          {
            pmjayId: tmsCaseId,
          },
          cas.id
        );
        console.log("tms id update response:", response.data);
        await updateStageMethods[updateStageMethodName?.method]();
      } catch (error) {
        window.alert(error);
      }
    } else {
      window.alert("Please Enter TMS Case ID before submitting.");
    }
    setSubmitting(false);
  };
  return (
    <div className="fixed top-0 left-0 bg-[#017C5780] w-screen h-screen flex justify-center items-center z-[1200]">
      <div className="relative h-[25%] w-[25%] bg-white flex flex-col justify-center items-center rounded-[16px]">
        <div
          className="absolute top-4 right-4 cursor-pointer"
          onClick={() => setConfirmationPopup(false)}
        >
          <img src={CloseIcon} alt="close-icon" />
        </div>
        {updateStageMethodName?.method
          ?.toLowerCase()
          ?.includes("handleinitiateontms") &&
        !cas?.pmjayId &&
        cas?.hospital?.tmsVersion === "1.0" ? (
          <div className="flex items-center gap-3 w-[90%]">
            {/* <div className="text-xl font-medium">
              Please enter TMS Case Id to initiate admission on TMS.
            </div> */}
            <div
              style={{ border: "1px solid #E4E4E4" }}
              className="p-2 rounded-[8px] w-[80%]"
            >
              <input
                type="text"
                value={tmsCaseId}
                placeholder="Enter TMS Case Id"
                className="w-full outline-none border-none"
                onChange={(e) => setTmsCaseId(e.target.value)}
              />
            </div>
            {submitting ? (
              <CircularProgress />
            ) : (
              <div
                className="text-sm text-white bg-[#017C57] font-semibold px-8 py-2 rounded-full cursor-pointer"
                onClick={handleTMSCaseIdSubmit}
              >
                SUBMIT
              </div>
            )}
          </div>
        ) : (
          <>
            <div className="text-xl text-center font-medium px-2">
              Are you sure you want to change current status.
            </div>
            <div className="flex justify-center items-center gap-2 mt-8">
              {submitting ? (
                <CircularProgress />
              ) : (
                <>
                  <div
                    className="w-[100px] text-white text-center bg-[#017C57] rounded-full cursor-pointer"
                    onClick={() => setConfirmationPopup(false)}
                  >
                    NO
                  </div>
                  <div
                    className="w-[100px] text-white text-center bg-[#017C57] rounded-full cursor-pointer"
                    onClick={() =>
                      updateStageMethods[updateStageMethodName?.method]()
                    }
                  >
                    YES
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CaseStageUpdateConfirmation;
