import axios from "axios";
import { getAuthorizationToken } from "../config/user";
const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

function getOtpList(page, limit, searchKey, hospitalId = "", smsDate) {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/get-tms-otps?page=${page}&limit=${limit}&mobileNo=${searchKey}&hospitalId=${hospitalId}&smsDate=${smsDate}`,
    headers: getAuthorizationToken(),
  });
}

export const OtpServices = {
  getOtpList,
};
